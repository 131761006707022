import { createTheme } from '@mui/material';


export const theme = createTheme({

    palette:{
        primary:{
            main: "#0000ff"
        },       
    },

    typography:{        

        h1:{
            fontSize:"3rem"
        },
        body1:{
            fontSize:"1rem"
        }
    },    

   

   
})