import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {Button, Typography, TextField, IconButton} from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';

// Checkbox:
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// Select:
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Radio Group:
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


function Mui2_FromHandlingWithTextFields() {

    const [inputs, setInputs] = useState( {name:"", email:"", password:"", subscribe: false, age:0, gender:"" } );

    const handleChange = (e) => {        
        setInputs( (prevState)=>({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(inputs);
    }

    return (
        <div>
             <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
            <Typography variant="h1" color="primary" sx={{textAlign:"center", marginBottom:"1em"}}>Form handling with all inputs fields</Typography>
            <Typography variant="h6" color="secondary" sx={{textAlign:"center", marginBottom:"2em"}}>Το τελικό object φαίνεται στην κονσόλα.</Typography>

            <form onSubmit={handleSubmit} style={{ display:'flex', flexDirection:'row' }}>
                <TextField name="name" value={inputs.name} type={'text'} placeholder='Name' variant='outlined' onChange={handleChange} />
                <TextField name="email" value={inputs.email} type={'email'} placeholder='Email' variant='standard' onChange={handleChange} />
                <TextField name="password" value={inputs.password} type={'password'} placeholder='password' variant='filled' onChange={handleChange} />

                <FormGroup>
                    <FormControlLabel 
                        control={<Checkbox  
                                                onChange={ () => setInputs( (prev) => ({
                                                    ...prev,
                                                    subscribe: !inputs.subscribe,
                                                })  ) } 
                                 />
                                } 
                        label="Subscribe to Newsletter" />
                </FormGroup>

                <FormControl>
                    <InputLabel>Age</InputLabel>

                    <Select
                        name="age"
                        value={inputs.age}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControl>
                    <FormLabel>Gender</FormLabel>

                    <RadioGroup
                        name="gender"                        
                        onChange={handleChange}               
                    >
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                    </RadioGroup>
                </FormControl>

                <Button type="submit">Click me</Button>                
            </form>

        </div>
    )
}

export default Mui2_FromHandlingWithTextFields