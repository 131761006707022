import React from "react";
import { Link } from "react-router-dom";
import Header1 from "../components/Header1";
import Header2 from "../components/Header2";

import { Typography, IconButton, Box } from "@mui/material";
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { TableHead, TableBody } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Header3_Middle from "../components/Header3_Middle";
import ScrollToTopButton from "../components/ScrollToTopButton";

import Flag_Germany from "../images/flag_Germany.jpg";
import Flag_English from "../images/flag_English.jpg";
import Flag_Hellas from "../images/flag_Hellas.jpg";

function Z205_Prepositions_aus() {
  return (
    <div>
      <IconButton component={Link} to="/">
        {" "}
        <HomeIcon />{" "}
      </IconButton>
      <Box className="global-box-header">
        <Header1 props="The preposition `aus`" />
        <Header2 props="Die Präposition `aus` +" />
      </Box>

      <Box className="global-box-not-header">
        <Typography
          variant="body1"
          color="primary"
          sx={{ fontWeight: "bold", marginTop: "3em" }}
          fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}
        >
          1. The first use of preposition "aus" indicates{" "}
          <spam class="green-text">origin</spam> or descent.
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "1em" }}
          elevation={7}
        >
          <Table>
            <TableRow>
              <TableCell
                sx={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: "bold",
                  backgroundColor: "#ffebee",
                }}
              >
                Mein Mann kommt <spam class="green-text">aus</spam> Deutschland.
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                sx={{
                  color: "blue",
                  textAlign: "center",
                  fontWeight: "bold",
                  backgroundColor: "#e3f2fd",
                }}
              >
                My man is coming <spam class="green-text">from Germany</spam>.
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>

        {/* <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          2.The second use of preposition "aus" indicates <spam class="green">material</spam> from which something is made.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                      
                    </TableCell>
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                      
                    </TableCell>
                </TableRow>                      

            </Table>
        </TableContainer> */}
      </Box>

      <ScrollToTopButton />
    </div>
  );
}

export default Z205_Prepositions_aus;
