import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableContainer, TableRow, TableCell, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z0037_Verbs_ListWithSeparableVerbs() {

  const verbs = [
    ["abheben", "to withdraw money", "κάνω ανάληψη"],
    ["einzahlen", "to deposit money", "καταθέτω"],
    ["abholen", "to pick up", "παραλαμβάνω, πηγαίνω να πάρω"],
    ["anfangen", "to begin, to start", "αρχίζω"],
    ["ankreuzen", "to mark with a cross", "σημειώνω με Χ, βάζω σταυρό"],
    ["annähen", "to sew", "ράβω"],
    ["anrufen bei + D)", "to call", "τηλεφωνώ"],
    ["aufpassen auf + A", "to pay attention", "προσέχω"],
    ["aufräumen", "to tidy", "τακτοποιώ, συγυρίζω"],
    ["aufstehen", "to get up", "σηκώνομαι όρθιος"],
    ["ausführen", "to take out an animal)", "βγάζω έξω τον σκύλο), εξάγω"],
    ["einkaufen", "to shop", "ψωνίζω"],
    ["einsammeln", "to collect", "μαζεύω"],
    ["eintreten", "to enter in", "μπαίνω εισέρχομαι κλοτσάω)"],
    ["kennenlernen", "to get to know sb", "γνωρίζω κάποιον για πρώτη φορά"],
    ["mitspielen", "to play, to participate", "παίζω, συμμετέχω στο παιχνίδι"],
    ["nachdenken über + Α", "to think about", "σκέφτομαι, αναλογίζομαι"],
    ["sporttreiben", "to do sport", "αθλούμαι, κάνω αθλητισμό"],
    ["stattfinden", "to take place", "λαμβάνει χώρα"],
    ["sich vorbereiten auf + A", "to prepare", "προετοιμάζω"],
    ["vorlesen", "to read out-read loudly", "διαβάζω δυνατά"],
    ["zusammenpassen", "to match", "ταιριάζω"],
    ["wehtun", "to hurt", "πονάει"],
    ["weitersuchen", "to keep looking", "συνεχίζω να ψάχνω"],
    ["festhalten", "to hold back", "κρατώ"],
    ["zusammengehören", "to match", "ταιριάζω"],
    ["weiterbauen", "continue to build", "συνεχίζω να χτίζω"],
    ["weiterlesen", "continue to read", "διαβάζω παρακάτω"],
    ["anbieten", "to offer", "προσφέρω"],
    ["sich ausruhen", "to rest", "ξεκουράζομαι"],
    ["sich auszeichnen", "to distinguish sb (from all others)", "διακρίνομαι"],
    ["aussuchen", "to choose", "διαλέγω, επιλέγω"],
    ["zusammenarbeiten", "to cooperate with sb", "συνεργάζομαι"],
    ["nachdenken", "to think about sth", "σκέφτομαι, συλλογίζομαι"],
    ["sich etwas leisten können", "can afford something", "έχω την οικονομική δυνατότητα να έχω/ κάνω κάτι"],
    ["anschaffen", "to buy, to take", "παίρνω, αγοράζω"],
    ["zusammenleben", "to live together", "συζώ"],
    ["abbrechen", "to break off", "διακόπτω"],
    ["wegwerfen", "to throw away sth", "πετώ στα σκουπίδια"],
    ["aussortieren", "to sort sth out", "ξεδιαλέγω"],
    ["durchkommen", "to come through", "περνώ"],
    ["zusammentragen", "to gather", "συγκεντρώνω"],
    ["rauswollen", "I want to get away", "θέλω να ξεφύγω"],
    ["angucken", "to look at", "κοιτάζω"],
    ["einritzen", "to scratch", "χαράζω"],
    ["hineintun", "to put in, to insert", "βάζω μέσα, εισάγω"],
    ["ausdrücken", "to express", "εκφράζω"],
    ["zusammensetzen", "to get together", "συνδυάζω"],
    ["zusammenfassen", "to sum up", "συνοψίζω"],
    ["abstimmen", "to vote", "ψηφίζω"],
    ["einbauen", "to install", "τοποθετώ"],
    ["ausbauen", "to remove", "αφαιρώ"],
    ["mitbringen", "to bring sth or sb along", "φέρνω μαζί μου"],
    ["abfahren", "to depart", "αναχωρώ"],
    ["losfahren", "to depart", "αναχωρώ"],
    ["mitfahren", "to go with", "πάω μαζί"],
    ["wegfahren", "to leave", "φεύγω, αναχωρώ"],
    ["weiterfahren", "to continue driving", "συνεχίζω την οδήγηση"],
    ["zurückfahren", "to go back", "φεύγω, αναχωρώ"],
    ["hinausfahren", "to drive out", "βγαίνω έξω,( πηγαίνω εκδρομή)"],
    ["überfahren", "to run over", "πατώ/χτυπώ (με όχημα)"],
    ["kaputtfahren", "to smash into sd, sth", "διαλύω (όχημα)"],
    ["hinfallen", "to fall", "πέφτω κάτω"],
    ["hinunterfallen", "to fall down", "πέφτω"],
    ["ausgeben (für + A)", "to spend", "ξοδεύω"],
    ["zurückgeben", "to return", "επιστρέφω"],
    ["zugeben", "to admit", "παραδέχομαι, ομολογώ"],
    ["angeben", "to declare", "δηλώνω"],
    ["abgeben", "to hand over", "παραδίδω"],
    ["dabeihaben", "to have with me", "έχω μαζί μου"],
    ["vorhaben", "to plan", "σκοπεύω, σχεδιάζω να κάνω"],
    ["anhaben", "to be wearing", "φορώ"],
    ["übrig haben", "have left", "(κάτι) μου περισσεύει, μου μένει"],
    ["anhalten", "to stop", "σταματώ"],
    ["aushalten", "to hold on", "αντέχω"],
    ["aufhängen", "to hang", "κρεμάω"],
    ["abhängen von + D", "to depend on sb/sth", "εξαρτώμαι από"],
    ["zusammenhangen mit +D", "to be joined with", "συσχετίζεται/συνδέεται με"],
    ["aufhören mit + D", "to stop", "σταματώ"],
    ["zuhören + D", "to listen carefully", "ακούω προσεκτικά"],
    ["sich etwas anhören", "to listen to sth", "ακούω κάτι"],
    ["ausgehen", "to go out", "βγαίνω έξω"],
    ["weggehen", "to go away", "φεύγω"],
    ["weitergehen", "to walk on", "προχωρώ"],
    ["mitgehen", "to go along", "πηγαίνω μαζί"],
    ["mitkommen", "to come with", "έρχομαι μαζί"],
    ["reinkommen", "to come in", "έρχομαι μέσα"],
    ["vorbeikommen", "to pass", "περνώ"],
    ["wiederkommen", "to come back", "γυρίζω, επιστρέφω"],
    ["zurückkommen", "to return", "επιστρέφω"],
    ["vorwärtskommen", "to progress, or push forward", "προοδεύω, προχωρώ"],
    ["anmelden", "to log on", "δηλώνω, εγγράφω"],
    ["abmelden", "to log out", "ακυρώνω, ξεδηλώνω, ανακαλώ"],
    ["ein/laden zu + D", "to invite", "προσκαλώ"],
    ["herunterladen", "download from internet", "κατεβάζω (από το διαδίκτυο)"],
    ["herausnehmen", "to take out", "βγάζω έξω"],
    ["mitnehmen", "to take with", "παίρνω μαζί (μου)"],
    ["aufnehmen", "to film", "γράφω ταινίες, μαγνητοσκοπώ"],
    ["abnehmen", "to get thin", "αδυνατίζω, ελαττώνομαι"],
    ["zunehmen", "to gain weight", "παχαίνω"],
    ["teilnehmen an + D", "to participate", "συμμετέχω"],
    ["annehmen", "to accept", "(από)δέχομαι, κάνω δεκτό"],
    ["eine Tätigkeit aufnehmen", "to take up an activity", "αναλαμβάνω καθήκοντα"],
    ["einpacken", "to pack sth in", "βάζω μέσα, πακετάρω"],
    ["auspacken", "to unpack sth", "αδειάζω/βγάζω πράγματα"],
    ["einreisen", "to enter a country", "εισέρχομαι σε μία χώρα."],
    ["ausreisen", "to leave a country", "αναχωρώ, φεύγω για εξωτερικό"],
    ["aufmachen", "to open (a door)", "ανοίγω (την πόρτα)"],
    ["zumachen", "to close", "κλείνω"],
    ["anmachen", "to switch on", "ανοίγω (τον διακόπτη)"],
    ["ausmachen", "to switch off", "σβήνω, κλείνω (το φως)"],
    ["ausmachen", "to switch off", "χαρακτηρίζει"],
    ["weitermachen", "to continue", "συνεχίζω"],
    ["mitmachen bei + D", "to take part in, to participate", "συμμετέχω"],
    ["teilnehmen an + D", "to take part", ""],
    ["kaputtmachen", "to ruin", "καταστρέφω, χαλάω"],
    ["durchmachen", "to undergo", "υπομένω, περνώ"],
    ["kaputtmachen", "to ruin, to break", "καταστρέφω, χαλάω"],
    ["ansehen", "to look at", "κοιτάζω, παρατηρώ"],
    ["anshauen", "to look at", ""],
    ["aussehen", "to look, to seem", "φαίνομαι"],
    ["fernsehen", "to watch television", "hat ferngeseh-en"],
    ["herschauen", "to look over", "κοιτάζω προς τα εδώ (her=εδώ)"],
    ["anschauen", "to look at", "κοιτάζω, βλέπω (προσεκτικά)"],
    ["einschlafen", "to fall asleep", "πέφτω για ύπνο"],
    ["wieterschlafen", "to continue sleeping", "συνεχίζω να κοιμάμαι"],
    ["ausschlafen", "to get enough sleep", "χορταίνω ύπνο"],
    ["aufwachen", "to wake up", "ξυπνώ"],
    ["aufbleiben", "to stay up", "μένω ξύπνιος"],
    ["abschließen", "to lock", "κλειδώνω"],
    ["aufschließen", "to unlock", "κλειδώνω"],
    ["anschließen", "to connect", "συνδέω"],
    ["einschließen", "to surround sb, sth", "περικυκλώνω, περιβάλλω"],
    ["abschreiben", "to copy", "αντιγράφω"],
    ["aufschreiben", "to write", "σημειώνω, γράφω"],
    ["hinschreiben", "to write down", "καταγράφω"],
    ["aufschlagen", "to open (book, eyes)", "ανοίγω (βιβλίο, μάτια)"],
    ["vorschlagen", "to propose", "προτείνω"],
    ["einschlagen", "to break, to smash", "σπάω"],
    ["anstellen", "to turn on", "ανάβω, ανοίγω"],
    ["abstellen", "to turn off", "κλείνω (συσκευή)"],
    ["sich vorstellen", "to introduce", "συστήνομαι, φαντάζομαι"],
    ["herstellen", "to manufacture", "κατασκευάζω, παρασκευάζω"],
    ["zusammenstellen", "to compile", "συνθέτω, φτιάχνω"],
    ["einstellen", "to adjust, to turn on", "ρυθμίζω, θέτω σε λειτουργία"],
    ["einsteigen", "to board", "επιβιβάζομαι, σκαρφαλώνω"],
    ["aussteigen", "to get out (of a car)", "κατεβαίνω, αποβιβάζομαι"],
    ["auswandern", "to emigrate (to somewhere)", "μεταναστεύω"],
    ["einwandern", "to immigrate", "έρχομαι σαν μετανάστης σε μία χώρα"],
    ["anziehen", "to dress", "ντύνομαι, φορώ, βάζω"],
    ["ausziehen", "to undress, to move out", "μετακομίζω, ξεντύνω (βγαίνω)"],
    ["sich ausziehen", "to undress", "ξεντύνομαι, γδύνομαι"],
    ["umziehen", "to move out, to change clothes", "μετακομίζω, αλλάζω ρούχα"],
    ["einziehen", "to get in", "εισέρχομαι από μετακόμιση"],
    ["zurücklassen", "to leave back, to abandon", "αφήνω πίσω, εγκαταλείπω"],
    ["auffordern", "to ask", "ζητώ"],
    ["weiterleiten", "to move forward (an email)", "προωθώ (ένα mail)"],
    ["abfliegen", "to depart by plane", "αναχωρώ αεροπορικώς"],
    ["zuordnen", "to assign", "αντιστοιχίζω"],
    ["zusagen", "to accept", "δέχομαι, απαντώ θετικά"],
    ["ausfühlen", "to feel", "αισθάνομαι"],
    ["ausdrucken", "to print", "εκτυπώνω"],
    ["auffallen", "to make good/bad impression", "μου κάνει εντύπωση, μου τραβά την προσοχή"],
    ["auffordern", "to request", "(ζητώ)"],
    ["weiterleiten", "to forward", "(προωθώ ένα email)"],
    ["nachprüfen", "to check", "(ελέγχω, εξετάζω)"],    
    ["nachschreiben", "to rewrite", "(γράφω ένα διαγώνισμα σε μεταγενέστερη ημερομηνία)"],
    ["nach/lassen", "to decrease", "(υποχωρώ, μειώνεται)"],
    ["auf/fallen", "to be noticed", "(πέφτω στην αντίληψη κάποιου)"],
    ["ab/driften", "to drift away", "(αφαιρούμαι, δεν συγκεντρώνομαι)"],
    ["sich heraus/stellen", "to turn out", "(αποδεικνύομαι)"],
    ["sich anschauen", "to get to know, to come into contact", "(h: γνωρίζω, έρχομαι σε επαφή)"],
    ["herausfinden", "to find out", "(βρίσκω, ανακαλύπτω)"],
    ["einrichten", "to set up (on the internet)", "(αναρτώ στο διαδίκτυο)"],
    ["einrichten", "to furnish", "(επιπλώνω)"],
    ["an/fordern", "to request, to order", "(ζητώ, παραγγέλνω)"],
    ["sich auf/halten", "to stay, to reside", "(μένω βρίσκομαι)"],
    ["losfahren", "to start with a vehicle", "(ξεκινώ με όχημα)"],
    ["jdm vorkommen", "to seem to someone", "(μου φαίνεται)"],
    ["sichausdenken", "to think up, to invent", "(σκαρφίζομαι, επινοώ)"],
    ["abschaffen", "to abolish", "(καταργώ)"],
    ["austauschen", "to exchange", "(ανταλλάσω)"],
    ["mitteilen", "to inform, to announce", "πληροφορώ, ανακοινώνω"],
    ["aussuchen", "to select", "(διαλέγω)"],
    ["rumlaufen", "to roam around", "(τριγυρνώ, κυκλοφορώ)"],
    ["zuschauen", "to watch", "(κοιτώ, παρακολουθώ)"],
    ["sich ausruhen", "to rest", "(ξεκουράζομαι)"],
    ["aufhaben", "to be open", "(είναι ανοιχτός)"],
    ["vorziehen", "to prefer", "(προτιμώ)"],
    ["meiner Meinung nach", "in my opinion", "(κατά την γνώμη μου)"],
    ["aufwachsen", "to grow up", "(μεγαλώνω, ανατρέφομαι)"],
    ["sich anstrengen", "to try hard", "(προσπαθώ πολύ)"],
    ["unterbringen", "to accommodate", "(βάζω σε δωμάτιο)"],
    ["zubereiten", "to prepare", "(ετοιμάζω)"],
    ["abfliegen", "to take off", "(απογειώνομαι)"],
    ["ausreichen", "to suffice", "(αρκεί)"],
    ["raustragen", "to carry out", "(μεταφέρω, κουβαλάω έξω)"],
    ["umkippen", "to faint and fall", "(λιποθυμώ και σωριάζομαι)"],
    ["anfassen", "to touch, to grab", "(πιάνω, αγγίζω)"],
    ["feststellen", "to ascertain", "(διαπιστώνω)"],
    ["aufstellen", "to set up", "(στήνω)"],
    ["anregen", "to stimulate", "(τονώνω)"],
    ["Wissen aufnehmen", "to acquire knowledge", "(προσλαμβάνω γνώσεις)"],
    ["fort/setzen", "to continue", "(συνεχίζω)"],
    ["dar/stellen", "to present", "(παρουσιάζω)"],
    ["ausleihen", "to lend", "(δανείζω)"],
    ["zulassen", "to permit", "(επιτρέπω)"],
    ["vor/sehen", "to foresee", "(προβλέπω)"],
    ["nach sich ziehen", "to result in", "(επιφέρει, έχει ως αποτέλεσμα)"],
    ["abhängig sein von + D", "to be dependent on", "(είμαι εξαρτημένος από)"],
    ["einsehen", "to realize", "(καταλαβαίνω, αντιλαμβάνομαι)"],
    ["ansprechen", "to address", "θίγω ένα θέμα, απευθύνω τον λόγο σε"],
    ["zuschauen", "to watch", "(κοιτώ, παρακολουθώ)"],
    ["zusammenwachsen", "to grow closely together", "(δημιουργούν στενές σχέσεις μεταξύ τους)"],
    ["auffrischen", "to refresh", "(φρεσκάρω)"],
    ["aufnehmen", "to receive", "(δέχομαι)"],
    ["voraussetzen", "to assume", "(προύποθέτω)"],
    ["ausklingen", "to fade out", "(σβήνει, καταλαγιάζει)"],
    ["vorführen", "to present", "(παρουσιάζω)"],
    ["Umsiedeln", "to relocate", "(μετακομίζω σε άλλο τόπο)"],
    ["wegziehen", "to move away", "(μετακομίζω, φεύγω)"],
    ["zustimmen", "to agree", "(συμφωνώ)"],
    ["jdm vorkommen", "to seem to someone", "(μου φαίνεται)"],
    ["abgeben", "to hand over", "(παραδίδω)"],
    ["gutschreiben", "to credit", "(πιστώνω)"],
    ["ausleihen", "to borrow", "(δανείζομαι)"],
    ["ausreichen", "to suffice", "(επαρκεί)"],
    ["mitreden", "to participate in a conversation", "(συμμετέχω στην συζήτηση)"],
    ["beisammensitzen", "to sit together", "(καθόμαστε μαζί)"],
    ["aufgeben", "to give up", "(εγκαταλείπω, παραιτούμαι από)"],
    ["herumtollen", "to romp around while playing", "(ξεφαντώνω παίζοντας)"],
    ["aufweisen", "to exhibit", "(εμφανίζω, παρουσιάζω)"],
    ["vorbei/schauen", "to stop by to see", "(περνώ να δω)"],
    ["anlegen", "to construct", "(κατασκευάζω)"],
    ["sich aus/denken", "to think up", "(σκαρφίζομαι, επινοώ)"],
    ["weiterleiten", "to forward, to transfer", "(μεταφέρω, προωθώ)"],
    ["ausschalten", "to turn off a device", "(κλείνω μια συσκευή)"],
    ["umtauschen", "to exchange, to return", "(αλλάζω, επιστρέφω)"],
    ["ausprobieren", "to try out", "(δοκιμάζω)"],
    ["jdm etw beibringen", "to teach someone something", "(διδάσκω κάτι σε κάποιον)"],
    ["auseinanderfallen", "to fall apart", "(διαλύεται)"],
    ["durchführen", "to conduct", "(διεξάγω)"],
    ["einhalten", "to adhere to", "(τηρώ)"],
    ["zulassen", "to allow", "(επιτρέπω)"],
    ["an/sprechen", "to address", "(απευθύνομαι, μιλώ σε)"],
    ["ausprobieren", "to try out", "(δοκιμάζω)"],
    ["einstellen", "to employ, to adjust", "προσλαμβάνω, ρυθμίζω"],
    ["wieterkommen", "to progress, to move forward", "(προοδεύω, προχωρώ)"],
    ["FRage aufwerfen", "to raise a question", "(θέτω, προκαλώ ερωτήσεις)"],
    ["festlegen", "to determine, to establish", "((καθ)ορίζω, θεσπίζω)"],
    ["anlegen", "to install, to construct", "(εγκαθιστώ, φτιάχνω)"],
    ["anlächeln", "to smile at someone", "(χαμογελώ σε κάποιον)"],
    ["die Arme ausbreiten", "to spread the arms (to hug)", "(απλώνω τα χέρια (για να αγκαλιάσω))"],
    ["aufheben", "to pick up", "(σηκώνω)"],
    ["wegschmeißen", "to throw away", "(πετώ, ρίχνω στα σκουπίδια)"],
    ["aufmuntern", "to cheer up", "(φτιάχνω την διάθεση)"],
    ["auswählen", "to select", "(επιλέγω)"],
    ["aufnehmen", "to receive, to perceive with the senses", "(προσλαμβάνω με τις αισθήσεις μου)"],
    ["hochsetzen", "to raise, to increase", "(ανεβάζω, αυξάνω)"],
    ["sich ausgleichen", "to balance out", "(ισοσκελίζεται)"],
    ["annehmen", "to accept, to suppose", "(δέχομαι, κάνω δεκτό, υποθέτω)"],
    ["sich das Recht vorhaben", "to reserve the right for oneself", "(επιφυλάσσω, διατηρώ για τον εαυτό μου το δικαίωμα)"],
    ["einschalten", "to turn on a device", "(ανοίγω, θέτω σε λειτουργία μια συσκευή)"],
    ["fortsetzen", "to continue", "(συνεχίζω)"],
    ["ablehnen", "to reject", "(αρνούμαι, απορρίπτω)"],
    ["ausarbeiten", "to develop, to elaborate", "(επεξεργάζομαι, εκπονώ)"],
    ["ansehen", "to watch (movie, sight)", "(βλέπω (ταινία, αξιοθέατο))"],
    ["hinkommen", "to get somewhere", "(πηγαίνω, φτάνω κάπου)"],
    ["ablaufen", "to unfold, to progress", "(εξελίσσεται, κυλά)"],
    ["zubereiten", "to prepare", "(ετοιμάζω)"],
    ["wegrationalisieren", "to eliminate for efficiency reasons", "(καταργώ για λόγους εξορθολογισμού)"],
    ["einführen", "to introduce", "(εισάγω)"],
    ["zurechtweisen", "to reprimand", "(επιπλήτω)"],
    ["ausgehen", "to go out for entertainment", "(βγαίνω για διασκέδαση)"],
    ["ankommen", "to arrive", "φτάνω"],
    ["auswerten", "to evaluate", "αξιολογώ"],
    ["nichts ausmachen", "to not matter", "δεν πειράζει"],
    ["wegziehen", "to move to another place", "μετακομίζω σε άλλο μέρος"],
    ["zusammenrücken", "to move closer to each other", "ο ένας έρχεται πιο κοντά στον άλλον."],
    ["einbringen", "to contribute, to bring in", "(συν)εισφέρω, συμβάλλω με"],
    ["annehmen", "to suppose", "υποθέτω"],
    ["jdm etw anvertrauen", "to entrust something to someone", "εμπιστεύομαι κάτι σε κάποιον"],
    ["kontakt aufnehmen", "to make contact", "έρχομαι σε επαφή"],
    ["abstempeln", "to stamp", "σφραγίζω"],
    ["herauskommen", "to come out, to discover", "μαθαίνω, βρίσκω"],
    ["den Kontakt ab/brechen", "to break off contact", "κόβω την επαφή, σταματάω να έχω επαφή"],
    ["ausfallen", "to stand out, to notice", "εμπίπτει στην προσοχή μου, παρατηρώ"],
    ["anlegen", "to create", "δημιουργώ"],
    ["einfügen", "to insert", "προσθέτω, εντάσσω"],
    ["nachfragen", "to inquire", "ρωτώ"],
    ["erwähnen", "to mention", "αναφέρω"],
    ["nachlassen", "to decrease", "υποχωρεί"],
    ["zusammenstoßen", "to collide", "συγκρούομαι, τρακάρω"],
    ["sichergehen", "to make sure", "σιγουρεύομαι"],
    ["ein/setzen", "to use", "χρησιμοποιώ"],
    ["aufrechterhalten", "to maintain", "διατηρώ"],
    ["erkennen an + D", "to recognize", "αναγνωρίζω"],
    ["sich durchsetzen", "to prevail", "επιβάλλομαι"],
    ["vorgeben", "to predetermine", "προκαθορίζω"],
    ["abbauen", "to dismantle, to reduce", "αποδομώ, μειώνω"],
    ["absitzen", "to spend time", "περνώ την ώρα μου"],
    ["sich vermischen", "to mix", "αναμιγνύομαι"],
    ["hervorrufen", "to provoke, to cause", "προκαλώ, προξενώ"],
    ["ausmachen", "to constitute", "αποτελεί"],
    ["einatmen", "to inhale", "εισπνέω"],
    ["ausatmen", "to exhale", "εκπνέω"],
    ["pendeln", "to commute (long distance daily)", "πηγαίνοντας καθημερινά μεταξύ τόπου εργασίας και κατοικίας διανύοντας μεγάλη απόσταση."],
    ["wieder/gut/machen", "to make amends", "επανορθώνω"],
    ["aufladen", "to charge", "φορτίζω"],
    ["aufzählen", "to enumerate", "απαριθμώ"],
    ["einholen", "to catch up, to request", "παίρνω, ζητώ"],
    ["antrefen", "to undertake", "αναλαμβάνω"],
    ["ein fallen + D", "to come to mind", "έχω μία ιδέα, μου έρχεται κάτι στον νου"],
    ["einstellen", "to employ, to adjust", "προσλαμβάνω"],
    ["einstellen", "to adjust", "ρυθμίζω"],
    ["ein Gerät einstellen", "to adjust a device", "ρυθμίζω μια συσκευή"],
    ["abschicken", "to send off", "αποστέλλω"],
    ["anstellen", "to employ permanently, to turn on", "προσλαμβάνω μόνιμα, θέτω σε λειτορυγία."],
    ["anlernen", "to train for a new job", "εκπαιδεύω, σε μια καινούργια δουλειά"],
    ["beibringen", "to teach someone else", "μαθαίνω κάτι σε κάποιον άλλον."],
    ["nachgehen + D", "to engage in, to do", "επιδίδομαι, κάνω"],
    ["aufstellen", "to set up, to place", "στήνω, τοποθετώ"],
    ["wahrnehmen", "to perceive with the help of the senses", "αντιλαμβάνομαι με την βοήθεια των αισθήσεων"],
    ["sicheinstellen", "to appear, to occur", "εμφανίζομαι, επέρχομαι"],
    ["antreiben", "to propel, to motivate", "ωθώ, παρακινώ"],
    ["sich etwas ausdenken", "to think up, to devise", "σκέφτομαι, σκαρφίζομαι"],
    ["eine Seite auf/bauen", "to build a webpage", "κατασκευάζω μία ιστοσελίδα"],
    ["zurechtkommen mit + D", "to get along well with", "τα καταφέρνω, τα πάω καλά με"],
    ["zurückblicken", "to look back, to review", "ανασκοπώ, κοιτάζω πίσω"],
    ["umsetzen", "to turn over, to implement", "κάνω τζίρο, πραγματοποιώ"],
    ["ausfallen", "to turn out, to be cancelled", "αποδεικνύεται, ακυρώνεται"],
    ["aussteigen", "to exit, to quit", "εγκαταλείπω"],
    ["sich aus/breiten", "to spread out", "(εξ)απλώνομαι"],
    ["nacherzählen", "to recount in one's own words", "διηγούμαι με δικά μου λόγια"],
    ["vorkommen", "to occur, to be found", "συμβαίνει, απαντάται"],
    ["vorantreiben", "to advance, to push forward", "προωθώ, κινώ"]
];




  return (
    <div>
      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
        <Header1 props="List with Separable verbs" />
        <Header2 props="Liste mit trennbaren Verben" />
      </Box>



      <Box className="global-box-not-header">

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          
          <Table>
            <TableRow>
              <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0'}}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
            </TableRow>

            {verbs.map((verb, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>                
              </TableRow>
            ))}
          </Table>

        </TableContainer>
      </Box>

      <ScrollToTopButton />
    </div>
  )
}

export default Z0037_Verbs_ListWithSeparableVerbs