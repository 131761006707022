import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z203_Prepositions_Island() {
  return (
    <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Prepositions for Islands, Island clusters" />
                <Header2 props="Präpositionen für Inseln, Inselngruppen" />
            </Box>



            <Box className="global-box-not-header" >


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em', marginBottom: 'em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The names of the islands never have article.<br/>
                    The names of the island clusters have article and are always in plural <spam class="green-text">(die Kykladen)</spam>.<br/>
                </Typography>               


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>1a. Movement to an island, island clusters.</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>nach, for islands</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>auf + Akkusativ, for island clusters, and the word `Insel`</Typography>
               

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Meine Eltern fahren zu Ostern <spam class="green-text">nach</spam> Skiathos.
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                My parents drive for the Easter <spam class="green-text">to</spam> Skiathos. 
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark möchte <spam class="green-text">auf die</spam>  Seychellen reise. (die Seychellen, plural)
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark wants to travel  <spam class="green-text">to</spam> Seychelles. 
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark möchte im Sommer <spam class="green-text">auf eine Insel </spam> reisen. (die Insel)
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark wants to travel at summer <spam class="green-text">to an island</spam>.
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>







                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1b. Staying in an island, island clusters</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>auf, for islands</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>auf + Dativ, for island clusters, and the word `Insel`</Typography>
               

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich war zwei Wochen <spam class="green-text">auf</spam> Skiathos. 
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I was two weeks <spam class="green-text">in</spam> Skiathos.
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich war zwei Wochen <spam class="green-text">auf den</spam> Seychellen. (die Seychellen, plural).
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I was two weeks  <spam class="green-text">to</spam> Seychelles.
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich war zwei Wochen <spam class="green-text">auf einer Insel</spam>. (die Insel)
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I was two weeks  <spam class="green-text">to an island</spam>.
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>






                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1c. Origin from an island, island clusters.</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>von, for islands</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>von + Dativ, for island clusters, word `Insel`</Typography>
               

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark kommt <spam class="green-text">von Skiathos</spam>
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark is <spam class="green-text">from</spam> Skiathos. 
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark kommt <spam class="green-text">von den Seychellen</spam>. (die Seychellen, plural)
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark ist  <spam class="green-text">from</spam> Seychelles.
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark ist <spam class="green-text">von einer Insel</spam>. (die Insel)
                            </TableCell>
                        </TableRow>

                        <TableRow>                            
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark is  <spam class="green-text">from</spam> an island.
                            </TableCell>
                        </TableRow>                      

                    </Table>
                </TableContainer>

            </Box>


            <ScrollToTopButton />



    </div>
  )
}

export default Z203_Prepositions_Island
