import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0039_Verbs_withNominative() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Verbs with Nominative" />
                <Header2 props="Verben mit Nominativ" />
            </Box>



            <Box className="global-box-not-header" >

                <Header3_Middle props="" />
                <Header2 props="" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginBottom: "2em" }} >
                    There are some verbs that after them follows noun in <span className="green-text">nominative</span> (nominativ). The most important verbs are:<br /><br />

                    1. <span className="green-text">sein</span> (to be).<br />
                    2. <span className="green-text">werden</span> (to becomme).<br />
                    3. <span className="green-text">bleiben</span> (to stay).<br />
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={3}>Nominative</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Tania <span className="green-text"> ist</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meine Tochter</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Tania is my daughter</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er <span className="green-text"> wird</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein guter Lehrer</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He will become a gut teacher</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Du <span className="green-text"> bist</span> and <span className="green-text"> bleibst</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein guter Freund</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>You are and you will stay a gut frend</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />
        </div>
    )
}

export default Z0039_Verbs_withNominative