import React from "react";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import { TableHead, TableBody } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Header3_Middle from "../components/Header3_Middle";
import ScrollToTopButton from "../components/ScrollToTopButton";
import { Button, Card, CardHeader, Typography, Grid, Box } from "@mui/material";
import Container from "@mui/material/Container";

function HomeScreen() {
  const commonButtonProps = {
    variant3: "outlined",
    size: "big",
    style: { textTransform: "none" },
  };

  return (
    <div>
      <Box className="global-box-header">
        <Typography
          variant="h1"
          color="primary"
          sx={{
            textAlign: "center",
            marginBottom: "0.5em",
            background: "#e2f0df",
            color: "red",
          }}
        >
          Home
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {/* 1. */}
        <Grid
          sx={{ background: "white" }}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          {/* 1a. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{
                color: "red",
                marginTop: "0em",
                paddingTop: "1em",
                paddingBottom: "1em",
                textAlign: "center",
              }}
            >
              Verbs
            </Typography>

            {/* <Button {...commonButtonProps} component={Link} to="/verbs1_verbs"> Verbs overview </Button><br /> */}
            <Button {...commonButtonProps} component={Link} to="/verbs1_verbs">
              {" "}
              Verbs overview{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs2_infinitive"
            >
              {" "}
              Infinitive{" "}
            </Button>

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Simple Present Tense (Präsens)
            </Typography>

            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs3_simplepresent"
            >
              {" "}
              Simple Present Tense{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs4_sein_haben"
            >
              {" "}
              The verbs sein und haben{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs5_regular"
            >
              Conjugation of regular verbs in present
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs6_irregular"
            >
              Conjugation of irregular verbs in present
            </Button>
            <br />
            <Button {...commonButtonProps} component={Link} to="/verbs7_werden">
              The verb werden
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs8_position"
            >
              The position of verb in the main sentence{" "}
            </Button>
            <br />

            <hr />
            <Button {...commonButtonProps} component={Link} to="/verbs9_modal">
              Modal verbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs10_modal_meaning"
            >
              Meaning of modal verbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs11_modal_sentence"
            >
              Modal verbs in the main sentence
            </Button>
            <br />
            <hr />

            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs12_semimodals"
            >
              Semimodal verbs
            </Button>
            <br />

            <hr />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs13_complex"
            >
              Complex verbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs14_inseparable"
            >
              Separable and Inseparable Prefix verbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs15_complex_conjuagtion"
            >
              Conjugation of Complex verbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs16_complex_position"
            >
              Position of complex veben in the main sentence
            </Button>
            <br />
            <hr />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Reflexive und Reziproke Verbs
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs17_reflexive"
            >
              Reflexive Verbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs18_reziproke"
            >
              Reciprocal verbs - einander
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Imperative
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs19_imperative"
            >
              Imperative
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Present Perfect Tense (Perfekt)
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs20_presentperfect"
            >
              Present Perfect Tense (Perfekt)
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs21_presentperfect_syntax"
            >
              Syntax in the main sentence
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs22_presentperfect_list"
            >
              Full list verbs with sein
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Präteritum
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs23_prateritum_sein"
            >
              The verbs sein, haben, modal
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs24_prateritum_regular"
            >
              Conjugation Regular verbs in Präteritum
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs25_prateritum_irregular"
            >
              Conjugation Irregular verbs in Präteritum
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Passiv
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs26_passivevoicce"
            >
              Passive voice
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs27_passivevoice_sentence"
            >
              Passive in the sentence
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs28_passivevoice_active"
            >
              Convert Active voice to Passive voice
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs29_passivevoice_modals"
            >
              Passive with Modal Verb
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Konjuktiv II
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs30_konjuktivII_moods"
            >
              Use of Moods
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs31_konjuktivII_Formation"
            >
              Formation
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              zu + infinitive
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs33_zu_plus_infinitive"
            >
              zu plus Infinitive
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs32_infinitive_without_zu"
            >
              Infinitive without zu
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/verbs34_brauchen_zu_infinitive"
            >
              brauchen ... zu + infinitive
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Syntax of vebrs
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0039_Verbs_withNominative"
            >
              Verbs with Nominative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0040_Verbs_withAccusative"
            >
              Verbs with Accusative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0041_Verbs_withDative"
            >
              Verbs with Dative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0042_Verbs_withDativeAndAccusative"
            >
              Verbs with Dative and Accusative
            </Button>
            <br />
            <hr />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0045_Verbs_WithPrepositionalObject"
            >
              Verbs with Prepositional Object
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0038_Verbs_ListWithPrepositionalObject"
            >
              List Verbs with Prepositional Object
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0044_Verbs_QuestionsWithPrepositionalObject"
            >
              Questions with Verbs with Prepositional Object
            </Button>
            <br />
            <hr />

            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0043_Verbs_PositionInSentence"
            >
              The position of verb in the sentence
            </Button>
            <br />
          </Paper>
        </Grid>

        {/* 2. */}
        <Grid
          sx={{ background: "white" }}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          {/* 2a. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{
                color: "red",
                marginTop: "0em",
                paddingTop: "1em",
                paddingBottom: "1em",
                textAlign: "center",
              }}
            >
              Nouns
            </Typography>

            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns50_gender"
            >
              {" "}
              Gender{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns51_main_names"
            >
              {" "}
              Main names{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns52_building_nouns"
            >
              {" "}
              Building Nouns{" "}
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Conjugation of the nouns
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns53_conjugation"
            >
              Number - Plural of nouns
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Cases
            </Typography>
            <Button {...commonButtonProps} component={Link} to="/nouns54_cases">
              Cases
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns55_nDeklination"
            >
              n Deklination
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns56_with_prepositions"
            >
              Nouns with prepositions
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/nouns57_from_verbs"
            >
              Nouns from Verbs
            </Button>
            <br />
          </Paper>

          {/* 2b. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "1em", textAlign: "center" }}
            >
              Question Words
            </Typography>

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Interrogative Pronouns
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords121_wer"
            >
              {" "}
              wer{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords122_was"
            >
              {" "}
              was{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords123_welcher"
            >
              {" "}
              welcher, welche, welches{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords124_wasfurein"
            >
              {" "}
              was für ein{" "}
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Interrogative Adverbs
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords125_wie"
            >
              {" "}
              wie{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords126_wieviel"
            >
              {" "}
              wie viel, wie viele{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords127_wann"
            >
              {" "}
              wann, um wie viel Uhr, wie lange, wie oft{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords128_woher"
            >
              {" "}
              woher{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords129_wo_wohin"
            >
              {" "}
              wo, wohin{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords130_wo_warum"
            >
              {" "}
              warum{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/questionwords131_wozu"
            >
              {" "}
              wozu{" "}
            </Button>
            <br />
          </Paper>

          {/* 2c. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "1em", textAlign: "center" }}
            >
              Prepositions
            </Typography>

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Time Prepositions
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z200_Prepositions_Time"
            >
              Prepositions with time meaning{" "}
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Local Prepositions
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z201_Prepositions_Local"
            >
              Prepositions with local meaning
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z202_Prepositions_Countries"
            >
              Continents, countries, towns, villages{" "}
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z203_Prepositions_Island"
            >
              Islands, Island clusters
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z204_Prepositions_Cardinal"
            >
              Prepositions for Cardinal Directions
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Special Notes
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z205_Prepositions_aus"
            >
              The Prepositions "aus" (+)
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z206_Prepositions_TimeDefinitions"
            >
              Time Definitions without prepositions
            </Button>
            <br />
          </Paper>
        </Grid>

        {/* 3. */}
        <Grid
          sx={{ background: "white" }}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          {/* 3a. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "0em", textAlign: "center" }}
            >
              Adjectives
            </Typography>

            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives61_comparatives"
            >
              Comparatives of the Adjectives
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives62_comparison_types"
            >
              Types of Comparison of Adjectives and Adverbs
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives63_conjugation"
            >
              Conjugation of the Adjectives
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives64_conjugation_overview"
            >
              Conjugation of the Adjectives - Overview
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives65_conjugation_superlative"
            >
              Conjugation of the Adjectives in Comperative and in Superlative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives66_conjugation_as_nouns"
            >
              Adjectives and participles as nouns
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives67_with_prepositions"
            >
              Adjectives with prepositions
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives68_vershieden"
            >
              anders-, verschieden, anders
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives69_derselbe"
            >
              derselbe, der gleiche, selbst
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adjectives70_ordinal_numbers"
            >
              Ordinal numbers
            </Button>
            <br />
          </Paper>

          {/* 3b. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "1em", textAlign: "center" }}
            >
              Adverbs
            </Typography>

            <Button
              {...commonButtonProps}
              component={Link}
              to="/adverbs141_sehr"
            >
              The adverb : sehr
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adverbs142_dabei"
            >
              The adverbs : dabei, nämlich
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/adverbs143_immer"
            >
              The adverbs : immer, meistens, manchmal, oft, selten, kaum, nie.
            </Button>
            <br />
          </Paper>

          {/* 3c ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "1em", textAlign: "center" }}
            >
              Pronouns
            </Typography>

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Personal pronouns
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns101_personal_nominative"
            >
              Personal pronouns in Nominative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns102_personal_accusative"
            >
              Personal Pronouns in Accusative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns103_personal_dative"
            >
              Personal Pronouns in Dative
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns104_personal_overview"
            >
              Overview of personal pronouns
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Indefinite pronouns
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns105_indefinite_man"
            >
              man
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns106_indefinite_jemand"
            >
              jemand, niemand
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns107_indefinite_ein"
            >
              ein-, welch, kein-
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/pronouns108_indefinite_etwas"
            >
              etwas, nichts
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Other pronouns
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0109_OtherPronouns"
            >
              jeder, alle, beide, viele, wenige, einige, manche, mahrere
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0110_OtherPronounsAlles"
            >
              alles, beides, vieles, weniges, einiges
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Demonstrative pronouns
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0111_Pronouns_die"
            >
              Demonstartive Pronouns: "der, die, das"
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0112_Pronouns_dieser"
            >
              Demonstartive Pronouns: "dieser, diese, dieses"
            </Button>
            <br />
          </Paper>

          {/* 3d ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "1em", textAlign: "center" }}
            >
              Various
            </Typography>

            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z250_Various_lassen"
            >
              The verb `lassen` (+)
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z251_Various_es"
            >
              The uses of personal pronoun `es`
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z252_Various_wenn_ob"
            >
              The difference between `wenn` and `ob`
            </Button>
            <br />
          </Paper>
        </Grid>

        {/* 4. */}
        <Grid
          sx={{ background: "white" }}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          {/* 4a. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "0em", textAlign: "center" }}
            >
              Articles
            </Typography>

            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles81_definite"
            >
              Articles
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles82_possessive"
            >
              Possessive Article
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles83_possessive_overview"
            >
              Overview of Possessive Articles
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles84_possessive_dieser"
            >
              dieser, diese, dieses, diese
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles85_solcher"
            >
              solcher, solche, solches, solche
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles86_other"
            >
              jeder, alle, beide, viele, wenige, manche, einige, mehrere
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/articles87_derselbe"
            >
              derselbe, dieselbe, dasselbe, dieselben
            </Button>
            <br />
          </Paper>

          {/* 4b. ----------------------------------------------------------------------------------------------------------- */}
          <Paper elevation="3" sx={{ background: "#e2f0df" }}>
            <Typography
              variant="h5"
              sx={{ color: "red", marginTop: "1em", textAlign: "center" }}
            >
              Sentence Connection
            </Typography>

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Special sentences
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection151_dass"
            >
              Subordinate clauses with "dass"
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Reasons
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection152_denn"
            >
              denn, nämlich, weil, da
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Conclusion
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection153_also"
            >
              also, deshald, deswegen, darum, daher
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Contrast sentences
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection154_trotzdem"
            >
              trotzdem, aber, sondern, doch, jedoch, obwohl, während
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Conditional sentences
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection155_wenn"
            >
              wenn
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Purpose
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection156_um_zu"
            >
              um...zu, damit, zum + infinitive
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Modal sentences
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection157_ohne_zu"
            >
              ohne ... zu + infinitive
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Temporal sentences
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection158_als"
            >
              wenn, als, während
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Comparative sentences
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection159_wie_als"
            >
              als, wie
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Indirect questions
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection160_ob"
            >
              Indirekte Fragesätze ob
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Relative sentences with der die das denen
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection161_relativ1"
            >
              Relativsätze mit der,die,das,denen
            </Button>
            <br />
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection162_relativ2"
            >
              Relativsätze mit wer, was, wo, worüber
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Double conjuctions:
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection163_doppel"
            >
              Doppelkonjunktionen
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              bevor
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection164_bevor"
            >
              bevor
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              A.D.U.S.O.
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/sentenceConnection165_aduso"
            >
              aber, denn, und, sondern, oder
            </Button>
            <br />

            <Typography variant="h6" sx={{ color: "red", marginTop: "1em" }}>
              Overview
            </Typography>
            <Button
              {...commonButtonProps}
              component={Link}
              to="/Z0166_SentenceConnection_Overview"
            >
              Overview of Sentences Connection
            </Button>
            <br />
          </Paper>
        </Grid>
      </Grid>

      <ScrollToTopButton />
    </div>
  );
}

export default HomeScreen;
