import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0160_SentenceConnection_ob() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Indirect Questions : ob" />
                <Header2 props="Indirekte Fragen" />
            </Box>



            <Box className="global-box-not-header">
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    We can express a question with two ways:<br /><br />

                    1. With a main sentence with <span className="green-text">direct question</span>.<br /><br />

                    2. With a Subordinate sentence (Nebensatz) after a verb. It is called <span className="green-text">indirect question</span>.
                </Typography>

            </Box>

            <ScrollToTopButton />





            <Box className="global-box-not-header">
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    When the direct question starts with a <span className="green-text">questionable pronoun</span>, Fragepronomen (wer, was,wie,wo,wohin,woher) then the indirect question starts with the same word.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wer bekommt eine Arbeitserlaubnis?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Who gets a work permission?</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main sentence (Hauptsatz)</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subordinate sentence (Nebensatz)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie möchten wissen,</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">wer</span> eine Arbeitserlaubnis bekommt.</TableCell>

                        </TableRow>

                        <TableRow>
                            <TableCell colspan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>They want to know who gets a work permission.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />








            <Box className="global-box-not-header">
                <Header3_Middle props="1. If" />
                <Header2 props="ob" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    When the direct question starts with a <span className="green-text"> verb</span>, then the indirect question starts with the conjunction "<span className="green-text"> ob</span>".
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Braucht man eine Arbeitserlaubnis?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Does someone need a work permission?</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main sentence (Hauptsatz)</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subordinate sentence (Nebensatz)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie möchten wissen,</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ob man eine Arbeitserlaubnis bekommt.</TableCell>

                        </TableRow>

                        <TableRow>
                            <TableCell colspan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>They want to know, if someone can get a work permission.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0160_SentenceConnection_ob