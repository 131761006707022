import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


function Z0030VerbsKonjuktivIIMoods() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Konjuktiv II : Use of moods" />
                <Header2 props="Konjuktiv II : Gebrauch der Modi" />
            </Box>


            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In German language there are three moods:<br /><br />

                    1. <span className="green-text"> Indikative (Indikativ)</span>. <br />
                    2. <span className="green-text"> Imperative (Imperativ)</span>. <br />
                    3. <span className="green-text"> Subjunctive II (Konjuktiv II)</span>. <br /><br />

                    Whereas the <span className="green-text">indicative mood</span> is used to make statements of fact, ask questions, and generally describe <span className="green-text"> reality</span>,
                    the <span className="green-text"> subjunctive mood</span> is used to express conditions and situations that are contrary to fact, unlikely, uncertain, or hypothetical.
                    <br /><br />

                    The <span className="green-text"> indicative</span> is used for actions that have taken place, are taking place, or will very likely take place.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er hat großen Hunger. </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He is very hungry.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich weiß, dass sie krank ist. </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I κnow that she is ill. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wir werden ihn besuchen </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>We will visit him</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er hat es genommen </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He took it.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The <span className="green-text"> subjunctive</span> is used to express that a certain action has not taken place or may not take place, because it is a <span className="green-text"> supposition</span>, conjecture,
                    or desire, rather than a fact. <br />
                    When a statement is contrary to fact or when it implies a possibility rather than a probability, the subjunctive mood is used.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich wollte, er wäre hier! </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I wish he was here. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er sagte, er würde keine Zeit haben. </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He said he would have no time. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie tut, als ob sie Geld hätte. </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>She acts as if she had money. </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />


            <Box className="global-box-not-header" >
                <Header3_Middle props="2. Subjunctive Forms in German" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In German, there are two forms of the <span className="green-text"> subjunctive</span>, for convenience often
                    referred to as<span className="green-text">  Subjunctive I (Konjuktiv I)</span> and <span className="green-text"> Subjunctive II (Konjuktiv II)</span>.<br />
                    <br />
                    <IconButton><RadioButtonCheckedIcon /></IconButton> <span className="green-text"> Subjunctive</span> I was given its name because it is based on the infinitive stem (the first principal part of the verb).<br />
                    <IconButton><RadioButtonCheckedIcon /></IconButton> <span className="green-text"> Subjunctive II</span> derives its name from the fact that it is based on the simple past tense stem of the verb (the second principal part of the verb). <br />
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Principal Parts of the Verb </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>First Part (infinitive)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Second Part (Simple Past) </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Third Part (Past Participle) </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>machen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>machte</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gemacht</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gehen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ging</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist gegangen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                <span className="green-text"> Subjunctive I</span> (sometimes called the special subjunctive) is used primarily to render indirect speech in formal situations. <br />
                    It calls attention to the fact that these are not the exact words of the original speaker, but are only someone else’s report of those words. <br />
                    This form of the subjunctive is commonly encountered in newspapers, magazines, and other media, as well as in very formal speech; in colloquial speech, however,
                    native speakers often use the indicative instead.<br /><br />

                    <span className="green-text"> Subjunctive II</span> (sometimes called the general subjunctive or the imperfect subjunctive) is used in a wider variety of situations,
                    mainly to express the following.<br /><br />

                    1. contrary-to-fact conditions.<br />
                    2. wishes and desires.<br />
                    3. hypothetical questions.<br />
                    4. polite requests and questions.<br />
                    5. as a substitute for Subjunctive I when those forms are identical to the past indicative and for that reason might be confusing.
                </Typography>

            </Box>

            <ScrollToTopButton />
        </div>
    )
}

export default Z0030VerbsKonjuktivIIMoods