import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0157_SentenceConnection_ohne_zu() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Modal sentences : ohne...zu + infinitive" />
                <Header2 props="Modale Sätze" />
            </Box>


            <Box className="global-box-not-header">
            <Header3_Middle props="1. ohne...zu + infinitive : Same Subject" />
            <Header2 props="without ... to" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Let's see these two sentences:
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>             

                    <TableRow> 
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}><span className="green-text">Er</span> geht aus den Haus. <span className="green-text">Er</span> sagt seinen Eltern nichts Tschüs.</TableCell>                  
                    </TableRow>                  
                
                </Table>
            </TableContainer>




            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                When two sentences have the <span className="green-text">same subject</span> we can connect these sentences with that way:
            </Typography>


            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>             

                    <TableRow> 
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er geht aus dem Haus, <span className="green-text">ohne</span> seinen Eltern Tschüs <span className="green-text">zu sagen</span>.</TableCell>                        
                    </TableRow>    

                     <TableRow>                         
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>He goes out of the house, <span className="green-text">without to</span> take his umbrella. </TableCell>
                    </TableRow>                
                
                </Table>
            </TableContainer>




            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                If the verb is a separate verb (trennbare verb) then, the zu placed inside the separable verb.
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>             

                    <TableRow> 
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er geht aus dem Haus, <span className="green-text">ohne</span> seinen Regenschirm mit<span className="green-text">zu</span>nehmen.</TableCell>                        
                    </TableRow>    

                    <TableRow>                         
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>He goes out of the house, <span className="green-text">without to</span> take his umbrella. </TableCell>
                    </TableRow>                  
                
                </Table>
            </TableContainer>

            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0157_SentenceConnection_ohne_zu