import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0044_Verbs_QuestionsWithPrepositionalObject() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Questions with Verbs with Prepositional Object" />
                <Header2 props="Fragen mit Präpositionalobjekt" />
            </Box>


            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    To make a question with a prepositional object and how we will replace it, we examine first if it is a <spam class="green-text">person</spam> or a <spam class="green-text">thing</spam>.
                </Typography>

            </Box>

            <ScrollToTopButton />





            <Box className="global-box-not-header" >

                <Header3_Middle props="1. Prepositional Object question with person" />
                <Header2 props="Präpositionalobjekt frage Objekt mit Person" />


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object with person</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object : sich interessieren für + Akkusativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mark <spam class="green-text">interessiert für</spam> die neue Mitschulerin.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Mark is <spam class="green-text">interested for</spam> the new classmate.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Question : <spam class="green-text">Für wen</spam> interessiert Mark?</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}><spam class="green-text">Who is</spam> Mark interested for?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Answer : Er interessiert sich <spam class="green-text">für sie</spam>.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He is interested <spam class="green-text">for her</spam></TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="2. Prepositional Object question with thing" />
                <Header2 props="Präpositionalobjekt-Frage mit einer Sache" />


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object question with thing</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object : sich interessieren für + Akkusativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mark <spam class="green-text">interessiert für</spam> Fußball.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Mark is <spam class="green-text">interested for</spam> football.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Question : <spam class="green-text">Wofür</spam> interessiert Mark?</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}><spam class="green-text">For what</spam> Mark interested for?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Answer : Er interessiert sich <spam class="green-text">dafür</spam>.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He is interested <spam class="green-text">to that</spam>.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


            </Box>

            <ScrollToTopButton />


            <Box className="global-box-not-header" >

                <Header3_Middle props="3. Prepositional Object question with referring to a whole sentence" />
                <Header2 props="" />


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich danke dir <spam class="green-text">dafür</spam>, dass du mir geholfen hast.
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I thank you for helping me
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Du hast mir geholfen. <spam class="green-text">Dafür</spam>, danke ich dir.
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I thank you for that
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="4. Examples" />
                <Header2 props="" />


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object with person</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object : denken an + Akkusativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich <spam class="green-text">denke an</spam> meinen Freund.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I think of my friend.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Question : <spam class="green-text">An wen</spam> denkst du?</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Who are you thinking?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Answer : <spam class="green-text">An ihn </spam>solltest du nicht so oft denken.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>You shouldn't think of him that often.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object with person</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Prepositional Object : denken an + Akkusativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Question : <spam class="green-text">Woran denkst</spam> du?</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Who are you thinkg?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Answer : <spam class="green-text">Daran</spam> denke ich auch oft</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I often think about that.</TableCell>
                        </TableRow>                   

                    </Table>
                </TableContainer>


            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0044_Verbs_QuestionsWithPrepositionalObject
