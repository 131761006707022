import React from 'react';
import {Link} from 'react-router-dom';
import { Button, IconButton, Typography, Iconbutton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import AbcIcon from '@mui/icons-material/Abc';


function Mui11_Icons() {
    return (
        <div>
            <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
            <Typography variant="h1" color="primary" sx={{textAlign:"center", marginBottom:"0em"}}>
                IconButton Component
            </Typography>

            <Typography variant="h6" color="secondary" sx={{textAlign:"center", marginBottom:"0em"}}>
                Πως μετατρέπουμε icons σε κουμπιά.
            </Typography>

            <AbcIcon />
            <br/><br/><br/>

            <Button>Click me!! <AbcIcon /> </Button>
            <br/><br/><br/>

            <IconButton> {" "}  <AbcIcon />  </IconButton>

            <IconButton> {" "}  <AbcIcon color="primary" />  </IconButton>

            <IconButton
            onClick={ () =>  alert('hello!!!') }
            
            > {" "}  <AbcIcon />  </IconButton>

        </div>
    )
}

export default Mui11_Icons