import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0009_Verbs_Modal() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Modal verbs" />
                <Header2 props="Modal verben" />
            </Box>



            <Box className="global-box-not-header" >
            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                1)   The modal verbs changes to content of the statement.<br /><br /><br />


                In german there are the following modal verbs:
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
            <Table>               

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sollen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>obligation</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>should, ought to, to be supposed to</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wollen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>desire, intention</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to want to</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>+ Akkusativ</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>müssen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>necessity, obligation</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>must, to have to, be obliged to</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>können</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>ability</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>can, to be able to, know how to</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dürfen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>permission</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>may, be allowed to do</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dürfen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>(in the negative: prohibition)</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>must NOT</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mögen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>inclination, desire, linking</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to like to, care for, want to</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}> + Akkusativ</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}> mögen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}> (in the negative: not linking)</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}> not to like to, not care for, not want to</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}> + Akkusativ</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möchten</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>would like to have - would like to do</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>+ Akkusativ</TableCell>
                </TableRow>

            </Table>
            </TableContainer>




            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
                Examples:<br /><br />

                Ich will Deutsch lernen = I want to learn German.<br />
                Ich muss Deutsch lernen = I have to learn German.<br />
                Ich darf Deutsch lernen = I am allowed to learn German.<br />
                Ich möchte Deutsch lernen = I would like to learn German.<br />
                Ich soll Deutsch lernen = I should learn German.<br /><br /><br />



                As we can see, we change a smoll word and we change to whole meaning.<br /><br />

                The second verb of the sentence goes to the end, always in infinitive.
            </Typography>


            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
            <Table>

                <TableRow>
                <TableCell colspan={8} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92', marginTop:'1em'}}>Modal verbs - Modalverben</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>wollen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>können</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>müssen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>dürfen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>mögen</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>möchten</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>sollen</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>want to</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>can</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>have to /must</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>be allowed to do something <br />do something<br />or may do something</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to like</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>would like to have/ do</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>should</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}>+Akkusativ</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}></TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}>+Akkusativ</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}>+Akkusativ</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#ebcfee'}}></TableCell>
                </TableRow>

                <TableRow>
                <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>ich</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>will </TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>kann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>muss</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>darf</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mag </TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möcht-e</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>soll</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>du</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>will-st</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>kann-st</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>musst</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>darfst</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>magst</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möchte-st</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>soll-st</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>er/sie/es</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>will</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>kann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>muss</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>darf</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mag</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möcht-e</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>soll</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>wir</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>woll-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>könn-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>müssen</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dürf-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mög-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möcht-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>soll-en</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>ihr</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>woll-t</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>könn-t</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>müss-t</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dürf-t</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mög-t</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möcht-e-t</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>soll-t</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>sie / Sie</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>woll-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>könn-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>müssen</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dürf-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mög-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>möcht-en</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>soll-en</TableCell>
                </TableRow>

            </Table>
            </TableContainer>
            

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>

                Ich will schwimmen = I want to swim.<br />
                Entschuldigen Sie, darf ich Sie etwas fragen? = Excuse me, may i ask you something?<br />
                Ihr dürft hier nicht fotografieren = You mustn't take pictures here. or   You are not allowed to take pictures here.<br />
                <br /><br />

                Magst du Milch? = Do you like milk?<br />
                Er mag sie = He likes her.<br />
                <br /><br />



                2)  An important Hint about "mögen"! = Ein wichtiger Tip für "mögen"<br /><br />

                Something important is that "mögen" we just usually use it as a full verb so we don't need another verb for this. This is a very common mistake.
                <br /><br />
                For Example, we say:<br />
                Ich mag Fußball = I like football.<br /><br />

                but we don't say<br />

                Ich mag Fußball spielen = I like playing football.<br /><br />

                the correct are:<br /><br />

                Ich will Fußball spielen = I want to playing football.<br />
                Ich mag Fußball  = I like football.<br />
                Ich spiele gern Fußball  = I like football.    ("gern" means like and it is after the verb and we use it for activities)<br />
                <br /><br /><br />


                so:<br /><br />

                1) we use (mögen + nouns)<br /><br />

                Ich mag Fußball  = I like football<br />
                Ich mag Milch = I like milk<br />
                Ich mag keine Wurst = I don't like sausage<br /><br />

                2) if we have an activity we use (activity verb + gern)<br /><br />

                Ich koche gern... = I like to cook...<br />
                Ich spiele gern... = I like to play...<br />




                3)  A sentence with 3 or more verbs<br /><br />

                All the other verbs except the modal verbs goes to the end and in the infinitive.<br /><br />

                Eigentlich möchte Veronika nicht einkaufen gehen, aber ihr Kühlschrank ist leer.<br />
                Actually Veronika don't want to go for shopping, but her refrigerator is empty.<br />




                4)  The negative meaning of the modal verbs<br /><br />

                When we want to say " You must not do this or that", you may think that we should say in german "Das muss du nicht machen"<br />

                but that is a common mistake. In that case we use modal verb "darf" with "nicht", for example:<br /><br />

                Das darfst du nicht machen = You mustn't do this.<br />

            </Typography>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0009_Verbs_Modal