import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0015_Verbs_Complex_Conjugation() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Conjugation of Complex verbs" />
                <Header2 props="Konjugation Zusammengesetze Verben" />
            </Box>



            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The prefixes are indeclinable. So in the complex verbs conjugated only the main verb.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>antworten : to answer</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>beantworten : to answer</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich antworte</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich <span className="green-text">be</span>antworte</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du antwortest</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du <span className="green-text">be</span>antwortest</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es antwortet</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es <span className="green-text">be</span>antwortet</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir antworten</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir <span className="green-text">be</span>antworten</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr antwortet</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr <span className="green-text">be</span>antwortet</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie antworten</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie <span className="green-text">be</span>antworten</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
                    Also.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>nehmen : to take</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>herausnehmen : to take out</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich nehme</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich nehme <span className="green-text">heraus</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du nimmst</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du nimmst <span className="green-text">heraus</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es nimmt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es nimmt <span className="green-text">heraus</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir nehmen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir nehmen <span className="green-text">heraus</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr nehmt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr nehmt <span className="green-text">heraus</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie nehmen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie nehmen <span className="green-text">heraus</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer >
            </Box>

            <ScrollToTopButton />



        </div>
    )
}

export default Z0015_Verbs_Complex_Conjugation