import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z252_Various_wenn_ob() {
  return (
    <div>

        <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box className="global-box-header">
            <Header1 props="The difference between `wenn` and `ob` " />
            <Header2 props="Der unterschied zwischen `wenn` und `ob`" />
        </Box>



        <Box className="global-box-not-header" >

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

            The conjuction <spam class="red-text">"wenn"</spam><spam class="green-text"> (when)</spam> or <spam class="green-text"> (if)</spam> imports <spam class="green-text">Subordinate clause</spam> <spam class="red">(Nebensatz)</spam>. <br/><br/>
            
            It is translated as: <br/>

            1. <spam class="green-text">"When"</spam> in temporal sentences <spam class="red">temporal sätze</spam><br/>

            2. <spam class="green-text">"If"</spam> in conditional sentences <spam class="red"></spam>.
            
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>
                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }} colSpan={3}>Subordinate clause (Nebensatz)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={2}>Main clause (Hauptsatz)</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }} colSpan={3}>position: 1</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }} colSpan={2}>position: 2 VERB</TableCell>                    
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }}>Wenn</TableCell>                    
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }}>mein Vater von der Arbeit</TableCell>                    
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }}>zurückommt,</TableCell>                    
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist</TableCell>                    
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er meistens sehr müde.</TableCell>                    
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}  colSpan={5}>
                        <spam class="green-text">When </spam>my father returns for the work, he is mostly tired.
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>



        <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
            <Table>
                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }} >Subordinate clause (Nebensatz)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={2}>Main clause (Hauptsatz)</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }} colSpan={1}>position: 1</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }} colSpan={1}>position: 2 verb</TableCell>                    
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }} colSpan={1}>rest of the sentence</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fce2' }}><spam class="green-text">Wenn</spam> mein Geld reicht <spam class="green-text">habe</spam></TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>(dann) kaufe</TableCell>                    
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich mir ein neues Handy.</TableCell>                    
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}  colSpan={5}>
                        <spam class="green-text">If</spam> i will have enough money, i will buy a new mobile phone.
                    </TableCell>
                </TableRow>
            </Table>
        </TableContainer>
        

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            The <spam class="green-text">conjuction </spam><spam class="red-text">(Konjukation), ob</spam> inserts always indirect question.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>
                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main clause (Hauptsatz)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subordinate clause (Nebensatz)</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie möchten wissen,</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">ob</spam> man eine Arbeitserlaubnis bekommt.</TableCell>                            
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }} colSpan={2}>
                        They want to know, <spam class="green-text">if</spam> someone can get a work permission. 
                    </TableCell>
                </TableRow>                
            </Table>
        </TableContainer>

      


        </Box>


        <ScrollToTopButton />


  </div>
  )
}

export default Z252_Various_wenn_ob