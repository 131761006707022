import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function Z0027VerbsPassiveSentence() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Passive in the sentence" />
                <Header2 props="Satzstellung im Passivsatz" />
            </Box>



            <Box className="global-box-not-header" >

                <Box sx={{ marginTop: "1em" }}>
                    <Header3_Middle props="1. In the main sentence" />
                    <Header2 props="Hauptsatz" />
                </Box>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
                    1. At <span className="green-text"> "Präsens"</span> and <span className="green-text"> "Präteritum"</span> the verb that
                    conjugated <span className="green-text"> (werden)</span> placed in the <span className="green-text"> second</span> position and the <span className="green-text"> "Partizip Perfekt"</span> placed
                    at the <span className="green-text"> end</span> of the sentence.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ι</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>ΙΙ</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>at the end</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Präsens : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Kind </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wird</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Präteritum : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Kind </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wurde</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} >
                    2. At <span className="green-text"> "Perfekt"</span> and <span className="green-text"> "Plusquamperfekt"</span> <br />

                    <IconButton><RadioButtonCheckedIcon /></IconButton>the verb that conjugated <span className="green-text"> (sein)</span> placed in the <span className="green-text"> second</span> position <br />
                    <IconButton><RadioButtonCheckedIcon /></IconButton>the <span className="green-text"> "Partizip Perfekt"</span> placed <span className="green-text"> before the end</span>  <br />
                    <IconButton><RadioButtonCheckedIcon /></IconButton>and the pertizip <span className="green-text"> worden</span> at the <span className="green-text"> end</span>.<br />
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ι</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>ΙΙ</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>at the end</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Perfekt :  : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Kind</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt worden.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plusquamperfekt : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Kind </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>war</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt worden.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />

            <Box className="global-box-not-header" >


                <Box sx={{ marginTop: "1em" }}>
                    <Header3_Middle props="2. In a subordinate sentence" />
                    <Header2 props="Nebensatz" />
                </Box>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
                    The verb that conjugated placed at the end of the sentence, and the partizips before that.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main clause</TableCell>
                                <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>subordinate clause</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Präsens : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Mutter hat mir gesagt, 	</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> dass</span> das Kind jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wird</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Präteritum : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Mutter hat mir gesagt, </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> dass</span> das Kind jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wurde.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Perfekt : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Mutter hat mir gesagt, 	</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> dass</span> das Kind jeden Tag von der Schule</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt worden</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plusquamperfekt : 	</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Mutter hat mir gesagt, </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> dass</span> das Kind jeden Tag von der Schule 	</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abgeholt worden</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>war.</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div >
    )
}

export default Z0027VerbsPassiveSentence