import './App.css';

import Mui_Typography from './components/Mui_Typography';
import Mui2_FromHandlingWithTextFields from './components/Mui2_FromHandlingWithTextFields';
// import Mui3_Appbar from './components/Mui3_Appbar';
import Mui4_FormValidation from './components/Mui4_FormValidation';

import Mui5_DataGrid from './components/Mui5_DataGrid';

import Mui6_DataGrid from './components/Mui6_DataGrid';
import Mui6_DataGrid_Item from './components/Mui6_DataGrid_Item';

//import Mui7_Theming from './components/Mui7_Theming';
//import Mui8_StyledComponents from './components/Mui8_StyledComponents';
//import Mui9_ComponentStylingWithTheme from './components/Mui9_ComponentStylingWithTheme';
//import Mui10_DarkLightProject from './components/Mui10_DarkLightProject';
import Mui11_Icons from './components/Mui11_Icons';
//import Mui12_GridSytem from './components/Mui12_GridSytem';
//import Mui13_Responsiveness from './components/Mui13_Responsiveness';
//import Mui14_ResponsiveValues from './components/Mui14_ResponsiveValues';
//import Mui15_useMediaQueryHook from './components/Mui15_useMediaQueryHook';
import Navbar from './components/Navbar';

import HomeScreen from './screens/HomeScreen';
import Home2Screen from './Home2Screen';
import Footer from './components/Footer';

import { Route, Routes } from 'react-router-dom';

import Z0001_Verbs_Verbs from './screens/Z0001_Verbs_Verbs';
import Z0002_Verbs_Infinitive from './screens/Z0002_Verbs_Infinitive';
import Z0003_Verbs_SimplePresent from './screens/Z0003_Verbs_SimplePresent';
import Z0004_Verbs_sein_haben from './screens/Z0004_Verbs_sein_haben';
import Z0005_Conjugation_regular_verbs from './screens/Z0005_Conjugation_regular_verbs';
import Z0006_Conjugation_irregular_verbs from './screens/Z0006_Conjugation_irregular_verbs';
import Z0007_Verbs_werden from './screens/Z0007_Verbs_werden';
import Z0008_Verbs_in_main_sentence from './screens/Z0008_Verbs_in_main_sentence';
import Z0009_Verbs_Modal from './screens/Z0009_Verbs_Modal';
import Z0010_Verbs_Modal_Meaning from './screens/Z0010_Verbs_Modal_Meaning';
import Z0011_Verbs_Modal_Main_Sentence from './screens/Z0011_Verbs_Modal_Main_Sentence';
import Z0012_Verbs_Semimodal from './screens/Z0012_Verbs_Semimodal'
import Z0013_Verbs_Complex from './screens/Z0013_Verbs_Complex';
import Z0014_Verbs_Inseparable from './screens/Z0014_Verbs_Inseparable';
import Z0015_Verbs_Complex_Conjugation from './screens/Z0015_Verbs_Complex_Conjugation';
import Z0016_Verbs_Comple_Position from './screens/Z0016_Verbs_Comple_Position';
import Z0017VerbsReflexive from './screens/Z0017VerbsReflexive';
import Z0018VerbsReziproke from './screens/Z0018VerbsReziproke';
import Z0019VerbsImperative from './screens/Z0019VerbsImperative';
import Z0020VerbsPresentPerfect from './screens/Z0020VerbsPresentPerfect';
import Z0021VerbsPresentPerfectSyntax from './screens/Z0021VerbsPresentPerfectSyntax';
import Z0022VerbsPresentPerfectList from './screens/Z0022VerbsPresentPerfectList';
import Z0023_Prateritum from './screens/Z0023_Prateritum';
import Z0024VerbsPrateritumRegular from './screens/Z0024VerbsPrateritumRegular';
import Z0025VerbsPrateritumIrregular from './screens/Z0025VerbsPrateritumIrregular';
import Z0026VerbsPassiveVoice from './screens/Z0026VerbsPassiveVoice';
import Z0027VerbsPassiveSentence from './screens/Z0027VerbsPassiveSentence';
import Z0028VerbsPassiveToActive from './screens/Z0028VerbsPassiveToActive';
import Z0029VerbsPassiveModals from './screens/Z0029VerbsPassiveModals';
import Z0030VerbsKonjuktivIIMoods from './screens/Z0030VerbsKonjuktivIIMoods';
import Z0031VerbsKonjuktivIIFormation from './screens/Z0031VerbsKonjuktivIIFormation';
import Z0032Infinitive_without_zu from './screens/Z0032Infinitive_without_zu';
import Z0033_Zu_plus_Infinitive from './screens/Z0033_Zu_plus_Infinitive';
import Z0034_brauchen_nur_zu_infinitive from './screens/Z0034_brauchen_nur_zu_infinitive';
import Z0035_Verbs_ListWithRegularVerbs from './screens/Z0035_Verbs_ListWithRegularVerbs';
import Z0036_Verbs_ListWithIrregularVerbs from './screens/Z0036_Verbs_ListWithIrregularVerbs';
import Z0037_Verbs_ListWithSeparableVerbs from './screens/Z0037_Verbs_ListWithSeparableVerbs';
import Z0038_Verbs_ListWithPrepositionalObject from './screens/Z0038_Verbs_ListWithPrepositionalObject';

import Z0039_Verbs_withNominative from './screens/Z0039_Verbs_withNominative';
import Z0040_Verbs_withAccusative from './screens/Z0040_Verbs_withAccusative';
import Z0041_Verbs_withDative from './screens/Z0041_Verbs_withDative';
import Z0042_Verbs_withDativeAndAccusative from './screens/Z0042_Verbs_withDativeAndAccusative';

import Z0043_Verbs_PositionInSentence from './screens/Z0043_Verbs_PositionInSentence';

import Z0044_Verbs_QuestionsWithPrepositionalObject from './screens/Z0044_Verbs_QuestionsWithPrepositionalObject';
import Z0045_Verbs_WithPrepositionalObject from './screens/Z0045_Verbs_WithPrepositionalObject';




import Z0050_Nouns_Gender from './screens/Z0050_Nouns_Gender';
import Z0051_Nouns_Main_Names from './screens/Z0051_Nouns_Main_Names';
import Z0052_Nouns_Building_Nouns from './screens/Z0052_Nouns_Building_Nouns';
import Z0053_Nouns_Conjugation from './screens/Z0053_Nouns_Conjugation';
import Z0054_Nouns_Cases from './screens/Z0054_Nouns_Cases';
import Z0055_Nouns_nDeklination from './screens/Z0055_Nouns_nDeklination';
import Z0056_Nouns_with_Prepositions from './screens/Z0056_Nouns_with_Prepositions';
import Z0057_Nouns_from_Verbs from './screens/Z0057_Nouns_from_Verbs';


import Z0061_Adjactives_Comparatives from './screens/Z0061_Adjactives_Comparatives';
import Z0062_Adjetives_Comparison_types from './screens/Z0062_Adjetives_Comparison_types';
import Z0063_Adjectives_Conjugation from './screens/Z0063_Adjectives_Conjugation';
import Z0064_Adjectives_Conjugation_Overview from './screens/Z0064_Adjectives_Conjugation_Overview';
import Z0065_Adjectives_Conjugation_Superlative from './screens/Z0065_Adjectives_Conjugation_Superlative';
import Z0066_Adjectives_as_nouns from './screens/Z0066_Adjectives_as_nouns';
import Z0067_Adjectives_with_prepositions from './screens/Z0067_Adjectives_with_prepositions';
import Z0068_Adjectives_verschieden from './screens/Z0068_Adjectives_verschieden';
import Z0069_Adjectives_derselbe from './screens/Z0069_Adjectives_derselbe';
import Z0070_Adjectives_Ordinal_numbers from './screens/Z0070_Adjectives_Ordinal_numbers';

import Z0081_Articles_Definite from './screens/Z0081_Articles_Definite';
import Z0082_Articles_Possessive from './screens/Z0082_Articles_Possessive';
import Z0083_Articles_Possessive_Overview from './screens/Z0083_Articles_Possessive_Overview';
import Z0084_Articles_dieser from './screens/Z0084_Articles_dieser';
import Z0085_Articles_solcher from './screens/Z0085_Articles_solcher';
import Z0086_Articles_other from './screens/Z0086_Articles_other';
import Z0087_Articles_derselbe from './screens/Z0087_Articles_derselbe';

import Z0101_Pronouns_Personal_Nominative from './screens/Z0101_Pronouns_Personal_Nominative';
import Z0102_Pronouns_Personal_Accusative from './screens/Z0102_Pronouns_Personal_Accusative';
import Z0103_Pronouns_Personal_Dative from './screens/Z0103_Pronouns_Personal_Dative';
import Z0104_Pronouns_Personal_Overview from './screens/Z0104_Pronouns_Personal_Overview';
import Z0105_Pronouns_Indefinite_man from './screens/Z0105_Pronouns_Indefinite_man';
import Z0106_Pronouns_Indefinite_jemand from './screens/Z0106_Pronouns_Indefinite_jemand';
import Z0107_Pronouns_Indefinite_ein from './screens/Z0107_Pronouns_Indefinite_ein';
import Z0108_Pronouns_Indefinite_etwas from './screens/Z0108_Pronouns_Indefinite_etwas';
import Z0109_OtherPronouns from './screens/Z0109_OtherPronouns';
import Z0110_OtherPronounsAlles from './screens/Z0110_OtherPronounsAlles';
import Z0111_Pronouns_die from './screens/Z0111_Pronouns_die';
import Z0112_Pronouns_dieser from './screens/Z0112_Pronouns_dieser';


import Z0121_Questionwords_wer2 from './screens/Z0121_Questionwords_wer2';
import Z0122_Questionwords_was from './screens/Z0122_Questionwords_was';
import Z0123_Questionwords_welcher from './screens/Z0123_Questionwords_welcher';
import Z0124_Questionwords_wasfurein from './screens/Z0124_Questionwords_wasfurein';
import Z0125_Questionwords_wie from './screens/Z0125_Questionwords_wie';
import Z0126_Questionwords_wieViel from './screens/Z0126_Questionwords_wieViel';
import Z0127_Questionwords_wann from './screens/Z0127_Questionwords_wann';
import Z0128_Questionwords_woher from './screens/Z0128_Questionwords_woher';
import Z0129_Questionwords_wo_wohin from './screens/Z0129_Questionwords_wo_wohin';
import Z0130_Questionwords_warun from './screens/Z0130_Questionwords_warun';
import Z0131_Questionwords_wozu from './screens/Z0131_Questionwords_wozu';

import Z0141_Adverbs_sehr from './screens/Z0141_Adverbs_sehr';
import Z0142_Adverbs_dabei from './screens/Z0142_Adverbs_dabei';
import Z0143_Adverbs_immer from './screens/Z0143_Adverbs_immer';


import Z0151_SentenceConnection_dass from './screens/Z0151_SentenceConnection_dass';
import Z0152_SentenceConnection_denn from './screens/Z0152_SentenceConnection_denn';
import Z0153_SentenceConnection_also from './screens/Z0153_SentenceConnection_also';
import Z0154_SentenceConnection_trotzdem from './screens/Z0154_SentenceConnection_trotzdem';
import Z0155_SentenceConnection_wenn from './screens/Z0155_SentenceConnection_wenn';
import Z0156_SentenceConnection_um_zu from './screens/Z0156_SentenceConnection_um_zu';
import Z0157_SentenceConnection_ohne_zu from './screens/Z0157_SentenceConnection_ohne_zu';
import Z0158_SentenceConnection_als from './screens/Z0158_SentenceConnection_als';
import Z0159_SentenceConnection_wie_als from './screens/Z0159_SentenceConnection_wie_als';
import Z0160_SentenceConnection_ob from './screens/Z0160_SentenceConnection_ob';
import Z0161_SentenceConnection_Relativ1 from './screens/Z0161_SentenceConnection_Relativ1';
import Z0162_SentenceConnection_Relativ2 from './screens/Z0162_SentenceConnection_Relativ2';
import Z0163_SentenceConnection_Doppel from './screens/Z0163_SentenceConnection_Doppel';
import Z0164_SentenceConnection_bevor from './screens/Z0164_SentenceConnection_bevor';
import Z0165_SentenceConnection_aduso from './screens/Z0165_SentenceConnection_aduso';
import Z0166_SentenceConnection_Overview from './screens/Z0166_SentenceConnection_Overview';

import Z0167_UsefulExpressions from './screens/Z0167_UsefulExpressions';
import Z0168_AllGramaticalPhenomena from './screens/Z0168_AllGramaticalPhenomena';


import Z200_Prepositions_Time from './screens/Z200_Prepositions_Time';
import Z201_Prepositions_Local from './screens/Z201_Prepositions_Local';
import Z202_Prepositions_Countries from './screens/Z202_Prepositions_Countries';
import Z203_Prepositions_Island from './screens/Z203_Prepositions_Island';
import Z204_Prepositions_Cardinal from './screens/Z204_Prepositions_Cardinal';
import Z205_Prepositions_aus from './screens/Z205_Prepositions_aus';
import Z206_Prepositions_TimeDefinitions from './screens/Z206_Prepositions_TimeDefinitions';





import Z250_Various_lassen from './screens/Z250_Various_lassen';
import Z251_Various_es from './screens/Z251_Various_es';
import Z252_Various_wenn_ob from './screens/Z252_Various_wenn_ob';



//---
import Mui68_DataGrid4_Results from './components/Mui68_DataGrid4_Results';




const linksArray = ["Home", "Menu 2w", "Menu 3w", "Menu 4w"];

function App() {

  return (
    <div>
      {/* <Mui3_Appbar /> */}
      {/* <Mui5_DataGrid /> */}
      {/* <Mui7_Theming /> */}
      {/* <Mui8_StyledComponents /> */}
      {/* <Mui9_ComponentStylingWithTheme/> */}
      {/* <Mui10_DarkLightProject /> */}
      {/* <Mui12_GridSytem/> */}
      {/* <Mui13_Responsiveness /> */}
      {/* <Mui14_ResponsiveValues /> */}
      {/* <Mui15_useMediaQueryHook /> */}

      <Navbar links={linksArray} />

      <main>

        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/muuri" element={<Home2Screen />} />


          <Route path="/results" element={<Mui68_DataGrid4_Results />} />


          <Route path="/textfield" element={<Mui_Typography />} />
          <Route path="/allinputs" element={<Mui2_FromHandlingWithTextFields />} />
          <Route path="/formvalidation" element={<Mui4_FormValidation />} />

          <Route path="/datagrid1" element={<Mui5_DataGrid />} />

          <Route path="/datagrid2" element={<Mui6_DataGrid />} />
          <Route path="/datagrid2/:id" element={<Mui6_DataGrid_Item />} />

          <Route path="/iconbutton" element={<Mui11_Icons />} />


          <Route path="/verbs1_verbs" element={<Z0001_Verbs_Verbs />} />
          <Route path="/verbs2_infinitive" element={<Z0002_Verbs_Infinitive />} />
          <Route path="/verbs3_simplepresent" element={<Z0003_Verbs_SimplePresent />} />
          <Route path="/verbs4_sein_haben" element={<Z0004_Verbs_sein_haben />} />
          <Route path="/verbs5_regular" element={<Z0005_Conjugation_regular_verbs />} />
          <Route path="/verbs6_irregular" element={<Z0006_Conjugation_irregular_verbs />} />
          <Route path="/verbs7_werden" element={<Z0007_Verbs_werden />} />
          <Route path="/verbs8_position" element={<Z0008_Verbs_in_main_sentence />} />
          <Route path="/verbs9_modal" element={<Z0009_Verbs_Modal />} />
          <Route path="/verbs10_modal_meaning" element={<Z0010_Verbs_Modal_Meaning />} />
          <Route path="/verbs11_modal_sentence" element={<Z0011_Verbs_Modal_Main_Sentence />} />
          <Route path="/verbs12_semimodals" element={<Z0012_Verbs_Semimodal />} />
          <Route path="/verbs13_complex" element={<Z0013_Verbs_Complex />} />
          <Route path="/verbs14_inseparable" element={<Z0014_Verbs_Inseparable />} />
          <Route path="/verbs15_complex_conjuagtion" element={<Z0015_Verbs_Complex_Conjugation />} />
          <Route path="/verbs16_complex_position" element={<Z0016_Verbs_Comple_Position />} />
          <Route path="/verbs17_reflexive" element={<Z0017VerbsReflexive />} />
          <Route path="/verbs18_reziproke" element={<Z0018VerbsReziproke />} />
          <Route path="/verbs19_imperative" element={<Z0019VerbsImperative />} />
          <Route path="/verbs20_presentperfect" element={<Z0020VerbsPresentPerfect />} />
          <Route path="/verbs21_presentperfect_syntax" element={<Z0021VerbsPresentPerfectSyntax />} />
          <Route path="/verbs22_presentperfect_list" element={<Z0022VerbsPresentPerfectList />} />
          <Route path="/verbs23_prateritum_sein" element={<Z0023_Prateritum />} />
          <Route path="/verbs24_prateritum_regular" element={<Z0024VerbsPrateritumRegular />} />
          <Route path="/verbs25_prateritum_irregular" element={<Z0025VerbsPrateritumIrregular />} />
          <Route path="/verbs26_passivevoicce" element={<Z0026VerbsPassiveVoice />} />
          <Route path="/verbs27_passivevoice_sentence" element={<Z0027VerbsPassiveSentence />} />
          <Route path="/verbs28_passivevoice_active" element={<Z0028VerbsPassiveToActive />} />
          <Route path="/verbs29_passivevoice_modals" element={<Z0029VerbsPassiveModals />} />
          <Route path="/verbs30_konjuktivII_moods" element={<Z0030VerbsKonjuktivIIMoods />} />
          <Route path="/verbs31_konjuktivII_formation" element={<Z0031VerbsKonjuktivIIFormation />} />
          <Route path="/verbs33_zu_plus_infinitive" element={<Z0033_Zu_plus_Infinitive />} />
          <Route path="/verbs32_infinitive_without_zu" element={<Z0032Infinitive_without_zu />} />
          <Route path="/verbs34_brauchen_zu_infinitive" element={<Z0034_brauchen_nur_zu_infinitive />} />
          <Route path="/Z0035_Verbs_ListWithRegularVerbs" element={<Z0035_Verbs_ListWithRegularVerbs />} />
          <Route path="/Z0036_Verbs_ListWithIrregularVerbs" element={<Z0036_Verbs_ListWithIrregularVerbs />} />
          <Route path="/Z0037_Verbs_ListWithSeparableVerbs" element={<Z0037_Verbs_ListWithSeparableVerbs />} />
          <Route path="/Z0038_Verbs_ListWithPrepositionalObject" element={<Z0038_Verbs_ListWithPrepositionalObject />} />
          <Route path="/Z0039_Verbs_withNominative" element={<Z0039_Verbs_withNominative />} />
          <Route path="/Z0040_Verbs_withAccusative" element={<Z0040_Verbs_withAccusative />} />
          <Route path="/Z0041_Verbs_withDative" element={<Z0041_Verbs_withDative />} />
          <Route path="/Z0042_Verbs_withDativeAndAccusative" element={<Z0042_Verbs_withDativeAndAccusative />} />
          <Route path="/Z0043_Verbs_PositionInSentence" element={<Z0043_Verbs_PositionInSentence />} />
          <Route path="/Z0044_Verbs_QuestionsWithPrepositionalObject" element={<Z0044_Verbs_QuestionsWithPrepositionalObject />} />
          <Route path="/Z0044_Verbs_QuestionsWithPrepositionalObject" element={<Z0044_Verbs_QuestionsWithPrepositionalObject />} />
          <Route path="Z0045_Verbs_WithPrepositionalObject" element={<Z0045_Verbs_WithPrepositionalObject />} />
          


          <Route path="/nouns50_gender" element={<Z0050_Nouns_Gender />} />
          <Route path="/nouns51_main_names" element={<Z0051_Nouns_Main_Names />} />
          <Route path="/nouns52_building_nouns" element={<Z0052_Nouns_Building_Nouns />} />
          <Route path="/nouns53_conjugation" element={<Z0053_Nouns_Conjugation />} />
          <Route path="/nouns54_cases" element={<Z0054_Nouns_Cases />} />
          <Route path="/nouns55_nDeklination" element={<Z0055_Nouns_nDeklination />} />
          <Route path="/nouns56_with_prepositions" element={<Z0056_Nouns_with_Prepositions />} />
          <Route path="/nouns57_from_verbs" element={<Z0057_Nouns_from_Verbs />} />


          <Route path="/adjectives61_comparatives" element={<Z0061_Adjactives_Comparatives />} />
          <Route path="/adjectives62_comparison_types" element={<Z0062_Adjetives_Comparison_types />} />
          <Route path="/adjectives63_conjugation" element={<Z0063_Adjectives_Conjugation />} />
          <Route path="/adjectives64_conjugation_overview" element={<Z0064_Adjectives_Conjugation_Overview />} />
          <Route path="/adjectives65_conjugation_superlative" element={<Z0065_Adjectives_Conjugation_Superlative />} />
          <Route path="/adjectives66_conjugation_as_nouns" element={<Z0066_Adjectives_as_nouns />} />
          <Route path="/adjectives67_with_prepositions" element={<Z0067_Adjectives_with_prepositions />} />
          <Route path="/adjectives68_vershieden" element={<Z0068_Adjectives_verschieden />} />
          <Route path="/adjectives69_derselbe" element={<Z0069_Adjectives_derselbe />} />
          <Route path="/adjectives70_ordinal_numbers" element={<Z0070_Adjectives_Ordinal_numbers />} />

          <Route path="/articles81_definite" element={<Z0081_Articles_Definite />} />
          <Route path="/articles82_possessive" element={<Z0082_Articles_Possessive />} />
          <Route path="/articles83_possessive_overview" element={<Z0083_Articles_Possessive_Overview />} />
          <Route path="/articles84_possessive_dieser" element={<Z0084_Articles_dieser />} />
          <Route path="/articles85_solcher" element={<Z0085_Articles_solcher />} />
          <Route path="/articles86_other" element={<Z0086_Articles_other />} />
          <Route path="/articles87_derselbe" element={<Z0087_Articles_derselbe />} />


          <Route path="/pronouns101_personal_nominative" element={<Z0101_Pronouns_Personal_Nominative />} />
          <Route path="/pronouns102_personal_accusative" element={<Z0102_Pronouns_Personal_Accusative />} />
          <Route path="/pronouns103_personal_dative" element={<Z0103_Pronouns_Personal_Dative />} />
          <Route path="/pronouns104_personal_overview" element={<Z0104_Pronouns_Personal_Overview />} />

          <Route path="/pronouns105_indefinite_man" element={< Z0105_Pronouns_Indefinite_man />} />
          <Route path="/pronouns106_indefinite_jemand" element={< Z0106_Pronouns_Indefinite_jemand />} />
          <Route path="/pronouns107_indefinite_ein" element={< Z0107_Pronouns_Indefinite_ein />} />
          <Route path="/pronouns108_indefinite_etwas" element={< Z0108_Pronouns_Indefinite_etwas />} />
          <Route path="/Z0109_OtherPronouns" element={< Z0109_OtherPronouns />} />
          <Route path="/Z0110_OtherPronounsAlles" element={< Z0110_OtherPronounsAlles />} />
          <Route path="/Z0111_Pronouns_die" element={< Z0111_Pronouns_die />} />
          <Route path="/Z0112_Pronouns_dieser" element={< Z0112_Pronouns_dieser />} />



          <Route path="/questionwords121_wer" element={< Z0121_Questionwords_wer2 />} />
          <Route path="/questionwords122_was" element={< Z0122_Questionwords_was />} />
          <Route path="/questionwords123_welcher" element={< Z0123_Questionwords_welcher />} />
          <Route path="/questionwords124_wasfurein" element={< Z0124_Questionwords_wasfurein />} />

          <Route path="/questionwords125_wie" element={< Z0125_Questionwords_wie />} />
          <Route path="/questionwords126_wieviel" element={< Z0126_Questionwords_wieViel />} />
          <Route path="/questionwords127_wann" element={< Z0127_Questionwords_wann />} />
          <Route path="/questionwords128_woher" element={< Z0128_Questionwords_woher />} />
          <Route path="/questionwords129_wo_wohin" element={< Z0129_Questionwords_wo_wohin />} />
          <Route path="/questionwords130_wo_warum" element={< Z0130_Questionwords_warun />} />
          <Route path="/questionwords131_wozu" element={< Z0131_Questionwords_wozu />} />

          <Route path="/adverbs141_sehr" element={< Z0141_Adverbs_sehr />} />
          <Route path="/adverbs142_dabei" element={< Z0142_Adverbs_dabei />} />
          <Route path="/adverbs143_immer" element={< Z0143_Adverbs_immer />} />

          <Route path="/sentenceConnection151_dass" element={< Z0151_SentenceConnection_dass />} />
          <Route path="/sentenceConnection152_denn" element={< Z0152_SentenceConnection_denn />} />
          <Route path="/sentenceConnection153_also" element={< Z0153_SentenceConnection_also />} />
          <Route path="/sentenceConnection154_trotzdem" element={< Z0154_SentenceConnection_trotzdem />} />
          <Route path="/sentenceConnection155_wenn" element={< Z0155_SentenceConnection_wenn />} />
          <Route path="/sentenceConnection156_um_zu" element={< Z0156_SentenceConnection_um_zu />} />
          <Route path="/sentenceConnection157_ohne_zu" element={< Z0157_SentenceConnection_ohne_zu />} />
          <Route path="/sentenceConnection158_als" element={< Z0158_SentenceConnection_als />} />
          <Route path="/sentenceConnection159_wie_als" element={< Z0159_SentenceConnection_wie_als />} />
          <Route path="/sentenceConnection160_ob" element={< Z0160_SentenceConnection_ob />} />
          <Route path="/sentenceConnection161_relativ1" element={< Z0161_SentenceConnection_Relativ1 />} />
          <Route path="/sentenceConnection162_relativ2" element={< Z0162_SentenceConnection_Relativ2 />} />
          <Route path="/sentenceConnection163_doppel" element={< Z0163_SentenceConnection_Doppel />} />
          <Route path="/sentenceConnection164_bevor" element={< Z0164_SentenceConnection_bevor />} />
          <Route path="/sentenceConnection165_aduso" element={< Z0165_SentenceConnection_aduso />} />
          <Route path="/Z0166_SentenceConnection_Overview" element={< Z0166_SentenceConnection_Overview />} />

          <Route path="/Z0167_UsefulExpressions" element={< Z0167_UsefulExpressions />} />
          <Route path="/Z0168_AllGramaticalPhenomena" element={< Z0168_AllGramaticalPhenomena />} />


          <Route path="/Z200_Prepositions_Time" element={< Z200_Prepositions_Time />} />
          <Route path="/Z201_Prepositions_Local" element={< Z201_Prepositions_Local />} />
          <Route path="/Z202_Prepositions_Countries" element={< Z202_Prepositions_Countries />} />
          <Route path="/Z203_Prepositions_Island" element={< Z203_Prepositions_Island />} />
          <Route path="/Z204_Prepositions_Cardinal" element={< Z204_Prepositions_Cardinal />} />
          <Route path="/Z205_Prepositions_aus" element={< Z205_Prepositions_aus />} />




          <Route path="/Z250_Various_lassen" element={< Z250_Various_lassen />} />
          <Route path="/Z251_Various_es" element={< Z251_Various_es />} />
          <Route path="/Z252_Various_wenn_ob" element={< Z252_Various_wenn_ob />} />
          <Route path="/Z206_Prepositions_TimeDefinitions" element={< Z206_Prepositions_TimeDefinitions />} />


        </Routes>

      </main>

      <Footer />

    </div>
  );

}

export default App;