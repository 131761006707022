import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0162_SentenceConnection_Relativ2() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Relative sentences Relative pronouns with 'wer, was, wo, worüber ...' " />
                <Header2 props="Relativsätze - Relativpronomen mit 'wer, was, wo, worüber ...' " />
            </Box>


            <Box className="global-box-not-header">
                <Header3_Middle props="1. " />
                <Header2 props="" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    As we saw in the chapter Relative pronouns with "der,die,das" the Relative sentences with "der, die, das, dennen" explains a noun, and they imported with the relative pronouns "der, die, das".
                    <br /><br />
                    For example:
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Relative sentences - Relativsätze</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Fluss, <span className="green-text"> der</span> durch Köln fließt, ist der Rhein.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The river <span className="green-text"> which</span> flows through Cologne, is the Rhine.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In that case we refered to the noun "der Fluss", the river.
                    <br /><br />
                    On the other hand, we can build relative sentences that they don't refered to a noun, but they refered to a <span className="green-text"> impersonal pronoun</span> <span className="red-text"> Impersonalpronomen</span>.
                     In that case we import the relative sentence with "was".
                </Typography>

            </Box>

            <ScrollToTopButton />





            <Box className="global-box-not-header">
                <Header3_Middle props="1. was" />
                <Header2 props="(that)" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The interrogative words "<span className="green-text"> wer,was, worüber, woran</span>" e.t.c. they can in some cases to import <span className="green-text"> Subordinate sentence</span> (Nebensatz).
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Alles, <span className="green-text"> was er über diese Sportart erzählt hat</span>, war sehr interessant.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Everything <span className="green-text"> that</span> he said about this sport was very interesting. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich danke meinem Vater für alles, <span className="green-text"> was</span> er getan hat.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I thank my father for everything, <span className="green-text"> that</span> he did. </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Usually is after the impersonal pronouns "nichts, alles, vieles, alles, das".
                </Typography>

            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0162_SentenceConnection_Relativ2