import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z0067_Adjectives_with_prepositions() {

    const verbs = [
        ["abhängig von + D", "to depend on", "εξαρτώμαι από"],
        ["arm an + D", "to be poor in sth", "φτωχός σε κάτι"],
        ["befreundet mit + D", "to be friends with sb", "φίλος με κάποιον"],
        ["beliebt bei + D", "to be popular with sb/sth", "δημοφιλής σε κάποιον/κάτι"],
        ["bereit zu + D", "to be ready/willing", "έτοιμος/πρόθυμος να"],
        ["berühmt für + A", "to be renowned for sth", "διάσημος για κάτι"],
        ["beschäftigt mit + D", "busy with", "απασχολημένος με"],
        ["dankbar für + A", "to be grateful to sb/sth", "ευγνώμων σε κάποιον/κάτι"],
        ["einverstanden mit + D", "to be in agreement with sb/sth", "συμφωνώ με κάποιον/κάτι"],
        ["enttäuscht von + D", "to be disappointed in/with", "απογοητευμένος από"],
        ["fertig mit + D", "ready with", "έτοιμος με"],
        ["froh über + A", "to be happy with", "χαρούμενος για"],
        ["geeignet für + A", "to be suitable for sth", "κατάλληλος για κάτι"],
        ["gewöhnt an + A", "to be used to sth", "συνηθισμένος σε κάτι"],
        ["glücklich über + A", "to be happy about sth", "χαρούμενος για κάτι"],
        ["ideal für + A", "ideal", "ιδανικός για"],
        ["interessiert an + D", "to be interested in sb/sth", "ενδιαφέρομαι για κάποιον/κάτι"],
        ["nett zu + D", "to be nice to sb", "ευγενικός με κάποιον"],
        ["neugierig auf + A", "to be curious about", "περίεργος για κάτι"],
        ["reich an + D", "to be rich in sth", "πλούσιος σε κάτι"],
        ["sicher vor + D", "to be certain about", "σίγουρος για κάτι"],
        ["stolz auf + A", "proud of", "περήφανος για"],
        ["traurig über + A", "sad about", "λυπημένος για"],
        ["typisch für + A", "typical of", "τυπικός για"],
        ["überzeugt von + D", "convinced of", "πεπεισμένος για"],
        ["verheiratet mit + D", "married to", "παντρεμένος με"],
        ["verliebt in + A", "in love with", "ερωτευμένος με"],
        ["verwandt mit + D", "related to", "συγγενής με"],
        ["wichtig für + A", "important for", "σημαντικός για"],
        ["wütend auf + A", "furious at", "θυμωμένος με"],
        ["zufrieden mit + D", "satisfied with", "ικανοποιημένος με"],
        ["mithilfe + G", "with the help of", "με τη βοήθεια"],
        ["mithilfe von + D", "with the help of", "με τη βοήθεια"],
        ["entfernt von + D", "far from", "μακριά από"]
    ];



    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Adjectives with prepositions" />
                <Header2 props="Adjektive mit Präpositionen" />
            </Box>

            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In German there are some adjectives that have syntax with <span className="green-text">Prepositional Object</span>, which means that the adjective connected with the object with a <span className="green-text"> preposition</span>.<br /><br />

                    Examples:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich bin <span className="green-text">mit</span> den Hausaufgaben fertig.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I am ready with the homeworks.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mein Bruder ist jetzt <span className="green-text">in</span> ein anderes Mädchen <span className="green-text"> verliebt</span>.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>My brother is now with another girl in love.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />




            <Box className="global-box-not-header" >

            <Header3_Middle props="1. List with adjectives with prepositions" />
            <Header2 props="Liste mit Adjektive mit Präpositionen" />



                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>

                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        {verbs.map((verb, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>
                            </TableRow>
                        ))}
                    </Table>

                </TableContainer>

            </Box>

            <ScrollToTopButton />


        </div>
    )
}

export default Z0067_Adjectives_with_prepositions