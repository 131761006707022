import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


function Z0054_Nouns_Cases() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box className="global-box-header">
        <Header1 props="Nouns: Cases" />
        <Header2 props="Nomen: Kasus" />
      </Box>



      <Box className="global-box-not-header" >


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          German has four cases. These cases signal how nouns (and pronouns, too) are used within a sentence, clause, or phrase. The cases are the following.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Nominative:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Nominativ</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>subject</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Accusative:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Akkusativ</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>direct object</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Dative:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Dativ</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>indirect object</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Genitive:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Genitiv</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>possessive cases</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          The grammatical functions reflected by these four cases correspond more or less to the <span className="green-text"> subject, direct object, indirect object, and possessive cases</span> in English usage. <br />
          Similarities and differences between German and English usage will be pointed out as each case is discussed.<br /><br />

          Most German nouns do not change their endings for the various cases. However, the articles and/or adjectives preceding the nouns do change their forms to reflect the case. <br />
          Thus, it is often necessary to look to the article and/or adjective in order to be able to identify the case of a noun.
        </Typography>

      </Box>

      <ScrollToTopButton />




      <Box className="global-box-not-header" >

        <Header3_Middle props="1. Nominative case" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          <span className="green-text">In Nominative</span> is the <span className="green-text"> subject (Subjekt)</span> of the sentence.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Der Film</span> beginnt um acht Uhr.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The film starts at eight o' clock.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> Vater</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Mutter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> das</span> Kind</TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Väter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Mütter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Kinder</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />





      <Box className="global-box-not-header" >

        <Header3_Middle props="2. Accusative case" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          In <span className="green-text"> Accusative</span> is the <span className="green-text">direct object (Objekt)</span> of the verb.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Fragst</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Lehrer?</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Will you ask the teacher?</TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> den</span> Vater</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Mutter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> das</span> Kind</TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Väter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Mütter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> die</span> Kinder</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />







      <Box className="global-box-not-header" >

        <Header3_Middle props="3. Dative case" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          There are some verb that take object in <span className="green-text"> Dative</span>.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Gib</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> katze</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>auch</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>einen Fisch</TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

          <Box sx={{ background: 'pink' }}>For example after the verb "geben"(to give) follows noun in dativ.</Box>
          <br /><br /><br />


          In plural dative, nomen take as ending an : <span className="green-text"> "n"</span>.
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> dem</span> Vater</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> Mutter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> dem</span> Kind</TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> den</span> Väter<span className="green-text"> n</span></TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> den</span> Mütter<span className="green-text"> n</span></TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> den</span> Kinder<span className="green-text"> n</span></TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />








      <Box className="global-box-not-header" >

        <Header3_Middle props="4. Genitive case" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          The <span className="green-text"> Genitive</span> case is used to show <span className="green-text"> possession</span> or <span className="green-text"> relationships between two nouns</span>.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Genitive</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wo</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Auto</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der Frau</span>?</TableCell>
            </TableRow>

            <TableRow>
              <TableCell colspan={4} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Where is the car of the Woman?</TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          1. Masculine and neuter take in <span className="green-text"> genitive singula</span>r an <span className="green-text"> "-s"</span> or <span className="green-text"> "-es"</span>. <br />
          Ending <span className="green-text"> "-es"</span> take the one word nouns and the nouns that ends in <span className="green-text"> "-s","-ß","-x","-z"</span>.<br /><br />

          2. Feminine don't take any ending in Genitive.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> des</span> Vater<span className="green-text"> s</span></TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> Mutter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> des</span> Kinde<span className="green-text"> s</span></TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> Väter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> Mütter</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> der</span> Kinder</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          Instead of genitive we can use <span className="green-text">"von + Dativ"</span> :
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das ist das Zimmer <span className="green-text">von Michaela</span>.</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>This is Michaela's room.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Berlin ist die Hauptstadt <span className="green-text"> von Deutschland</span>.</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Berlin is the capital of Germany.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />




      <Box className="global-box-not-header" >
        <Header3_Middle props="5. Full table of nomen conjugation" />

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
              <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Mann</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Frau</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Kind</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Leute</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Mann</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Frau</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Kind</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Leute</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem Mann</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Frau</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem Kind</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Leute<span className="green-text">n</span></TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Genitive:</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>des Mann<span className="green-text">es</span></TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Frau</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>des Kind<span className="green-text">es</span></TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Leute</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />



    </div>
  )
}

export default Z0054_Nouns_Cases