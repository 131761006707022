import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0069_Adjectives_derselbe() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="The adjectives: derselbe (the same), der gleiche (the same), selbst (the same) " />
                <Header2 props="Die Adjektive derselbe, der gleiche, selbst " />
            </Box>



            <Box className="global-box-not-header" >
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The two adjectives <span className="green-text">selb-, gleich</span>, are used always with the <span className="green-text">definite article</span> der,die,das.<br /><br />

                    At <span className="green-text">"derselbe"</span> the article is joined with the adjective. That has no influence with the conjugation neither of the article nor of the adjective.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>derselbe</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der gleiche</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">den</span>selb<span className="green-text">en</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">den</span> gleich<span className="green-text">en</span></TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >
                <Header3_Middle props="2. How to use them" />
                <Header2 props="" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Both are translated as <span className="green-text"> "the same"</span> but they used different.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>derselbe</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie trägt <span className="green-text">denselben</span> Pullover wie gestern</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>She wears <span className="green-text">the same</span> pullover as yesterday.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    At the example it is about for <span className="green-text"> the same</span> pullover.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '7em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>dergleiche</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie trägt <span className="green-text"> dengleichen</span> Pullover wie ihre Freundin.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>She wears <span className="green-text"> the same</span> pullover as her friend.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    At the example it is about for <span className="green-text"> two similar</span> pullovers.
                </Typography>
            </Box>

            <ScrollToTopButton />






            <Box className="global-box-not-header" >
                <Header3_Middle props="3. selbst, selber" />
                <Header2 props="" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The word <span className="green-text"> selbst</span> or <span className="green-text"> selber</span>, is translated <span className="green-text"> "the same"</span>. Is used after pronous or nouns. Sometimes it is used as "adverb". 
                    It declares that i do something <span className="green-text"> on my own</span>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>selbst</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Auto habe ich <span className="green-text">selbst</span> repariert.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I repaired the car myself.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>selbst</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich repariere das Auto <span className="green-text">selbst</span></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I repair the car by myself.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0069_Adjectives_derselbe