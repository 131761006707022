import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0040_Verbs_withAccusative() {
  return (
    <div>

    <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
    <Box className="global-box-header">
        <Header1 props="Verbs with Accusative" />
        <Header2 props="Verben mit Accusativ" />
    </Box>



    <Box className="global-box-not-header" >

        <Header3_Middle props="" />
        <Header2 props="" />        


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>Accusative</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Hilfst du mancmal</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>deiner Mutter</TableCell>                            
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>in der Küche?</TableCell>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Do you sometimes help your mother in the kitchen?</TableCell>
                </TableRow>    

                 <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Braucht er morgen</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Atlas?</TableCell>                            
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Does he need the atlas tomorrow?</TableCell>
                </TableRow>             

            </Table>
        </TableContainer>


        
        
        <Box sx={{ marginTop: "5em" }} ></Box>



        <Header3_Middle props="es gibt, mögen" sx={{ marginTop: "2em" }} />
        <Header2 props="" />


        <TableContainer component={Paper} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={3}>Accusative</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Gibt es hier</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>einen Polizisten?</TableCell>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Is there a policeman here?</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich mag</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den neuen Direktor.</TableCell>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I like the new director</TableCell>
                </TableRow>

            </Table>
        </TableContainer>

    </Box>

    <ScrollToTopButton />
</div>

  )
}

export default Z0040_Verbs_withAccusative