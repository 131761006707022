import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z201_Prepositions_Local() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box className="global-box-header">
        <Header1 props="Prepositions with local meaning" />
        <Header2 props="Präpositionen mit lokaler Bedeutung" />
      </Box>




      <Box className="global-box-not-header" >

        <Header3_Middle props="1. Persons" />
        <Header2 props="Personen" />



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>1a. When "I go to a person"</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>zu + Dativ</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          When we go to a person we use zu+Dativ.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Am Nachmittag ich gehe <spam class="green-text">zu meiner Freundin.</spam> (die Freundin)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                At the afternoon i will go to my girlfriend.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1b. When "I am with person" </Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>bei + Dativ</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          When I am with person we use bei+Dativ.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Ich bin <spam class="green-text">beim Zahnärzt.</spam> (der Zahnärzt, beim=bei+dem)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                I am at the dentist.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1c. When "I return for a person"</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>von + Dativ</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          When I return for a person we use von+Dativ.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Ich have <spam class="green-text">von meinen Eltern</spam> eine Kamera bekommen [ die Eltern (plural) ]
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                I get <spam class="green-text">from my parents </spam>a camera.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          With von + Dativ we declare the owner, instead of genitive.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Maria<spam class="green-text">s</spam> Fahrrad = das Fahrrad <spam class="green-text">von</spam> Maria
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Herrn Müller<spam class="green-text">s</spam> Tasche = die Tasche <spam class="green-text">von </spam>Herrn Müller.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>


      <ScrollToTopButton />





      <Box className="global-box-not-header" >

        <Header3_Middle props="2. in, auf, an ,über, unter, von hinter, neben, zwischen" />
        <Header2 props="" />



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>Use</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          With these prepositions we describe the position of which somebody or something placed. That have syntax with:<br /><br />

          1. <spam class="green-text">Accusative</spam>, when the verb shows <spam class="green-text">movement</spam> or change of an area.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Frau Müller <u>kommt</u> <spam class="green-text">in die Klasse</spam>. (die Klasse)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mrs Müller is comming in the class.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          2. <spam class="green-text">Dative</spam>, when the verb shοws <spam class="green-text">stasis</spam> or <spam class="green-text">movement <u>without</u> change of an area</spam>.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Wir <u>sitzen</u> <spam class="green-text">in der Klasse.</spam> (die Klasse).
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                We are sitting in the class.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          Some verbs that shows movement from one place to another are:
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gehen</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to go</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>kommen</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to come</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>bringen + Akkusativ</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to bring somebody something</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mit/nehmen + Akkusativ</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to take somebody something together </TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1. in </Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>in</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                <u>Kommst </u> du mit <spam class="green-text">in die Bibliothek?</spam>   (die Bibliothek) <br />
                <i>(The question here is "wohin?=to where?", so we use Akkusativ.)</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Will you come with me in the library?
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mein Handy <u>ist </u> <spam class="green-text">in der Tasche</spam>   (die Tasche) <br />
                <i>(The question here is "wo?=where?", so we use Dativ.)</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                My mobile is in the pocket.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>2. auf</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>above,on and in touch</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>stellt</u> die Teller <spam class="green-text">auf den Tisch</spam>.<br />
                (der Tisch, "wohin:to Where? so Akkusativ)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark puts the plates on the table
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Die Blumen <u>sind</u>  <spam class="green-text">auf dem Balkon</spam>.<br />
                (der Tisch, "wo:Where? so Dativ)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The flowers are on the balcony.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>





        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>3. an</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>at, close to</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Wer möchte <spam class="green-text">an die Tafel</spam> <u>kommen?</u> (die Tafel)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Who wants to come to the blackboard?
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>sitzt</u>  <spam class="green-text">am Tisch</spam> und isst (der Tisch)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark sits at the table and eats.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          The preposition "<spam class="green-text">an</spam>" is used usually with vertical surfaces like:<br />
          <spam class="red-text">die Wand</spam> (the wall)<br />
          <spam class="red-text">das Fenster</spam> (the window)<br />
          <spam class="red-text">die Tür</spam> (the door)<br />
        </Typography>



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>4. über</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>on, above,up and not in touch</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>wirft</u> den Ball  <spam class="green-text">über den Tisch</spam>. (der Tisch)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark throws the ball up the table.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Die lampe <u>hängt</u>  <spam class="green-text">über den Tisch</spam>.  (der Tisch)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The lamp hängt above the table.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>5. unter</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>unter</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Der ball <u>rollt</u> <spam class="green-text">unter das Bett</spam>. (das Bett)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The ball scrolling unter the bed.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                The ball <u>ist</u> <spam class="green-text">unter dem Bett</spam>. (das Bett)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The ball is unter the bed.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>6. vor</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>in front of</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>fährt</u> das Auto <spam class="green-text">vor das Haus</spam>. (das Haus)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark drives the car in front of the house.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Die Schlüssel <u>sind</u> <spam class="green-text">unter der Zeitung</spam>. (die Zeitung)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The keys are unter the newspaper.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>7. hinter</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>behind</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>fährt</u> das Auto <spam class="green-text">hinter das Haus</spam>. (das Haus)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark drives the car behind the house.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Die Tasche <u>ist</u> <spam class="green-text">hinter der Tür</spam>. (die Tür)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The bag ist behind the door.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>





        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>8. neben</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>next to</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>liegt</u> das Magazin <spam class="green-text">neben das Telefon</spam>. (das Telefon)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark puts the magazine next to the telefone.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Mark <u>sitzt</u> <spam class="green-text">neben seinem Freund</spam>.
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Mark sits next to his friend.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>9. zwischen</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>between</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Der Tisch <u>kommt</u> <spam class="green-text">zwischen das Soffa und den Sessel</spam>. (das Soda, der Sessel)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The table will placed between the sofa and the armchair.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Der Tisch <u>ist</u> <spam class="green-text">zwischen dem Soffa und dem Sessel</spam>. (das Soda, der Sessel)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The table is between the sofa and the armchair.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>


      <ScrollToTopButton />




      <Box className="global-box-not-header" >

        <Header3_Middle props="3. Position verbs - Positionverben" />
        <Header2 props="stellen,stehen - legen, liegen - setzen, sitzen - hängen - stecken" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

          These verbs called <spam class="green-text">position verbs</spam> because they shows the specific position somebody or something in the space.<br />
          The position verbs are used often with the prepositions "<spam class="green-text">in, an, auf, über, unter, vor, hinter, neben, zwischen</spam>" and have syntax with: <br /><br />
          1. <spam class="green-text">Accusative</spam> : when the verb shows <spam class="green-text">movement</spam>. <br />
          2. <spam class="green-text">Dative</spam> :  when the verb shows <spam class="green-text">stasis</spam>.
        </Typography>



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>1. stellen - stehen</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>to put something vertically - to stand vertically</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Ich <spam class="green-text">stelle</spam> das Buch <spam class="green-text">ins</spam> Bücherregal. (das Bücherregal)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                I put (<spam class="green-text">vertically</spam>) the book on the bookshelf.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Das buch  steht <spam class="green-text">im</spam> Bücherregal. (das Bücherregal, im=in+dem
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                I put (<spam class="green-text">vertically</spam>) the book on the bookshelf.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>2. legen - liegen</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>to put something horizontally - to lie</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Ich <spam class="green-text">lege</spam> das Buch <spam class="green-text">auf den</spam> Tisch. (der Tisch)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>                
                I put the book (<spam class="green-text">vertically</spam>) on the table.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Das buch <spam class="green-text">liegt</spam> <spam class="green-text">auf den</spam> Tisch. (der Tisch)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The book is placed (<spam class="green-text">horizontally</spam>) on the table.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>3. setzen - sitzen</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>to put someone/something to sit - to sit</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Die Katze <spam class="green-text">setzt</spam> <spam class="green-text">in den</spam> Korb. (der Korb) (The cat is walking to the basket)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>                
                The cat <spam class="green-text">sits</spam> in the basket.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Die Katze <spam class="green-text">sitzt</spam> <spam class="green-text">im</spam> Korb. (der Korb, im=in+der)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The cat <spam class="green-text">is sitting</spam> in the basket.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          Be carefull: <br/><br/>

          1. With the words:<br/>
          <spam class="green-text">Der Stuhl</spam><spam class="red-text"> (the chair)</spam> and <spam class="green-text">Das 
            Sofa</spam><spam class="red-text"> (the sofa)</spam> we use always the preposition <spam class="green-text">auf</spam>.<br/><br/>

          2. With the word: <br/>
          <spam class="green-text">Der Sessel</spam><spam class="red-text"> (the armchair)</spam> and
          we use always the preposition <spam class="green-text">in</spam>.
          </Typography>





          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>4. hängen - hängen</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>to put someone/something to hang - to hang</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Ich <spam class="green-text">hänge</spam> das Bild <spam class="green-text">an die</spam> Wand. (die Wand)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>                
                I <spam class="green-text">hang</spam> the picture at the wall. (I do the movement)
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Das bild <spam class="green-text">hängt</spam> <spam class="green-text"> an der</spam> Wand. (die Wand)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The picture <spam class="green-text">is hanged</spam> at the wand.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>5. stecken - stecken</Typography>
        <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>to put something in - something is in</Typography>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Ich <spam class="green-text">stecke</spam> das Heft <spam class="green-text">in die</spam> Tasche. (die Tasche)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>                
                I <spam class="green-text">put</spam> the exercise book in the bag.
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                Das Heft <spam class="green-text">steckt</spam> <spam class="green-text">in der</spam> Tasche. (die Tasche)
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                The exercise book <spam class="green-text">is putted</spam> the bag. 
              </TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>


      <ScrollToTopButton />



    </div >
  )
}

export default Z201_Prepositions_Local