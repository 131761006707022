import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText   } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function DrawerComp( {links} ) {

    const [open, setOpen] = useState(false);

    return (
        <>

            <Drawer             
                    anchor="left" open={open} onClose={ ()=>setOpen(false) } sx={{ backgroundColor:'redlight'}}

                    PaperProps={{
                        sx:{backgroundColor:'rgba(195,225,139,0.7609244381346288)'}
                    }}
            >
                <List>                                        
                    <ListItemButton  component={Link} to="/">
                        <ListItemIcon>
                            <ListItemText>
                                Home
                            </ListItemText>
                        </ListItemIcon>
                    </ListItemButton>

                    {/* <ListItemButton  component={Link} to="/muuri">
                        <ListItemIcon>
                            <ListItemText>
                                DataGrid
                            </ListItemText>
                        </ListItemIcon>
                    </ListItemButton> */}
                </List>

                {/* <List>                                        
                    {links.map( (link, index) =>
                        <ListItemButton key={index} divider onClick={ ()=>setOpen(false) } >
                            <ListItemIcon>
                                <ListItemText sx={{color:'black'}} >
                                    {link}
                                </ListItemText>
                            </ListItemIcon>
                        </ListItemButton>
                        
                    )}
                </List> */}

            </Drawer>

            <IconButton sx={{marginLeft:'auto', color:'white'}}  onClick={ ()=>setOpen(!open) }> 
                <MenuRoundedIcon/> 
            </IconButton>

        </>   

    )
}

export default DrawerComp