import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0012_Verbs_Semimodal() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Semimodal verbs" />
                <Header2 props="Semimodalverben" />
            </Box>

            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The verbs:<br /><br />

                    1. <span className="green-text"> hören</span> (to hear),<br />
                    2. <span className="green-text"> sehen</span> (to see),<br />
                    3. <span className="green-text"> lassen</span> (to leave), more about the meaning of the verb lassen here<br />
                    4. <span className="green-text"> helfen</span> (to help),<br />
                    5. <span className="green-text"> fühlen</span> (to feel),<br /><br />

                    6. <span className="green-text"> bleiben</span> (to stay),<br />
                    7. <span className="green-text"> fahren</span> (to drive),<br />
                    8. <span className="green-text"> gehen</span> (to go),<br />
                    9. <span className="green-text"> lehren</span> (to teach),<br />
                    10. <span className="green-text"> lernen</span> (to learn).<br /><br />

                    called   <span className="green-text">  Semimodalverben</span>  because they have the same syntax as modal verbs.<br />
                    The only different is that the Semimodalverbs and the infinitive haben different sugject.<br /><br />

                    Like modals, the verbs these verbs, can be used either alone or with the infinitive of another verb. <br />
                    This infinitive is referred to as a   <span className="green-text">dependent infinitive</span>.<br /><br />

                    Note: that the verb lassen means to leave when used alone.<br />
                    When used with a dependent infinitive, it means to let, allow.<br />
                    more about the verb "lassen" here.<br /><br />

                    Examples :
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich höre meine Eltern kommen.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I hear my parents coming.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Siehst du den Lehrer kommen?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Do you see the teacher comming? </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Wir hörten Musik </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>We heard Musik.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Wir hörten Anita singen. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>We heard Anita sing.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er sah Inge tanzen. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>He saw Inge dance. </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Hilfst du Peter?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Are you helping Peter?</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Hilfst du Peter schreiben? </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Are you helping Peter write? </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Meine Eltern lassen mich ins Konzert gehen.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>My parents let me to go to concert.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich lasse den Mantel hier.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I will leave the coat here.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich lasse Robert kommen</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I will let Robert come</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />

        </div>

    )
}

export default Z0012_Verbs_Semimodal