import React,{useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { Box, Typography } from '@mui/material';


import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';


function Mui6_DataGrid_Item() {

    const id = useParams().id; 
    console.log("id: ", id);

    const [todo, setTodo] = useState(null);

    useEffect( ()=>{
       
        async function fetchTodos() {            

            await fetch(`https://dummyjson.com/todos/${id}`)
                .then( (res) => res.json() )
                //.then( (data) => console.log(data) )   // Object
                .then( (data) => setTodo(data) )
                .catch( (err) => console.log((err) ))                
        }

        fetchTodos();

    }, [id] )

    console.log("todo: ", todo);


    return (
        
       <Box padding={10} width={"100%"} display={"flex"} flexDirection="colomn">            
            <Typography variant="h4" padding={1}>To do details:</Typography><br/><br/>

            <Typography variant="body1" padding={1}>id: {todo.id} </Typography><br/><br/>
            <Typography variant="body1" padding={1}>todo: {todo.todo} </Typography><br/><br/>
            <Typography variant="body1" padding={1}>
                completed: { todo.completed? (<AssignmentTurnedInRoundedIcon sx={{ color:'green' }} />) : (<AssignmentLateRoundedIcon sx={{ color:'orange' }}/>) } 
            </Typography><br/><br/>
            <Typography variant="body1" padding={1}>userId: {todo.userId} </Typography><br/><br/>

       </Box>
    )
}

export default Mui6_DataGrid_Item