import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0006_Conjugation_irregular_verbs() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Conjugation of irregular verbs in present" />
                <Header2 props="Konjugation Starke Verben im Präsens" />
            </Box>



            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em', marginBottom: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    These verbs change their stem of the vowel, in <span className="green-text">second</span> and <span className="green-text">third</span> person in <span className="green-text">Present</span>.
                </Typography>

                <TableContainer component={Paper} >
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich sprech-e</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du spr<span className="green-text">i</span>ch-st</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es spr<span className="green-text">i</span>ch-t</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir sprech-en</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr sprech-t</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie sprech-en</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0006_Conjugation_irregular_verbs