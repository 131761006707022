import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0001_Verbs_Verbs() {
    return (

        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Verbs Overview" />
                <Header2 props="Verben" />
            </Box>



            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8, paddingLeft: '15px', paddingBottom: '15px', paddingTop: '15px' }}>
                <Header3_Middle props="1. Transitive and Intransitive Verbs" />
                <Header2 props="" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                    Also as in English, German <span className="green-text">verbs</span> are divided into two basic grammatical categories:
                    <span className="green-text"> transitive verbs</span> and <span className="green-text">intransitive verbs.</span> <br /><br /><br />

                    <hr />
                    A <span className="green-text">transitive verb</span> is a verb that <span className="green-text">requires a direct object</span>. <br />
                    Take, for example, the verb "to describe".<br />
                    He describes is an incomplete statement; it is necessary to specify what he describes.<br /><br /><br />

                    <hr />
                    An <span className="green-text">intransitive verb</span> is one that does <span className="green-text">not require a direct object</span>.<br />
                    An example of an intransitive verb is "to live". The utterance he lives" can by itself be a complete sentence and thus can stand alone.<br /><br />

                    Some verbs may be used either as transitive verbs or as intransitive verbs, e.g., he whistled as he worked and he whistled a tune.<br /><br /><br />

                    <Link to="/Z0041_Verbs_withDative" style={{ textDecoration: 'underline', color: 'orange' }}>
                        Press here
                    </Link> to learn about verbs with dative.


                </Typography>
            </Box>


            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8, paddingLeft: '15px', paddingBottom: '15px', paddingTop: '15px', marginTop: '20px' }}>
                <Header3_Middle props="2. Irregular Verbs and Regular Verbs " />
                <Header2 props="" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                    There are two basic types of verbs in German: <span className="green-text">regular verbs</span> and <span className="green-text">irregular verbs</span>.<br /><br />

                    <span className="green-text">Regular</span> verbs keep the <span className="green-text">same stem vowel</span> throughout all their forms, and strong verbs have stem vowel
                    changes in their past tenses.<br />
                    As a general rule, the weak verbs have regular and predictable forms, whereas the strong verbs are irregular.<br />
                    Since, however, there are irregular weak verbs and certain predictable patterns for strong verbs,
                    we will not use the terms regular and irregular verbs, but will instead refer to verbs as weak, strong, or mixed.<br /><br />

                    Since the patterns for the strong verbs and the irregular weak verbs are not fully predictable, it is essential to learn all the principal parts of such verbs when they are first introduced.
                    The three principal parts of a verb that must be learned are the <span className="green-text">infinitive, the past tense</span>, and the <span className="green-text">past participle</span>.<br />
                    In some cases a fourth form of the German verb must also be memorized—the second-or third-person singular of the present tense, since a small group of strong verbs also
                    have vowel changes in these forms. Speakers of English are already familiar with the phenomenon that some verbs have no vowel changes in their various forms while others do.
                    Compare, for example, regular verbs such as <span className="green-text">play, played, played</span> and <span className="green-text">paint, painted, painted</span>
                </Typography>
            </Box>

            <ScrollToTopButton />


        </div>

    )
}

export default Z0001_Verbs_Verbs