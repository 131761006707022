import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z202_Prepositions_Countries() {
  return (
    <div>

       <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box className="global-box-header">
          <Header1 props="Prepositions for Continents, countries, towns, villages" />
          <Header2 props="Kontinente, Länder, Ständte, Dörfer" />
        </Box>


        <Box className="global-box-not-header" >

          {/* <Header3_Middle props="" />
          <Header2 props="" /> */}



          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>1a. Origin from a place</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>aus + Dativ</Typography>

          <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            As we know continents, countries, towns and villages don't have plural number, except some exceptions. In that case we use aus + Dativ.<br/><br/>
            Also with the words:<br/>
            &nbsp;&nbsp;&nbsp;1. <spam class="red-text">das Land</spam> <spam class="green-text">(country)</spam><br/>
            &nbsp;&nbsp;&nbsp;2. <spam class="red-text">das Ausland</spam> <spam class="green-text">(abroad)</spam><br/>
            we use <spam class="red-text">aus + Dativ</spam>.
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Unsere Nachbarn sind <spam class="green-text">aus der</spam> Türkei.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  Our neibours are  <spam class="green-text">from Turkey</spam>.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>


          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  In Deutschland studieren viele Junge Leute  <spam class="green-text">aus dem Ausland</spam>. (das Ausland)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  In Germany there are many students <spam class="green-text">from abroad.</spam>
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>





          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1b. Staying in a place</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>in + Dativ</Typography>

          <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            When the main verb express stasis (sein, wohnen,arbeiten e.t.c.) then we use in + Dativ.<br/><br/>


            Also with the words:<br/>
            &nbsp;&nbsp;&nbsp;1. <spam class="red-text">das Land</spam> <spam class="green-text">(country)</spam><br/>
            &nbsp;&nbsp;&nbsp;2. <spam class="red-text">das Ausland</spam> <spam class="green-text">(abroad)</spam><br/>
            we use in + Dativ.
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Meine Tante <u>wohnt</u> <spam class="green-text">in Wien</spam> (no article)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  My aunt lives <spam class="green-text">in Vienna.</spam>
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>


          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Zürich <u>liegt</u> <spam class="green-text">in der Schweiz</spam> (der Schweiz - with article)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  Zurich <spam class="green-text">is in Switzerland.</spam>
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>




          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1c. Movement to a place</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>nach + Dativ</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>in + Akkusativ</Typography>

          <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
              When the main verb express movement (gehen, fliegen, unziehen, fahren, reisen e.t.c.) then we use   <spam class="red-text">nach + Dativ</spam>.<br/><br/>

              Also  with the words:<br/>
              &nbsp;&nbsp;&nbsp;1. <spam class="red-text">das Land</spam> <spam class="green-text">(country)</spam><br/>
              &nbsp;&nbsp;&nbsp;2. <spam class="red-text">das Ausland</spam> <spam class="green-text">(abroad)</spam><br/>
              &nbsp;&nbsp;&nbsp;3. and with countries that have article.<br/>
            
              we use <spam class="red-text">in + Akkusativ</spam> (Be carefull: not nach + Dativ).
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Wir <u>fahren</u> in Sommer<spam class="green-text"> nach Frankreich.</spam> (no article)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  We will drive the summer <spam class="green-text">to France</spam>.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>


          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Herr Müller <u>fliegt</u> <spam class="green-text">in die USA.</spam> (die USA)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  Mr Müller <spam class="green-text">is flying to the United States.</spam>
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>



          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1d. Return from a place</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>von + Dativ / aus + Dativ</Typography>          

          <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Die Klasse <u>kommt</u> morgen <spam class="green-text"> aus Italien </spam>zurück. (no article)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  The class is coming from  <spam class="green-text">back from</spam> Italy.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>


          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Die Klasse <u>kommt</u> morgen <spam class="green-text"> aus der Schweiz </spam>zurück. (die Schweiz)
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  The class is coming  <spam class="green-text">back from</spam> Switzerland.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>





          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1e. Description of a place </Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>bei + Dativ</Typography>          

          <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            When we want to say that a place is close to another we use the preposition "bei".
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Reutlingen ist <spam class="green-text"> bei</spam> Stuttgart. 
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                Reutlingen is <spam class="green-text"> near</spam> Stuttgart.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>         





          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1f. Description of a route</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>von + Dativ .... nach + Dativ</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>bis</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>von + Dativ .... bis + Dativ</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>ab</Typography>
          <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '0em' }}>über</Typography>

          <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Der Eurostar fährt täglich <spam class="green-text">von London nach Paris.</spam>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  The Eurostart goes every day <spam class="green-text">from</spam> London <spam class="green-text">to</spam> Paris.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>         
          

          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Der Eurostar fährt täglich <spam class="green-text">bis Paris</spam>.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  The Eurastart goes every day <spam class="green-text">until</spam> Paris.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>



          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Der Eurostar fährt täglich <spam class="green-text">bis Paris</spam>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  The Eurastart goes every day <spam class="green-text">from</spam> London <spam class="green-text">to</spam> Paris.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>


          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Der Eurostar fährt täglich <spam class="green-text">ab</spam> London.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  The Eurastart goes every day <spam class="green-text">from</spam> London.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>


          <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                  Wir fahren <spam class="green-text">von</spam> München <spam class="green-text">über</spam> Nürnberg <spam class="green-text">nach</spam> Frankfurt.
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                  We drive <spam class="green-text">from</spam> Munich <spam class="green-text">to</spam> Frankfurt <spam class="green-text">via</spam> Nuremberg.
                </TableCell>
              </TableRow>

            </Table>
          </TableContainer>         


        </Box>


        <ScrollToTopButton />


    </div>
  )
}

export default Z202_Prepositions_Countries