import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z251_Various_es() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box className="global-box-header">
          <Header1 props="The uses of personal pronoun `es`" />
          <Header2 props="Die Verwendung des Personalpronomen `es`" />
      </Box>



      <Box className="global-box-not-header" >

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            The personal pronoun "<spam class="green-text">es</spam>" has many uses:<br /><br /><br />


            1. It replaces neutral nouns:
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                        Das ist mein Haus. Wie findest du <spam class="green-text">es</spam>?
                    </TableCell>
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                        This is my home. How do you find <spam class="green-text">it</spam>?
                    </TableCell>
                </TableRow>                      

            </Table>
        </TableContainer>      



        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            2. The word <spam class="green-text">es</spam> it is a sign that follows a <spam class="green-text">Subordinate clause</spam> <spam class="red-text">(Nebensatz)</spam>.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main clause (Hauptsatz)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subordinate clause (Nebensatz)</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich hasse <spam class="green-text">es</spam></TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wenn jemand zu viel redet.</TableCell>                    
                </TableRow>

                <TableRow>                    
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }} colSpan={2}> 
                        I hate <spam class="green-text">it</spam>, when somebody talks too much.
                    </TableCell>
                </TableRow>

            </Table>
        </TableContainer>

      </Box>



      <ScrollToTopButton />


      <Box className="global-box-not-header">

        <Header3_Middle props="Impersonal verbs" />
        <Header2 props="Unpersönlich verben" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            3. There are some <spam class="green-text">impersonal verbs</spam> <spam class="red-text">unpersönlich verben</spam> which have no subject. <br/>
            Τhat means no one is acting. <br/>
            In that case we use the personal pronoun "es" as subject of these impersonal verb.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>
                <TableBody>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> stimmt, dass</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is true, that</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> ist gut, dass</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is good, that</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> ist schlecht, dass</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is bad, that</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> ist schade, dass</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is pity, that</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> dauert</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it takes time</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> gibt</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>there is</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">es</span> klappt</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it works out</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wie geht <span className="green-text">es</span> dir?</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>how are you?</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> geht</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>so and so</TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            In that team of impersonal verbs and expressions belongs the expressions which describes the wheather.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>
                <TableBody>         
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> regnet</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is raining</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> schneit</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is snowing</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist kalt</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is cold</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist kühl</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is cool</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist warm</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is warm</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist heiß</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is hot</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist trocken</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is dry</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist feucht</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is wet</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist nass</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is very wet</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist neblig</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is foggy</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist wolkig</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is cloudy</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Es</span> ist windig</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>it is windy</TableCell>
                </TableRow>
                </TableBody>
            </Table>
        </TableContainer>


      </Box>


      <ScrollToTopButton />


    </div>
  )
}

export default Z251_Various_es