import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableContainer, TableRow, TableCell, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';

function Z0038_Verbs_ListWithPrepositionalObject() {

    const verbs = [
        ["sich entschuldigen bei + Dativ", "to ask excuse for somebody", "ζητώ συγνώμη από κάποιον"],
        ["sich entschuldigen für + Akkusativ", "to ask excuse for something", "ζητώ συγνώμη για κάτι"],
        ["sich bedanken bei + Dativ", "to thank somebody", "ευχαριστώ κάποιον - λέω ευχαριστώ σε"],
        ["sich bedanken für + Akkusativ", "to thank of sb/sth", "ευχαριστώ κάτι - λέω ευχαριστώ για κάτι"],
        ["danken für + Akkusativ", "to thank for something", "ευχαριστώ για"],
        ["denken an + Akkusativ", "to think for something", "ευχαριστώ για"],
        ["sich informieren bei + Dativ", "to inform somebody", "ενημερώνομαι από κάποιον"],
        ["sich informieren über + Akkusativ", "to inform about something", "ενημερώνομαι / ενημερώνω σχετικά με κάτι/θέμα"],
        ["sich beschweren bei + Dativ", "to complain to somebody", "διαμαρτύρομαι σε κάποιον"],
        ["sich beschweren über + Akkusativ", "to complain about something", "διαμαρτύρομαι για κάτι"],
        ["sich erkündigen bei + Dativ", "to ask informations about somebody", "ζητώ πληροφορίες από κάποιον"],
        ["sich erkündigen nach + Dativ", "to ask informations about something", "ζητώ πληροφορίες για κάτι"],
        ["diskutieren mit + Dativ", "to discuss with somebody", "συζητώ με κάποιον"],
        ["diskutieren über + Akkusativ", "to discuss for something", "συζητώ για κάτι"],
        ["sich unterhalten mit + Dativ", "to talk with somebody", "συζητώ με κάποιον - κουβεντιάζω"],
        ["sich unterhalten über + Akkusativ", "to talk for something", "συζητώ για κάτι"],
        ["sprechen mit + Dativ", "to speak with somebody", "μιλώ με κάποιον"],
        ["sprechen über + Akkusativ", "to speak about something", "μιλώ για κάτι"],
        ["sprechen von + Dativ", "to speak about something", "μιλώ για κάτι"],
        ["reden mit + Dativ", "to speak with somebody", "μιλώ με κάποιον"],
        ["reden über + Akkusativ", "to speak for something", "μιλώ για κάτι"],
        ["sich streiten mit + Dativ", "to argue with somebody", "μαλώνω, τσακώνομαι με κάποιον"],
        ["sich streiten um + Akkusativ", "to argue for something", "μαλώνω, τσακώνομαι για κάτι"],
        ["anrufen bei + Dativ", "to call somebody", "παίρνω κάποιον τηλέφωνο"],
        ["telefonieren mit + Dativ", "to speak with somebody on the telephone", "μιλώ στο τηλέφωνο με"],
        ["anfangen mit + Dativ", "to begin", "αρχίζω/ ξεκινώ με"],
        ["beginnen mit + Dativ", "to begin", "αρχίζω/ ξεκινώ με"],
        ["erzählen über + Akkusativ", "to tell a story", "διηγούμαι για"],
        ["erzählen von + Dativ", "to tell a story", "διηγούμαι για"],
        ["wissen über + Akkusativ", "to know for something", "ξέρω/γνωρίζω για (γενικές γνώσεις)"],
        ["wissen von + Dativ", "to know for something", "ξέρω/γνωρίζω για (πιο προσωπικό)"],
        ["jdn fragen nach + Dativ", "to ask somebody for something", "ρωτώ (κάποιον) για κάτι"],
        ["fragen um + Akkusativ", "to ask for permission", "ζητώ (την άδεια του)"],
        ["Um Erlaubnis fragen.", "Ask for permission.", "Ζητώ την άδεια"],
        ["schreiben an + Akkusativ", "to write to somebody", "γράφω σε κάποιον"],
        ["schreiben über + Akkusativ", "to write for something", "γράφω για κάτι"],
        ["sich bewerben bei + Dativ", "to apply for the position to the company", "κάνω αίτηση σε"],
        ["sich bewerben um + Akkusativ", "to apply for the position as", "κάνω αίτηση για"],
        ["sich freuen über + Akkusativ", "to be glad for something happened in the past", "χαίρομαι για κάτι που έχει συμβεί στο παρελθόν"],
        ["freuen auf + Akkusativ", "to be glad for something will happened in the future", "χαίρομαι για κάτι που θα συμβεί μέλλον"],
        ["sich interessieren für + Akkusativ", "to be interested in sb/sth", "ενδιαφέρομαι για"],
        ["arbeiten bei + Dativ", "to work at", "δουλεύω σε"],
        ["aufhören mit + Dativ", "to stop", "σταματώ"],
        ["gehören zu + Dativ", "to belong", "ανήκω"],
        ["aufpassen auf + Akkusativ", "to pay attention", "προσέχω"],
        ["jdn bitten um + Akkusativ", "to ask somebody", "ζητώ"],
        ["Bitten Sie ihn um hilfe.", "Ask him for help.", "Ζητήστε του να σας βοηθήσει"],
        ["brauchen für + Akkusativ", "to need for", "χρειάζομαι για"],
        ["einverstanden sein mit + Dativ", "to be in agreement with sb/sth", "είμαι σύμφωνος με κάτι"],
        ["glauben an + Akkusativ", "to thing", "πιστεύω σε"],
        ["lachen über + Akkusativ", "to laugh at sb/sth", "γελώ με (κάτι/θέμα)"],
        ["nachdenken über + Akkusativ", "to think about sb/sth", "συλλογίζομαι (κάτι/θέμα)"],
        ["zurückdenken über + Akkusativ", "to think back", "θυμάμαι, αναπολώ"],
        ["sich ärgern über + Akkusativ", "to be/get annoyed about sb/sth", "τσατίζομαι με"],
        ["sich aufregen über + Akkusativ", "to get upset about sb/sth", "εκνευρίζομαι με"],
        ["suchen nach + Dativ", "to search, or be looking for sb/sth", "ψάχνω για, αναζητώ"],
        ["spielen mit + Dativ", "to play with something", "παίζω"],
        ["vergleichen mit + Dativ", "to compare", "συγκρίνω με"],
        ["warten auf + Akkusativ", "to wait", "περιμένω"],
        ["Ein Mann wartet auf dich.", "A man is waiting for you.", "Ένας άντρας σε περιμένει"],
        ["weinen über + Akkusativ", "to cry for sb/sth", "κλαίω για (κάτι/θέμα)"],
        ["abhängen von + Dativ", "to depend on sb/sth", "εξαρτώμαι από"],
        ["zusammenhängen mit + Dativ", "to connect", "συνδέεται"],
        ["achten auf + Akkusativ", "to pay attention to sb/sth", "δίνω προσοχή σε"],
        ["ausgeben für + Akkusativ", "to spend sth on sth", "ξοδεύω για κάτι"],
        ["sich bemühen um + Akkusativ", "to try hard", "καταβάλω προσπάθειες για"],
        ["sich beschäftigen mit + Dativ", "to occupy sb with sth", "ασχολούμαι με"],
        ["jdn einladen zu + Dativ", "to invite sb to sth", "(προς)καλώ κάποιον για"],
        ["sich entscheiden für + Akkusativ", "to decide in favour of sb/sth", "αποφασίζω υπέρ για"],
        ["sich entscheiden gegen + Akkusativ", "to decide against of sb/sth", "αποφασίζω κατά για"],
        ["sich entschließen zu + Dativ", "to decide on sth", "αποφασίζω να κάνω κάτι, επιλέγω"],
        ["sich erinnern an + Akkusativ", "to remember sb/sth", "θυμάμαι, (υπεν)θυμίζω κάτι"],
        ["sich fürchten vor + Dativ", "to be afraid of sb/sth", "φοβάμαι"],
        ["sich gewöhnen an + Akkusativ", "to get, or become used to sb/sth", "συνηθίζω, εξοικειώνομαι με"],
        ["hoffen auf + Akkusativ", "to hope for something", "ελπίζω σε κάτι"],
        ["kämpfen für + Akkusativ", "to fight for something", "αγωνίζομαι για"],
        ["kämpfen gegen + Akkusativ", "to fight against something", "αγωνίζομαι ενάντια σε"],
        ["sich konzentrieren auf + Akkusativ", "to concentrate on something", "συγκεντώνομαι / είμαι συγκεντρωμένος σε"],
        ["sich kümmern um + Akkusativ", "to take care of something", "φροντίζω, νοιάζομαι για"],
        ["leiden an + Dativ", "to suffer from something", "πάσχω από (μία αρρώστια)"],
        ["leiden unter + Dativ", "to suffer because of situation", "πάσχω από (μία κατάσταση) υποφέρω από (kopfschmerzen)"],
        ["sorgen für + Akkusativ", "to look after sb/sth", "φροντίζω για, περιποίηση"],
        ["sterben an + Dativ", "to die of sth", "πεθαίνω από"],
        ["mitmachen bei + Dativ", "to take part in sth", "παίρνω μέρος, συμμετέχω σε"],
        ["teilnehmen an + Dativ", "to participate, or take part in a contest", ""],
        ["träumen von + Dativ", "to dream about sb/sth", "συμμετέχω σε"],
        ["sich verabreden mit + Dativ", "to arrange to meet", "δίνω ραντεβού με"],
        ["sich verabschieden von + Dativ", "to say goodbye to somebody", "αποχαιρετώ"],
        ["sich verlassen auf + Akkusativ", "to rely, or depend on sb/sth", "βασίζομαι, στηρίζομαι σε"],
        ["sich verlieben in + Akkusativ", "to fall in love with somebody", "ερωτεύομαι"],
        ["sich vorbereiten auf + Akkusativ", "to prepare for", "προετοιμάζομαι για"],
        ["sich wenden an + Akkusativ", "to turn to somebody", "απευθύνομαι σε"],
        ["sich wundern über + Akkusativ", "to be surprised at sb/sth", "απορώ με"],
        ["zweifeln an + Dativ", "to doubt about sb/sth", "αμφιβάλλω για"],
        ["zwingen zu + Dativ", "to force", "αναγκάζω"],
        ["wohnen mit + Dativ", "to live with", "συγκατοικώ"],
        ["wohnen bei + Dativ", "to stay with", "φιλοξενούμαι"],
        ["warnen vor + Dativ", "to warn sb about sb/sth", "προειδοπoιώ (για τις συνέπειες)"],
        ["belieben bei + Dativ", "to desire", "επιθυμώ"],
        ["drücken auf + Akkusativ", "to push sb/sth", "πιέζω, πατώ"],
        ["hängen in + Akkusativ", "to dangle", "κρεμώ"],
        ["flirten mit + Dativ", "to flirt with somebody", "φλερτάρω"],
        ["sich verloben mit + Dativ", "to get engaged to somebody, each other", "αρραβωνιάζομαι"],
        ["schimpfen über + Akkusativ", "to grumble about sb/sth", "βάζω τις φωνές για"],
        ["sich sehr gut verstehen mit + Dativ", "to communicate gut with somebody", "συνεννοούμαι, τα πάω καλά"],
        ["sein für + Akkusativ", "to be for", "είμαι υπέρ"],
        ["sein gegen + Akkusativ", "to be against", "είμαι κατά"],
        ["es ernst meinen mit + Dativ", "to be serious about something", "παίρνω κάτι στα σοβαρά"],
        ["absehen von + Dativ", "to ignore, or disregard something", "εξαιρώ, δε λαμβάνω υπόψη"],
        ["schmecken nach + Dativ", "to taste of something", "έχω γεύση από"],
        ["sich einigen auf + Akkusativ", "---", "συμφωνώ (σε κάτι)"],
        ["kontakt finden zu + Dativ", "i make an acquaintance, i meet someone", "κάνω μια γνωριμία, γνωρίζομαι με κάποιον"],
        ["denken an + Akkusativ", "to thing", "σκέφτομαι"],
        ["denken über + Akkusativ", "to have opinion of", "έχω γνώμη για"],
        ["es geht um + Akkusativ", "the thing is about", "το θέμα είναι, πρόκειται να"],
        ["In diesem Test geht es um...", "This test is about...", "Αυτό το κείμενο είναι για..."],
        ["verzichten auf + Akkusativ", "to forgo something", "παραιτούμαι από"],
        ["sich spezialisieren auf + Akkusativ", "to specialize in something", "(εξ) ειδικεύομαι σε"],
        ["gelten in + Akkusativ", "to be valid in something", "ισχύει σε"],
        ["gelten für + Akkusativ", "to be valid for somebody", "ισχύει για"],
        ["reagieren auf + Akkusativ", "to react to something", "αντιδρώ σε"],
        ["drohen mir + Dativ", "to threaten somebody with something", "απειλώ με"],
        ["passen zu + Dativ", "to match", "ταιριάζει σε"],
        ["beschützen vor + Dativ", "to protect for", "προστατεύω από"],
        ["kommen zu + Dativ", "to end up, to come to a conclusion", "καταλήγω να, φτάνω να"],
        ["sich treffen mit + Dativ", "to meet somebody", "συναντάω, συνταντιέμαι"],
        ["befreunde sein mit + Dativ", "to make, or become friends with somebody", "είμαι φίλος με κάποιον"],
        ["sich ein Bild machen von + Dativ", "to stand over", "σχηματίζω/εικόνα για"],
        ["schwärmen von + Dativ", "I'm talking enthusiastically about", "μιλώ για ενθουασμό για"],
        ["wirken auf + Akkusativ", "i look at somebody", "φαίνομαι σε κάποιον"],
        ["es handelt sich um + Akkusativ", "It is about", "πρόκειται για"],
        ["helfen bei + Dativ", "---", "βοηθώ σε"],
        ["antworten auf + Akkusativ", "to answer to", "απαντώ σε"],
        ["schwärmen von + Dativ", "to enthuse about", "μιλώ για ενθουασμό για"],
        ["wirken auf + Akkusativ", "to have an effect on", "φαίνομαι σε κάποιον"],
        ["es geht um + Akkusativ", "it is about", "πρόκειται για"],
        ["anfangen mit + Dativ", "to start with", "κάνω κάτι με, αξιοποιώ"],
        ["berichten von + Dativ", "to report on", "περιγράφω, μιλώ για, αναφέρω"],
        ["sich knien in + Akkusativ", "to be deeply involved in", "ασχολούμαι εντατικά με"],
        ["typisch für + Akkusativ", "typical of", "χαρακτηριστικός για"],
        ["gedacht sein zu + Dativ", "intended for", "έχει ως σκοπό"],
        ["sich auskennen mit + Dativ", "to be familiar with", "ξέρω από"],
        ["sich beteiligen an + Dativ", "to participate in", "συμμετέχω σε"],
        ["jdn bekannt machen mit + Dativ", "to introduce sb to", "κάνω κάτι γνωστό σε κάποιον, γνωρίζω κάτι σε κάποιον"],
        ["vergleichen mit + Dativ", "to compare with", "συγκρίνω με"],
        ["verantwortlich sein für + Akkusativ", "to be responsible for", "υπεύθυνος για"],
        ["absprechen mit + Dativ", "to coordinate with", "συνεννοούμαι με"],
        ["berichten über + Akkusativ", "to report about", "διηγούμαι"],
        ["rechnen mit + Dativ", "to count on", "υπολογίζω, περιμένω"],
        ["gelten als + Nominative + Adj", "to be considered as", "θεωρούμαι"],
        ["halten für + Akkusativ", "to consider as", "θεωρώ"],
        ["vergeben an + Akkusativ", "to award to", "προσφέρω, δίνω σε"],
        ["umgehen mit + Dativ", "to deal with", "διαχειρίζομαι"],
        ["bestehen in + Dativ", "to consist in", "έγκειται, συνιστάται σε"],
        ["umherlaufen", "to run around", "τρέχω από δω και από εκεί, τριγυρίζω"],
        ["kämpfen mit + Dativ", "to struggle with", "παλεύω με"],
        ["aufnehmen in + Akkusativ", "to include in", "εισάγω, συμπεριλαμβάνω"],
        ["sich einlassen auf + Akkusativ", "to engage in", "ασχολούμαι πολύ, έχω στενή σχέση με"],
        ["sich gut verstehen mit + Dativ", "to get along well with", "συνεννοούμαι, τα πάω καλά με"],
        ["rechnen mit + Dativ", "to expect", "υπολογίζω, περιμένω"],
        ["dienen zu + Dativ", "to serve for", "χρησιμεύω για"],
        ["sich richten an + Akkusativ", "to be aimed at", "απευθύνομαι σε"],
        ["tendieren zu + Dativ", "to tend to", "τείνω προς"],
        ["halten für + Akkusativ", "to consider as", "θεωρώ"],
        ["einhergehen mit + Dativ", "to accompany", "συνοδεύω, είμαι συνδεδεμένος με"],
        ["es liegt an + Dativ", "it is due to", "οφείλεται σε"],
        ["unabhängig von + Dativ", "independent of", "ανεξάρτητα από"],
        ["liegen an + Dativ", "to be due to", "οφείλεται σε"],
        ["verbinden mit + Dativ", "to connect with", "συνδυάζω με"],
        ["eingreifen in + Akkusativ", "to intervene in", "επεμβαίνω σε"],
        ["kämpfen um + Akkusativ", "to fight for", "αγωνίζομαι, παλεύω για"],
        ["bestehen aus + Dativ", "to consist of", "αποτελούμαι από"],
        ["schützen vor + Dativ", "to protect from", "προστατεύω από"],
        ["rechnen mit + Dativ", "to count on", "υπολογίζω, περιμένω"],
        ["verzichten auf + Akkusativ", "to give up", "παραιτούμαι από"],
        ["einführen in + Akkusativ", "to introduce into", "εισάγω σε"],
        ["sparsam umgehen mit + Dativ", "to economize on", "κάνω οικονομία σε"],
        ["beitragen zu + Dativ", "to contribute to", "συνεισφέρω σε, συμβάλλω σε"],
        ["sich Sorgen machen um + Akkusativ", "to worry about", "ανησυχώ για"],
        ["verwandt sein mit + Dativ", "to be related to", "έχω συγγένεια, συγγενεύω με"],
        ["reagieren auf + Akkusativ", "to react to", "αντιδρώ, ανταποκρίνομαι σε"],
        ["einsetzen in + Dativ", "to use in", "εφαρμόζω, χρησιμοποιώ σε"],
        ["kontakt halten zu + Dativ", "to keep in touch with", "διατηρώ επαφή με"],
        ["eintauchen in + Akkusativ", "to immerse in", "βουτώ σε"],
        ["es geht um + Akkusativ", "it is about", "πρόκειται για, το θέμα είναι"],
        ["integrieren in + Akkusativ", "to integrate into", "εντάσσω"],
        ["es fehlt an + Dativ", "there is a lack of", "υπάρχει έλλειψη σε"],
        ["es handelt sich um + Akkusativ", "it is about", "πρόκειται για"],
        ["weitergeben an + Akkusativ", "to pass on to", "μεταβιβάζω, δίνω σε"],
        ["Rücksicht nehmen auf + Akkusativ", "to be considerate of", "σέβομαι, λαμβάνω υπόψιν μου"],
        ["sich konzentrieren auf + Akkusativ", "to concentrate on", "επικεντρώνομαι σε"],
        ["stammen aus + Dativ", "to originate from", "προέρχομαι από"],
        ["verfügen über + Akkusativ", "to have at one's disposal", "διαθέτω"],
        ["gefallen an + Dativ", "what I liked about someone", "τι μου άρεσε σε κάποιον"],
        ["verstehen unter + Akkusativ", "mean by", "εννοώ με"],
        ["ankommen auf + Akkusativ", "to depend on", "εξαρτάται από"],
        ["hinzufügen zu + Dativ", "to add to", "προσθέτω"],
        ["sich äußern zu + Dativ", "to comment on", "εννοώ με"],
        ["sauer auf + Akkusativ", "angry at", "θυμωμένος με"],
        ["böse auf + Akkusativ", "angry at", "θυμωμένος με"],
        ["zerbrechen an + Dativ", "to break on", "καταστέφομαι, διαλύομαι"],
        ["gemeint sein mit + Dativ", "mean by", "λέγοντας, εννοούμε"],
        ["sich vertrauen machen mit + Dativ", "to familiarize oneself with", "εξοικειώνομαι με"],
        ["werden aus + Dativ", "to become", "απογίνομαι"],
        ["zusammen kommen mit + Dativ", "to meet with", "συναντιέμαι με"],
        ["zugehen auf + Akkusativ", "to approach", "πλησιάζω, προσεγγίζω"],
        ["sich nähern + Dativ", "to approach", "πλησιάζω, προσεγγίζω"],
        ["neigen zu + Dativ", "to tend to", "τείνω σε, έχω τάση να"],
        ["sich anfreunden mit + Dativ", "to make friends with", "συνάπτω φιλία με"],
        ["sich ergeben aus + Dativ", "to result from", "προκύπτει από"],
        ["auf bauen auf + Dativ", "to build on", "βασίζομαι, εποικοδομώ"],
        ["Wert legen auf + Akkusativ", "to place value on", "δίνω μεγάλη σημασία σε"],
        ["voneinander abgrenzen", "to distinguish from one another", "διαζωρίζω μεταξύ τους"],
        ["umgehen mit + Dativ", "to deal with", "χειρίζομαι"],
        ["Stellung nehmen zu + Dativ", "to take a position on", "παίρνω θέση σε (ένα θέμα)"],
        ["zutreffen auf + Akkusativ", "to apply to", "ισχύει για, ταιριάζει σε"],
        ["erfahren sein in + Dativ", "to be experienced in", "έμπειρος σε"],
        ["greifen zu + Dativ", "to resort to", "καταφεύγω, το ρίχνω σε"],
        ["naschen", "to snack", "τρώω γλυκά, τρώω στα κρυφά"],
        ["geraten in + Akkusativ", "to get into", "καταλήγω σε"],
        ["ausgehen von + Dativ", "to assume", "υποθέτω"],
        ["abgeben von + Dativ", "to give away", "αφαιρείται από"],
        ["betrügen um + Akkusativ", "to cheat out of", "αφαιρώ, κλέβω με απάτη"],
        ["adressiert an + Akkusativ", "addressed to", "απευθυνόμενος προς, με παραλήπτη τον/την."],
        ["hinweisen auf + Akkusativ", "to point out", "εφιστώ την προσοχή σε"],
        ["sich befassen mit + Dativ", "to deal with", "ασχολούμαι με"],
        ["sich strecken", "to stretch", "τεντώνομαι"],
        ["sich auswirken auf + Akkusativ", "to affect", "έχει επιπτώσεις σε"],
        ["zielen auf + Akkusativ", "to aim at", "έχω ως στόχο, στοχεύω σε"],
        ["sich auseinandersetzen mit + Dativ", "to deal with", "ασχολούμαι με, αντιμετωπίζω"],
        ["zurechtkommen mit + Dativ", "to get along with", "τα καταφέρνω, τα πάω καλά με"],
        ["verfügen über + Akkusativ", "to have at one's disposal", "διαθέτω"],
        ["hohe Anforderungen stellen/haben an + Akkusativ", "to have high expectations", "έχω υψηλές προσδοκίες"],
        
    ];

  return (
    <div>
      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
        <Header1 props="List Verbs with Prepositional Object" />
        <Header2 props="Liste Verben mit Präpositional Objekt" />
      </Box>

      <Box className="global-box-not-header">
        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>
            <TableRow>
              <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0'}}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
            </TableRow>

            {verbs.map((verb, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>                
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Box>

      <ScrollToTopButton />
    </div>
  )
}

export default Z0038_Verbs_ListWithPrepositionalObject;
