import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0045_Verbs_WithPrepositionalObject() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Verbs with Prepositional Object" />
                <Header2 props="Verben mit  Präpositionalobjekt" />
            </Box>




            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    There are some verbs that have syntax with <spam class="green-text">Prepositional Object</spam>. Which means that the verb connected with the object with a preposition.<br /><br />

                    Like English, German has a number of verbs with preposition combinations; however, in German, a decision must be made as to whether the object of such a preposition should be in the dative or the accusative case.
                    Also, German preposition usage often does not correspond to English preposition usage following verbs that have the same meaning.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb : fragen nach + Dativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Hast du <spam class="green-text">nach</spam> dem Preis für dieses Modell <spam class="green-text">gefragt</spam>? </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Have you asked for the price of that model?</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb : helfen bei + Dativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Niemand <spam class="green-text">hilf </spam> mir <spam class="green-text">bei</spam> den Hausaufgaben?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Nobody helped me with the homeworks.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Full list Verbs with Prepositional Object&nbsp;&nbsp;
                    <Link to="/Z0038_Verbs_ListWithPrepositionalObject" style={{ textDecoration: 'underline', color: 'orange' }}>
                        Press here
                    </Link>
                </Typography>



            </Box>

            <ScrollToTopButton />



        </div>
    )
}

export default Z0045_Verbs_WithPrepositionalObject