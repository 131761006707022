import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0028VerbsPassiveToActive() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Convert Active voice to Passive voice" />
                <Header2 props="Umformung Aktiv in Passivsatz" />
            </Box>




            <Box className="global-box-not-header" >
                <Header3_Middle props="1. Generally" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    <span className="green-text"> Active voice (Aktiv)</span> of a verb is when the subject of the sentence is acting.<br /><br />
                    In the sentence below <span className="green-text"> "Die Frau"</span> is <span className="green-text"> acting</span>.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subjekt</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Verb</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Akkusative-object</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Frau </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>kocht</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Essen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subjekt</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Verb</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Partizip Perfekt</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv:  </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Essen </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wird</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>(von der Frau)</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gekocht</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />





            <Box className="global-box-not-header" >

                <Header3_Middle props="2. Subject with 'man' in the active sentence." />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} >
                    Passive constructions are not used as frequently in German as they are in English. Especially in spoken German, the active construction is preferred.
                    <br /><br />
                    One common substitute for the passive is an active sentence containing the <span className="green-text"> indefinite pronoun man</span> as subject. <br />
                    <span className="green-text">Man</span> can only be used in an active sentence. However, such sentences are often rendered as passive sentences in English.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> baut eine neue Straße.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Eine neue Straße <span className="green-text"> wird gebaut</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> trinkt in Deutschland viel Bier.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Viel Bier <span className="green-text"> wird</span> in Deutschland <span className="green-text"> getrunken</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>





                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} >
                    At the next sentence there is no objekt.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> arbeitet in Deutschland viel.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es <span className="green-text"> wird</span> in Deutschland viel <span className="green-text"> gearbeitet</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In Deutschland <span className="green-text"> wird</span> viel <span className="green-text"> gearbeitet</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>





                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> spiel in Dänemark, viele Spiele.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Viel Musik <span className="green-text"> wird</span> in den Pubs <span className="green-text"> gemacht</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> macht in Ireland in den Pubs, viel Musik.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Viel Musik <span className="green-text"> wird</span> in den Pubs <span className="green-text"> gemacht</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} >
                    Note that in the following pairs of sentences, the subject of the passive sentence is the <span className="green-text"> direct object</span> in the active sentence.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> hat mich gesehen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich bin gesehen <span className="green-text"> worden</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> fragt Peter.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Peter <span className="green-text"> wird gefragt</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> reparier<span className="green-text">te</span> den Wagen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Wagen <span className="green-text"> wurde repariert</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} >
                    Pay attention to the following sentence with the verb <span className="green-text"> "wird" in active</span>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Man</span> wird wohl das Haus verkaufen. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Haus <span className="green-text"> wird</span> wohl <span className="green-text"> verkauft werden</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />









            <Box className="global-box-not-header" >

                <Header3_Middle props="3. With Dative" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In German, the <span className="green-text"> indirect object</span> of the active sentence <span className="green-text"> cannot</span> become the subject of the passive sentence; it must remain the indirect object.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative (Wer?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative (Wem?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative (Was?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Kellner</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>bringt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem Gast</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Frühstück</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>aufs Zimmer. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Frühstück</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wird </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem Gast</TableCell>
                                <TableCell colSpan={2} sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>vom Kellner aufs Zimmer <span className="green-text"> gebracht</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>





                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative (Wer?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative (Wem?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative (Was?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mein Vater</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>schenkt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meiner Mutter 	</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>am Valetinstag</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Blumen. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Blumen </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>werden 	</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meiner Mutter</TableCell>
                                <TableCell colSpan={2} sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>vom meinem Vater am Valentinstag <span className="green-text"> geschenkt</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>






                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative (Wer?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative (Wem?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative (Was?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Lehrer</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>liest</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meinen Mitschlülern</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meinen Aufsatz</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>vor. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mein Aufsatz</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wird </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meinen Mitschlülern</TableCell>
                                <TableCell colSpan={2} sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>vom Lehrer <span className="green-text"> vorgelessen</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>









                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative (Wer?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative (Wem?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative (Was?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Viele Firmen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>in der Scheiz</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>exportieren </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Schokolade</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative (Wer?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Agent</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative (Wem?)</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Schokolade</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wird</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>von vielen Firmen 	</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>in der Schweiz</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>exportiert</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />








            <Box className="global-box-not-header" >

                <Header3_Middle props="4. Exercises" />
                <Header2 props="Beispiele" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} >
                    Below there some exercises, to convert active voice to passive voice :
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Man wäscht die leeren Flaschen und füllt sie dann wieder.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die leeren Flaschen <span className="green-text"> werden gewaschen</span> und dann wieder <span className="green-text"> gefüllt</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Jedes Jahr werfen wir in Deutschland 30 Millionen Tonnen Abfall auf den Müll.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>30 Millionen Tonnen Abfall <span className="green-text">werden</span> jedes Jahr in Deutschland auf den Müll <span className="green-text"> geworfen</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In vielen Städten sortiert man den Müll im Haushalt.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Müll <span className="green-text"> wird</span> in vielen Städten im Haushalt <span className="green-text"> sortiert</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Durch gefährlichen Müll vergiften den Boden und das Grundwasser</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Boden und das Grundwasser <span className="green-text"> werden</span> durch gefährlichen Müll <span className="green-text"> vergiften</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ein Drittel des Mülls verbrennt man in Müllverbrennungslagen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In Müllverbrennungslagen <span className="green-text"> wird</span> ein Drittel des Mülls <span className="green-text"> verbrannt</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Altglass, Altpapper und Altkleider sammelt man in öffentlichen Containern.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In öffentlichen Containern <span className="green-text"> werden</span> Altglass, Altpapper und Altkleider <span className="green-text"> gesammelt</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Nur den RestMüll(A) wirft man(N) noch in die Normale Mülltone.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Nur der RestMüll <span className="green-text"> wird</span> noch in die Normale Mülltone <span className="green-text"> geworfen</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In vielen Regionen kontrolliert man den Inhalt der Mülltonen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Inhalt der Mülltonen <span className="green-text"> wird</span> in vielen Regionnen <span className="green-text"> kontrolliert</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Auf öffentlichen Feiern <span className="green-text"> sollte</span> man kein Plastikgeschirr benutzen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Kein Plastikgeschirr <span className="green-text"> sollte</span> auf öffentlichen Feiern <span className="green-text"> benutzt werden</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Vielleicht verbietet man bald alle Getränke in Dosen und Plastikflaschen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Alle Getränke in Dosen und Plastikflaschen <span className="green-text"> werden</span> vielleicht bald <span className="green-text"> verboten</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Heute erzieht man die Kinder nicht so streng.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv Präsens:  </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Kinder <span className="green-text"> werden</span> heute nicht so streng <span className="green-text"> erzogen</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv Präteritum:  </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Kinder <span className="green-text"> wurden</span> heute nicht so streng <span className="green-text"> erzogen</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </Box>

                <ScrollToTopButton />

                
                

        </div>
    )
}

export default Z0028VerbsPassiveToActive