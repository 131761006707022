import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0082_Articles_Possessive() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Possessive Articles" />
                <Header2 props="PossesivArtikel" />
            </Box>

            <Box className="global-box-not-header" >
            <Header3_Middle props="1. Possessive Article in Nominative" />
            <Header2 props="PossessivArtikel im Nominativ" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                The Possessive Articles declares to whοm belongs something. Generally the Possessive Articles take the positions of the article, which means they placed infrond of the noun.<br /><br />

                The gender of the noun that follows defines the ending of the Possessive Article.
            </Typography>



            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell colspan={5} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Possessive Article in Nominative - PossessivArtikel im Nominativ</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                        <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>ich</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mein</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mein<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mein</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>mein<span className="green-text">e</span></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>du</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dein</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dein<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dein</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>dein<span className="green-text">e</span> </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>er</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>sein<span className="green-text">e</span> </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>sie</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ihr<span className="green-text">e</span> </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>es</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>sein<span className="green-text">e</span> </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>wir</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>unser</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>unser<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>unser</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>unser<span className="green-text">e</span> </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>ihr</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>eu<span className="green-text">er</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>eur<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>eu<span className="green-text">er</span></TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>eur<span className="green-text">e</span></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>sie</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ihr<span className="green-text">e</span> </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Sie</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ihr</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ihr<span className="green-text">e</span></TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ihr</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Ihr<span className="green-text">e</span> </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>



            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Examples:
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das ist <span className="green-text">mein</span> Platz. (der Platz)</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>This is my place. </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das ist <span className="green-text">meine</span> Mutter. (die Mutter)</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>This is my mother.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die sind <span className="green-text">meine</span> Freunde. [ der Freund(Singular), <span className="green-text">die Freunde</span>(Plural) ]</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>These are my friends</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />






            <Box className="global-box-not-header" >
            <Header3_Middle props="2. Conjugation of the Possessive Articles" />
            <Header2 props="Deklination der PossessivArtikel" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Examples:
            </Typography>


            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell colspan={3} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Singular</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Plural</TableCell>                
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein Mann</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">e</span> Frau</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein Kind</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">e</span> Leute</TableCell>                        
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">en</span> Mann</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">e</span> Frau</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein Kind</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">e</span> Leute</TableCell>                        
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">em</span> Mann</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">er</span> Frau</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">em</span> Kind</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">en</span> Leute<span className="green-text">n</span></TableCell>                        
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">es</span> Mann<span className="green-text">es</span></TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">er</span> Frau</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">es</span> Kind<span className="green-text">es</span></TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mein<span className="green-text">er</span> Leute</TableCell>                        
                    </TableRow>

                    
                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />


        </div>
    )
}

export default Z0082_Articles_Possessive

