import React from 'react';

import { Link } from 'react-router-dom';
import { Grid, IconButton, Box } from "@mui/material";

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

import HomeIcon from '@mui/icons-material/Home';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function Footer() {

    return (
        <>
            <Box className="global-box-not-header" style={{ marginTop: "1em" }}>

                <Grid container spacing={1} justifyContent="center" sx={{ marginTop: "0em", marginLeft: "0em", marginRight: "0em" }}>

                    {/* // 1. */}
                    <Grid item sx={{ background: 'lightyellow' }} xs={12} sm={6} md={4} lg={3} xl={3} >
                        <Grid container direction="column" justifyContent="center" spacing={2}>
                            <Grid item >
                                <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                DataGrid
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                Sitemap
                            </Grid>

                            <Grid container direction="row" justifyContent="center" spacing={2}>
                                <Grid item component={Link} to="http://www.facebook.com" rel="noopener noreferrer" target="_blank">
                                    <IconButton> <FacebookIcon /> </IconButton>
                                </Grid>

                                <Grid item component={"a"} href="http://www.twitter.com" rel="noopener noreferrer" target="_blank">
                                    <IconButton> <TwitterIcon /> </IconButton>
                                </Grid>

                                <Grid item component="a" href="http://www.instagram.com" rel="noopener noreferrer" target="_blank">
                                    <IconButton> <InstagramIcon /> </IconButton>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* // 2. */}
                    <Grid item sx={{ background: 'lightblue' }} xs={12} sm={6} md={4} lg={3} xl={3} >
                        <Grid container direction="column" justifyContent="center" spacing={2}>

                            <Grid item sx={{ textDecoration: 'none' }} >
                                Ä = alt + 142.
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                Ö = alt + 153.
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                Ü = alt + 154.
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                ß = alt + 0223.
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} >
                                ä = alt + 0228.
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                ö = alt + 0246.
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                ü = alt + 0252.
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* // 3. */}
                    <Grid item sx={{ background: 'lightpink' }} xs={12} sm={6} md={4} lg={3} xl={3} >

                        <Grid container direction="column" justifyContent="center" spacing={2}>

                            <Grid item sx={{ textDecoration: 'none', textAlign: 'center', color: 'red' }} >
                                Basic Tables: Verbs
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0035_Verbs_ListWithRegularVerbs">
                                1. List with Regular verbs
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0036_Verbs_ListWithIrregularVerbs">
                                2. Conjugation Irregular verbs
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0037_Verbs_ListWithSeparableVerbs">
                                3. List with Separable verbs
                            </Grid>
                            
                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0038_Verbs_ListWithPrepositionalObject">
                                4. List Verbs with Prepositional Object
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0039_Verbs_withNominative">
                                5. Verbs with Nominative
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0040_Verbs_withAccusative">
                                6. Verbs with Accusative
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0041_Verbs_withDative">
                                7. Verbs with Dative
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/Z0042_Verbs_withDativeAndAccusative">
                                8. Verbs with Dative and Accusative
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} component={Link} to="/verbs22_presentperfect_list">
                                9. Present Perfekt: Verbs with sein
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}  component={Link} to="/Z0043_Verbs_PositionInSentence">
                                10. The position of verb in the sentence
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none', textAlign: 'center', color: 'red' }} >
                                Basic Tables: Nouns
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/nouns56_with_prepositions" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Nouns with prepositions
                                </Link>
                            </Grid>

                        </Grid>


                    </Grid>

                    {/* // 4. */}
                    <Grid item sx={{ background: 'lightgreen' }} xs={12} sm={6} md={4} lg={3} xl={3} >

                        <Grid container direction="column" justifyContent="center" spacing={2}>

                            <Grid item sx={{ textDecoration: 'none', textAlign: 'center', color: 'red' }} >
                                Basic Tables: Articles
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}} >
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/articles83_possessive_overview" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Overview of Possessive Articles
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none', textAlign:'center', color:'red' }} >
                                Basic Tables: Adjectives
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/adjectives63_conjugation" style={{ textDecoration: 'none', color: 'blue' }}>
                                Adjectives Conjugation
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }} >
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/adjectives67_with_prepositions" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Overview Adjectives with prepositions
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none', textAlign:'center', color:'red' }} >
                                Basic Tables:
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/articles83_possessive_overview" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Overview of Possessive Articles
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/pronouns104_personal_overview" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Overview of Personal Pronouns
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/Z0166_SentenceConnection_Overview" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Overview of Sentences Connection
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton>
                                <Link to="/Z0167_UsefulExpressions" style={{ textDecoration: 'none', color: 'blue' }}>
                                    Very Useful Expressions
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>

                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>
                                <IconButton size='small' sx={{color:'green'}}>
                                    <RadioButtonCheckedIcon/>
                                </IconButton> 
                                <Link to="/Z0168_AllGramaticalPhenomena" style={{ textDecoration: 'none', color: 'blue' }}>
                                    All Grammatical Phenomena
                                </Link>
                            </Grid>

                            <Grid item sx={{ textDecoration: 'none' }}>

                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>
            </Box>

        </>
    )
}

export default Footer
