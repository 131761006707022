import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z250_Various_lassen() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box className="global-box-header">
          <Header1 props="The verb `lassen`" />
          <Header2 props="Das verb `lassen`" />
      </Box>



      <Box className="global-box-not-header" >

        <Header3_Middle props="Conjugation" />
        <Header2 props="Konjugation" />           

            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>3rd type</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Präteritum</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Past Participle (Partizips Perfekt (Partizip II) )</TableCell>                    
                    </TableRow>

                    <TableRow>                    
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lässt</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ließ</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>hat gelassen</TableCell>                    
                    </TableRow>               

                </Table>
            </TableContainer>

      </Box>


      <ScrollToTopButton />



      <Box className="global-box-not-header" >

        <Header3_Middle props="Use" />
        <Header2 props="Benutzen" />

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Below we will see two ways of how we can use the verb <spam class="green-text">lassen</spam>. <br/><br/>

                First example:
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subject</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>object</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mein kleiner Sohn</TableCell>
                        <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lässt</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mich</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>nicht lesen.</TableCell>
                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }} colSpan={4}>
                            My junger son doesn't <spam class="green-text">leave  </spam> me to read.                            
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>
            

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Second example:
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subject</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>object</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich</TableCell>
                        <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lasse</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meinen Wagen</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>reparieren.</TableCell>
                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }} colSpan={4}>
                        I assign someone to repair my car. <br /><br />(I am having my car repaired.)         
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Both of the sentences the verb <spam class="green-text">lassen</spam> has syntax with the <spam class="green-text">object in accusative</spam>. <br/>
                In the first sentence the object is a person <spam class="green-text">mich</spam> and in the second sentence the object is a thing <spam class="green-text"> meinen Wagen</spam>. <br/><br/>

                When the infinitive is a transition verb, then we can have in the sentence two objects in accusative. One person and one thing
            </Typography>


            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subject</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>object in accusative</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>object in accusative</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mein kleiner Sohn</TableCell>
                        <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lässt</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mich</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>nicht</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Brief</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lesen.</TableCell>
                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }} colSpan={7}>
                            My junger son doesn't <spam class="green-text">leave  </spam> me the letter to read.
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>



            <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subject</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>verb</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>object in accusative</TableCell>                                                
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>object in accusative</TableCell>                        
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich</TableCell>
                        <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lasse</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Mechaniker</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>meinen Wagen</TableCell>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>reparieren</TableCell>                        
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }} colSpan={6}>
                            I assign the mechanic to repair my car. <br/><br/> (I am letting the mechanic repair my car)
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                The object in accusative which is a <spam class="green-text">person</spam> goes <spam class="green-text">first</spam> 
                and the object in accusative which is a <spam class="green-text">thing</spam> goes <spam class="green-text">second</spam>.
            </Typography>

      </Box>


      <ScrollToTopButton />



      <Box className="global-box-not-header" >

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            Note: that the verb <spam class="green-text">lassen</spam> means <spam class="green-text">to leave</spam> when used alone. <br/>
            When used with a dependent infinitive, it means<spam class="green-text"> to let, allow</spam>
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
            <Table>                

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                        Meine Eltern <spam class="green-text">lassen</spam> mich ins Konzert gehen.    
                    </TableCell>                    
                </TableRow>

                <TableRow>                    
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                        My parents <spam class="green-text">let</spam> me to go to concert.                        
                    </TableCell>
                </TableRow>

            </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
            <Table>                

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                        Ich <spam class="green-text">lasse</spam> den Mantel hier.
                    </TableCell>                    
                </TableRow>

                <TableRow>                    
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                        I <spam class="green-text">leave</spam> the coat here.
                    </TableCell>
                </TableRow>

            </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
            <Table>                

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                        Ich <spam class="green-text">lasse</spam> Robert kommen.
                    </TableCell>                    
                </TableRow>

                <TableRow>                    
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                        I <spam class="green-text">let</spam> Robert come
                    </TableCell>
                </TableRow>

            </Table>
        </TableContainer>


        </Box>


        <ScrollToTopButton />



        <Box className="global-box-not-header" >

            <Header3_Middle props="Other meanings" />
            <Header2 props="" />

            <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>sich nutzen lassen = can be used</Typography>

            <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                <Table>                

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                            Die Vorteile von Webstamp <spam class="green-text">lassen sich</spam> einfach <spam class="green-text">nutzen</spam>
                        </TableCell>                    
                    </TableRow>

                    <TableRow>                    
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                            The advantages of Webstamp are easy <spam class="green-text">to use</spam>.   
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>



            <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>sich bejahen lassen = to be affirmed</Typography>

            <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                <Table>                

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                            Die Frage <spam class="green-text">lässt sich</spam> nur <spam class="green-text">bejahen</spam>.
                        </TableCell>                    
                    </TableRow>

                    <TableRow>                    
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                            The question can only be answered in the affirmative.
                            <br/><i>(We can say only yes.)</i>
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>           

        </Box>        

        <ScrollToTopButton />

    </div>
  )
}

export default Z250_Various_lassen

