import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0016_Verbs_Comple_Position() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box className="global-box-header">
        <Header1 props="Position of complex verben in the main sentence" />
        <Header2 props="Stellung der Trennbare Verben im Hauptsatz" />
      </Box>



      <Box className="global-box-not-header" >

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          A <span className="green-text"> separable prefix</span> is always the <span className="green-text"> final element</span> of a sentence or main clause when the verb is in the present tense, the simple past tense,
          or the imperative. <br />
          
          Study the following examples.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Position 1</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Position 2</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >next Position</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >at the end : Prefix</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gehe</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>oft</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> aus</span>.</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I often go out.</TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Kommst</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>auch</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> heim</span>?</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Are you comming home too?</TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Geht</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>morgen</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> mit</span>?</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Are you going with us tomorrow?</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer >

      </Box>

      <ScrollToTopButton />



    </div>
  )
}

export default Z0016_Verbs_Comple_Position