import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';




function Z0066_Adjectives_as_nouns() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
        <Header1 props=" Adjectives and participles as nouns" />
        <Header2 props="Adjektive und Partizipien als Nomen" />
      </Box>




      <Box className="global-box-not-header" >

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          1.Some adjectives can be used as nouns. In that case they written with capital letter and conjugated.
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der <span className="green-text">Kranke Mann</span> muss Medizin nehmen.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der <span className="green-text">Kranke</span> muss Medizin nehmen.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The patient has to take medicine.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>



        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          2. After the words viel (many), wenig (a few) etwas (something) nichts (nothing) when the nouns nominalized then they have neuter gender.
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich habe nichts Interessantes im Radio gehört</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I hadn't heard something interest at the radio.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Heute habe ich etwas Komisches gesehen.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Today i have seen something strange.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich wollte etwas Modernes kaufen, aber ich habe nichts Preiswertes gefunden.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I wanted to buy something modern, but I couldn't find anything cheap.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          3. After the pronoun alles (everything), the adjective follows the conjugation of the definite article.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich wünsche dir alles Gute.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I wish you all the best.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />



    </div>
  )
}

export default Z0066_Adjectives_as_nouns