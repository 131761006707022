import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0007_Verbs_werden() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="The verb werden" />
                <Header2 props="Das Verb werden" />
            </Box>


            <Box className="global-box-not-header" >
            <Header3_Middle props="1. Conjugation" />

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                The verb <span className="green-text">"werden"</span> means <span className="green-text">"to become"</span>. We mention it seperatelly because we will see it many times.
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>


                    <TableRow >
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Präsens</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Präteritum</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Konjuktiv II</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Perfect</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ich</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>werde</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">urde</span></TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">ürde</span></TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ich bin gew<span className="green-text">o</span>rden</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}> <span className="green-text">wirst</span></TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">u</span>rdest</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">ü</span>rdest</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du bist gew<span className="green-text">o</span>rden</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er, sie, es</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}> <span className="green-text">wird</span></TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">u</span>rde</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">ü</span>rde</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er ist gew<span className="green-text">o</span>rden</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>werden</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">u</span>rden</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">ü</span>rden</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir sind gew<span className="green-text">o</span>rden</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ihr</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>werdet</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">u</span>rdet</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">ü</span>rdet</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ihr seid gew<span className="green-text">o</span>rden</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sie</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>werden</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">u</span>rden</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>w<span className="green-text">ü</span>rden</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sie sind gew<span className="green-text"></span>orden</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >
            <Header3_Middle props="2. werden as main verb" />

            <TableContainer>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Peter <span className="green-text">wird</span> Lehrer.</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Peter will become a teacher.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Warum <span className="green-text">wirst</span> du rot?</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Why are you becoming rot?</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />




            <Box className="global-box-not-header" >
            <Header3_Middle props="3. werden in future tense" />
            <Header2 props="werden + infinitive" />

            <TableContainer>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ich <span className="green-text"> werde</span> besuchen</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>i will visit</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du <span className="green-text"> wirst</span> besuchen</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>you will visit</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er, sie, es <span className="green-text"> wird</span> besuchen</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>he, she, it will visit</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir <span className="green-text"> werden</span> besuchen</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>we will visit</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ihr <span className="green-text"> werdet</span> besuchen</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>you will visit</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sie, Sie <span className="green-text"> werden</span> besuchen</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>they will visit</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >
            <Header3_Middle props="4. werden in Passive voice" />
            <Header2 props="werden + Partizip II" />

            <TableContainer>
                <Table>

                    <TableRow >
                        <TableCell colspan={3} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Passive voice in main sentence</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Präsens</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich <span className="green-text"> werde</span> angerufen.</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I am getting a call.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Präteritum</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich <span className="green-text"> wurde</span> angerufen.</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I was called.</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>        



            <TableContainer sx={{ textAlign: "center", marginTop: "1em" }}>
                <Table sx={{ textAlign: "center", marginTop: "5em" }}>

                    <TableRow >
                        <TableCell colspan={3} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Passive voice in a secondary sentence</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Präsens</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Die Mutter hat mir gesagt, dass das Kind jede Tag von Schule abgeholt <span className="green-text"> wird</span>.</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>The mother told me that the child is picked up from school every day.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Präteritum</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Die Mutter hat mir gesagt, dass das Kind jede Tag von Schule abgeholt <span className="green-text"> wurde</span>.</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>The mother told me that the child was picked up from school every day.</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>


            <TableContainer sx={{ textAlign: "center", marginTop: "1em" }}>
                <Table sx={{ textAlign: "center", marginTop: "5em" }}>

                    <TableRow >
                        <TableCell colspan={3} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Example:</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Das Auto <span className="green-text"> wird</span> geprüft.</TableCell>                        
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>The car is checked.</TableCell>
                    </TableRow>                  

                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />




            <Box className="global-box-not-header" >
            <Header3_Middle props="5. werden in Konjuktiv II" />

            <TableContainer sx={{ textAlign: "center", marginTop: "5em" }}>
                <Table>                

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich <span className="green-text"> würde</span> gehen.</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I would go.</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0007_Verbs_werden