import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0142_Adverbs_dabei() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="The adverbs : dabei, nämlich " />
                <Header2 props="Die Adverbien dabei, nämlich " />
            </Box>

            <Box className="global-box-not-header">
                <Header3_Middle props="1. The adverb : dabei" />
                <Header2 props="Das Adverb : dabei" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The adverb "<span className="green-text">  dabei</span>" is used to joined sentences. It is translated "<span className="red-text">  however</span>",
                    "<span className="red-text">  but</span>", "<span className="red-text">  and to think</span>".
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>dabei</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Produktion von Glas und Papier kostet Geld. <span className="green-text">Dabei</span> gibt es eine bessere Lösung, das Recycling.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The production of glass and paper costs money. <span className="green-text">And to think</span> there is a better solution, recycling.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />




            <Box className="global-box-not-header">
                <Header3_Middle props="2. The adverb : nämlich" />
                <Header2 props="Das Adverb : nämlich" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The adverb "<span className="green-text">  nämlich</span>" is used to joined sentences. It is translated "<span className="red-text">namely</span>" or <span className="red-text">"which is</span>" (specifically).
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>nämlich</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Für Glas und Papier gibt es eine bessere Lösung, <span className="green-text">nämlich</span> das Recycling.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>For glass and paper there is a better solution, <span className="green-text">which is</span> (specifically) the recycling.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0142_Adverbs_dabei