import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { Box, Button, Typography, IconButton, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';

//import Mui6_DataGrid_Toolbar from './Mui6_DataGrid_Toolbar';
import * as XLSX from 'xlsx'; // Import the entire module using *

import Mui6_DataGrid_Toolbar from './components/Mui6_DataGrid_Toolbar';


const columns = [
  
  { field: 'id', headerName: 'ID', width: 50,  
        renderCell: (params) => (
          <strong style={{ color: 'blue' }}>{params.value}</strong>
      ),
  },

  { field: 'link', headerName: 'link', width: 150, 
                  renderCell: (params) => ( <Button variant="contained" style={{backgroundColor:'#f8fc92'}}> 
                                                <CustomLinkCell value={params.value}/>   
                                            </Button>) 
  },

  { field: 'german', headerName: 'german', width: 200, 
          renderCell: (params) => (
              <strong style={{ color: 'red' }}>{params.value}</strong>
          ),
  },

  { field: 'english', headerName: 'english', width: 200,
            renderCell: (params) => (
              <strong style={{ backgroundColor: 'pink',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),
  },

  { field: 'greek', headerName: 'greek', width: 200,
            renderCell: (params) => (
              <strong style={{ backgroundColor: 'pink',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ), 

  },

  { field: 'type1De', headerName: 'type1De', width: 150,
            renderCell: (params) => (
              <strong style={{ backgroundColor: '#e7f2c3',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),  

  },

  { field: 'type1En', headerName: 'type1En', width: 150,
            renderCell: (params) => (
              <strong style={{ backgroundColor: '#e7f2c3',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),  
  },


  { field: 'type1Gr', headerName: 'type1Gr', width: 150, 
            renderCell: (params) => (
              <strong style={{ backgroundColor: '#e7f2c3',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),  
  },

  { field: 'type2De', headerName: 'type2De', width: 200, 
            renderCell: (params) => (
              <strong style={{ backgroundColor: '#eed9ad',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),  

  },

  { field: 'type2En', headerName: 'type2En', width: 200, 
            renderCell: (params) => (
              <strong style={{ backgroundColor: '#eed9ad',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),  

  },

  { field: 'type2Gr', headerName: 'type2GR', width: 200, 
            renderCell: (params) => (
              <strong style={{ backgroundColor: '#eed9ad',padding: '10px', color: 'blue' }}>{params.value}</strong>
            ),  
  },

  // { field: 'link', headerName: 'link', width: 150, renderCell: (params) => <CustomLinkCell value={params.value} /> },
  
  { field: 'linkDescriptionDe', headerName: 'linkDescriptionDe', width: 200,
              renderCell: (params) => (
                <strong style={{ backgroundColor: '#a8dad6',padding: '10px', color: 'blue' }}>{params.value}</strong>
              ),  
  },

  { field: 'linkDescriptionEn', headerName: 'linkDescriptionEn', width: 200, 
              renderCell: (params) => (
                <strong style={{ backgroundColor: '#a8dad6',padding: '10px', color: 'blue' }}>{params.value}</strong>
              ), 
  },

  { field: 'linkDescriptionGr', headerName: 'linkDescriptionGr', width: 200, 
              renderCell: (params) => (
                <strong style={{ backgroundColor: '#a8dad6',padding: '10px', color: 'blue' }}>{params.value}</strong>
              ), 
  },

  { field: 'example', headerName: 'Example', width: 200, 
              renderCell: (params) => (
                <strong style={{ backgroundColor: '#a8dad6',padding: '10px', color: 'blue' }}>{params.value}</strong>
              ), 
  },
  // { field: 'age', headerName: 'Age', type: 'number', width: 10 },
  
];

const CustomLinkCell = ({ value }) => {
  return <Link to={value}>{value}</Link>;
};


function Home2Screen() {

  // For loading from Excel.
  const [rows, setRows] = useState([]);


  // For Modal.  
  const [isHovering, setIsHovering] = useState(false);
  

  useEffect(() => {

      const fetchData = async () => {
          const file = 'data.xlsx'; // Assuming your Excel file is named data.xlsx
          const response = await fetch(file);
          const blob = await response.blob();
          
          const reader = new FileReader();
          
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];                            // Assuming data is in the first sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const header = jsonData.shift();                                     // Remove header row
        
            const formattedData = jsonData.map((row, index) => ({
              id: index + 1,
              link: row[1],
              german: row[2],
              english: row[3],

              greek: row[4],
              type1De: row[5],
              type1En: row[6],
              type1Gr: row[7],

              type2De: row[8],
              type2En: row[9],
              type2Gr: row[10],

             
              linkDescriptionDe: row[11],
              linkDescriptionEn: row[12],
              linkDescriptionGr: row[13],
              example: row[14],
              // age: parseInt(row[3]),
              
            }));
        
            setRows(formattedData);
          };
        
          reader.readAsArrayBuffer(blob);
        };

    fetchData();
  }, []);


  return (
    <>

         
{isHovering && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px' }}>
          <p>Hello</p>
        </div>
      )}
            

      <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
      <IconButton component={Link} to="https://mui.com/x/react-data-grid/#mit-version"> {" "}  <LinkIcon />  </IconButton>

      <Typography variant="h1" color="primary" sx={{textAlign:"center", marginBottom:"0em"}}>DataGrid API 7</Typography>
      <Typography variant="h6" color="secondary" sx={{textAlign:"center", marginBottom:"1em"}}>Mui6_DataGrid_Excel</Typography>
      <Typography variant="body2" color="secondary" sx={{textAlign:"center", marginBottom:"1em"}}>
          npm install @mui/x-data-grid xlsx
      </Typography>

      <div style={{ height: '60vh', width: '100%' }}>
          
          <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              // checkboxSelection
              disableSelectionOnClick
              components = {{ Toolbar: Mui6_DataGrid_Toolbar, BaseButton: Button }}
              componentsProps = {{ baseButton:{
                                      variant:"outlined"
              } }}

              onRowEnter={() => setIsHovering(true)}
        onRowLeave={() => setIsHovering(false)}
             
      />
      </div>

 
  </>
  )
}

export default Home2Screen