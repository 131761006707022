import React from 'react';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';

import LinkIcon from '@mui/icons-material/Link';

// field: '3rd type': Πρέπει να ταιριάζει με το Excel.
// headerName: Δικό μου όνομα.

const columns = [

  // {
  //   field: 'id', headerName: 'ID', width: 80,
  //   renderCell: (params) => (
  //     <strong style={{ color: 'blue' }}>{params.value}</strong>
  //   ),
  // },

  {
    field: 'link', 
    // headerName: 'link', 
    headerName: (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LinkIcon style={{ marginRight: '5px', color:'red' }} /> 
        Link
      </div>
    ),
    width: 150,
    headerAlign: 'center',
    renderCell: (params) => (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button variant="contained" style={{ backgroundColor: '#ffffff', padding: '0px' }}>
          <CustomLinkCell value={params.value} />
        </Button>
      </div>
    )
  },

  {
    field: 'german',
    headerName: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', textAlign: 'center' }}>
        <img src={Flag_Germany} alt="Flag of Germany" style={{ width: '20px', height: '15px' }} />
        German
      </div>
    ),
    headerAlign: 'center',
    width: 200,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%', color: 'green' }}>{params.value}</div>
    )
  },

  {
    field: 'english',
    headerName: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', textAlign: 'center' }}>
        <img src={Flag_Germany} alt="Flag of Germany" style={{ width: '20px', height: '15px' }} />
        3rd type
      </div>
    ),
    headerAlign: 'center',
    width: 200,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%', color: 'green' }}>{params.value}</div>
    )
  },

  {
    field: 'greek',
    headerName: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', textAlign: 'center' }}>
        <img src={Flag_Germany} alt="Flag of Germany" style={{ width: '20px', height: '15px' }} />
        Präteritum
      </div>
    ),
    headerAlign: 'center',
    width: 200,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%', color: 'green' }}>{params.value}</div>
    )
  },

  {
    field: 'type1De',
    headerName: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', textAlign: 'center' }}>
        <img src={Flag_Germany} alt="Flag of Germany" style={{ width: '20px', height: '15px' }} />
        Perfekt
      </div>
    ),
    headerAlign: 'center',
    width: 200,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%', color: 'green' }}>{params.value}</div>
    )
  }, 

  {
    field: 'type1En',
    headerName: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', textAlign: 'center' }}>
        <img src={Flag_English} alt="Flag of Germany" style={{ width: '20px', height: '15px' }} />
        English
      </div>
    ),
    headerAlign: 'center',
    width: 200,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%', color: 'red' }}>{params.value}</div>
    )
  }, 

  {
    field: 'type1Gr',
    headerName: (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', textAlign: 'center' }}>
        <img src={Flag_Hellas} alt="Flag of Germany" style={{ width: '20px', height: '15px' }} />
        Greek
      </div>
    ),
    headerAlign: 'center',
    width: 200,
    renderCell: (params) => (
      <div style={{ textAlign: 'center', width: '100%', color: 'blue' }}>{params.value}</div>
    )
  }, 

];


const CustomLinkCell = ({ value }) => {
  // return <Link to={value}>{value}</Link>;
  return <Link to={value}>link</Link>;
};


const Mui68_DataGrid4_Results = () => {

  const location = useLocation();

  const { rows } = location.state || { rows: [] };

  return (
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={15}        

        sx={{
          // Το χρώμα του Header στο DataGrid.
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#efcdf4', 
          },

          // Το χρώμα του Body στο DataGrid.
          '& .MuiDataGrid-cell': {
            backgroundColor: '#f6f9ba',
          }
        }}
      // checkboxSelection
      />
    </div>
  );
};



export default Mui68_DataGrid4_Results