import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {Button, Typography, TextField, IconButton} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';

function Mui_Typography() {

    const [inputs, setInputs] = useState( {name:"", email:"", password:""} );

    const handleChange = (e) => {        

        setInputs( (prevState)=>({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
        
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(inputs);
    }

    return (
        <div>
            <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
            <Typography variant="h1" color="primary" sx={{textAlign:"center", marginBottom:"1em"}}>Text Field Component</Typography>

            <form onSubmit={handleSubmit}>
                <TextField name="name" value={inputs.name} type={'text'} placeholder='Name' variant='outlined' onChange={handleChange} />
                <TextField name="email" value={inputs.email} type={'email'} placeholder='Email' variant='standard' onChange={handleChange} />
                <TextField name="password" value={inputs.password} type={'password'} placeholder='password' variant='filled' onChange={handleChange} />

                <Button type="submit">Click me</Button>
                
            </form>
            
            
            <Typography variant="body2" color="primary" sx={{color:"red"}}>{inputs.name}</Typography>
            <Typography variant="body2" color="primary" sx={{color:"red"}}>{inputs.email}</Typography>
            <Typography variant="body2" color="primary" sx={{color:"red"}}>{inputs.password}</Typography>
            

        </div>
    )
}

export default Mui_Typography