import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


function Z0033_Zu_plus_Infinitive() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="zu + infinitive" />
                <Header2 props="zu + infinitiv" />
            </Box>


            <Box className="global-box-not-header" >
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The word <span className="green-text">'to'</span> in english rendered in english with <span className="green-text"> 'zu + infinitive'</span>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich versuche <span className="green-text">zu kommen</span></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I will try <span className="green-text"> to come</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    *** Basic: *** <br />
                    It is necessarily the <span className="green-text"> subjekt</span> of the <span className="green-text"> verb</span> to be the same with the subject of <span className="green-text"> infinitive</span>.<br /><br />

                    Wer versucht? : Ich<br />
                    Wer kommt? : Ich
                </Typography>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    If the verb is <span className="green-text">separable verb</span> (trennbare verb) then the word zu placed between the two arrangements.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Tania versucht ab<span className="green-text">zu</span>schreiben</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Tania tries <span className="green-text"> to copy</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    There structure <span className="green-text">'zu + infinitive'</span> is depending not only from verbs but also from some expressions:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>                   

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er  hat <span className="green-text">keine Lust</span> mit mir <span className="green-text"> zu tanzen</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er <span className="green-text"> hat Zeit</span> mir <span className="green-text"> zu helfen</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es <span className="green-text"> ist wichtig</span> das Auto <span className="green-text"> zu reparien</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es <span className="green-text"> ist langweilig</span> allein ins Kino <span className="green-text"> zu gehen</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </div>
    )
}

export default Z0033_Zu_plus_Infinitive