import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';

function Z0167_UsefulExpressions() {
    const verbs = [
        ["Wie geht es Ihnen?", "How are you?", "Πώς είστε;"],
        ["Wie heiβt du?", "What is your name?", "Πώς σε λένε;"],
        ["Wie ist Ihr Familienname?", "What is your family name?", "Ποιο είναι το επώνυμό σας;"],
        ["Wo wohnen Sie?", "Where do you live?", "Πού κατοικείτε;"],
        ["Wo liegt das denn?", "Where is that?", "Πού βρίσκεται αυτό;"],
        ["Wie ist die Postleitzahl von Mark, bitte?", "What is the postcode number from Kostas, please?", "Ποιος είναι ο ταχυδρομικός κώδικας του Κώστα, παρακαλώ;"],
        ["Woher kommst du?", "Where are you from?", "Από πού είσαι;"],
        ["Was machen Sie?", "What do you do?", "Τι κάνετε;"],
        ["Was sind Sie von Beruf?", "What's your occupation?", "Ποια είναι η επαγγελματική σας ιδιότητα;"],
        ["Wann sind Sie geboren?", "When were you born?", "Πότε γεννηθήκατε;"],
        ["Wie bitte?", "I beg your pardon?", "Μπορείτε να επαναλάβετε;"],
        ["Ist da nicht ... ?", "Isn't that the...", "Δεν είναι αυτό το...;"],
        ["Wie viel ist das?", "How much is this?", "Πόσο κοστίζει αυτό;"],
        ["Wohin möchtet ihr?", "Where would you like to go?", "Πού θα θέλατε να πάτε;"],
        ["Alle sein - Das Benzin ist alle.", "It is out of fuel.", "Έχει τελειώσει το καύσιμο."],
        ["Ist aus - Das Kochfeld ist aus Glaskeramik.", "The hob is made of glass ceramic.", "Η εστία είναι από γυάλινη κεραμική."],
        ["Zu sein - Der Wasserhahn ist zu.", "The tap is closed.", "Ο νεροχύτης είναι κλειστός."],
        ["Was ist los?", "What's happening?", "Τι συμβαίνει;"],
        ["Wir möchten gern bestellen.", "We would like to order.", "Θα θέλαμε να παραγγείλουμε."],
        ["Bitte, was bekommen Sie?", "Please, what will you have?", "Παρακαλώ, τι θα πάρετε;"],
        ["Als Nachtisch esse ich...", "For dessert I eat...", "Για επιδόρπιο τρώω..."],
        ["Wir möchten bitte bezahlen.", "We would like to pay, please.", "Θα θέλαμε να πληρώσουμε, παρακαλώ."],
        ["Zusammen oder getrennt?", "Together or separately?", "Μαζί ή χωριστά;"],
        ["Auf Deck kann man einen Film sehen.", "You can watch a movie on deck.", "Μπορείτε να δείτε μια ταινία στο κατάστρωμα."],
        ["Willkommen an Bord!", "Welcome on board!", "Καλώς ορίσατε στο πλοίο!"],
        ["Zu Mittag essen.", "To have lunch.", "Για μεσημεριανό γεύμα."],
        ["Los… sein - Was ist heute los?", "What is happening today?", "Τι συμβαίνει σήμερα;"],
        ["Wie lange?", "How long?", "Πόσο διαρκεί;"],
        ["Zu Hause bleiben", "Stay at home", "Μένω σπίτι"],
        ["Zu Hause darf ich keine Musik hören", "I am not allowed to listen to music at home", "Δεν επιτρέπεται να ακούω μουσική στο σπίτι"],
        ["Ich gehe nach Hause.", "I go home.", "Πάω σπίτι."],
        ["Ich habe keine Lust.", "I have no desire.", "Δεν έχω καμία όρεξη."],
        ["Pro Woche", "Per week", "Ανά εβδομάδα"],
        ["Die Hauptstraße immer geradeaus bis zur Buchhandlung.", "The main street always straight to the bookstore.", "Ακολουθήστε την κεντρική οδό ευθεία μέχρι το βιβλιοπωλείο."],
        ["Gehen Sie links in die Agnesstraße.", "Turn left into Agnes Street.", "Στρίψτε αριστερά στην οδό Agnes."],
        ["An der Ecke ist ein Restaurant.", "There is a restaurant on the corner.", "Υπάρχει ένα εστιατόριο στη γωνία."],
        ["Die Kantgasse ist zwischen der Post und dem Rathaus.", "Kantgasse is between the post office and the town hall.", "Η Kantgasse βρίσκεται ανάμεσα στο ταχυδρομείο και το δημαρχείο."],
        ["Die Bäckerei ist neben dem Fotostudio Siebert.", "The bakery is next to the Siebert photo studio.", "Το αρτοποιείο είναι δίπλα στο φωτογραφείο Siebert."],
        ["Zum Schluss", "At the end", "Στο τέλος"],
        ["Zwei Jahre lang", "For two years", "Για δύο χρόνια"],
        ["Zum ersten Mal", "For the first time", "Για πρώτη φορά"],
        ["Rund um", "All around", "Γύρω από"],
        ["Zu Weihnachten", "For Christmas", "Για τα Χριστούγεννα"],
        ["Zum Geburtstag", "For birthday", "Για τα γενέθλια"],
        ["Zu Ende", "Over", "Τελείωσε"],
        ["Es reicht mir.", "It is enough for me.", "Μου φτάνει."],
        ["Mir ist langweilig.", "I'm bored.", "Βαριέμαι."],
        ["Eine Frage stellen", "Ask a question", "Να κάνω μια ερώτηση"],
        ["Morgen ist die Party bei Hilde und Georg", "Tomorrow is the party at Hilde and Georg's place", "Αύριο είναι το πάρτι στον Hilde και Georg"],
        ["Den Führerschein machen", "To get the driver’s license", "Να πάρω το δίπλωμα οδήγησης"],
        ["Können Sie mir bitte welche zeigen?", "Can you show me some, please?", "Μπορείτε να μου δείξετε μερικά, παρακαλώ;"],
        ["Eine Stadtwohnung mit Blick auf die Binnenalster.", "A city apartment with a view of the Inner Alster.", "Ένα διαμέρισμα στην πόλη με θέα στην Εσωτερική Alster."],
        ["Eine Minute lang", "For a minute", "Για ένα λεπτό"],
        ["Lesen Sie dann auf der nächsten Seite weiter", "Then read on the next page", "Συνεχίστε την ανάγνωση στην επόμενη σελίδα"],
        ["Wir waren der Meinung", "We thought", "Είχαμε την άποψη"],
        ["In Wirklichkeit", "In reality", "Στην πραγματικότητα"],
        ["Auf die Waage bringen", "To put on the scales", "Να βάλω στη ζυγαριά"],
        ["Ins Gleichgewicht bringen", "Bring into balance", "Να φέρω σε ισορροπία"],
        ["Für etwas sein", "To be for something", "Να είμαι υπέρ κάτι"],
        ["Im Moment", "At the moment", "Αυτή τη στιγμή"],
        ["Ins Bett gehen", "Go to bed", "Να πάω για ύπνο"],
        ["Etwas macht mir Spaß", "Something is fun for me", "Κάτι μου προκαλεί ευχαρίστηση"],
        ["Im Augenblick", "At the moment", "Αυτή τη στιγμή"],
        ["Zu Ende sein", "To be over", "Να έχει τελειώσει"],
        ["Ein gutes Examen machen", "To take a good exam", "Να δώσω μια καλή εξέταση"],
        ["Auf jeden Fall", "Definitely", "Σίγουρα"],
        ["Auf keinen Fall", "No way", "Με κανένα τρόπο"],
        ["In diesem Fall", "In this case", "Σε αυτή την περίπτωση"],
        ["Mit Ausnahme", "With the exception", "Με εξαίρεση"],
        ["Unter anderem", "Among other things", "Μεταξύ άλλων"],
        ["Auf dem Weg nach Paris sein", "To be on the way to Paris", "Να είμαι στο δρόμο για το Παρίσι"],
        ["Per Telefon", "Via phone", "Τηλεφωνικώς"],
        ["Auf einmal", "At once", "Με μιας"],
        ["Am Tag vorher", "The day before", "Την προηγούμενη μέρα"],
        ["Kriminalfilm nach F.D.", "Crime film based on F.D.", "Αστυνομική ταινία βασισμένη στον F.D."],
        ["Auf die Spur kommen", "Get on the trail", "Να βρω το ίχνος"],
        ["In Lebensgefahr geraten", "To be in mortal danger", "Να βρίσκομαι σε θανάσιμο κίνδυνο"],
        ["Um Erlaubnis fragen", "Ask for permission", "Να ζητήσω άδεια"],
        ["In der Vergangenheit", "In the past", "Στο παρελθόν"],
        ["Auf diese Weise", "In this way", "Με αυτόν τον τρόπο"],
        ["Am Tage", "During the day", "Κατά τη διάρκεια της ημέρας"],
        ["Nach Osten", "Eastward", "Προς την ανατολή"],
        ["Gegen Mittag", "Around noon", "Γύρω στο μεσημέρι"],
        ["In den frühen Morgenstunden", "In the early morning hours", "Τις πρώτες πρωινές ώρες"],
        ["Am späten Nachmittag", "In the late afternoon", "Αργά το απόγευμα"],
        ["Aus Süd-West", "From the southwest", "Από το νοτιοδυτικά"],
        ["Auf den Müll kommen", "End up in the trash", "Να καταλήξω στα σκουπίδια"],
        ["Per Hand", "By hand", "Με το χέρι"],
        ["Beim Einkaufen", "While shopping", "Κατά τη διάρκεια των αγορών"],
        ["Etwas geht mir auf die Nerven", "Something gets on my nerves", "Κάτι με εκνευρίζει"],
        ["Ein Gespräch führen", "To have a conversation", "Να έχω μια συζήτηση"],
        ["Alle 4 Jahre", "Every four years", "Κάθε τέσσερα χρόνια"],
        ["Unter dem Einfluss von … stehen", "To be under the influence of...", "Να βρίσκομαι υπό την επήρεια του..."],
        ["Immer größer", "Getting bigger", "Γίνομαι όλο και μεγαλύτερος"],
        ["Es kommt zu etwas", "Something happens", "Συμβαίνει κάτι"],
        ["Für Ruhe sorgen", "Provide for rest", "Να εξασφαλίσω ηρεμία"],
        ["Die sechziger Jahre", "The sixties", "Τα έξηντα"],
        ["Gespräche führen", "To have conversations", "Να έχω συζητήσεις"],
        ["Es ist soweit", "The time has come", "Έφτασε η ώρα"],
        ["Vorstellbar sein", "To be imaginable", "Να είναι φανταστικό"],
        ["Alle drei bis vier Jahre", "Every three to four years", "Κάθε τρία έως τέσσερα χρόνια"],
        ["Am Anfang", "At the beginning", "Στην αρχή"],
        ["Am Ende", "At the end", "Στο τέλος"],
        ["Am Schluss", "In the end", "Στο τέλος"],
        ["In den meisten Dingen", "In most things", "Στα περισσότερα πράγματα"],
        ["Wenn ich es mir recht überlege", "If I think about it", "Αν το σκεφτώ καλά"],
        ["Wie läuft es?", "How are things going?", "Πώς πηγαίνουν τα πράγματα;"],
        ["Auf Zeit", "For a limited time", "Για περιορισμένο χρόνο"],
        ["In Erinnerung bleiben", "To be remembered", "Να μείνω στη μνήμη"],
        ["Auf jeden Fall", "In any case", "Σε κάθε περίπτωση"],
        ["Laufen über", "To run over", "Να τρέχω πάνω"],
        ["In diesem Fall", "In this case", "Σε αυτήν την περίπτωση"],
        ["Aus beruflichen Gründen", "For professional reasons", "Για επαγγελματικούς λόγους"],
        ["In Pflege nehmen", "To take care of", "Να φροντίσω"],
        ["Sehen als + Akkusativ", "To consider as", "Να θεωρώ ως"],
        ["Für private Zwecke", "For private purposes", "Για ιδιωτικούς σκοπούς"],
        ["Für gewerbliche Zwecke", "For commercial purposes", "Για εμπορικούς σκοπούς"],
        ["Im Zusammenhang mit + Dativ", "In connection with", "Σε σχέση με"],
        ["Auf die Rückmeldung reagieren", "To respond to the feedback", "Να ανταποκριθώ στο σχόλιο"],
        ["Sich wie Zuhause fühlen", "To feel at home", "Να αισθάνομαι σαν στο σπίτι μου"],
        ["Wieder in den Beruf einsteigen", "To re-enter the profession", "Να επιστρέψω στο επάγγελμα"],
        ["Sich selbstständig machen", "To become self-employed", "Να γίνω αυτοαπασχολούμενος"],
        ["Einen Auftrag bekommen", "To receive an assignment", "Να λάβω μια ανάθεση"],
        ["Ich bin angekommen", "I have arrived", "Έχω φτάσει"],
        ["Etw. steht jdm", "Something suits someone", "Κάτι ταιριάζει σε κάποιον"],
        ["Sich in Szene setzen", "To show off", "Να επιδείξω τον εαυτό μου"],
        ["Eine Voraussetzung erfüllen", "To meet a requirement", "Να πληρώσω μια προϋπόθεση"],
        ["Im Gegenteil dazu", "On the contrary", "Αντίθετα με αυτό"],
        ["Auf sich aufmerksam machen", "To draw attention to oneself", "Να τραβήξω την προσοχή"],
        ["Eindruck machen auf + A", "To make an impression on", "Να κάνω εντύπωση σε"],
        ["Auf Interesse stoßen bei +D", "To arouse interest in", "Να προκαλέσω το ενδιαφέρον κάποιου"],
        ["Unter ärztlicher Leitung", "Under medical supervision", "Υπό ιατρική επίβλεψη"],
        ["In der Nähe", "Nearby", "Κοντά"],
        ["Zum Nachdenken bringen", "To make someone think", "Να κάνω κάποιον να σκεφτεί"],
        ["In Ordnung sein", "To be okay", "Να είναι εντάξει"],
        ["Auf einmal", "Suddenly", "Ξαφνικά"],
        ["Ein Gespräch führen", "To have a conversation", "Να έχω μια συζήτηση"],
        ["Gebrauch machen von + D", "To make use of", "Να κάνω χρήση"],
        ["Aus aller Welt", "From all over the world", "Από όλο τον κόσμο"],
        ["Zum Nulltarif", "For free", "Δωρεάν"],
        ["Eins steht fest", "One thing is certain", "Ένα πράγμα είναι σίγουρο"],
        ["Man sieht es ihr nicht an", "You can't tell by looking at her", "Δεν φαίνεται"],
        ["Ein Ziel erreichen", "To achieve a goal", "Να επιτύχω έναν στόχο"],
        ["In Lebensgröße", "Life-sized", "Σε φυσικό μέγεθος"],
        ["Auf den Markt bringen", "To launch on the market", "Να λανσάρω στην αγορά"],
        ["In gutem Zustand", "In good condition", "Σε καλή κατάσταση"],
        ["In Ruhe", "In peace", "Με ηρεμία"],
        ["Zur Mitnahme", "For takeout", "Για παραλαβή"],
        ["Vor allem", "Above all", "Πάνω απ' όλα"],
        ["Wenn man nicht gerade", "If one is not just", "Εάν κάποιος δεν είναι ακριβώς"],
        ["Eine Sprache beherrschen", "To master a language", "Να κατέχω μια γλώσσα"],
        ["Zu begrüßen sein", "To be welcomed", "Να υποδέχομαι"],
        ["Zu jeder Zeit", "At any time", "Ανά πάσα στιγμή"],
        ["Von Nachteil sein", "To be a disadvantage", "Να αποτελεί μειονέκτημα"],
        ["In der Regel", "As a rule", "Κατά κανόνα"],
        ["Nach Geschlecht getrennt", "Separated by gender", "Χωρισμένος ανά φύλο"],
        ["In Ordnung halten", "To keep in order", "Να κρατάω σε τάξη"],
        ["Nach Absprache", "By arrangement", "Κατόπιν συνεννόησης"],
        ["Zum Glück", "Luckily", "Ευτυχώς"],
        ["Es ist so weit", "It is time", "Έφτασε η ώρα"],
        ["Witze machen", "To joke", "Να κάνω αστεία"],
        ["Es geht los", "It starts", "Ξεκινάει"],
        ["Es gab kein Halten mehr", "There was no stopping", "Δεν υπήρχε πια καταστολή"],
        ["Zu Ende sein", "To be over", "Να έχει τελειώσει"],
        ["In der Früh", "In the morning", "Το πρωί"],
        ["Je nach", "Depending on", "Ανάλογα με"],
        ["Die Wirkung auf + A", "The effect on", "Η επίδραση σε"],
        ["Sich Sorgen machen", "To worry", "Να ανησυχώ"],
        ["Sich etwas leisten können", "To be able to afford something", "Να μπορώ να αντέξω κάτι"],
        ["Abitur machen", "To take the A-levels", "Να δώσω τις τελικές εξετάσεις"],
        ["Auf Honorarbasis", "On a fee basis", "Με βάση το τιμολόγιο"],
        ["Eine Erwartung erfüllen", "To meet an expectation", "Να πληρώ την προσδοκία"],
        ["Bewilligt bekommen", "To get approved", "Να εγκρίνω"],
        ["Ein Vorschlag machen", "To make a suggestion", "Να κάνω μια πρόταση"],
        ["Tätig sein als + N", "To be active as", "Να δραστηριοποιούμαι ως"],
        ["Auf dem Papier", "On paper", "Στα χαρτιά"],
        ["Das Spiel an/pfeifen", "To whistle/start the game", "Να σφυρίξω/να ξεκινήσω το παιχνίδι"],
        ["Ernst nehmen", "To take seriously", "Να παίρνω στα σοβαρά"],
        ["In die Pfeife pusten", "To blow the whistle", "Να φυσάω τη σφυρίχτρα"],
        ["Auf sich aufmerksam machen", "To draw attention to oneself", "Να τραβήξω την προσοχή"],
        ["Eine Prüfung machen", "To take an exam", "Να δώσω μια εξέταση"],
        ["Viel gemein haben mit + D", "To have a lot in common with", "Να έχω πολλά κοινά με"],
        ["In hohem Alter", "At an advanced age", "Σε μεγάλη ηλικία"],
        ["Es ist kein Wunder", "It's no wonder", "Δεν είναι περίεργο"],
        ["Einem Hobby nachgehen", "To pursue a hobby", "Να ασχολούμαι με ένα χόμπι"],
        ["Unter der Leitung", "Under the direction", "Υπό την καθοδήγηση"],
        ["Ab sofort", "From now on", "Από τώρα"],
        ["Im Vorverkauf", "In pre-sale", "Στην προπώληση"],
        ["Zu erhalten sein", "To be available", "Να είναι διαθέσιμο"],
        ["Aufs Land ziehen", "To move to the countryside", "Να μετακομίσω στην υπαίθριο"],
        ["Das kommt darauf an", "That depends", "Εξαρτάται"],
        ["Schwer fallen + D", "To be difficult for", "Να μου είναι δύσκολο"],
        ["Über Bankeinzug", "Via direct debit", "Μέσω απευθείας χρέωσης"],
        ["Ein Konto eröffnen", "To open an account", "Να ανοίξω έναν λογαριασμό"],
        ["Ins Gespräch kommen", "To start a conversation", "Να ξεκινήσω μια συζήτηση"],
        ["Es stellte sich heraus, dass...", "It turned out that...", "Αποδείχτηκε ότι..."],
        ["In Pension gehen", "To retire", "Να πάω στη σύνταξη"],
        ["Eine Entscheidung treffen", "To make a decision", "Να πάρω μια απόφαση"],
        ["Zur Verfügung haben", "To have at one's disposal", "Να έχω στη διάθεσή μου"],
        ["Nach wie vor", "As before", "Όπως πριν"],
        ["Weit ... entfernt", "Far ... away", "Μακριά ... από"],
        ["Verkleidet als + N", "Disguised as", "Μεταμφιεσμένος ως"],
        ["In Erfüllung gehen", "To be fulfilled", "Να εκπληρωθεί"],
        ["Für Aufregung sorgen", "To cause excitement", "Να προκαλέσω αναστάτωση"],
        ["Zu kurz kommen", "To get a raw deal", "Να βγω ζημιωμένος"],
        ["In fachkundiger Begleitung", "With expert guidance", "Με τη συνοδεία ενός ειδικού"],
        ["Unter Aufsicht", "Under supervision", "Υπό επίβλεψη"],
        ["Vor Kurzem", "Recently", "Πρόσφατα"],
        ["In Ruhe lassen", "To let be", "Να αφήσω στην ησυχία του"],
        ["Ein Hobby ausüben", "To practice a hobby", "Να ασχολούμαι με ένα χόμπι"],
        ["Das kann nicht ihr Ernst sein", "You can't be serious", "Δεν μπορεί να εννοείτε αυτό"],
        ["Das ist ja wohl das Letzte", "That is really the last straw", "Αυτό πραγματικά ξεπερνά τα όρια"],
        ["Die Tür aufhalten", "To hold the door", "Να κρατάω την πόρτα"],
        ["Beim Betreten", "Upon entering", "Κατά την είσοδο"],
        ["Auf sich aufmerksam machen", "To draw attention to oneself", "Να τραβήξω την προσοχή"],
        ["Aus Höflichkeit", "Out of politeness", "Από ευγένεια"],
        ["Sich mit seiner Meinung zurückhalten", "To hold back one's opinion", "Να κρατάω τη γνώμη μου"],
        ["Am Fuße eines Berges", "At the foot of a mountain", "Στους πρόποδες ενός βουνού"],
        ["In die Höhe befördern", "To lift up", "Να ανυψώσω"],
        ["Das Wetter spielt mit", "The weather is cooperating", "Ο καιρός συνεργάζεται"],
        ["In der Ferne", "In the distance", "Στον ορίζοντα"],
        ["Zu erkennen sein", "To be recognizable", "Να είναι αναγνωρίσιμο"],
        ["Zum Ziel haben", "To aim for", "Να έχω ως στόχο"],
        ["Sich bemerkbar machen", "To make oneself noticed", "Να κάνω την παρουσία μου αισθητή"],
        ["Ab und zu", "Now and then", "Πότε πότε"],
        ["Wir führen Sie an langem Faden", "We lead you by a long thread", "Σας οδηγούμε κατευθείαν"],
        ["Unter fachlicher Leitung", "Under professional supervision", "Υπό επαγγελματική καθοδήγηση"],
        ["Im Vergleich zu + D", "In comparison to", "Σε σύγκριση με"],
        ["Ab sofort", "From now on", "Από εδώ και πέρα"],
        ["Abstand nehmen von + D", "To keep distance from", "Να απέχω από"],
        ["Zu Besuch sein", "To be visiting", "Να είμαι επισκέπτης"],
        ["Auf ein Konto einzahlen", "To deposit into an account", "Να καταθέσω σε λογαριασμό"],
        ["Auf Kredit", "On credit", "Με πίστωση"],
        ["Vor einem Problem stehen", "To face a problem", "Να αντιμετωπίζω ένα πρόβλημα"],
        ["Ein Lehrlingsverhältnis auflösen", "To dissolve an apprenticeship", "Να διαλύσω μια σχέση μαθητείας"],
        ["Eine Interesse haben an + D", "To have an interest in", "Να έχω ενδιαφέρον για"],
        ["Im Anschluss an + A", "Following", "Μετά από"],
        ["Im Ganzen", "As a whole", "Στο σύνολο"],
        ["Das geregelte Einkommen", "The regular income", "Το τακτικό εισόδημα"],
        ["Sich einen Wunsch erfüllen", "To fulfill a wish", "Να εκπληρώσω ένα επιθυμία"],
        ["Eine Ausbildung machen", "To undergo training", "Να λάβω εκπαίδευση"],
        ["Sich einig sein über + A", "To agree on", "Να συμφωνώ σε"],
        ["Im Moment", "At the moment", "Αυτήν τη στιγμή"],
        ["Auf der Hand liegen", "To be obvious", "Να είναι προφανές"],
        ["Auf nationaler Ebene", "On a national level", "Σε εθνικό επίπεδο"],
        ["Auf keinen Fall", "Under no circumstances", "Με κανέναν τρόπο"],
        ["In ordentlichem Zustand", "In a tidy condition", "Σε τακτική κατάσταση"],
        ["Zum Glück", "Fortunately", "Ευτυχώς"],
        ["In einer schwierigen Lage", "In a difficult situation", "Σε μια δύσκολη κατάσταση"],
        ["Mit der Mode gehen", "To follow fashion", "Να ακολουθώ τη μόδα"],
        ["In der Früh", "In the early morning", "Το πρωί"],
        ["Sich auf die faule Haut legen", "To be lazy", "Να τεμπελιάζω"],
        ["Ab und zu", "From time to time", "Πότε πότε"],
        ["Wär das nicht was?", "Wouldn't that be something?", "Δεν θα ήταν κάτι;"],
        ["Im Internet surfen", "To surf the Internet", "Να σερφάρω στο διαδίκτυο"],
        ["Gebrauch machen von + D", "To make use of", "Να κάνω χρήση"],
        ["Im Austausch für + A", "In exchange for", "Σε αντάλλαγμα για"],
        ["Einen Anruf annehmen", "To answer a call", "Να απαντήσω σε μια κλήση"],
        ["Ab und zu", "Now and then", "Πότε πότε"],
        ["Sich aus den Augen verlieren", "To lose touch", "Να χάσω την επαφή"],
        ["Alle paar Wochen", "Every few weeks", "Κάθε λίγες εβδομάδες"],
        ["Einen Vortrag halten", "To give a lecture", "Να κάνω μια διάλεξη"],
        ["In dieser Hinsicht", "In this respect", "Από αυτή την άποψη"],
        ["Nach Ansicht + G", "According to the opinion of", "Σύμφωνα με την άποψη"],
        ["Zum Abschied", "For farewell", "Για αποχαιρετισμό"],
        ["Aufmerksam machen auf + A", "To draw attention to", "Να εφιστώ την προσοχή σε"],
        ["Der Lehrer hat die Schüler auf ihre Fehler in der letzten Klassenarbeit aufmerksam gemacht.", "The teacher pointed out the students' mistakes in the last class test.", "Ο δάσκαλος επισήμανε τα λάθη των μαθητών στην τελευταία τάξη."],
        ["Aus Kindertagen", "From childhood days", "Από τα παιδικά χρόνια"],
        ["Im Laufe + G", "During", "Κατά τη διάρκεια"],
        ["Zu Gast sein", "To be a guest", "Να είμαι καλεσμένος"],
        ["Einen Kontakt pflegen", "To maintain a contact", "Να διατηρώ μια επαφή"],
        ["Zur Verfügung stellen", "To make available", "Να θέσω στη διάθεση"],
        ["Vor Kurzem", "Recently", "Πρόσφατα"],
        ["Aus Neugierde", "Out of curiosity", "Από περιέργεια"],
        ["Zu Studienzwecken", "For study purposes", "Για σκοπούς μελέτης"],
        ["Eine Diskussion führen", "To conduct a discussion", "Να διεξάγω μια συζήτηση"],
        ["Jdn zu Wort kommen lassen", "To let someone have their say", "Να αφήσω κάποιον να μιλήσει"],
        ["Jdn sprechen lassen", "To let someone speak", "Να αφήσω κάποιον να μιλήσει"],
        ["Zu tun haben mit + D", "To have to do with", "Να έχω να κάνω με"],
        ["Eine feste Beziehung haben", "To have a steady relationship", "Να έχω μια σταθερή σχέση"],
        ["Mitte 30", "In the mid-30s", "Στα μέσα των 30"],
        ["Eine Präsentation halten", "To give a presentation", "Να κάνω μια παρουσίαση"],
        ["Einen Einblick geben", "To provide an insight", "Να δώσω μια εικόνα"],
        ["Freundschaft schließen", "To make friends", "Να κάνω φίλους"],
        ["Nach eigenen Angaben", "According to one's own statements", "Σύμφωνα με τις δικές του δηλώσεις"],
        ["Laut + D", "According to", "Σύμφωνα με"],
        ["Laut Statistik gibt es jedes Jahr immer weniger Babys in Deutschland.", "According to statistics, there are fewer and fewer babies in Germany every year.", "Σύμφωνα με τη στατιστική, υπάρχουν όλο και λιγότερα μωρά στη Γερμανία κάθε χρόνο."],
        ["Nach + D", "According to", "Σύμφωνα με"],
        ["Fürs Leben", "For life", "Για τη ζωή"],
        ["Mit Bedacht", "With care", "Με προσοχή"],
        ["Was es damit auf sich hat", "What it's all about", "Τι πραγματικά συμβαίνει"],
        ["Dank + G/D", "Thanks to", "Χάρη σε"],
        ["Dank dem Internet können wir viele Informationen in sehr kurzer Zeit finden.", "Thanks to the internet, we can find a lot of information in a very short time.", "Χάρη στο Ίντερνετ, μπορούμε να βρούμε πολλές πληροφορίες σε πολύ σύντομο χρόνο."],
        ["In der Lage sein", "To be able", "Να είμαι σε θέση"],
        ["Über kurz oder lang", "Sooner or later", "Αργά ή γρήγορα"],
        ["Zu Ende gehen", "To come to an end", "Να τελειώνει"],
        ["Es ist geschafft!", "It is done!", "Τελείωσε!"],
        ["Er/sie ist von uns gegangen", "He/she has passed away", "Έχει φύγει από εμάς"],
        ["Es ist Wahnsinn!", "It's crazy!", "Είναι τρελό!"],
        ["Alles im Griff haben", "To have everything under control", "Να έχω τα πάντα υπό έλεγχο"],
        ["Eine Vorlesung halten", "To give a lecture", "Να κάνω μια διάλεξη"],
        ["Rund um die Uhr", "Around the clock", "Συνεχώς"],
        ["Von Angesicht zu Angesicht", "Face to face", "Πρόσωπο με πρόσωπο"],
        ["Im Blick behalten", "To keep an eye on", "Να παρακολουθώ"],
        ["Bis auf + A", "Except for", "Εκτός από"],
        ["Ich bin bei Facebook", "I am on Facebook", "Είμαι στο Facebook"],
        ["Auf Facebook habe ich 30 Freunde", "I have 30 friends on Facebook", "Έχω 30 φίλους στο Facebook"],
        ["Kontakte knüpfen", "To make contacts", "Να κάνω επαφές"],
        ["Zur Verfügung haben", "To have at one's disposal", "Να έχω στη διάθεσή μου"],
        ["Ins Stocken geraten", "To come to a halt", "Να κολλήσω"],
        ["Die Ablage machen", "To do the filing", "Να κάνω την αρχειοθέτηση"],
        ["Einen Terminkalender führen", "To keep an appointment calendar", "Να τηρώ ημερολόγιο ραντεβού"],
        ["Voll und ganz", "Completely", "Πλήρως"],
        ["Am Laufen halten", "To keep going", "Να κρατάω σε λειτουργία"],
        ["Er hält die Kommunikation mit den Kunden des Unternehmens am Laufen", "He keeps the communication with the company's clients going", "Διατηρεί την επικοινωνία με τους πελάτες της εταιρείας"],
        ["Im Rückblick", "In retrospect", "Αναδρομικά"],
        ["Es nicht leicht haben", "To have a hard time", "Να τα βρίσκω σκούρα"],
        ["Es eignen als", "To be suitable as", "Να είναι κατάλληλο ως"],
        ["Als Snack eignen sich frisches Gemüse und Obst.", "Fresh vegetables and fruit are suitable as snacks.", "Φρέσκα λαχανικά και φρούτα είναι κατάλληλα για σνακ."],
        ["Welche Sätze passen in die Lücken?", "Which sentences fit in the blanks?", "Ποιες προτάσεις ταιριάζουν στα κενά;"],
        ["Bei Bedarf", "If needed", "Αν χρειαστεί"],
        ["Verständnis haben für + A", "To have understanding for", "Να έχω κατανόηση για"],
        ["Aufgrund + G", "Because of", "Εξαιτίας"],
        ["Auf andere Art und Weise", "In another way", "Με άλλον τρόπο"],
        ["Sich die Zeit vertreiben", "To pass the time", "Να περνάω την ώρα"],
        ["Auf Höflichkeit", "Out of politeness", "Από ευγένεια"],
        ["Vor Müdigkeit", "From tiredness", "Από κούραση"],
        ["Ein Gespräch führen", "To have a conversation", "Να διεξάγω μια συζήτηση"],
        ["Auf der Fahrt", "During the journey", "Κατά τη διάρκεια της διαδρομής"],
        ["Bedenken äußern", "To express concerns", "Να εκφράσω ανησυχίες"],
        ["Für Aufregung sorgen", "To cause excitement", "Να προκαλέσω αναστάτωση"],
        ["Die Ergebnisse des Tests sorgten für Aufregung in der Klasse.", "The results of the test caused excitement in the class.", "Τα αποτελέσματα της δοκιμασίας προκάλεσαν αναστάτωση στην τάξη."],
        ["Auf den ersten Blick", "At first glance", "Κατά την πρώτη ματιά"],
        ["Eine ganze Reihe von D", "A whole series of", "Μια ολόκληρη σειρά από"],
        ["Von geringem Wert", "Of little value", "Μικρής αξίας"],
        ["Klagen vor + D", "To sue before", "Να κάνω αγωγή εναντίον"],
        ["Simulieren", "To simulate", "Να προσομοιώνω"],
        ["Aus Zeitmangel", "Due to lack of time", "Λόγω έλλειψης χρόνου"],
        ["Aus diesem Grund (deswegen, darum, daher)", "For this reason (therefore, that's why, hence)", "Για αυτόν τον λόγο"],
        ["Schaden + G", "To harm", "Να βλάπτω"],
        ["Etw. verdient haben", "To have deserved something", "Να αξίζει κάτι"],
        ["Empfinden als", "To perceive as", "Να αντιλαμβάνομαι ως"],
        ["Lang andauernd", "Long-lasting", "Μακροχρόνια"],
        ["Nichts halten von + D", "To think nothing of", "Να μην έχω καλή γνώμη για"],
        ["Nichts allzu viel, nichts sehr viel", "Not too much, not very much", "Όχι πολύ πολύ, όχι πολύ πολύ"],
        ["Zunächst, am Anfang, zuerst", "First, initially, firstly", "Αρχικά, στην αρχή, πρώτα"],
        ["Verletzen", "To injure", "Να τραυματίσω"],
        ["Eine Warnung aussprechen, warnen", "To issue a warning, to warn", "Να εκδώσω μια προειδοποίηση, να προειδοποιήσω"],
        ["Sich auf die Reise machen", "To set off on a journey", "Να ξεκινήσω για ταξίδι"],
        ["Heraus bekommen", "To find out", "Να μάθω"],
        ["Es wird dunkel", "It is getting dark", "Σκοτεινιάζει"],
        ["Es wird hell", "It is getting light", "Ξημερώνει"]
    ];
    
    

    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Very Useful Expressions" />
                <Header2 props="Sehr nützliche Ausdrücke" />
            </Box>

            <Box className="global-box-not-header" >

                <Header3_Middle props="" />
                <Header2 props="" />



                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>

                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        {verbs.map((verb, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>
                            </TableRow>
                        ))}
                    </Table>

                </TableContainer>

            </Box>

            <ScrollToTopButton />


        </div>
    )
}

export default Z0167_UsefulExpressions