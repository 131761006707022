import React from 'react';
import {Typography} from "@mui/material";

function Header1({props}) {
    return (
      <div>
          <Typography variant="h4" color="primary" sx={{textAlign:"center", marginBottom:"0em"}}
          >
            {props}
          </Typography>
      </div>
    )
}

export default Header1