import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0019VerbsImperative() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Imperative" />
                <Header2 props="Imperativ" />
            </Box>



            <Box className="global-box-not-header" >
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The imperative expresses <span className="green-text"> commands, requests, or orders</span>.<br /><br />

                    Just as there are three different forms of address (Sie, ihr, du), there are three corresponding imperative forms. <br />
                    The imperative verb is the first element in a command. However, it may be preceded by <span className="green-text"> bitte</span> please,
                    which can also occur within the sentence or in final position. <br /><br />

                    The word <span className="green-text"> doch</span> softens the command,
                    corresponding to the English <span className="green-text"> why don't you?</span> In written German commands, an exclamation point is used. <br /><br />

                    <Box sx={{ backgroundColor: 'pink' }}>Imperative = (Bitte) verb + Personal pronoun (if required)</Box>
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>du :</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Spiel(e)!</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Hilf mir!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>ihr :</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Spielt!</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Helft mir!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Sie :</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Spielen Sie</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Helfen Sie mir!</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    1. How we make imperative.<br /><br />

                    With the imperative we expess an <span className="green-text"> order</span>, an <span className="green-text"> incitement</span> or a <span className="green-text"> request</span>.<br />

                    If we are talking in <span className="green-text"> singular</span> we will use <span className="green-text"> second singular person</span>.<br />

                    If we talking with one, or many people in <span className="green-text"> plural</span> then we will use <span className="green-text"> formal type</span>.<br /><br />


                    <ul>
                        <li>To make imperative in <span className="green-text"> second singular</span> person, we remove  the personal pronoun "<span className="green-text"> du</span>" and the ending "<span className="green-text"> st</span>".</li>
                        <li>To make imperative in <span className="green-text"> second plural person</span>, we remove  the personal article "<span className="green-text"> ihr</span>".</li>
                        <li>When we have <span className="green-text"> irregular verbs</span> which change their subject from "ä " to "a".</li>
                        <li>When we want <span className="green-text"> formal type</span>, then to make impretive we just transfer the pronoun "<span className="green-text"> Sie</span>" after the verb.</li>
                        <li>When we have <span className="green-text"> plural</span> , then to make imperative we <span className="green-text"> remove the article</span>.</li>
                    </ul>
                    <br /><br /><br />


                    For example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} ></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Imperativ</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du trink-st</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> trink!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr nehmm-t  </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> nehmmt!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> du fähr-st</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> fahr!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie trink-en </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> trinken Sie!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Gehen Sie</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Gehen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    So:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive</TableCell>

                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> du</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>
                                    singular (imperative)
                                </TableCell>

                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>ihr</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> plural(imperative)</TableCell>


                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Sie </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> formally plural (imperative)</TableCell>


                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> sein</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> sei!</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>seid!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>seien Sie!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>sag-en</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du sagst</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sag!</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr sagt</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> sagt!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie sagen</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> sagen Sie!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> regular verb</TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> aufsteh-en</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> du stehst</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> steh auf!</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr steht auf</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> steht auf!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie stehen auf</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>stehen auf Sie!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> separable verb</TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}></TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> arbeit-en</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> du arbeitest </TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> arbeit</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr arbeitet</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> arbeitet!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie arbeiten</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> arbeiten Sie!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>special verb</TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>nehm-en</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> du nimmst</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> nimm!</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr nehmt 	</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> nehmt!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie nehmen</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>nehmen Sie!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> irregular verb</TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> schlaf-en</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du schlafst</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> schlaf! <br />(without Umlaut)</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr schlaft</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> schlaft! <br />(without Umlaut)</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie schlafen</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> schlafen Sie! <br />(without Umlaut)</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> irregular verb<br />

                                    with a to: a</TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>fernseh-en</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> du siehst fern</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sieh fern!</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr seht fern</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> seht fern!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie sehen fern</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> sehen Sie fern!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}> separable and irregular verb </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> lesen</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du liest</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> lies! (not lie)</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr lest</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> lest!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie lesen</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> lesen Sie</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> essen</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du isst</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> iss! (not is)</TableCell>


                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> ihr esst</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> esst!</TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Sie essen</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> essen Sie! </TableCell>

                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    2. How we make sentences with imperative, when we talk to one person.<br /><br />

                    The sentences with imperative starts always with the verb.<br /><br />

                    The sentences in imperative can be more polite when after the verb we use one the
                    words "<span className="green-text"> bitte</span>", or "<span className="green-text"> doch</span>", or "<span className="green-text"> doch bitte</span>", "<span className="green-text"> doch mal</span>".<br /><br />


                    <ul>
                        <li><span className="green-text"> Nimm doch</span> noch etwas Fleisch!</li>
                        <li><span className="green-text"> Nehmen doch</span> noch etwas Fleisch!</li>
                    </ul>

                </Typography>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Nominative</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Imperative</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll schwimmen <span className="green-text"> gehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Geh doch </span> schwimmen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Du soll eine Freundin <span className="green-text"> besuchen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Besuch doch</span> eine Freundin.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll Freunde <span className="green-text"> einladen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Lad doch</span> Freunde <span className="green-text"> ein</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll spazieren <span className="green-text"> gehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Geh doch </span> spazieren.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll etwas <span className="green-text"> lesen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Lies doch</span> etwas.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll eine Stunde <span className="green-text"> schlafen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Schlaf doch</span> eine Stunde.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll das Kinderzimmer <span className="green-text"> aufraumen</span>. </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Raum doch</span> das Kinderzimmer <span className="green-text"> auf</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll einen Brief <span className="green-text"> schreiben</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Schreib doch</span> einen Brief.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll einkaufen <span className="green-text"> gehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Geh doch</span> einkaufen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll das Geschirr <span className="green-text"> spulen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Spul doch</span> das Geschirr</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll Abendessen <span className="green-text"> vorbereiten</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Bereit doch</span> das Abendessen <span className="green-text"> vor</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll <span className="green-text"> fernsehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Sieh</span> doch <span className="green-text"> fern</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Du soll endlich zufrieden <span className="green-text"> sein</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Sei doch</span> endlich zufreiden</TableCell>
                            </TableRow>


                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    3. How we make sentences with imperative, when we talk to many persons.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst schwimmen <span className="green-text"> gehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Geht doch</span> swimmen.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst Musik <span className="green-text"> horen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Hort doch</span> Musik.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst Freundde <span className="green-text"> besuchen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Besucht doch</span> Freunde.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ihr sollst Freunde <span className="green-text"> einladen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Ladet</span> doch Freunde <span className="green-text"> ein</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ihr sollst Fußball <span className="green-text"> spielen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Spielt doch</span> Fußball.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst einkaufen <span className="green-text"> gehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Geht doch</span> einkaufen. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst fur die Schule <span className="green-text"> arbeiten</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Arbeitet doch</span> fur die Schule. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst <span className="green-text"> fernsehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Seht doch</span> fern. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst ein bisschen <span className="green-text"> aufraumen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Raumt doch</span> ein bisschen ein.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst ein Buch <span className="green-text"> lesen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Lest doch</span> ein Buch.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst spazieren <span className="green-text"> gehen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Geht doch</span> spazieren.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst Musik <span className="green-text"> machen</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Macht doch</span> Musik.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> Ihr sollst endlich zufrieden <span className="green-text"> sein</span>.</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}> <span className="green-text"> Seid doch</span> zufrieden.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                    4) Another way to make imperative<br /><br />

                    <span className="green-text"> Bring</span> das Fahrrad bitte in die Garage = Bring the bike to the garage.  (die Garage)<br /><br />

                    "bring-en" is the verb in the infinitive, and "bring" is its imperative. Also that sentence shows movement so we use accusative.<br /><br /><br /><br />



                    <span className="green-text"> Tu</span> den mantel bitte in den Schrank = Please put the coat in the closet. (der Schrank)<br /><br />

                    "tun" is the verb in the infinitive, and "tu" is its imperative. Also that sentence shows movement so we use accusative.

                </Typography>
            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >
                <Header3_Middle props="2. Examples" />


                <Box sx={{ marginTop: "5em" }}>
                    <Header2 props="2a. Formal Commands (Singular and Plural) (Sie)" />
                </Box>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Formal commands are formed by using the <span className="green-text"> infinitive</span> plus <span className="green-text"> Sie</span>. <br />
                    The same form is used for addressing one or more individuals.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Kommen Sie</span> !</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Come.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Bitte <span className="green-text"> parken Sie</span> hier!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Please park hier.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Rauchen Sie</span> bitte nicht!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Please don't smoke hier.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Antworten Sie</span> doch!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Please answer!</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Herr Muller <span className="green-text"> erzhälen Sie</span> die Geschichte bitte!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Mr Muller, please tell me the story.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Meine Herren <span className="green-text"> nehmen Sie</span> bitte nichts!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Gentlemen please don't take anything.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2b. Singular (du)" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The familiar singular command is formed by using the infinitive stem plus -e. In colloquial speech, this -e is usually dropped. <br />
                    Note that the pronoun du is normally not expressed except for special emphasis.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Frag</span> deinen Vater !</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Ask your father.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Kommm</span> mit deinem Bruder bitte!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Please come with your father.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Mark, bitte <span className="green-text"> kauf</span> die kamera!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Mark please buy the camera.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Trink</span> doch Wasser!</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Why don't you drink wasser?</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Geh</span> ins Zimmer</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Go into the room.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> Geh</span> du nach House</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Go home.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />





            <Box className="global-box-not-header" >
                <Header3_Middle props="3. Imperative of verbs : sein, haben, werden" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >sein</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >du</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sei</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >ihr</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>seid</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Sie</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>seien Sie</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >haben</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >du</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>hab</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >ihr</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>habt</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Sie</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>haben Sie</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>





                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >werden</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >du</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>werd(e)</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >ihr</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>werdet</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Sie</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>werden Sie</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />


        </div >
    )
}

export default Z0019VerbsImperative