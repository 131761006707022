import React from 'react';
import {Link} from 'react-router-dom';

import { Button, TextField, Typography, IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';



import {useForm} from "react-hook-form";

function Mui4_FormValidation() {

    const {register, 
           handleSubmit, 
           formState: {errors}
        } = useForm();

    console.log("errors:", errors);    
    console.log("Rendered:");

    return (
        <div>
            <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
            <Typography variant="h1" color="primary" sx={{textAlign:"center", marginBottom:"0em"}}>Form Validation</Typography>
            <Typography variant="h6" color="secondary" sx={{textAlign:"center", marginBottom:"0em"}}>
                using "react-hook-form" library. useForm hook
            </Typography>
            <Typography variant="h6" color="secondary" sx={{textAlign:"center", marginBottom:"0em"}}>
                Υπάρχει απενεργοποίηση του button όταν δεν είναι validate. Ο κωδικός πρέπει να είναι πάνω από 5 χαρακτήρες.<br/>
                Το object είναι στην κονσόλα.
            </Typography>

            <form onSubmit={handleSubmit( (data)=>console.log(data) )} 
                style={{ display:'flex', flexDirection:'column', gap:5, border:"1px solid black", width:"300px", margin:"100px auto" }}    
            >
                
                <TextField 
                    error={Boolean(errors.email)}
                    helperText ={ Boolean(errors.email) ? <Typography>Invalid email</Typography> : <Typography>Valid email</Typography>}
                    {...register("email", {
                        validate: (val)=>/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)
                                            }  
                    )} 
                />

                <TextField 
                    error={Boolean(errors.password)}
                    helperText ={ Boolean(errors.password) ? <Typography>Minimun Length should be 5 characters</Typography> : null}
                    type={'password'} 
                    {...register("password", {minLength:6, required:true}   )} />

                <Button 
                    disabled={ Boolean(errors.email)  ||  Boolean(errors.password) }
                    type="submit" variant='contained'>
                        Submit
                </Button>

            </form>

        </div>
    )
}

export default Mui4_FormValidation