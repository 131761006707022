import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z0041_Verbs_withDative() {

  const verbs = [
    ["jdm begegnen", "to meet someone", "συναντώ κάποιον"],
    ["jdm danken", "to thank someone", "ευχαριστώ κάποιον"],
    ["jdm dienen", "to serve someone", "υπηρετώ κάποιον"],
    ["jdm einfallen", "to occur to someone", "έρχεται στον νου κάποιου"],
    ["jdm folgen", "to follow someone", "ακολουθώ κάποιον"],
    ["jdm gefallen", "to please someone", "αρέσω σε κάποιον"],
    ["jdm gehören", "to belong to someone", "ανήκω σε κάποιον"],
    ["jdm gelingen", "to succeed for someone", "πετυχαίνω για κάποιον"],
    ["jdm gratulieren", "to congratulate someone", "συγχαίρω κάποιον"],
    ["jdm helfen", "to help someone", "βοηθώ κάποιον"],
    ["jdm kündigen", "to give notice to someone", "δίνω προειδοποίηση σε κάποιον"],
    ["jdm leidtun", "to feel sorry for someone", "λυπάμαι για κάποιον"],
    ["jdm nützen", "to benefit someone", "ωφελώ κάποιον"],
    ["jdm passen", "to suit someone", "ταιριάζω σε κάποιον"],
    ["jdm schaden", "to harm someone", "βλάπτω κάποιον"],
    ["jdm schmecken", "to taste good to someone", "γεύομαι καλά σε κάποιον"],
    ["jdm stehen", "to suit someone", "πηγαίνει σε κάποιον"],
    ["jdm vertrauen", "to trust someone", "εμπιστεύομαι κάποιον"],
    ["jdm verzeihen", "to forgive someone", "συγχωρώ κάποιον"],
    ["jdm wehtun", "to hurt someone", "πληγώνω κάποιον"],
    ["jdm widersprechen", "to contradict someone", "αντιφάσκω σε κάποιον"],
    ["jdm zuhören", "to listen to someone", "ακούω κάποιον"],
    ["jdm zuschauen", "to watch someone", "παρακολουθώ κάποιον"],
    ["jdm zusehen", "to watch someone", "παρακολουθώ κάποιον"],
    ["jdm zustimmen", "to agree with someone", "συμφωνώ με κάποιον"],
    ["einfallen + D", "to occur to", "έρχεται στον νου"]
  ];


  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box className="global-box-header">
        <Header1 props="Verbs with Dative" />
        <Header2 props="Verben mit Dativ" />
      </Box>



      <Box className="global-box-not-header" >

        <Header3_Middle props="" />
        <Header2 props="" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginBottom: "2em" }} >
          There are some verbs that have syntax <span className="green-text">only</span> with <span className="green-text">object in dative</span>. <br />
          These verbs called <span className="green-text">intransitive verbs</span> (intransitive Verben).
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={3}>Dative</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Hilfts</span> du manchmal dein<span className="green-text">er</span> Mutter	in der Küche?</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Do you sometimes help your mother in the kitchen?</TableCell>
            </TableRow>

          </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: "5em" }} >
          The most important verbs with dative are:
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>
            <TableRow>
              <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
            </TableRow>

            {verbs.map((verb, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: "5em" }} >
          Also with dative have syntax some expressions. The most important are:
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Wie geht es dir? / Ihnen?</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>How are you? (informal/formal)</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Πώς είσαι; / Πώς είστε;</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Es geht mir/dir ... gut/schlecht.</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I am/You are ... well/bad.</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> Είμαι/Είσαι ... καλά/άσχημα.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Etwas ist mir/dir ... egal/gleich.</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Something is ... I don't care/the same to me/you.</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Κάτι είναι ... μου/σου ... αδιάφορο/ίδιο.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Mir/Dir ... ist kalt/warm.</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I am/You are ... cold/warm.</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Κρυώνω/Κρυώνεις ... έχω/έχεις ζέστη.</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Mir/Dir ... ist langweilig.</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I am/You are ... bored.</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Πλήττω/Πλήττεις.</TableCell>
            </TableRow>

          </Table>
        </TableContainer>

      </Box>

      <ScrollToTopButton />
    </div>
  )
}

export default Z0041_Verbs_withDative