import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0020VerbsPresentPerfect() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Present Perfect Tense" />
                <Header2 props="Perfekt" />
            </Box>


            <Box className="global-box-not-header" >
                <Header3_Middle props="1. Use" />
                <Header2 props="Gebrauch" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The German present perfect tense can refer to <span className="green-text"> completed actions in the past</span>, as well as to <span className="green-text"> actions that have begun in the past and
                        continue into the present</span> (as is the case for the present perfect tense in English).<br /><br />

                    In German, the present perfect tense, also called the <span className="green-text"> conversational past</span>, is the past tense that is most frequently used to describe
                    past events in normal conversation. In this situation, it is best translated by the simple past tense in English.<br /><br />

                    The present perfect tense of German verbs consists of the present tense inflected forms of the <span className="green-text"> auxiliary haben</span> or <span className="green-text"> sein</span> plus
                    the <span className="green-text"> past participle </span> of the main verb.<br /><br />

                    The verbs that take <span className="green-text"> haben</span> as their auxiliary include the <span className="green-text"> transitive verbs</span> (those that take a <span className="green-text"> direct object</span>),
                    the <span className="green-text"> reflexive verbs</span>, and the <span className="green-text"> modal</span> auxiliaries.<br />
                    The verbs that take <span className="green-text"> sein</span> as their auxiliary include the <span className="green-text"> intransitive verbs</span> (those without a direct object) indicating a change of condition
                    or a change in location.<br /><br />

                    NOTE: When used as part of the verb (rather than as an adjective), the past participle never takes an ending, and in independent clauses
                    the past participle is in final position.
                </Typography>
            </Box>

            <ScrollToTopButton />




            <Box className="global-box-not-header" >
                <Header3_Middle props="2. Formation of Perfekt" />
                <Header2 props="Bildung des Perfekt" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The Perfekt is bulding with the verbs <span className="green-text"> sein</span> or <span className="green-text"> haben</span> in the Present and the <span className="green-text"> Past Participle</span>.<br /><br />
                    For example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich habe gelernt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin gekommen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du hast gelernt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>du bist gekommen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es hat gelernt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>er, sie, es bin gekommen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir haben gelernt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>wir sind gekommen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr habt gelernt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ihr seid gekommen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie haben gelernt</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>sie, Sie sind gekommen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />




            <Box className="global-box-not-header" >
                <Header3_Middle props="2. Formation of the Past Participle" />
                <Header2 props="Bildung des Partizips Perfekt (Partizip II)" />


                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2a. Past Participle of Regular Verbs" />
                </Box>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The past participle of most regular verbs is formed by placing the prefix <span className="green-text"> "ge-"</span> in front of
                    the infinitive stem and adding : <span className="green-text"> "-t"</span> at the end of the stem.<br /><br />

                    <Box sx={{ backgroundColor: 'pink' }}>Past participle (weak verbs) = "ge" + Infinitive stem + "-t"</Box>
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>spielen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ge + spiel + t</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>= <span className="green-text"> ge</span>spiel<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>lachen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ge + lach +t</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>= <span className="green-text"> ge</span>lach<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>arbeiten</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ge + arbeit + et</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>= <span className="green-text"> ge</span>arbeite<span className="green-text">t</span></TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>



                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2b. Past Participle of Irregular Verbs" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The past participle of most irregular verbs is formed by placing the prefix <span className="green-text"> "ge-"</span> in front of the infinitive stem and adding : <span className="green-text"> "-en"</span> at the end
                    of the stem.<br /><br />

                    <Box sx={{ backgroundColor: 'pink' }}>Past participle (stromg verbs) = "ge" + Infinitive sten + "-en"</Box>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>singen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ge + sung + en</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>= <span className="green-text"> ge</span>sung<span className="green-text">en</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    There are three exceptions, these verbs forms the Past Participle with "-t":
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>br<span className="green-text">i</span>ngen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>gebr<span className="green-text">a</span>ch<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>d<span className="green-text">e</span>nken</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ged<span className="green-text">a</span>ch<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>w<span className="green-text">i</span>ssen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>gew<span className="green-text">u</span>ss<span className="green-text">t</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>



                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2c. Regular verbs with the stem ending in : -t, -d, -m, -n" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Verbs with the stem ending in : <span className="green-text"> -t, -d, -m, -n</span> take ending : <span className="green-text"> -et</span> as we do in Present tense.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>arbei<span className="green-text">t</span>-en</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>gearbeit<span className="green-text">et</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ba<span className="green-text">d</span>-en</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>gebad<span className="green-text">et</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>at<span className="green-text">m</span>-en</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>geatm<span className="green-text">et</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>rech<span className="green-text">n</span>-en</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>gerechn<span className="green-text">et</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2d. Regular verbs with the ending in : -ieren" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Verbs with the stem ending in : <span className="green-text"> -ieren</span> they <span className="green-text"> don't</span> take <span className="green-text"> ge-</span>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>telefon<span className="green-text">ieren</span></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>telefonier<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>stud<span className="green-text">ieren</span></TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>studier<span className="green-text">t</span></TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>






                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2e. Separable verbs - Trennbare verben" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Separable verbs takes the ge- inside.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>zuhören</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>zu<span className="green-text">ge</span>hört</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >hören - gehört</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>abschreiben</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ab<span className="green-text">ge</span>schrieben</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >geschreiben - geschrieben</TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>









                <Box sx={{ marginTop: "7em" }}>
                    <Header2 props="2f. Regular verbs with the inseparable prefixes : be-, emp-, ent-, er-, ge-, ver-, zer-" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Verbs with the inseparable prefixes : <span className="green-text"> be-, emp-, ent-, er-, ge-, ver-, zer-</span> do <span className="green-text"> not</span> take
                    the <span className="green-text"> "ge"</span>- prefix in the past participle.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">be</span>stellen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>bestell<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">ent</span>decken</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>entdeck<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">er</span>lären</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>erklär<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">ge</span>hören</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>gehör<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">ver</span>kaufen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>verkauf<span className="green-text">t</span></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    There are two exceptions:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wiederholen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>wiederhol<span className="green-text">t</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>unterschr<span className="green-text">ei</span>ben</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>unterschr<span className="green-text">ie</span>ben</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />







            <Box className="global-box-not-header" >
                <Header3_Middle props="3. Formation of the Past Participle with sein or haben" />
                <Header2 props="Bildung des Perfekt mit haben oder sein" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                    The most verbs builds "Perfekt" with the auxiliary verb "haben".<br />
                    Among them are the <span className="green-text"> Reflexive verbs</span> and the <span className="green-text"> Modal</span> verbs. <br />
                    The present perfect tense of some German verbs is formed with the present tense of  the auxiliary verb sein instead of haben. <br />
                    Such verbs are referred to as intransitive verbs (verbs that do not take a direct object). <br />
                    They usually denote a change of location or condition.<br /><br /><br />

                    1. Intransitive verb that shows <span className="green-text"> movement and change a place</span>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>fahren</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> ist</span> gefahren</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin gefahren</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gehen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> ist</span> gegangen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin gegangen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>kommen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> ist</span> gekommen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin gekommen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>fallen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text"> ist</span> gefallen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin gefallen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    2. Intransitive verb that shows movement and change of situation.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein/schlafen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>eingeschlafen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin eingeschlafen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>auf/wachen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>aufgewachen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin aufgewachen</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sterben</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gestorben</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>ich bin gestorben</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    3. The verbs:<br />
                    <span className="green-text"> sein</span> (to be),<br />
                    <span className="green-text"> werden</span> (to become),<br />
                    <span className="green-text"> bleiben</span> (to stay),<br />
                    <span className="green-text"> passieren</span> (to happen),<br />
                    <span className="green-text"> geschehen</span> (to happen),<br />
                    <span className="green-text"> gelingen</span> (to success), <br />
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Partizip II - Partizip Perfekt</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sein </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist gewesen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to be</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>werden </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist geworden </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to become </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>bleiben </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist geblieben </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to stay </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>passieren</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist passiert </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to happen </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>geschehen </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist geschehen </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to happen </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>gelingen</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ist gelungen </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to success </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0020VerbsPresentPerfect