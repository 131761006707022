import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';

function Z200_Prepositions_Time() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Prepositions with time meaning" />
                <Header2 props="Präpositionen mit temporaler Bedeutung" />
            </Box>



            <Box className="global-box-not-header" >

                <Header3_Middle props="1. At that point of time - At a certain time" />
                <Header2 props="" />


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>1a. Days, Time of day</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>Tage,Tageszeiten</Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    To the question: "<spam class="green-text">When</spam> <spam class="red-text">(Wann)"</spam> we use : <spam class="red-text">"an + Dativ"</spam>
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">am</spam> Montag</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>on Monday </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>am = an + dem (der Montag)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">am</spam> 6. Februar </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>on the 6th of February </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">am</spam> Morgen, <spam class="green-text">am</spam> Vormittag, <spam class="green-text">am</spam> Mitttag, <spam class="green-text">am</spam> Nachmittag, <spam class="green-text">am</spam> Abend
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>In the morning, In the late morning, at noon, in the afternoon, In the evening </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Morgen, der Vormittag, der Mittag <br /><br /> der Nachmittag, der Abend</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">am</spam> Wochenende </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>on the weekend </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Wochenende </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1b. Months, Seasons, Year </Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>Monate, Jahreszeiten, Jahre </Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    To the question: "<spam class="green-text">When</spam> <spam class="red-text">(Wann)"</spam> we use : <spam class="red-text">"in + Dativ"</spam>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">im</spam> Mai</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>in May </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>im = in + dem (der Mai )</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">im</spam> Frühling, <spam class="green-text">im</spam> Winter, <spam class="green-text">im</spam> Sommer, <spam class="green-text">im</spam> Herbst
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>in the spring, in the summer,  in the autumn, in the winter </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Frühling, der Winter <br /> der Sommer, der Herbst</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">im</spam> Jahr 2019</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>in year 2019  </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Jahr</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>




                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1c. Hour </Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>Uhrzeit</Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    1. To the question: "<spam class="green-text">When</spam> <spam class="red-text">(Wann)"</spam> we use : <spam class="red-text">"um" </spam> when we want to declare the time exactly.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">um</spam> 20.30 Uhr</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>exactly 20.30 hour </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">um</spam> Mitternacht</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>at Midnight </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    2. To the question: "<spam class="green-text">When</spam> <spam class="red-text">(Wann)"</spam> we use : <spam class="red-text">"gegen" </spam> when we want to declare the time approximately.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">gegen</spam> 20.30 <spam class="green-text">Uhr</spam></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>about 20.30 hour </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>





                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>1d. Holidays</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}>Feiertage </Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    To the question: "<spam class="green-text">When</spam> <spam class="red-text">(Wann)"</spam> we use : <spam class="red-text">"zu + Dativ"</spam>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">zu Ostern</spam> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>at Easter  </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because "Ostern" has not an article</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">zu Weihnachten</spam></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>at Christmas</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because "Weihnachten" has not an article.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">zu Neujahr</spam></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>at Newyear</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because "Neujahr" has not an artikle.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">zum Geburtstag</spam> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>at birthday</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because it is: "der Geburtstag" (zum = zu + dem)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><spam class="green-text">zum Namenstag</spam> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>at name day </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because it is: "der Namenstag" (zum = zu + dem).</TableCell>
                        </TableRow>



                    </Table>
                </TableContainer>


            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="2. Timeline " />
                <Header2 props="Zeitliche Abfolfe: vor, nach  " />



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>vor + Dativ = before</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Wir haben <spam class="green-text">vor</spam> ein<spam class="green-text">er</spam> Woche die letzte Klassenarbeit geschreiben. (die Woche)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                We wrote <spam class="green-text">before</spam> a week the last class test.
                            </TableCell>
                        </TableRow>


                    </Table>
                </TableContainer>


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>nach + Dativ = before</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Gehen wir <spam class="green-text">nach dem Kino</spam> Eis essen? (das Kino)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Will we go <spam class="green-text">after cinema</spam> to eat ice cream?
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>


            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="3. Start" />
                <Header2 props="Beginn: seit, ab" />



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>seit + Dativ = for</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    We describe something that has started at the past and continued since now.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich lerne <spam class="green-text">seit drei Jahren</spam> Deutch.
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I have been learning German <spam class="green-text">for three years.</spam>
                            </TableCell>
                        </TableRow>


                    </Table>
                </TableContainer>


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>ab + Dativ = from</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">Ab September</spam> beginnt wieder die Schule.
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">From September </spam> the school will start.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>




            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="4. Start and End" />
                <Header2 props="Beginn und Ende: bis(zu) " />



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>bis + Akkusativ = until (without article)</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    It shows the <spam class="green-text">end</spam> of an time period.<br />
                    When it follows time definition <u>without article</u> we use only <spam class="green-text">"bis"</spam>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Morgen bin ich <spam class="green-text">bis 15.00 Uhr</spam> in der Schule
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Tomorrow i will be <spam class="green-text">until 15.00 o'clock</spam> at the school.
                            </TableCell>
                        </TableRow>


                    </Table>
                </TableContainer>


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>bis zu + Dativ: until (with article)</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    When it follows date or noun  <u>with article</u> we use <spam class="green-text">"bis zu+Dativ"</spam>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Schaffst du das <spam class="green-text">bis zum dritten</spam> April?
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Will you manage this <spam class="green-text">until 3th</spam> of April?
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <hr />

                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>von ... bis : from ... until (without article)</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    These prepositions shows the start and the end of a time period. We use then follows time definition <u>without article</u>
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">Von Donnerstag bis Sonntag</spam> waren wir auf Fereien.
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">From Wednesday until Sunday</spam> we were holidays.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Be careful : With the days of the week and the moths we use the prepositions "von" and "bis" without article.
                </Typography>




                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>von + Dativ... bis zu +Dativ :     from ... until (with article)</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    When it follows date or noun <u>with article</u> we use <spam class="green-text">"von+Dativ ... bis zu + Dativ"</spam>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">Vom zweiten bis zum achten April</spam> bin ich in München. (Vom=von+dem)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">From 2 until 8 of April</spam> i will be in Munich.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <hr />

                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>zwischen + Dativ : between</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    It shows the time period between two periods.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                In meiner Familie haben alle <spam class="green-text">zwischen dem 1. Januar and dem 20 März</spam>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                In my family have all name day <spam class="green-text">between</spam> 1th January and 20 March.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>




            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="5. Simultaneously events  " />
                <Header2 props="Gleichzeitige Ereignisse" />



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>bei + Dativ  = during</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">Beim Tanzen </spam>ist mir Tim auf den Fuß getreten (beim=bei+dem)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">While we were dancing</spam> Tim stepped on my foot.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '5em' }}>während + Genitiv = during</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">Während der Klassenarbeit</spam> war es still in der Klasse. (die Klassenarbeit)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">During</spam> the class test there was quiet.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>



            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="6. Time period" />
                <Header2 props="Zeitraum" />


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>in + Dativ = in</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">In der ersten Stunde </spam>haben wir Mathe. (die Stunde)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">In the first hour</spam> we have mathematics.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    With the preposition "in" we can refer also to the future.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">In einem Monat </spam>habe ich Geburtstag. (der Monat)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">In a month </spam>is my birthday.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>für + Akkusativ = for</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich fahre <spam class="green-text">für eine Woche </spam> nach Deutschland. (die Woche)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I will go <spam class="green-text">for a week</spam> at Germany.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>über + Akkusativ = in time of period</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Ich muss <spam class="green-text">übers Wochenende </spam> lernen. (übers=über +das, das Wochenende)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                I have to <spam class="green-text">in the weekend</spam> study.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>





                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>unter + Dativ = less than time of period</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark läuft die 2 Km <spam class="green-text">unter einer Stunde</spam>. (die Stunde)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark ran the 2 Km <spam class="green-text">in less than eine hour</spam>.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>



            <ScrollToTopButton />




            <Box className="global-box-not-header" >

                <Header3_Middle props="7. Age" />
                <Header2 props="Alter" />


                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>mit + Dativ = at the age</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                Mark hat <spam class="green-text">mit 11 Jahren </spam> sein erstes Handy bekommen.
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                Mark hat <spam class="green-text">at the age of 11 </spam> his first mobile phone
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "0em", marginTop: '3em' }}>ab + Dativ = from the age</Typography>
                <Typography variant="h5" color="orange" sx={{ textAlign: "center", marginBottom: "1em", marginTop: '0em' }}></Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>

                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                <spam class="green-text">Ab 18 Jahren </spam> kann man fahren.
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                <spam class="green-text">From the age of 18 </spam> can somebody drive.
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>


            <ScrollToTopButton />



        </div>
    )
}

export default Z200_Prepositions_Time