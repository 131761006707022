import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


function Z0032Infinitive_without_zu() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Infinitive without 'zu' " />
                <Header2 props="Infinitiv ohne 'zu' " />
            </Box>




            <Box className="global-box-not-header" >
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    There are some verbs that take infinitive <span className="green-text"> without "zu"</span>. These verbs are:
                </Typography>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    1. Modalverbs :
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Tania <span className="green-text"> will</span> einen neuen Mann <span className="green-text"> finden</span></TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Tania wants, <span className="green-text"> to find</span> a new Man.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    2. Semimodalverbs : The verbs:<br /><br />

                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text"> hören</span> (to hear),<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  sehen</span> (to see),<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  lassen</span> (to leave), more about the meaning of the verb lassen here<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text"> helfen</span> (to help)<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  fühlen</span> (to feel)<br /><br />

                    called semimodal verbs and they take infinitive without "zu".
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Meine Eltern <span className="green-text"> lassen</span> mich nicht ins Konzert <span className="green-text"> gehen</span>.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>My parents don't, <span className="green-text"> let</span> me at the konzert <span className="green-text"> to go</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>






                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    3. The verbs:<br /><br />

                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  bleiben</span> (to stay),<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  fahren</span> (to drive),<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  gehen</span> (to go),<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text">  lehren</span> (to teach),<br />
                    <IconButton size='small' sx={{color:'green'}}><RadioButtonCheckedIcon/></IconButton><span className="green-text"> lernen</span> (to learn) <br />
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Tania <span className="green-text"> geht</span> gern <span className="green-text"> tanzen</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Tania likes <span className="green-text"> to go</span> dancing. </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Heute <span className="green-text"> gehe</span> ich <span className="green-text"> schwimmen</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Today i will go <span className="green-text"> to swimming</span>.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0032Infinitive_without_zu