import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Button, Card, CardHeader, Typography, IconButton } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import Mui6_DataGrid_Toolbar from './Mui6_DataGrid_Toolbar';

import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';

import HomeIcon from '@mui/icons-material/Home';

const columns = [
    
    { field: 'id', headerName: 'ID', width: 100 , 

        renderCell : (params)=>{            
            console.log("************** Params:", params);
            return (
                <Link to={`/datagrid2/${params.value}`}  style={{ color:'black', textUnderlineOffset:5 }} >
                    <Typography> {params.value} </Typography>
                </Link>
            )
        }    
    },

    {
        field: 'userId',
        headerName: 'userId',
        width: 100,
        editable: true,     
      },

    {
      field: 'todo',
      headerName: 'todo',
      width: 450,
      editable: true,     
    },

    {
        field: 'completed',
        headerName: 'completed',
        width: 100,
        editable: true,
        renderCell: (params)=>{
            return (
                params.value ? (<AssignmentTurnedInRoundedIcon sx={{ color:'green' }} />) : (<AssignmentLateRoundedIcon sx={{ color:'orange' }}/>)
            )
        }
    },    
        
  ];


function Mui6_DataGrid() {

    const [todos, setTodos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect( ()=>{
       
        async function fetchTodos() {
            setLoading(true);

            await fetch("https://dummyjson.com/todos")
                .then( (res) => res.json() )
                //.then( (data) => console.log(data) )   // Object
                .then( (data) => setTodos(data.todos) )
                .catch( (err) => console.log((err) ))
                .finally( ()=> setLoading(false));
        }

        fetchTodos();

    }, [] )


    console.log(todos);    // Array

    return (
        <>
            <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
            <Typography variant="h1" color="primary" sx={{textAlign:"center", marginBottom:"0em"}}>
                Data Grid with Toolbar
            </Typography>
            <Typography variant="h6" color="secondary" sx={{textAlign:"center", marginBottom:"0em"}}>
                by pressing the link we take the values of the row to another page.
            </Typography>


            <Card sx={{padding:5}}>

                <CardHeader title="Todo List"></CardHeader>

                <DataGrid   
                    checkboxSelection
                    // rowsPerPageOptions={[10, 20, 30]}
                    // pageSize={10}
                    loading={loading}  columns={columns} rows={todos} sx={{ height:500 }}  
                    components = {{ Toolbar: Mui6_DataGrid_Toolbar, BaseButton: Button }}
                    componentsProps = {{ baseButton:{
                                            variant:"outlined"
                    } }}
                    //hideFooter
                    
                />

            </Card>    

        </>
    )
}

export default Mui6_DataGrid