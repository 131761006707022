import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0163_SentenceConnection_Doppel() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Double conjuctions - zweiteilige Konnektoren " />

                <Header1 props="'entweder ... oder', 'nicht nur ... sondern auch ', 'sowohl ... als auch' , 'weder ... noch' ,  'zwar ... aber' " />
                <Header2 props="Doppelkonjunktionen" />
            </Box>



            <Box className="global-box-not-header" >

            <Header3_Middle props="1. entweder ... oder" />
            <Header2 props="either ... or" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                The entweder ... oder connects two sentences. <br/>
                The Entweder is always in the first position and the oder is always in position 0 because it is considered it is out of the sentence.
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1	</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>subject</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 0</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2</TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Entweder</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>habe</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ich</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>meinen Pass vergessen</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>oder</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ich</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>habe ihn verloren.</TableCell>
                        
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={7} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Either i have forgot my passport, or i have lost it.</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            </Box>



            <ScrollToTopButton />







            <Box className="global-box-not-header" >

            <Header3_Middle props="2. nicht nur ... sondern auch" />
            <Header2 props="not just ... but also" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Example : 1 <br/>
                When the sentence starts with the subject then the nicht nur is after the verb.
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1	</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 3</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 0</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Wir</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>lernen</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nicht nur	</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Formeln in Chemie,	</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sondern</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>machen</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>auch	</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>viele Experimente.</TableCell>
                        
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={9} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>We learn not just Formulas in Chemistry but we make also many experiments.</TableCell>
                    </TableRow>

                </Table>
            </TableContainer>






            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Example : 2 <br/>
                When the subject is after the verb then the nicht nur is after the verb.
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1	</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 3</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 0</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>In Chemie</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>lernen</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nicht nur</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Formeln,</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sondern</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>machen	</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>auch</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>viele Experimente.</TableCell>
                        
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={10} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>
                            In Chemistry we learn not just Formulas but we make also many experiments.
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>




            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Example : 3 <br/>
                The nicht nur ... sondern auch can also connect the term of a sentence.                
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>                 

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er ist</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nicht nur</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nett</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sondern auch</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>romantich.</TableCell>                        
                    </TableRow>

                    <TableRow>                        
                        <TableCell colspan={5} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>Either i have forgot my passport, or i have lost it.</TableCell>
                    </TableRow>
                
                </Table>
            </TableContainer>

            </Box>



      <ScrollToTopButton />





            <Box className="global-box-not-header" >


            <Header3_Middle props="3. sowohl ... als auch" />
            <Header2 props="both ... as well as" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Connects the term of a sentence.<br/><br/>

                Example 1:                
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>             

                    <TableRow> 
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Diese Handy ist sowohl preiswert als auch ganz modern.</TableCell>                        
                    </TableRow>

                    <TableRow>                                             
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>This mobile phone is both inexpensive, as well as very modern.</TableCell>
                    </TableRow>                  
                
                </Table>
            </TableContainer>




            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>               
                Example 2:                
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>             

                    <TableRow> 
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich habe sowohl Englisch als auch Deutsch gelernt.</TableCell>                        
                    </TableRow>

                    <TableRow>                        
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I have learned both English, as well as German.</TableCell>
                    </TableRow>                  
                
                </Table>
            </TableContainer>

            </Box>



      <ScrollToTopButton />




            <Box className="global-box-not-header" >

            <Header3_Middle props="4. weder ... noch" />
            <Header2 props="neither ... nor" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                The weder ... oder connects two sentences.<br/><br/>

                When the sentence starts with the subject then the weder is usually placed after the verb.<br/>
                Example 1:
            </Typography>


            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>weder</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>subject</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>antwortet</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>weder</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>auf meine SMS</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>noch</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ruft</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mich an</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={8} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>
                            He answers neither on my text message, nor he calls me.
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            When the subject is after the verb then the weder is placed after the subject.
            <br/><br/>
            Example 2:
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>subject</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>weder</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>noch</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb	</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Oft</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>antwortet	</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>weder</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>auf meine SMS</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>noch</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ruft</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mich an.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={9} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>
                            He answers neither on my text message, nor he calls me.
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            </Box>



      <ScrollToTopButton />





            <Box className="global-box-not-header" >

            <Header3_Middle props="5. zwar ... aber" />
            <Header2 props="it may be true .... but" />


            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                The zwar ... aber connects two sentences.<br/><br/><br/>


                When the subject is in the first position, then the zwar placed usually after the verb.<br/>
                Example 1:
            </Typography>


            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>zwar</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 0 aber	</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1 subject</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sieht</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zwar</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>super aus</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aber</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ist</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ganz blöd.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={8} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>
                            It may be true he looks awesome, but he is very stupid.
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            When the subject is after the verb then the zwar is placed after the subject.
            <br/><br/>
            Example 2:
            </Typography>

            <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>position: 2 verb</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                    </TableRow>

                    <TableRow>                     
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Heute</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sieht</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zwar</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>super aus</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aber</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>macht</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wieder nur Blödsinn.</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colspan={9} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>
                            Today it is true he looks awesome, but he makes again nonsenses.
                        </TableCell>
                    </TableRow>

                </Table>
            </TableContainer>

            </Box>


            <ScrollToTopButton />
            

        </div>
    )
}

export default Z0163_SentenceConnection_Doppel