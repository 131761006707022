import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0161_SentenceConnection_Relativ1() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Relative sentences - Relative pronouns with 'der,die,das' " />
                <Header2 props="Relativsätze - Relativpronomen mit 'der, die ,das' " />
            </Box>



            <Box className="global-box-not-header">
                <Header3_Middle props="1. Relative sentences" />
                <Header2 props="Relativsätze" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The <span className="green-text">Relative sentences</span> <span className="red-text"> Relativsätze</span> refer to a member of the main sentence and explain it. <br />
                    They imported with the <span className="green-text">Relative pronouns "der,die,das"</span> and they have the meaning of <span className="green-text">"who,which"</span> e.t.c.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Relative sentences - Relativsätze</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wie heißt der Fluss, <span className="green-text">der</span> durch Köln fließt?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>What is the name of the river <span className="green-text">which</span> flows through Cologne?</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    1. The Relative sentence is a <span className="green-text">Subordinate sentence</span> (Nebensatz). For that reason the conjugated verb goes at the end.<br />
                    2. The Relative sentence explains a <span className="green-text">noun of the main sentence</span>, so for that reason it goes after the defined noun.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Relative sentences - Relativsätze</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Fluss, <span className="green-text">der</span> durch Köln fließt, ist der Rhein.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The river <span className="green-text">which</span> flows through Cologne, is the Rhine.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />





            <Box className="global-box-not-header">
                <Header3_Middle props="2. Relative pronoun" />
                <Header2 props="Relativpronomen" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The relative pronouns are:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colspan={5} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Relative pronoun - Relativpronomen</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Singular</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Plural</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Masculine</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Feminine</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Neuter</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Accusative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den<span className="green-text">en</span></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Genitive:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>des<span className="green-text">sen</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>de<span className="green-text">ren</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>des<span className="green-text">sen</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der<span className="green-text">en</span></TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The relative pronoun is refered to the previous noun, so it must match <span className="green-text">gender</span> and <span className="green-text">number</span>.<br /><br /><br />

                    1. Example (with nominative):
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es gibt einen Fluss. <span className="green-text">Er</span> fließt durch Köln.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Es gibt einen Fluss, <span className="green-text">der</span> durch Köln fließt. </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    At the above example in the relative pronoun the gender of the noun Fluss is <span className="green-text">masculine</span> <span className="red-text"> (der Fluss)</span>, 
                        the number is <span className="green-text">singular</span> and it is a subject so it is in <span className="green-text">nominative</span> <span className="red-text"> "Er"</span>.
                    <br /><br /><br /><br /><br /><br />



                    2. Example (with accusative):
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es gibt einen Fluss. Jeder hat <span className="green-text">ihn</span> schon gesehen.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Es gibt einen Fluss, <span className="green-text">den</span> jeder schon gesehen hat.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    At the above example in the relative pronoun the gender of the noun Fluss is <span className="green-text">masculine</span> (der Fluss), the number is <span className="green-text">singular</span> 
                    and the object is in <span className="green-text">accusative</span> <span className="red-text"> "ihn"</span>.
                    <br /><br /><br /><br /><br /><br />



                    3. Example (with preposition):
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es gibt einen Fluss. <span className="green-text">An dem</span> Fluss bin ich geboren.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Es gibt einen Fluss, <span className="green-text">an dem</span> ich bin geboren.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    At the above example in the relative pronoun the gender of the noun Fluss is masculine (der Fluss), the number is singular and there is a preposition with local meaning "An dem".
                    <br /><br /><br /><br /><br /><br />



                    Also in that case, when we use relative pronoun, as we can see we write an dem and not am. It is wrong to write:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es gibt einen Fluss. <span className="green-text">An dem</span> Fluss bin ich geboren.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Es gibt einen Fluss, <span className="green-text"> am (!!! wrong !!!)</span> ich bin geboren.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    If there is preposition, the relative pronoun goes after the preposition.
                    <br /><br /><br /><br /><br /><br />



                    4. Example (with genitive):
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es gibt einen Fluss. <span className="green-text"> Sein</span> Name ist Rhein.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Es gibt einen Fluss, <span className="green-text"> dessen</span> Name ist Rhein</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    At the above example in the relative pronoun the gender of the noun Fluss is masculine (der Fluss), the number is singular and we need to use genitive.
                </Typography>

            </Box>

            <ScrollToTopButton />










            <Box className="global-box-not-header">
                <Header3_Middle props="3. Relative pronoun (Some more examples)" />
                <Header2 props="Relativpronomen" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Let's see some more examples, how we guess the relative pronoun.
                    <br /><br /><br />


                    1. Example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Junge, 
                                <span className="green-text">den</span> Tina jeden Tag auf dem Weg zur Schule trifft, ist sehr attraktiv.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The boy, 
                                <span className="green-text">which</span> Tina meets every day on the way to school, is very attractive.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In that example the relative pronoun is refered to the noun "<span className="green-text">Junge</span>". That noun determines the gender and the number, 
                    so in that case it is <span className="green-text">masculine</span> and it is in singular number. <br />
                    So to find the case of the relative pronoun we isolate the relative sentence and we examine it:<br /><br />

                    _____ Tina jeden Tag auf dem Weg zur Schule trifft<br /><br />

                    And the question is :<br />
                    <span className="green-text">Whom meets Tina?</span><br />
                    or<br />
                    <span className="red-text"> Wen trifft Tina?</span><br /><br />

                    so we use <span className="green-text">accusative</span>, which means we use the relativ pronoun "<span className="green-text">den</span>".
                    <br /><br /><br /><br />




                    2. Example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Meine Freundinnen, ______ mich jeden Morgen abholen, sind immer pünktlich.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>My girlfriend, <span className="green-text">which</span> pick me up every morning sind always on time.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In that example the relative pronoun is refered to the noun <span className="green-text">"Meine Freundinnen"</span>. 
                    That noun determines the gender and the number, so in that case it is in <span className="green-text">plural</span> number. <br />
                    So to find the case of the relative pronoun we isolate the relative sentence and we examine it:<br /><br />

                    And the question is :<br />
                    <span className="green-text">Who picks up me?</span><br />
                    or<br />
                    <span className="green-text">Wer hole mich ab?</span><br /><br />

                    so we use <span className="green-text">nominative</span>, which means we use the relativ pronoun "<span className="green-text">die</span>".
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Meine Freundinnen, <span className="green-text">die</span> mich jeden Morgen abholen, sind immer pünktlich.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>My girlfriend, <span className="green-text">which</span> pick me up every morning sind always on time.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    So the noun of the main sentence, of which refered the relative sentence, defines the gender and the number of the relative pronoun, and the structure of the relative sentence defines the case of the relative pronoun.
                </Typography>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    3. Example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Junge, <span className="green-text">dessen</span> Handynummer Tina gerade bekommen, ist sehr sportlich.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The boy, <span className="green-text">whose</span> cellphone number Tina just got, is very athletic.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In that example the relative pronoun is refered to the noun "Junge". That noun determines the gender and the number, so in that case it is masculine and it is in singular number. <br />
                    So to find the case of the relative pronoun we isolate the relative sentence and we examine it:
                </Typography>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    4. Example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Meine Freundinnen, <span className="green-text">mit denen</span> ich jeden Tag zur Schule gehe, sind immer pünktlich.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>My girlfriends, <span className="green-text">with whom</span> i go to scholl every day, are always on time.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    5. Example:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Junge, <span className="green-text">für den</span> sich Tina interessiert, ist neu in der Schule.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The boy, <span className="green-text">which</span> Tina is interested, is new to school.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />


        </div>
    )
}

export default Z0161_SentenceConnection_Relativ1