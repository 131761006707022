import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';



function Z0056_Nouns_with_Prepositions() {

    const verbs = [
        ["eine/keine Ahnung von + D", "some/no idea of", "κάποια/καμία ιδέα για"],
        ["die Angst vor + D", "the fear of", "ο φόβος για"],
        ["die Antwort auf + A", "the answer to", "η απάντηση σε"],
        ["der Appetit auf + A", "the appetite for", "η όρεξη για"],
        ["die Auskunft über + A", "the information about", "πληροφορίες για"],
        ["der Bedarf an + D", "the need for", "η ανάγκη για"],
        ["der Bericht über + A", "the report on", "η αναφορά για"],
        ["die Bitte um + A", "the request for", "το αίτημα για"],
        ["die Demonstration für + A", "the demonstration for", "η διαδήλωση για"],
        ["die Demonstration gegen + A", "the demonstration against", "η διαδήλωση ενάντια σε"],
        ["die Diskussion über + A", "the discussion about", "η συζήτηση για"],
        ["der Eindruck auf + A", "the impression on", "η εντύπωση σε"],
        ["der Einfluss auf + A", "the influence on", "η επιρροή σε"],
        ["die Einladung zu + D", "the invitation to", "η πρόσκληση σε"],
        ["der Erfolg bei + D", "the success in", "η επιτυχία σε"],
        ["die Erinnerung an + A", "the memory of", "η ανάμνηση για"],
        ["die Frage nach + D", "the question about", "η ερώτηση για"],
        ["das Geschpräch über + A", "the conversation about", "η συζήτηση για"],
        ["die Hilfe bei + D", "the help with", "η βοήθεια σε"],
        ["die Hilfe für + A", "the help for", "η βοήθεια για"],
        ["die Hoffnung auf + A", "the hope for", "η ελπίδα για"],
        ["die Information über + A", "the information about", "πληροφορίες για"],
        ["das Interesse an + D", "the interest in", "το ενδιαφέρον για"],
        ["der Kontakt zu + D", "the contact with", "η επαφή με"],
        ["die Liebe zu + D", "the love for", "η αγάπη για"],
        ["die Lust auf + A", "the desire for", "η διάθεση για"],
        ["die Lust zu + D", "the desire to", "η διάθεση να"],
        ["die Meinung über + A", "the opinion about", "η γνώμη για"],
        ["das Problem mit + A", "the problem with", "το πρόβλημα με"],
        ["das Recht auf + A", "the right to", "το δικαίωμα σε"],
        ["der Streik für + A", "the strike for", "η απεργία για"],
        ["der Streik gegen + A", "the strike against", "η απεργία ενάντια σε"],
        ["der Streit mit + D", "the argument with", "ο καβγάς με"],
        ["das Verständnis für + A", "the understanding for", "η κατανόηση για"],
        ["das Vertrauen zu + D", "the trust in", "η εμπιστοσύνη σε"],
        ["die Voraussetzung für + A", "the prerequisite for", "η προϋπόθεση για"],
        ["die Vorbereitung auf + A", "the preparation for", "η προετοιμασία για"],
        ["der Wunsch nach + D", "the wish for", "η επιθυμία για"],
        ["der Zweifel an + D", "the doubt about", "η αμφιβολία για"],
        ["die Zeit für + A", "the time for", "ο χρόνος για"],
        ["in Anlehnung an + A", "in reference to", "αναφορικά με"],
        ["in der Nähe von + D", "near", "κοντά σε"],
        ["das Verhältnis zu + D", "the relationship with", "η σχέση με"],
        ["der Umgang mit + D", "the handling of", "η διαχείριση του/της"],
        ["der Anlass zu + D", "the occasion for", "η αφορμή για"],
        ["die Annäherung an + A", "the approach to", "η προσέγγιση σε"],
        ["sich beschränken auf + A", "to limit oneself to", "να περιορίζεται σε"],
        ["Hunderte von + D", "hundreds of", "εκατοντάδες"]
    ];

    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Nouns with prepositions" />
                <Header2 props="Nomen mit Präpositionen" />
            </Box>



            <Box className="global-box-not-header" >
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In German there are some nouns that have syntax with <span className="green-text"> Prepositional Object</span>, which means that the noun connected with the object with a preposition.<br /><br /><br />

                    Examples:
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Meine Mutter hat keine <span className="green-text"> Ahnung von</span> Computern.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>My mother has no <span className="green-text"> idea of</span> computers </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich möchte einige <span className="green-text"> Informationen über</span> den Computern.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I want some <span className="green-text"> informations about</span> the computers. </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="List with nouns with prepositions" />
                <Header2 props="Nomen mit Präpositionen" />

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        {verbs.map((verb, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </TableContainer>


            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0056_Nouns_with_Prepositions