import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


function Z0052_Nouns_Building_Nouns() {
  return (
    <div>
      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
      <Box className="global-box-header">
        <Header1 props="Conjugation of the nouns" />
        <Header2 props="Deklination der Nomen" />
      </Box>




      <Box className="global-box-not-header" >
        <Header3_Middle props="1. Diminutive" />
        <Header2 props="Diminutiv" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
          The most diminutives are formed with the ending -chen and are always neuter.
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Fenster</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Fensterchen</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>little window</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Glas</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Gläschen</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>little Glas</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Stuhl</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Stühlchen</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>little chair</TableCell>
            </TableRow>

          </Table>
        </TableContainer>
      </Box>

      <ScrollToTopButton />




      <Box className="global-box-not-header" >
        <Header3_Middle props="2. Compound nouns" />
        <Header2 props="Zusammengesetzte Nomen" />

        <Box sx={{ marginTop: '5em' }}>
          <Header2 props="2a. noun + noun" />
        </Box>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
          German is well-known for its ability to form compound nouns composed of two or more words. This can be of great help to the student of German,
          since knowing the meaning of the individual components often makes it possible to understand the meaning of a compound noun that has not been encountered previously.<br /><br />

          The last component of the compound noun determines the gender of the noun.
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Hotel, das Zimmer</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Hotelzimmer</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the hotel room </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Winter, das Mantel</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Wintermantel</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the winter coat </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Zahn, die Bürste</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Zahnbürste</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the toothbrush </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Auto, die Bahn</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Autobahn</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the motorway </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} >
          Some compound nouns are formed from two singular nouns connected by -s or -es.
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Sport, der Mann</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Sportsmann</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the sportsman </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die Universität, der Professor</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Universitätsprofessor</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the university profesor </TableCell>
            </TableRow>

          </Table>
        </TableContainer>




        <Box sx={{ marginTop: '5em' }}>
          <Header2 props="2b. adjective + noun" />
        </Box>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>rot, der Kohl</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Rotkohl</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the red cabbage </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>blau, der Stifft</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Blaustift</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the blue pencil </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>hoch, das Haus</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>das Hochhaus</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the high-rise </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> </TableCell>
            </TableRow>

          </Table>
        </TableContainer>







        <Box sx={{ marginTop: '5em' }}>
          <Header2 props="2c. verb + noun" />
        </Box>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          In that case we use only the stem of the verb
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>schreib-en, der Tisch</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der schreibtisch</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the desk</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>tanz-en, die Musik</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die tanzmusik</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the dance musik</TableCell>
            </TableRow>

          </Table>
        </TableContainer>







        <Box sx={{ marginTop: '5em' }}>
          <Header2 props="2d. preposition + noun" />
        </Box>

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          In that case we use only the stem of the verb.
        </Typography>




        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mit, der Schüler</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Mitschüler</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the classmate </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>vor, der Ort</TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>der Vorort</TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the Suburb </TableCell>
            </TableRow>

          </Table>
        </TableContainer>
      </Box>

      <ScrollToTopButton />


    </div>
  )
}

export default Z0052_Nouns_Building_Nouns