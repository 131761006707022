import React from 'react';
import {Typography} from "@mui/material";

function Header2({props}) {
  return (
    <div>
        <Typography variant="h4" color="secondary" sx={{textAlign:"center", marginBottom:"1em"}}>{props}</Typography>        
    </div>
  )
}

export default Header2