import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0008_Verbs_in_main_sentence() {

    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="The position of verb in the main sentence" />
                <Header2 props="Verstellung im Hauptsatz" />
            </Box>



            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The verb in the main sentence is:<br /><br /><br />


                    1. In an <span className="green-text">affirmative sentence (Aussagesatz)</span>, is always in the <span className="green-text">second</span> position.
                </Typography>

                <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow >
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Position 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Position 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>next Position</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich</TableCell>
                        <TableCell sx={{color:'green', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>komme</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aus Deutchland</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Mein Freud</TableCell>
                        <TableCell sx={{color:'green', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>heißt</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Stefan.</TableCell>
                    </TableRow>

                </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
                    2. In an <span className="green-text">interrogative sentence (Fragesatz)</span> with <span className="green-text">question word (Wortfrage)</span>, is always in the second position.
                </Typography>

                <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
                <Table>

                    <TableRow >
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Position 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Position 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>next Position</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Wie</TableCell>
                        <TableCell sx={{color:'green', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>heißt</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du?</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Woher</TableCell>
                        <TableCell sx={{color:'green', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>kommst</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du?</TableCell>
                    </TableRow>

                </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 24, sm: 24, md: 16, lg: 16, xl: 16 }}>
                    3. To the questions, where the anwser is <span className="green-text">"Yes"</span> or <span className="green-text">"No", (Fragesatz)</span> the verb is always in the first position.
                </Typography>

                <TableContainer>
                <Table>

                    <TableRow >
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Position 1</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Position 2</TableCell>
                        <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>next Position</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'green', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Kommst</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aus Deutscland?</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{color:'green', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Bist</TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du </TableCell>
                        <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>neu hier?</TableCell>
                    </TableRow>

                </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />

        </div>
    )

}

export default Z0008_Verbs_in_main_sentence
