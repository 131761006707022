import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableContainer, TableRow, TableCell, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';

function Z0036_Verbs_ListWithIrregularVerbs() {

    const verbs = [
      
        ["abfahren", "fährt ab", "fuhr ab", "ist abgefahren", "to depart", "αναχωρώ"],
        ["abnehmen", "nimmt ab", "nahm ab", "hat abgenommen", "to get thin", "αδυνατίζω"],
        ["abschließen", "schließt ab", "schloss ab", "hat abgeschlossen", "to lock", "κλειδώνω"],
        ["abschreiben", "schreibt ab", "schrieb ab", "hat abgeschrieben", "to copy", "αντιγράφω"],
        ["anbieten", "bietet an", "bot an", "hat angeboten", "to offer", "προσφέρω"],
        ["anfangen", "fängt an", "fing an", "hat angefangen", "to begin, to start", "ξεκινώ"],
        ["anhaben", "hat an", "hatte an", "hat angehabt", "to be wearing", "φορώ"],
        ["anhalten", "hält an", "hielt an", "hat angehalten", "to stop", "σταματώ"],
        ["ankommen", "kommt an", "kam an", "ist angekommen", "to arrive", "φτάνω"],
        ["anrufen", "ruft an", "rief an", "hat angerufen", "to call", "τηλεφωνώ"],
        ["ansehen", "sieht an", "sah an", "hat angesehen", "to look at", "κοιτάζω"],
        ["anziehen", "zieht an", "zog an", "hat angezogen", "to dress", "ντύνομαι"],
        ["aufschlagen", "schlägt auf", "schlug auf", "hat aufgeschlagen", "to open (book, eyes)", "ανοίγω (βιβλίο, μάτια)"],
        ["aufschreiben", "schreibt auf", "schrieb auf", "hat aufgeschrieben", "to write", "γράφω"],
        ["aufstehen", "steht auf", "stand auf", "ist aufgestanden", "to get up", "σηκώνομαι"],
        ["ausgeben für + A", "gibt aus", "gab aus", "hat ausgegeben", "to spend", "ξοδεύω"],
        ["ausschneiden", "schneidet aus", "schnitt aus", "hat ausgeschnitten", "to cut", "κόβω"],
        ["aussehen", "sieht aus", "sah aus", "hat ausgesehen", "to look, to seem", "φαίνομαι"],
        ["aussprechen", "spricht aus", "sprach aus", "hat ausgesprochen", "to express", "εκφράζω"],
        ["aussteigen", "steigt aus", "stieg aus", "ist ausgestiegen", "to get out (of a car)", "αποβιβάζομαι"],
        ["ausziehen", "zieht aus", "zog aus", "ist ausgezogen", "to undress, to move out", "βγάζω, μετακομίζω"],
        ["backen", "bäckt", "backte", "hat gebacken", "to bake", "ψήνω"],
        ["befehlen", "befiehlt", "befahl", "hat befohlen", "to order", "διατάζω"],
        ["beginnen", "beginnt", "begann", "hat begonnen", "to start", "ξεκινώ"],
        ["behalten", "behält", "behielt", "hat behalten", "to keep", "κρατώ"],
        ["beißen", "beißt", "biss", "hat gebissen", "to bite", "δαγκώνω"],
        ["beitreten", "tritt bei", "trat bei", "ist beigetreten", "to join", "εντάσσομαι"],
        ["bekommen", "bekommt", "bekam", "hat bekommen", "to get", "παίρνω"],
        ["beschreiben", "beschreibt", "beschrieb", "hat beschrieben", "to describe", "περιγράφω"],
        ["besprechen", "bespricht", "besprach", "hat besprochen", "to discuss", "συζητώ"],
        ["bestehen", "besteht", "bestand", "hat bestanden", "to pass the exams", "περνώ τις εξετάσεις"],
        ["biegen", "biegt", "bog", "hat gebogen", "to bend", "λυγίζω"],
        ["bieten", "bietet", "bot", "hat geboten", "to offer", "προσφέρω"],
        ["binden", "bindet", "band", "hat gebunden", "to bind", "δένω"],
        ["bitten", "bittet", "bat", "hat gebeten", "to beg", "παρακαλώ"],
        ["bleiben", "bleibt", "blieb", "ist geblieben", "to stay", "μένω"],
        ["braten", "brät", "briet", "hat gebraten", "to grill", "ψήνω"],
        ["brechen", "bricht", "brach", "hat/ist gebrochen", "to break", "σπάζω"],
        ["brennen", "brennt", "brannte", "hat gebrannt", "to burn", "καίω"],
        ["bringen", "bringt", "brachte", "hat gebracht", "to bring", "φέρνω"],
        ["denken", "denkt", "dachte", "hat gedacht", "to think", "σκέφτομαι"],
        ["dürfen", "darf", "durfte", "hat gedurft", "to be allowed", "επιτρέπεται"],
        ["einfallen", "fällt ein", "fiel ein", "ist eingefallen", "to collapse", "καταρρέω"],
        ["einladen", "lädt ein", "lud ein", "hat eingeladen", "to invite", "προσκαλώ"],
        ["einschlafen", "schläft ein", "schlief ein", "ist eingeschlafen", "to fall asleep", "κοιμάμαι"],
        ["einsteigen", "steigt ein", "stieg ein", "ist eingestiegen", "to get on", "επιβιβάζομαι"],
        ["einziehen", "zieht ein", "zog ein", "ist eingezogen", "to get in", "μπαίνω"],
        ["empfehlen", "empfiehlt", "empfahl", "hat empfohlen", "to recommend", "συνιστώ"],
        ["entscheiden", "entscheidet", "entschied", "hat entschieden", "to decide", "αποφασίζω"],
        ["entstehen", "entsteht", "entstand", "ist entstanden", "to be created", "δημιουργούμαι"],
        ["erfinden", "erfindet", "erfand", "hat erfunden", "to invent", "εφευρίσκω"],
        ["erhalten", "erhält", "erhielt", "hat erhalten", "to receive", "λαμβάνω"],
        ["erkennen", "erkennt", "erkannte", "hat erkannt", "to recognize", "αναγνωρίζω"],
        ["essen", "isst", "aß", "hat gegessen", "to eat", "τρώω"],
        ["fahren", "fährt", "fuhr", "ist gefahren", "to drive", "οδηγώ"],
        ["fallen", "fällt", "fiel", "ist gefallen", "to fall", "πέφτω"],
        ["fangen", "fängt", "fing", "hat gefangen", "to catch", "πιάνω"],
        ["fernsehen", "sieht fern", "sah fern", "hat ferngesehen", "to watch television", "βλέπω τηλεόραση"],
        ["finden", "findet", "fand", "hat gefunden", "to find", "βρίσκω"],
        ["fliegen", "fliegt", "flog", "ist geflogen", "to fly", "πετάω"],
        ["fliehen", "flieht", "floh", "ist geflohen", "to escape", "δραπετεύω"],
        ["fließen", "fließt", "floss", "ist geflossen", "to flow", "ρέω"],
        ["fressen", "frisst", "fraß", "hat gefressen", "to eat (for animals)", "τρώω (για ζώα)"],
        ["frieren", "friert", "fror", "hat gefroren", "to freeze", "παγώνω"],
        ["geben", "gibt", "gab", "hat gegeben", "to give", "δίνω"],
        ["gefallen", "gefällt", "gefiel", "hat gefallen", "to please", "ευχαριστώ"],
        ["gehen", "geht", "ging", "ist gegangen", "to go", "πηγαίνω"],
        ["gelten", "gilt", "galt", "hat gegolten", "to be valid", "ισχύω"],
        ["genießen", "genießt", "genoss", "hat genossen", "to enjoy", "απολαμβάνω"],
        ["geschehen", "geschieht", "geschah", "ist geschehen", "to happen", "συμβαίνει"],
        ["greifen", "greift", "griff", "hat gegriffen", "to grab", "αρπάζω"],
        ["gewinnen", "gewinnt", "gewann", "hat gewonnen", "to win", "κερδίζω"],
        ["gießen", "gießt", "goss", "hat gegossen", "to water", "ποτίζω"],
        ["haben", "hat", "hatte", "hat gehabt", "to have", "έχω"],
        ["halten", "hält", "hielt", "hat gehalten", "to hold", "κρατώ"],
        ["hängen", "hängt", "hing", "hat gehangen", "to hang", "κρεμώ"],
        ["heben", "hebt", "hob", "hat gehoben", "to lift", "σηκώνω"],
        ["heißen", "heißt", "hieß", "hat geheißen", "to be called", "ονομάζομαι"],
        ["helfen", "hilft", "half", "hat geholfen", "to help", "βοηθώ"],
        ["herauskommen", "kommt heraus", "kam heraus", "ist herausgekommen", "to come out", "βγαίνω"],
        ["hinfallen", "fällt hin", "fiel hin", "ist hingefallen", "to fall", "πέφτω"],
        ["kaputtgehen", "geht kaputt", "ging kaputt", "ist kaputtgegangen", "to break down", "χαλάω"],
        ["kennen", "kennt", "kannte", "hat gekannt", "to know", "γνωρίζω"],
        ["klingen", "klingt", "klang", "hat geklungen", "to clink", "ηχώ"],
        ["kommen", "kommt", "kam", "ist gekommen", "to come", "έρχομαι"],
        ["können", "kann", "konnte", "hat gekonnt", "to be able", "μπορώ"],
        ["kriechen", "kriecht", "kroch", "ist gekrochen", "to crawl", "σέρνομαι"],
        ["lassen", "lässt", "ließ", "hat gelassen", "to leave", "αφήνω"],
        ["laufen", "läuft", "lief", "ist gelaufen", "to run", "τρέχω"],
        ["leiden", "leidet", "litt", "hat gelitten", "to suffer", "υποφέρω"],
        ["leihen", "leiht", "lieh", "hat geliehen", "to lend", "δανείζω"],
        ["lesen", "liest", "las", "hat gelesen", "to read", "διαβάζω"],
        ["liegen", "liegt", "lag", "hat gelegen", "to lie", "ξαπλώνω"],
        ["losfahren", "fährt los", "fuhr los", "ist losgefahren", "to drive towards", "αναχωρώ"],
        ["lügen", "lügt", "log", "hat gelogen", "to lie", "λέω ψέματα"],
        ["messen", "misst", "maß", "hat gemessen", "to measure", "μετρώ"],
        ["mitbringen", "bringt mit", "brachte mit", "hat mitgebracht", "to bring", "φέρνω"],
        ["mitfahren", "fährt mit", "fuhr mit", "ist mitgefahren", "to drive with", "πηγαίνω με"],
        ["mitgehen", "geht mit", "ging mit", "ist mitgegangen", "to go with", "πηγαίνω με"],
        ["mitkommen", "kommt mit", "kam mit", "ist mitgekommen", "to come with", "έρχομαι με"],
        ["mitnehmen", "nimmt mit", "nahm mit", "hat mitgenommen", "to take with", "παίρνω μαζί"],
        ["mögen", "mag", "mochte", "hat gemocht", "to want to do", "θέλω"],
        ["müssen", "muss", "musste", "hat gemusst", "to have to do", "πρέπει"],
        ["nachdenken", "denkt nach", "dachte nach", "hat nachgedacht", "to think", "σκέφτομαι"],
        ["nachschlagen", "schlägt nach", "schlug nach", "hat nachgeschlagen", "to look", "κοιτάζω"],
        ["nehmen", "nimmt", "nahm", "hat genommen", "to take", "παίρνω"],
        ["nennen", "nennt", "nannte", "hat genannt", "to name", "ονομάζω"],
        ["raten", "rät", "riet", "hat geraten", "to advise", "συμβουλεύω"],
        ["rausgehen", "geht raus", "ging raus", "ist rausgegangen", "to go out", "βγαίνω"],
        ["reißen", "reißt", "riss", "hat/ist gerissen", "to tear", "σκίζω"],
        ["reiten", "reitet", "ritt", "ist geritten", "to ride", "ιππεύω"],
        ["rennen", "rennt", "rannte", "ist gerannt", "to run", "τρέχω"],
        ["riechen", "riecht", "roch", "hat gerochen", "to smell", "μυρίζω"],
        ["rufen", "ruft", "rief", "hat gerufen", "to shout", "φωνάζω"],
        ["scheinen", "scheint", "schien", "hat geschienen", "to shine", "λάμπω"],
        ["schießen", "schießt", "schoss", "hat geschossen", "to shoot", "πυροβολώ"],
        ["schlafen", "schläft", "schlief", "hat geschlafen", "to sleep", "κοιμάμαι"],
        ["schlagen", "schlägt", "schlug", "hat geschlagen", "to hit", "χτυπώ"],
        ["schließen", "schließt", "schloss", "hat geschlossen", "to close", "κλείνω"],
        ["schneiden", "schneidet", "schnitt", "hat geschnitten", "to cut", "κόβω"],
        ["schreiben", "schreibt", "schrieb", "hat geschrieben", "to write", "γράφω"],
        ["schreien", "schreit", "schrie", "hat geschrien", "to shout", "φωνάζω"],
        ["schwimmen", "schwimmt", "schwamm", "ist/hat geschwommen", "to swim", "κολυμπώ"],
        ["sehen", "sieht", "sah", "hat gesehen", "to see", "βλέπω"],
        ["sein", "ist", "war", "ist gewesen", "to be", "είμαι"],
        ["singen", "singt", "sang", "hat gesungen", "to sing", "τραγουδώ"],
        ["sitzen", "sitzt", "saß", "hat gesessen", "to sit", "κάθομαι"],
        ["sollen", "soll", "sollte", "hat gesollt", "to do", "πρέπει"],
        ["sprechen", "spricht", "sprach", "hat gesprochen", "to speak", "μιλάω"],
        ["springen", "springt", "sprang", "ist gesprungen", "to jump", "πηδώ"],
        ["stehen", "steht", "stand", "ist/hat gestanden", "to stand", "στέκομαι"],
        ["steigen", "steigt", "stieg", "ist gestiegen", "to rise, to lift", "ανεβαίνω"],
        ["sterben", "stirbt", "starb", "ist gestorben", "to die", "πεθαίνω"],
        ["stinken", "stinkt", "stank", "hat gestunken", "to stink", "βρωμάω"],
        ["streiten", "streitet", "stritt", "hat gestritten", "to argue", "μαλώνω"],
        ["tragen", "trägt", "trug", "hat getragen", "to carry", "κουβαλώ"],
        ["treffen", "trifft", "traf", "hat getroffen", "to meet", "συναντώ"],
        ["treiben", "treibt", "trieb", "hat getrieben", "to rush", "οδηγώ"],
        ["treten", "tritt", "trat", "hat/ist getreten", "to tread", "πατώ"],
        ["trinken", "trinkt", "trank", "hat getrunken", "to drink", "πίνω"],
        ["tun", "tut", "tat", "hat getan", "to do", "κάνω"],
        ["umziehen", "zieht um", "zog um", "ist umgezogen", "to move", "μετακομίζω"],
        ["unterhalten", "unterhält", "unterhielt", "hat unterhalten", "to talk", "κουβεντιάζω"],
        ["unterscheiden", "unterscheidet", "unterschied", "hat unterschieden", "to distinguish", "διακρίνω"],
        ["unterschreiben", "unterschreibt", "unterschrieb", "hat unterschrieben", "to sign", "υπογράφω"],
        ["unterstreichen", "unterstreicht", "unterstrich", "hat unterstrichen", "to underline", "υπογραμμίζω"],
        ["verbieten", "verbietet", "verbot", "hat verboten", "to forbid", "απαγορεύω"],
        ["verbringen", "verbringt", "verbrachte", "hat verbracht", "to spend", "περνώ"],
        ["vergessen", "vergisst", "vergaß", "hat vergessen", "to forget", "ξεχνώ"],
        ["vergleichen", "vergleicht", "verglich", "hat verglichen", "to compare", "συγκρίνω"],
        ["verlassen", "verlässt", "verließ", "hat verlassen", "to abandon", "εγκαταλείπω"],
        ["verlieren", "verliert", "verlor", "hat verloren", "to lose", "χάνω"],
        ["verschwinden", "verschwindet", "verschwand", "ist verschwunden", "to disappear", "εξαφανίζομαι"],
        ["versprechen", "verspricht", "versprach", "hat versprochen", "to promise", "υπόσχομαι"],
        ["verstehen", "versteht", "verstand", "hat verstanden", "to understand", "καταλαβαίνω"],
        ["vorbeikommen", "kommt vorbei", "kam vorbei", "ist vorbeigekommen", "to pass", "περνώ"],
        ["vorlesen", "liest vor", "las vor", "hat vorgelesen", "to read aloud", "διαβάζω δυνατά"],
        ["vorschlagen", "schlägt vor", "schlug vor", "hat vorgeschlagen", "to propose", "προτείνω"],
        ["wachsen", "wächst", "wuchs", "ist gewachsen", "to grow", "μεγαλώνω"],
        ["waschen", "wäscht", "wusch", "hat gewaschen", "to wash", "πλένω"],
        ["wegfahren", "fährt weg", "fuhr weg", "ist weggefahren", "to leave", "αναχωρώ"],
        ["weggehen", "geht weg", "ging weg", "ist weggegangen", "to go away", "φεύγω"],
        ["wegnehmen", "nimmt weg", "nahm weg", "hat weggenommen", "to take away", "παίρνω μακριά"],
        ["wegwerfen", "wirft weg", "warf weg", "hat weggeworfen", "to throw away", "πετάω"],
        ["weitergehen", "geht weiter", "ging weiter", "ist weitergegangen", "to go on", "συνεχίζω"],
        ["werden", "wird", "wurde", "ist geworden", "to become", "γίνομαι"],
        ["werfen", "wirft", "warf", "hat geworfen", "to throw", "ρίχνω"],
        ["wiederkommen", "kommt wieder", "kam wieder", "ist wiedergekommen", "to come back", "επιστρέφω"],
        ["wiegen", "wiegt", "wog", "hat gewogen", "to weigh", "ζυγίζω"],
        ["wissen", "weiß", "wusste", "hat gewusst", "to know", "ξέρω"],
        ["wollen", "will", "wollte", "hat gewollt", "to want to do", "θέλω"],
        ["zerbrechen", "zerbricht", "zerbrach", "hat/ist zerbrochen", "to break down", "σπάζω"],
        ["zerreißen", "zerreißt", "zerriss", "hat zerrissen", "to tear sth to pieces", "σκίζω"],
        ["ziehen", "zieht", "zog", "hat/ist gezogen", "to pull", "τραβώ"],
        ["zurückgeben", "gibt zurück", "gab zurück", "hat zurückgegeben", "to give back", "επιστρέφω"],
        ["zurückkommen", "kommt zurück", "kam zurück", "ist zurückgekommen", "to return", "επιστρέφω"],
        ["zusammenstoßen", "stößt zusammen", "stieß zusammen", "ist zusammengestoßen", "to collide", "συγκρούομαι"],
        ["zuschlagen", "schlägt zu", "schlug zu", "hat zugeschlagen", "to bang", "χτυπώ δυνατά"],
        ["zusehen", "sieht zu", "sah zu", "hat zugesehen", "to watch", "παρακολουθώ"],
        ["zwingen", "zwingt", "zwang", "hat gezwungen", "to force", "αναγκάζω"]
    ];

  return (
      <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="List of irregular verbs" />
                <Header2 props="Liste mit Starke Verben" />
            </Box>



            <Box className="global-box-not-header">

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>
                        <TableRow>
                            {/* <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0'}}>No.</TableCell> */}
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> <br/> Infinitive </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> <br/> 3rd type</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> <br/> Präteritum</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> <br/> Perfekt</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        {verbs.map((verb, index) => (
                            <TableRow key={index}>
                                {/* <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell> */}
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[1]}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[2]}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[3]}</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[4]}</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[5]}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />
        </div>
  )
}

export default Z0036_Verbs_ListWithIrregularVerbs