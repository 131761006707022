import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


function Z0034_brauchen_nur_zu_infinitive() {
    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="brauchen nur... zu + infinitiv" />
                <Header2 props="" />
            </Box>


            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The expression <span className="green-text"> 'brauchen nur... zu + infinitive'</span> has the same meaning with the expression <span className="green-text"> 'müssen nur'</span>.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                    Wenn du Mitglied in dieser Organisation werden möchtest, <span className="green-text"> braucht</span> du <span className="green-text"> nur</span> eine e-mail <span className="green-text"> zu schinken</span>.
                                </TableCell>
                            </TableRow>
                        </TableBody>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                    Wenn du Mitglied in dieser Organisation werden möchtest, <span className="green-text"> musst</span> du <span className="green-text"> nur</span> eine e-mail schinken
                                </TableCell>
                            </TableRow>
                        </TableBody>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                                    If you want to become member in that organization, you need only to send an email.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The modal verb <span className="green-text"> "müssen" never</span> takes <span className="green-text"> "zu"</span>, and <span className="green-text"> "brauchen"</span> is always with <span className="green-text"> "zu + infinitive"</span>.
                </Typography>

            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0034_brauchen_nur_zu_infinitive