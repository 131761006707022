import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z0042_Verbs_withDativeAndAccusative() {

    const verbs = [
        ["jdm bringen", "to bring someone", "φέρνω κάποιον"],
        ["jdm erlauben", "to allow someone", "επιτρέπω σε κάποιον"],
        ["jdm erzählen", "to tell someone", "διηγούμαι σε κάποιον"],
        ["jdm geben", "to give someone", "δίνω σε κάποιον"],
        ["jdm kaufen", "to buy someone", "αγοράζω σε κάποιον"],
        ["jdm leihen", "to lend someone", "δανείζω σε κάποιον"],
        ["jdm sagen", "to say to someone", "λέω σε κάποιον"],
        ["jdm schenken", "to give a gift to someone", "χαρίζω σε κάποιον"],
        ["jdm schicken", "to send someone", "στέλνω σε κάποιον"],
        ["jdm schreiben", "to write to someone", "γράφω σε κάποιον"],
        ["jdm verbieten", "to forbid someone", "απαγορεύω σε κάποιον"],
        ["jdm versprechen", "to promise someone", "υπόσχομαι σε κάποιον"],
        ["jdm wegnehmen", "to take away from someone", "αφαιρώ από κάποιον"],
        ["jdm zeigen", "to show someone", "δείχνω σε κάποιον"],
        ["jdm empfehlen", "to recommend someone", "συστήνω σε κάποιον"],
        ["jdm erklären", "to explain to someone", "εξηγώ σε κάποιον"],
        ["jdm vorlesen", "to read aloud to someone", "διαβάζω σε κάποιον"]
      ];

      
  return (
    <div>

    <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
    <Box className="global-box-header">
        <Header1 props="Verbs with Dative and Accusative" />
        <Header2 props="Verben mit Dativ und Akkusativ" />
    </Box>



    <Box className="global-box-not-header" >

        <Header3_Middle props="" />
        <Header2 props="" />

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginBottom: "2em" }} >
        There are some verbs that take <span className="green-text">two objects</span>. <br/>
        The one shows the <span className="green-text">person</span> and has syntax with <span className="green-text">dative</span>
         and the other shows the <span className="green-text">thing</span> and has syntax with <span className="green-text">accusative</span>.
        </Typography>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Nominative (Wer?)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} ></TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Dative(Wem?)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Accusative (Was?)</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Lehrer</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>zeigt</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den Schülern</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein Beispiel.</TableCell>                    
                </TableRow>

                <TableRow>                
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}  colSpan={4}>The teacher shows the students an example.</TableCell>
                </TableRow>                

            </Table>
        </TableContainer>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Nominative (Wer?)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} ></TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Dative(Wem?)</TableCell>
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} >Accusative (Was?)</TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>schenke</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dem Kind</TableCell>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>einen Bleistifft</TableCell>                    
                </TableRow>

                <TableRow>                
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}  colSpan={4}>I give the child a pencil.</TableCell>
                </TableRow>                

            </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: "5em" }} >
          The most important <span className="green-text">verbs with dative</span> are:
        </Typography>

        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
          <Table>
            <TableRow>
              <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
              <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
              <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
            </TableRow>

            {verbs.map((verb, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>

    </Box>

    <ScrollToTopButton />
</div>
  )
}

export default Z0042_Verbs_withDativeAndAccusative