import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z0168_AllGramaticalPhenomena() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="All Gramatical Phenomena" />
                <Header2 props="" />
            </Box>

            <Box className="global-box-not-header" >

                {/* <Header3_Middle props="All Gramatical Phenomena" />
                <Header2 props="" /> */}

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>1 .Präsens</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Modal Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Hier können wir gut essen.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Here we can eat well.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Εδώ μπορούμε να φάμε καλά.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Werden Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Er wird Arzt.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>He will become a doctor.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Θα γίνει γιατρός.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Vorgangspassiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Der Wagen wird (vom Mechaniker) repariert.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The car is being repaired (by the mechanic).</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Το αυτοκίνητο επισκευάζεται (από τον μηχανικό).</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with Subordinate sentence:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Die Mutter hat mir gesagt, dass das Kind jeden Tag von der Schule abgeholt wird.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The mother told me that the child is picked up from school every day.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Η μητέρα μου είπε ότι το παιδί παραλαμβάνεται από το σχολείο κάθε μέρα.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with modal Passiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich muss untersucht werden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I need to be examined.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Πρέπει να εξεταστώ.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Zustand Passiv::</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Die E-Mails sind geschrieben.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The emails are written.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Τα μηνύματα έχουν γραφτεί.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>





                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>
                                2. Perfekt: haben/sein + Partizip Perfekt (μετοχή παρακειμένου)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich habe gelernt.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I have learned.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Έχω μάθει.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich bin gekommen.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I have come.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Έχω έρθει.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Modal Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich habe die Mathaufgabe lösen können.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I have been able to solve the math problem.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Κατάφερα να λύσω το μαθηματικό πρόβλημα.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Werden Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Er ist Arzt geworden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>He has become a doctor.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Έγινε γιατρός.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Vorgangspassiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Der Wagen ist (vom Mechaniker) repariert worden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The car has been repaired (by the mechanic).</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Το αυτοκίνητο έχει επισκευαστεί (από τον μηχανικό).</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with Subordinate sentence:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Die Mutter hat mir gesagt, dass das Kind jeden Tag von der Schule abgeholt worden ist.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The mother told me that the child has been picked up from school every day.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Η μητέρα μου είπε ότι το παιδί παραλαμβανόταν από το σχολείο κάθε μέρα.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Zustand Passiv::</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Die E-Mails sind (schon) geschrieben worden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The emails have (already) been written.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Τα email έχουν (ήδη) γραφτεί.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>
                                3. Präteritum
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Modal Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Werden Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Er wurde Arzt.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>He became a doctor.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Έγινε γιατρός.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Vorgangspassiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Der Wagen wurde (vom Mechaniker) repariert.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The car was repaired (by the mechanic).</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Το αυτοκίνητο επισκευάστηκε (από τον μηχανικό).</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with Subordinate sentence:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Die Mutter hat mir gesagt, dass das Kind jeden Tag von der Schule abgeholt wurde.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The mother told me that the child was picked up from school every day.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Η μητέρα μου είπε ότι το παιδί παραλαμβανόταν από το σχολείο κάθε μέρα.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with modal Passiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich musste untersucht werden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I had to be examined.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Έπρεπε να εξεταστώ.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Zustand Passiv::</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Die E-Mails waren (schon) geschrieben worden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The emails had (already) been written.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Τα email είχαν (ήδη) γραφτεί.</TableCell>
                        </TableRow>


                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>
                                4. Plusquamperfekt (Υπερσυντέλικος) : hatte/war + Partizip Perfekt (μετοχή παρακειμένου)
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich hatte gelernt.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I had learned.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Είχα μάθει.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Modal Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich war gekommen.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I had come.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Είχα έρθει.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Werden Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich hatte die Mathaufgabe lösen können.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I had been able to solve the math problem.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Είχα καταφέρει να λύσω το μαθηματικό πρόβλημα.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Vorgangspassiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Er war Arzt geworden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>He had become a doctor.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Είχε γίνει γιατρός.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with Subordinate sentence:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Der Wagen war (vom Mechaniker) repariert worden.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>The car had been repaired (by the mechanic).</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Το αυτοκίνητο είχε επισκευαστεί (από τον μηχανικό).</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>with modal Passiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Zustand Passiv::</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}></TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>
                                5. Futur I (Μέλλοντας): werden + infinitive
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}>Aktiv:</TableCell>
                            <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Ich werde besuchen.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>I will visit.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Θα επισκεφθώ.</TableCell>
                        </TableRow>


                    </Table>
                </TableContainer>


            </Box>

            <ScrollToTopButton />
        </div>
    )
}

export default Z0168_AllGramaticalPhenomena