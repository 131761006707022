import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Toolbar, Typography, AppBar, Tabs, Tab, Button, Drawer, useTheme, useMediaQuery, TextField } from '@mui/material';

import DrawerComp from './DrawerComp';

// Search Text with DataGrid.
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

import SearchIcon from '@mui/icons-material/Search';


const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'german', headerName: 'german', width: 200 },
    { field: 'english', headerName: 'english', width: 200 },
    { field: 'greek', headerName: 'greek', width: 200 },

    { field: 'type1De', headerName: 'type1De', width: 150 },
    { field: 'type1En', headerName: 'type1En', width: 150 },
    { field: 'type1Gr', headerName: 'type1Gr', width: 150 },

    { field: 'type2De', headerName: 'type2De', width: 150 },
    { field: 'type2En', headerName: 'type2En', width: 150 },
    { field: 'type2Gr', headerName: 'type2GR', width: 150 },

    { field: 'link', headerName: 'link', width: 150 },
    { field: 'linkDescriptionDe', headerName: 'linkDescriptionDe', width: 150 },
    { field: 'linkDescriptionEn', headerName: 'linkDescriptionEn', width: 150 },
    { field: 'linkDescriptionGr', headerName: 'linkDescriptionGr', width: 150 },
    // { field: 'age', headerName: 'Age', type: 'number', width: 10 },
];



function Navbar({ links }) {

    const theme = useTheme();   //console.log(theme);

    const isMatch = useMediaQuery(theme.breakpoints.down("sm"))
    console.log(isMatch);

    const [value, setValue] = useState();        // Tab value.

    // Search Text with DataGrid.
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const file = 'data.xlsx'; // Assuming your Excel file is named data.xlsx
            const response = await fetch(file);
            const blob = await response.blob();

            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];                            // Assuming data is in the first sheet
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const header = jsonData.shift();                                     // Remove header row

                const formattedData = jsonData.map((row, index) => ({
                    id: index + 1,
                    link: row[1],
                    german: row[2],
                    english: row[3],

                    greek: row[4],
                    type1De: row[5],
                    type1En: row[6],
                    type1Gr: row[7],

                    type2De: row[8],
                    type2En: row[9],
                    type2Gr: row[10],


                    linkDescriptionDe: row[11],
                    linkDescriptionEn: row[12],
                    linkDescriptionGr: row[13],
                    example: row[14],
                    // age: parseInt(row[3]),
                }));

                setRows(formattedData);
            };

            reader.readAsArrayBuffer(blob);
        };

        fetchData();
    }, []);

    const handleSearch = () => {
        const filteredRows = rows.filter(row =>
            Object.values(row).some(val =>
                val && val.toString().toLowerCase().includes(searchText.toLowerCase())
            )
        );
        navigate('/results', { state: { rows: filteredRows } });
    };



    return (
        <AppBar sx={{ backgroundImage: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(195,225,139,0.7609244381346288) 0%, rgba(0,212,255,1) 100%)" }}>
            <Toolbar>

                {isMatch ? <>

                    <Grid item xs={12} sx={{ marginRight: '2em' }}>
                        {/* <Typography> <PoolIcon /> </Typography> */}
                        <Typography sx={{ color: 'blue', textDecoration: 'none' }} component={Link} to="/"> German Grammar </Typography>

                        <TextField

                            label="Search"

                            variant="outlined"

                            size="small"

                            value={searchText}

                            // sx={{ marginRight: '0em' }}
                            onChange={(e) => setSearchText(e.target.value)}

                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSearch();
                                }
                            }}

                            sx={{
                                marginRight: '0em',
                                width: '100px',
                                '& .MuiInputBase-input': {
                                    fontSize: '0.8rem',
                                },
                                '& .MuiOutlinedInput-root': {
                                    padding: '4px',
                                }
                            }}

                        />


                        <Button onClick={handleSearch} variant="text" color="primary"
                            sx={{
                                minWidth: 0,
                                padding: 0,
                                marginLeft: '5px',
                                '&:hover': {
                                    backgroundColor: '#8bbbb1',
                                }
                            }}>
                            <SearchIcon sx={{ fontSize: '2rem', marginTop: '0.2em' }} />
                        </Button>


                    </Grid>


                    <DrawerComp links={links} />

                </>
                    :

                    <Grid container sx={{ placeItems: "center" }}>

                        <Grid item xs={2}>
                            {/* <Typography> <PoolIcon /> </Typography> */}
                            <Typography sx={{ color: 'blue', textDecoration: 'none' }} component={Link} to="/"> German Grammar </Typography>
                        </Grid>

                        <Grid item xs={6}>

                            {/* <Tabs indicatorColor='secondary' textColor="inherit" value={value} onChange={(e, val) => setValue(val)}>
                                <Tab label="Home" sx={{ color: 'blue' }} component={Link} to="/" />
                                <Tab label="DataGrid" component={Link} to="/muuri" />
                                {links.map((link, index) =>
                                    <Tab key={index} label={link} />
                                )}
                            </Tabs> */}

                            <Box display={'flex'} alignItems={"center"}>

                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    size="small"
                                    value={searchText}
                                    sx={{ marginRight: '1em' }}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch();
                                        }
                                    }}
                                />

                                <Button onClick={handleSearch} variant="contained" color="primary">
                                    Search
                                </Button>

                            </Box>
                        </Grid>

                        <Grid item xs={4} >

                        </Grid>



                    </Grid>
                }

            </Toolbar>
        </AppBar >
    )
}

export default Navbar