
import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0141_Adverbs_sehr() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="The adverb : sehr" />
                <Header2 props="Das Adverb sehr" />
            </Box>

        
            <Box className="global-box-not-header"> 
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The adverb <span className="green-text">  sehr</span> <span className="red-text"> (very)</span> is an intensifier and precedes an adjective or adverb to indicate a high degree of the expressed quality.
                </Typography>



                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Dieses Auto fährt <span className="green-text">  sehr</span> schnell.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>This car goes <span className="green-text">  very</span> fast.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Im Sommer ist es <span className="green-text">  sehr</span> heiß.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>It is <span className="green-text">  very</span> hot in summer. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie ist eine <span className="green-text">  sehr</span> hübsche Frau.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>She is a <span className="green-text">  very</span> pretty woman.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Hier gibt es <span className="green-text">  sehr</span> hohe Berge.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>There are <span className="green-text">  very</span> high mountains here.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0141_Adverbs_sehr