import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton'


function Z0013_Verbs_Complex() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Complex verbs" />
                <Header2 props="Zusammengesetze Verben" />
            </Box>


            <Box className="global-box-not-header" >

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em', marginBottom: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    In german language there are many complex verbs. They formed with a basic verb and a prefix.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Basic verb : </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>kommen</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to come</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Complex verbs :</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>bekommen</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to get</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">an</span>kommen</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to arrive</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">zurück</span>kommen</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>to return</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    As we can see the prefix can be:<br />
                    1. A word which means nothing alone.<br />
                    2. A whole word, an <span className="green-text">adverb</span> (zurück) or a <span className="green-text">preposition</span> (an).<br /><br />

                    The meaning of the complex verb is usually very different for the meaning of the main verb.
                </Typography>

            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0013_Verbs_Complex