import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0084_Articles_dieser() {
  return (
    <div>

        <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
            <Header1 props="this one, these" />
            <Header2 props="dieser, diese, dieses, diese" />
        </Box>


        <Box className="global-box-not-header" >
        <Header3_Middle props="1. Demonstrative Article" />
        <Header2 props="Demonstrativ Artikel" />


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            The demonstrative article <span className="green-text"> dieser</span> (masculine), <span className="green-text"> diese</span> (feminine), <span className="green-text"> dieses</span> (neuter) and <span className="green-text"> diese</span> (these) in plural conjugated as the definite article.
        </Typography>


        <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell colspan={3} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Singular</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Plural</TableCell>                
                </TableRow>

                <TableRow>                     
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">er</span> Mann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">e</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">es</span> Kind</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">e</span> Leute</TableCell>                    
                </TableRow>                

                <TableRow>                     
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">en</span> Mann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">e</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">es</span> Kind</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">e</span> Leute</TableCell>                    
                </TableRow>                

                <TableRow>                     
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">em</span> Mann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">er</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">em</span> Kind</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">en</span> Leute<span className="green-text">n</span></TableCell>                    
                </TableRow>                

                <TableRow>                     
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">es</span> Mann<span className="green-text">es</span></TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">er</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">es</span> Kind<span className="green-text">es</span></TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>dies<span className="green-text">er</span> Leute</TableCell>                    
                </TableRow>                

            </Table>
        </TableContainer>




        <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
            <Table>             

                <TableRow> 
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}><span className="green-text"> Dieses</span> Buch habe ich noch nicht gelesen. (das Buch)</TableCell>                
                </TableRow>

                <TableRow>                                     
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I don't have read <span className="green-text"> this</span> book yet.</TableCell>
                </TableRow>
            
            </Table>
        </TableContainer>

        </Box>

        <ScrollToTopButton />



    </div>
  )
}

export default Z0084_Articles_dieser