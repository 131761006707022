import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0026VerbsPassiveVoice() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Passive voice" />
                <Header2 props="Passiv" />
            </Box>




            <Box className="global-box-not-header" >
                <Header3_Middle props="1. Use" />
                <Header2 props="Gebrauch" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    We use <span className="green-text"> passive voice (Passiv)</span> when we interested about the <span className="green-text"> action</span> and not who did it.<br /><br />

                    Example:<br />
                    Auf dem Platz <span className="green-text"> wird</span> ein Einkaufzentrum <span className="green-text"> gebaut</span>.<br />
                    At the public square is being builded a shopping center<br /><br />


                    In German as in English, passive constructions shift the emphasis from the <span className="green-text"> subject</span> of the active sentence to the <span className="green-text"> direct object</span>.<br />
                    What had been the direct object of the active sentence becomes the subject of the passive sentence.<br /><br />

                    In an active sentence, the subject performs an action, whereas in a passive sentence the subject is acted upon by an agent that had been
                    the subject of the active sentence. This agent might or might not be expressed in the passive sentence. <br /><br />

                    If the agent is expressed in a passive construction in German, it is preceded by either <span className="green-text"> von</span> or <span className="green-text"> durch</span>. <br />
                    If the agent is a person, it is preceded by <span className="green-text"> von</span> and is in the <span className="green-text"> dative case</span>. <br />
                    If the agent is an impersonal means by which something is done,  it is preceded by <span className="green-text"> durch</span> and is in the <span className="green-text"> accusative case</span>. <br />

                    In English, the word <span className="green-text"> by</span> is used to introduce the agent, whether it is a person or an impersonal means.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Example:</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Eltern fragen den Jungen.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The parents ask the boy.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Junge <span className="green-text"> wird</span> von den Eltern <span className="green-text"> gefragt</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The boy is asked by his parents.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Example:</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Aktiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der Sturm zerstört die Ernte.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The storm is destroying the harvest.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Ernte <span className="green-text"> wird</span> durch den Sturm <span className="green-text">zerstört</span>. </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The harvet is destroyed by the storm.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Many passive sentences in German do not express an agent, however. They simply consist of a subject and the passive verb pattern.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '2em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Example:</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Tür <span className="green-text"> wird geschlossen</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The door is (being) closed.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Vorlessung <span className="green-text"> wird gahalten</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The lecture is (beeing) held.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />






            <Box className="global-box-not-header" >
                <Header3_Middle props="2. Formation" />
                <Header2 props="Bildung" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The passive voice in German consists of a form of <span className="green-text"> werden</span> plus the <span className="green-text"> past participle (Partizip II - Partizip Perfekt)</span> of the main verb. <br />
                    Note that all tenses in German can be expressed in the passive.<br /><br />

                    <Box sx={{ background: 'pink' }}>Passiv = werden + Partizip Perfekt (Partizip II)</Box><br />

                    As we know Partizip II - Partizip Perfekt in a not conjugated type so we put the verb <span className="green-text"> werben</span> to the corresponding tense.
                </Typography>



                <Box sx={{ marginTop: "7em" }}>
                    <Header3_Middle props="2a. Present Tense Passive" />
                    <Header2 props="Präsens Passiv" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The present tense of the passive is constructed from the present tense of werden plus the past participle of the main verb.<br /><br />

                    <Box sx={{ background: 'pink' }}>Present Tense Passive = Present tense of werden + Past participle</Box>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive : "anrufen" = to call</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich werde angerufen</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du wirst angerufen </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es wird angerufen </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir werden angerufen</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr werdet angerufen </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie werden angerufen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Box sx={{ marginTop: "7em" }}>
                    <Header3_Middle props="2b. Past Tense Passive" />
                    <Header2 props="Präteritum Passiv" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} >
                    The present tense of the passive is constructed from the past tense of werden plus the past participle of the main verb.<br /><br />

                    <Box sx={{ background: 'pink' }}>Past Tense Passive = Past tense of werden + Past participle</Box>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive : "anrufen" = to call</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich wurde angerufen</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du wurdest angerufen </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es wurde angerufen </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir wurden angerufen</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr wurdet angerufen </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie wurden angerufen</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
                    Study the following conjugation :
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wir <span className="green-text"> wurden</span> von ihm <span className="green-text"> gesehen</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>We were seen by ihm.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Geschichte <span className="green-text"> wurde</span> von dem Lehrer <span className="green-text"> erzählt</span>.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The story was told by the teacher.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wann <span className="green-text"> wurderst</span> du <span className="green-text"> gefragt?</span></TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>When were you asked?</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Box sx={{ marginTop: "7em" }}>
                    <Header3_Middle props="2c. Present Perfekt Passive" />
                    <Header2 props="Present Perfekt Passiv" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} >
                    The simple present tense and the simple past tense of the auxiliary verb <span className="green-text"> sein</span> are used in the formation of the <span className="green-text"> present perfect</span> and
                    the <span className="green-text"> past perfect</span> passive.<br /><br />

                    The past participle of <span className="green-text"> werden</span> is <span className="green-text"> geworden</span>, but the <span className="green-text"> ge-</span> prefix is dropped in these tenses.

                    Thus, the perfect tenses in the passive consist of a form of sein plus the past participle of the main verb plus worden.<br /><br />

                    <Box sx={{ background: 'pink' }}>Present   perfect passive = Present tense of sein + Past participle of the main verb + worden</Box>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive : "anrufen" = to call</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich bin angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du bist angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es ist angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir sind angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr seid angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie sind angerufen worden</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Box sx={{ marginTop: "7em" }}>
                    <Header3_Middle props="2d. Past Perfect Passive" />
                    <Header2 props="Plusquamperfekt Passiv" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} >
                    <Box sx={{ background: 'pink' }}>Past perfect passive = Past tense of sein + Past participle of the main verb + worden</Box>
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive : "anrufen" = to call</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich war angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du warst angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es war angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir waren angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr wart angerufen worden</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie waren angerufen worden</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Auto ist vom Mechaniker repariert worden.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The car was repaired by the mechanic.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich binvon meiner Mutter gefragt worden.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I was asked by my mother.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Das Haus war von meinem Bruder verkauft worden.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The house had been sold by my brother.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>




                <Box sx={{ marginTop: "7em" }}>
                    <Header3_Middle props="2e. Future Tense Passive " />
                    <Header2 props="" />
                </Box>

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
                    The future passive is constructed from the present tense of werden plus the past participle of the main verb plus werden.<br />
                    The future passive is used chiefly to express probability.<br /><br />

                    <Box sx={{ background: 'pink' }}>Future passive = Present tense of werden + Past participle of the main verb + werden</Box>
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>infinitive : "anrufen" = to call</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich werde angerufen werden</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>du wirst angerufen werden</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>er, sie, es wird angerufen werden</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wir werden angerufen werden</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ihr werdet angerufen werden</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>sie, Sie werden angerufen werden</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }}>
                    <Table>

                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er wird wohl abgeholt werden.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He will probably be picked up.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Möbel werden wohl gebracht werden.</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The furniture will probably be delivered.</TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv:</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich werde von meinem Freund besucht werden. </TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I will be visited by my friend.</TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
                    If an adverb of time referring to the future occurs in the sentence, the present passive is preferred.
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Passiv: </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Die Apfel werden von den Männer gepflückt</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The apples will be picked by the men tomorrow.</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />








            <Box className="global-box-not-header" >
                <Header3_Middle props="3. Summary Passive voice" />
                <Header2 props="Zukunftsform Passiv" />

                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} >
                    1. Present Tense Passive = Present tense of werden + Past participle<br /><br />

                    2. Past Tense Passive = Past tense of werden + Past participle<br /><br />

                    3. Present perfect passive = Present tense of sein + Past participle of the main verb + worden<br /><br />

                    4. Past perfect passive = Past tense of sein + Past participle of the main verb + worden<br /><br />

                    5. Future passive = Present tense of werden + Past participle of the main verb + werden
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>1. Present Tense Passive (Präsens Passiv) : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich werde angerufen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Παίρνω μια κλήση </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>2. Past Tense Passive (Präteritum Passiv) : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich wurde angerufen</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Πήρα μία κλήση </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>3. Present perfect passive (Perfekt Passiv) : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich bin angerufen worden</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Πήρα μία κλήση  </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>4. Past perfect passive (Plusquamperkekt) : </TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich war angerufen worden</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Με είχαν καλέσει </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>5. Future passive :</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ich werde angerufen werden</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>Θα με καλέσουν </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />

        </div>
    )
}

export default Z0026VerbsPassiveVoice