import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

//-------------------------------------------------------------------------- Image
import ImageListItem from '@mui/material/ImageListItem';
import AdjectivesImage from '../images/z0066_Adjectives.jpg'; 


function Z0065_Adjectives_Conjugation_Superlative() {
    return (
        <div>

            <IconButton component={Link} to="/"> {" "}  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Conjugation of the Adjectives in Comperative and in Superlative" />
                <Header2 props="Adjektivdeklination der Adjektive im Komperativ und Superlativ" />
            </Box>


            <Box className="global-box-not-header" >
                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    When we conjugate the adjectives in comperative or in superlative degree, then we shape firstly the comperative or superlative degree and then we adding the analog ending.
                </Typography>

                <br />


                <Paper variant="outlined">
                    <ImageListItem sx={{ border: '1px solid black', padding: '1px', borderRadius: '4px' }}>
                        
                            <img src={AdjectivesImage} alt="Adjectives Conjugation" />
                        
                    </ImageListItem>
                </Paper>



                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Positive:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>schön</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein schön<span className="green-text">er</span> Tag (der Tag)</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>a beautiful day </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Comparative:	</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>schön<span className="green-text">er</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                ein schöner<span className="green-text">er</span> Tag (der Tag)<br /><br />

                                1. the first (red) "-er" is the comparative degree.<br />
                                2. the second (green) "-er" is the ending of masculine in nominative.
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>a more beautiful day </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Superlative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">am</span> schön<span className="green-text">st</span>en	</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                der schönst<span className="green-text">e</span> Tag (der Tag)<br /><br />

                                1. we delete "am" and "-en", and then remain only the "schönst"<br />
                                2. then we add the appropriate ending of definite article.
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>the most beautiful day </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>





                <TableContainer component={Paper} sx={{ marginTop: '7em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Der beste Spiele.</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                because:<br /><br />

                                1. gut - besser - am besten - remains: " best "<br />
                                2. then we add the appropriate ending of definite article
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The best game. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>
                                Das interessanteste Geschichte.<br />(die Geschichte)
                            </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                because:<br /><br />

                                1. interessant - interessant-er - am interessant-e-sten - remains: " interessant-e-st "<br />
                                2. then we add the appropriate ending of definite article

                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The most interesting story. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Das größte Kind. <br />(das Kind).</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                because:<br /><br />

                                1. groß - größ-er - am größ-ten - remains: " größt "<br />
                                2. then we add the appropriate ending of definite article
                            </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>The biggest child. </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    2. viel - mehr - am meistens
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Degrees of comparison of the adjective : "viel"</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Positive:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>viel</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}> a lot</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Comparative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>mehr</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>more</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Superlative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>am meistens</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>most</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>




                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    When the adjective <span className="green-text">"mehr"</span> is in the comparative degree, which means <span className="green-text">"more"</span> then it don't conjugated.
                    The same with the adjective "wenig".
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell colspan={3} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Degrees of comparison of the adjective : "viel"</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Positive:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>viele Kinder.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>a lot of childs</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Comparative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">mehr</span> Kinder.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}><span className="green-text"> more</span> childs</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Superlative:</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>die meisten Kinder.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>most of the childs</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In meiner Klasse gibt es mehr Jungen als Mädchen.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>In my class there are more boys than girls.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />



            <Box className="global-box-not-header" >

                <Header3_Middle props="2. Some Examples" />
                <Header2 props="" />

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subject (Nominative):</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Object (Accusative):</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Der</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>VW-Polo hat</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den längsten</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Kofferraum</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colspan={4} sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                because:<br /><br />

                                1. lang - läng-er - am läng-sten - remains: " längst "<br />
                                2. then we add the appropriate ending of definite article den, in accusative because is an object
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>




                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subject (Nominative):</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Object (Accusative):</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Welcher</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wagen hat</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den besten</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Preis?</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colspan={4} sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                                because:<br /><br />

                                1. gut - besser - am besten - remains: " best "<br />
                                2. then we add the appropriate ending of definite article den, in accusative because is an object
                            </TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />



        </div>
    )
}

export default Z0065_Adjectives_Conjugation_Superlative