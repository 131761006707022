import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableContainer, TableRow, TableCell, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';

function Z0035_Verbs_ListWithRegularVerbs() {

    const verbs = [
        ["angeln", "to fish", "ψαρεύω"],
        ["ankommen", "to arrive", "φτάνω"],
        ["antworten", "to answer", "απαντώ"],
        ["arbeiten", "to work", "εργάζομαι, δουλεύω"],
        ["atmen", "to breathe", "αναπνέω"],
        ["backen", "to bake", "ψήνω, φτιάχνω γλυκό"],
        ["baden", "to have a bath", "κάνω μπάνιο"],
        ["basteln", "to do handicrafts", "κάνω χειροτεχνία"],
        ["bauen", "to build", "χτίζω"],
        ["beantworten", "to answer", "απαντώ"],
        ["bearbeiten", "to work on", "επεξεργάζομαι"],
        ["bedeuten", "to mean", "σημαίνω"],
        ["bedienen", "to serve", "εξυπηρετώ"],
        ["beginnen", "to start, to begin", "ξεκινάω, καλωσορίζω"],
        ["begrüßen", "to keep", "χαιρετώ"],
        ["bekommen", "to get", "λαμβάνω, παίρνω,(βρίσκω)"],
        ["beobachten", "to observe", "παρατηρώ"],
        ["beraten", "to advise", "συσκέπτομαι, συζητώ, συμβουλεύω"],
        ["berichten", "to narrate, to report", "διηγούμαι, αναφέρω"],
        ["beschreiben", "to describe", "περιγράφω"],
        ["besichtigen", "to visit", "επισκέπτομαι, βλέπω"],
        ["besorgen", "to procure", "προμηθεύομαι, παίρνω"],
        ["besprechen", "to discuss", "συζητώ"],
        ["bestehen (aus)", "to consist of", "αποτελούμαι (από)"],
        ["bestellen", "to order", "παραγγέλνω"],
        ["besuchen", "to visit", "επισκέπτομαι"],
        ["betreiben", "to preserve", "διατηρώ"],
        ["bewundern", "to admire", "θαυμάζω"],
        ["bezahlen", "to pay", "πληρώνω"],
        ["bieten", "to offer", "προσφέρω"],
        ["bleiben", "to stay", "μένω, παραμένω"],
        ["bohren", "to drill a hole", "τρυπώ"],
        ["brauchen", "to need", "χρειάζομαι"],
        ["brauen", "to make", "παρασκευάζω, φτιάχνω"],
        ["brechen", "to brake", "σπάω"],
        ["bringen", "to bring, to take", ""],
        ["buchen", "to book", "κάνω κράτηση"],
        ["buchstabieren", "to spell", "συλλαβίζω"],
        ["chatten", "to chat", "κάνω chat"],
        ["dauern", "to last", "διαρκεί"],
        ["denken", "to think", "σκέφτομαι, θυμάμαι"],
        ["diskutieren", "to discuss", "συζητώ"],
        ["dürfen", "to be allowed to do something", "επιτρέπεται"],
        ["duschen", "to shower", "κάνω ντουζ"],
        ["einschalten", "to switch on", "ανάβω"],
        ["empfehlen", "to recommend", "συνιστώ"],
        ["entdecken", "to discover", "ανακαλύπτω"],
        ["entfernen", "to remove", "απομακρύνω, αφαιρώ"],
        ["entscheiden", "to decide", "αποφασίζω"],
        ["entschuldigen", "to apologize", "συγχωρώ"],
        ["erben", "to inherit", "κληρονομώ"],
        ["erfinden", "to invent", "εφευρίσκω, επινοώ"],
        ["ergänzen", "to fill in", "συμπληρώνω"],
        ["erkennen", "to recognize", "αναγνωρίζω"],
        ["erklären", "to explain", "εξηγώ"],
        ["erleben", "to live to see, to experience", "ζω, βιώνω"],
        ["erledigen", "to settle", "τακτοποιώ δουλειές, διεκπερεώνω"],
        ["erreichen", "to catch", "φτάνω (σε)"],
        ["ersteigern", "to overbid", "πλειοδοτώ"],
        ["erzählen", "to tell", "διηγούμαι, αφηγούμαι"],
        ["erziehen", "to bring up", "ανατρέφω, διαπεδαγωγώ"],
        ["essen", "to eat", "τρώω"],
        ["existieren", "to exist", "υπάρχω"],
        ["fahren", "to drive", "οδηγώ, πηγαίνω"],
        ["fallen", "to fall", "πέφτω"],
        ["fangen", "to catch", "πιάνω, συλλαμβάνω"],
        ["fehlen", "to be missing", "λείπω"],
        ["feiern", "to celebrate", "γιορτάζω"],
        ["filmen", "to film", "τραβάω ταινία"],
        ["finden", "to find", "βρίσκω, μου φαίνεται"],
        ["fliegen", "to fly", "πετώ"],
        ["fließen", "to flow", "ρέει, κυλάει"],
        ["flirten", "to flirt", "φλερτάρω"],
        ["fotografieren", "to take photographs", "φωτογραφίζω"],
        ["fragen", "to ask", "ερωτώ"],
        ["fressen", "to eat", "τρώω (για ζώα)"],
        ["freuen sich", "to be glad", "χαίρομαι"],
        ["frisieren", "to do someone's hair", "χτενίζω"],
        ["frühstücken", "to have breakfast", "τρώω πρωϊνό"],
        ["funktionieren", "to function", "λειτουργώ"],
        ["füttern", "to feed", "ταϊζω (για ζώα)"],
        ["geben", "to give", "δίνω"],
        ["geboren", "to be born", "γεννιέμαι"],
        ["gebrauchen", "to use", "χρησιμοποιώ, (μετα)χειρίζομαι"],
        ["gehen", "to go", "πηγαίνω"],
        ["gehören zu + D", "to belong", "ανήκω"],
        ["gewinnen", "to win", "κερδίζω"],
        ["gießen", "to water the plants", "ποτίζω"],
        ["glauben", "to believe", "πιστεύω"],
        ["gucken", "to look", "κοιτάζω"],
        ["haben", "to have", "έχω"],
        ["halten", "to hold", "κρατώ"],
        ["hangen", "to hang", "κρέμομαι"],
        ["heiraten", "to getting married", "παντρεύομαι"],
        ["heißen", "to be called or to mean something", "ονομάζομαι"],
        ["heizen", "to heat", "θερμαίνω"],
        ["helfen", "to help", "βοηθώ"],
        ["holen", "to get", ""],
        ["hören", "to hear, to listen", "ακούω"],
        ["hüpfen", "to hop", "χοροπηδάω"],
        ["informieren", "to inform", "πληροφορώ, ενημερώνω"],
        ["kaufen", "to buy", "αγοράζω"],
        ["kennen", "to know", "γνωρίζω"],
        ["klettern", "to climb", "σκαρφαλώνω"],
        ["klingeln", "to sound", "ηχεί, ακούγεται"],
        ["kochen", "to cook", "μαγειρεύω"],
        ["kommen", "to come", "είμαι, κατάγομαι, προέρχομαι"],
        ["komponieren", "to compose", "συνθέτω"],
        ["können", "to can", "μπορώ"],
        ["kontrollieren", "to control", "ελέγχω"],
        ["korrigieren", "to correct", "διορθώνω"],
        ["kosten", "to cost", "κοστίζω"],
        ["kündigen", "to quit", "παραιτούμαι"],
        ["lächeln", "to smile", "χαμογελώ"],
        ["lachen", "to smile", "γελώ"],
        ["landen", "to land", "προσγειώνομαι"],
        ["lassen", "to leave", "αφήνω, βάζω κάποιον να"],
        ["laufen", "to run", "τρέχω"],
        ["leben", "to live", "ζω"],
        ["leidtun", "to sorry", "λυπάμαι"],
        ["leihen", "to borrow, to lend", "δανείζω, δανείζομαι"],
        ["lernen", "to learn", "μαθαίνω, διαβάζω, μελετώ"],
        ["lesen", "to read", "διαβάζω"],
        ["lieben", "to love", "αγαπώ"],
        ["liefern", "to deliver", "παραδίδω"],
        ["liegen", "to locate", "βρίσκεται"],
        ["machen", "to do", "κάνω"],
        ["machen lassen", "to get somebody to do something", "βάζω(κάποιoν) να κάνει (κάτι)"],
        ["malen", "to paint", "ζωγραφίζω"],
        ["markieren", "to mark", "σημειώνω"],
        ["massieren", "to massage", "κάνω μασάζ"],
        ["meinen", "to think", "νομίζω, πιστεύω"],
        ["merken", "to notice", "καταλαβαίνω, αντιλαμβάνομαι"],
        ["messen", "to measure", "μετρώ"],
        ["mieten", "to rent", "νοικιάζω"],
        ["mischen", "to mix", "ανακατεύω, αναμιγνύω"],
        ["möchten", "would like to have/do", "θέλω"],
        ["mögen", "to like", "μου αρέσει"],
        ["montieren", "to fit", "τοποθετώ, στερεώνω"],
        ["müssen", "have to /must", "πρέπει"],
        ["nehmen", "to take", "παίρνω"],
        ["nennen", "to name", "ονομάζω, αποκαλώ"],
        ["notieren", "to make a note", "σημειώνω"],
        ["öffnen", "to open", "ανοίγω (την πόρτα)"],
        ["operieren", "to operate", "χειρουργώ"],
        ["ordnen", "to arrange", "τακτοποιώ"],
        ["packen", "to pack", "πακετάρω"],
        ["parken", "to park", "παρκάρω, σταθμεύω"],
        ["passen zu + D", "to fit", "ταιριάζει"],
        ["passieren", "to happen", "συμβαίνει, γίνεται"],
        ["praktizieren", "to practise", "εξασκώ, εφαρμόζω"],
        ["präsentieren", "to present", "παρουσιάζω"],
        ["putzen", "to clean", "καθαρίζω"],
        ["raten", "to advise", "μαντεύω"],
        ["rauchen", "to smoke", "καπνίζω"],
        ["rechnen", "to calculate", "υπολογίζω, λογαριάζω"],
        ["reden mit + D", "to talk", "μιλάω"],
        ["regnen", "to rain", "βρέχει"],
        ["reichen", "to be enough", "αρκεί, φτάνει"],
        ["reinigen", "to clean", "καθαρίζω"],
        ["reisen", "to travel", "ταξιδεύω"],
        ["reiten", "to ride", "κάνω ιππασία"],
        ["reklamieren", "to complain", "διαμαρτύρομαι, παραπονιέμαι"],
        ["rekonstruieren", "to reconstruct", "ξαναφτιάχνω, ανασυνθέτω"],
        ["reparieren", "to repair", "διορθώνω"],
        ["rufen", "to call (to shout)", "φωνάζω, καλώ"],
        ["sagen", "to say", "λέω"],
        ["sammeln", "to pick", "μαζεύω, συλλέγω"],
        ["schauen", "to look", "κοιτάζω"],
        ["schaukeln", "to rock", "κάνω κούνια, κουνιέμαι"],
        ["scheinen", "to shine", "γυαλίζω"],
        ["schenken", "to give a present", "κάνω δώρο , δωρίζω"],
        ["schicken", "to send", "στέλνω"],
        ["schlafen", "to sleep", "κοιμάμαι"],
        ["schlagen", "to hit", "χτυπώ"],
        ["schließen", "to close", "κλείνω (την πόρτα)"],
        ["schmecken", "to taste", "γεύομαι"],
        ["schneiden", "to cut", "κόβω, τεμαχίζω"],
        ["schreiben", "to write", "γράφω"],
        ["schreien", "to shout", "φωνάζω, τσιρίζω"],
        ["schwimmen", "to swim", "κολυμπάω"],
        ["schwitzen", "to sweat", "ιδρώνω"],
        ["segeln", "to go sailing", "κάνω ιστιοπλοϊα"],
        ["sehen", "to see", "βλέπω"],
        ["sein", "to be", "είμαι"],
        ["singen", "to sing", "τραγουδώ"],
        ["sitzen", "to sit", "κάθομαι, είμαι καθισμένος"],
        ["spazieren", "to walk", "περπατάω, κάνω βόλτα"],
        ["spielen", "to play", "παίζω"],
        ["sport treiben", "to make sport", "κάνω αθλητισμό"],
        ["sprechen", "to speak", "μιλάω"],
        ["springen", "to jump", "πηδώ"],
        ["spülen", "to wash up", "ξεπλένω, πλένω (τα πιάτα)"],
        ["starten", "to take off", "απογειώνομαι"],
        ["stehen", "to stand (vertically)", "στέκομαι (κάθετα - στάσιμα)"],
        ["steigen", "to rise", "αυξάνεται, ανεβαίνει"],
        ["stellen", "to put sth vertically", "βάζω/ τοποθετώ κάτι όρθιο"],
        ["stimmen", "to be right", "συμφωνώ"],
        ["stören", "to disturb", "ενοχλώ, διαταράσσω"],
        ["studieren", "to study", "σπουδάζω"],
        ["suchen", "to look for - to search", "ψάχνω"],
        ["surfen", "to surf", "σερφάρω"],
        ["tanzen", "to dance", "χορεύω"],
        ["tauschen", "to exchange", "αλλάζω"],
        ["teilen", "to divide", "χωρίζω"],
        ["telefonieren", "to telephone", "τηλεφωνώ"],
        ["tragen", "to carry, to wear", "κουβαλάω, φοράω"],
        ["träumen", "to dream", "ονειρεύομαι"],
        ["treffen", "to meet", "συναντώ"],
        ["treten", "to step", "πατώ, κλοτσάω"],
        ["trinken", "to drink", "πίνω"],
        ["tropfen", "to drip", "στάζω"],
        ["tun", "to do", "κάνω"],
        ["turnen", "to do gymnastics", "κάνω γυμναστική"],
        ["überlegen", "to consider", "σκέφτομαι, αναλογίζομαι"],
        ["übernachten", "to stay the night somewhere", "διανυκτερεύω"],
        ["übernehmen", "to take things in hand", "αναλαμβάνω"],
        ["übersetzen", "to translate", "μεταφράζω"],
        ["unterstreichen", "to underline", "υπογραμμίζω"],
        ["sich verabschieden", "to say goodbye", "αποχαιρετώ"],
        ["verbieten", "to forbid", "απαγορεύω"],
        ["verdienen", "to earn money", "κερδίζω χρήματα"],
        ["vergessen", "to forget", "ξεχνώ"],
        ["vergleichen", "to compare", "συγκρίνω"],
        ["verkaufen", "to sell", "πουλάω"],
        ["verkehren", "to go with Bus, Boat", "κάνω δρομολόγιο"],
        ["verlieren", "to lose", "χάνω"],
        ["verpassen", "to miss (the bus)", "χάνω (το λεωφορείο)"],
        ["verstehen", "to understand", "καταλαβαίνω"],
        ["verwenden", "to use", "χρησιμοποιώ"],
        ["wachsen", "to grow", "μεγαλώνω"],
        ["wählen", "to choose, to vote", "διαλέγω, επιλέγω, ψηφίζω"],
        ["wandern", "to hike", "κάνω πεζοπορία"],
        ["warten", "to wait", "περιμένω"],
        ["waschen", "to wash", "πλένω"],
        ["wechseln", "to change", "αλλάζω"],
        ["wecken", "to wake", "ξυπνώ (κάποιον)"],
        ["weinen über", "to cry", "κλαίω"],
        ["werden", "to become", "γίνομαι"],
        ["werfen", "to throw", "ρίχνω πετώ"],
        ["wiederholen", "to repeat", "to repeat"],
        ["willkommen", "to welcome", "to welcome"],
        ["wischen", "to wipe", "σφουγγαρίζω, καθαρίζω"],
        ["wissen", "to know", "ξέρω"],
        ["wohnen", "to live somewhere", "μένω, κατοικώ"],
        ["wollen", "to want", "θέλω"],
        ["wünschen", "to wish", "επιθυμώ"],
        ["würfeln", "to throw dice", "ρίχνω ζάρι"],
        ["zahlen", "to pay", "πληρώνω"],
        ["zeichnen", "to drawing", "σχεδιάζω"],
        ["zeigen", "to show", "δείχνω"],
        ["zerstören", "to destroy", "καταστρέφω"],
        ["ziehen", "to relocate", "μετακομίζω"],
        ["zusehen", "to watch", "παρακολουθώ"],
        ["unterstreichen", "to underline", "υπογραμμίζω"],
        ["kritisieren", "to criticize", "κριτικάρω, (κατά)κρίνω"],
        ["sabotieren", "to sabotage", "σαμποτάρω"],
        ["ärgern", "to annoy", "τσατίζω, εκνευρίζω"],
        ["kündigen", "to quit", "παραιτούμαι"],
        ["verlangen", "to demand", "απαιτώ"],
        ["lügen", "to lie", "λέω ψέματα"],
        ["ändern", "to change", "αλλάζω, μεταβάλω"],
        ["interessieren", "to interest", "ενδιαφέρω"],
        ["wiegen", "to weigh", "ζυγίζω"],
        ["genießen", "to enjoy", "απολαμβάνω"],
        ["kontakten", "to contact", "επικοινωνώ"],
        ["kümmern um + A", "to take care of", "φροντίζω για"],
        ["gefallen + D", "to like", "αρέσω σε"],
        ["melden (sich)", "to report", "επικοινωνώ"],
        ["tätowieren", "to tattoo", "κάνω τατουάζ"],
        ["beschützen vor + D", "to protect from", "προστατεύω από"],
        ["bestimmen", "to determine", "(καθ)ορίζω, προσδιορίζω"],
        ["studieren", "to study", "σπουδάζω"],
        ["wechseln", "to change", "αλλάζω"],
        ["schätzen", "to estimate", "εκτιμώ, υπολογίζω"],
        ["kämpfen", "to fight", "πολεμώ, αγωνίζομαι"],
        ["geschehen", "to happen", "γίνεται, συμβαίνει"],
        ["bitten um + A", "to ask for", "παρακαλώ, ζητάω"],
        ["funkeln", "to sparkle", "λαμποκοπώ"],
        ["benutzen", "to use", "χρησιμοποιώ"],
        ["sich beschweren über + A", "to complain about", "διαμαρτύρομαι, παραπονιέμαι"],
        ["versprechen", "to promise", "υπόσχομαι"],
        ["lösen", "to solve", "λύνω"],
        ["sich bewerben", "to apply", "υποβάλλω αίτηση"],
        ["befragen", "to interview", "ρωτώ"],
        ["nützen", "to benefit", "ωφελώ"],
        ["promovieren", "to get a PhD", "παίρνω διδακτορικό τίτλο"],
        ["bewegen", "to move", "κουνώ, κινώ"],
        ["überfahren", "to run over", "πατώ/χτυπώ (με όχημα)"],
        ["fangen", "to catch", "πιάνω, συλλαμβάνω"],
        ["entdecken", "to discover", "ανακαλύπτω"],
        ["erschließen", "to shoot", "σκοτώνω με όπλο, πυροβολώ"],
        ["sich ärgern über + A", "to get annoyed at", "εκνευρίζομαι / θυμώνω με"],
        ["sich freuen auf + A", "to look forward to", "περιμένω με χαρά"],
        ["benutzen", "to use", "χρησιμοποιώ"],
        ["sich etwas an/hören", "to listen to something", "ακούω κάτι"],
        ["stören", "to disturb", "ενοχλώ"],
        ["prüfen", "to check", "ελέγχω, εξετάζω"],
        ["testen", "to test", "δοκιμάζω"],
        ["verbrauchen", "to consume", "καταναλώνω"],
        ["tanken", "to refuel", "βάζω βενζίνη"],
        ["lügen", "to lie", "λέω ψέματα"],
        ["pressen", "to press", "πρεσάρω"],
        ["schweißen", "to weld", "συγκολλώ"],
        ["lackieren", "to paint", "βάφω"],
        ["spritzen", "to spray", "ψεκάζω, βάφω"],
        ["schützen", "to protect", "προστατεύω, προφυλάσσω"],
        ["formen", "to form", "φορμάρω"],
        ["lehren", "to teach", "διδάσκω, μαθαίνω"],
        ["versorgen", "to supply", "εφοδιάζω"],
        ["trocknen", "to dry", "στεγνώνω"],
        ["reichen", "to suffice", "αρκεί, φτάνει"],
        ["klemmen", "to clamp", "μαγκώνω"],
        ["besorgen", "to provide", "εφοδιάζω, προμηθεύω"],
        ["erziehen", "to educate", "ανατρέφω, διαπεδαγωγώ"],
        ["abnehmen", "to lose weight", "αδυνατίζω, χάνω βάρος"],
        ["rauchen", "to smoke", "καπνίζω"],
        ["hassen", "to hate", "μισώ, απεχθάνομαι"],
        ["bilden", "to form", "σχηματίζω"],
        ["sparen", "to save", "αποτεμιεύω, κάνω οικονομία"],
        ["genießen", "to enjoy", "απολαμβάνω"],
        ["hoffen", "to hope", "ελπίζω"],
        ["töten", "to kill", "σκοτώνω"],
        ["grillen", "to grill", "ψήνω στη σχάρα"],
        ["sich wohl fühlen", "to feel well", "αισθάνομαι, νιώθω καλά"],
        ["sich etwas holen", "to get something", "παίρνω κάτι"],
        ["verbringen", "to spend time", "περνώ (χρόνο)"],
        ["schlagen", "to hit", "χτυπώ"],
        ["sterben", "to die", "πεθαίνω"],
        ["kommen", "to come", "πηγαίνω, ξεκινώ"],
        ["sich sicher fühlen", "to feel safe", "αισθάνομαι, νιώθω ασφαλής"],
        ["behaupten", "to claim", "ισχυρίζομαι, υποστηρίζω"],
        ["bestimmen", "to determine", "καθορίζω"],
        ["krank machen", "to make sick", "αρρωσταίνει, προκαλει αδιαθεσία"],
        ["überraschen", "to surprise", "αιφνιαδιάζω, εκπλήσω"],
        ["beantworten", "to answer", "απαντώ (σε κάτι)"],
        ["fließen", "to flow", "ρέει, εδώ: περνάει μέσα από"],
        ["konsumieren", "to consume", "καταναλώνω"],
        ["werfen", "to throw", "πετώ"],
        ["füllen", "to fill", "γεμίζω"],
        ["produzieren", "to produce", "παράγω"],
        ["sortieren", "to sort", "ξεδιαλέφω, ξεχωρίζω"],
        ["verbrennen", "to burn", "καίγομαι"],
        ["entstehen", "to arise", "δημουργείται, προκαλείται"],
        ["kompostieren", "to compost", "μετατρέπω σε φυτικό λίπασμα"],
        ["erhalten", "to receive", "λαμβάνω, μου δίνουν"],
        ["vermeiden", "to avoid", "αποφεύγω"],
        ["interviewen", "to interview", "παίρνω συνέντευξη"],
        ["klagen", "to complain", "παραπονιέμαι"],
        ["riechen", "to smell", "μυρίζω"],
        ["reservieren", "to reserve", "κλείνω, κάνω κράτηση"],
        ["impfen", "to vaccinate", "εμβολιάζω"],
        ["verlängern", "to extend", "ανανεώνω, παρατείνω"],
        ["beantragen", "to apply for", "κάνω/ υποβάλλω αίτηση"],
        ["untersuchen", "to examine", "εξετάζω"],
        ["bestellen", "to order", "παραγγέλνω, κάνω κράτηση"],
        ["wiegen", "to weigh", "ζυγίζω"],
        ["planen", "to plan", "σχεδιάζω, προγραμματίζω"],
        ["retten", "to save", "σώζω"],
        ["überzeugen", "to convince", "πείθω"],
        ["gefragt sein", "to be in demand", "έχει ζήτηση"],
        ["gelten", "to apply", "ισχύει"],
        ["die Prüfung bestehen", "to pass the exam", "περνώ τις εξετάσεις"],
        ["kündigen", "to resign", "παραιτούμαι, εγκαταλείπω"],
        ["erleben", "to experience", "ζω, βιώνω"],
        ["verstecken", "to hide", "κρύβω"],
        ["überqualifiziert sein", "to be overqualified", "(κατ) έχω τα προσόντα"],
        ["sein Glück versuchen", "to try one's luck", "δοκιμάζω την τύχη μου"],
        ["reagieren", "to react", "αντιδρώ"],
        ["klagen", "to complain", "παραπονιέμαι"],
        ["fühlen", "to feel", "αισθάνομαι"],
        ["tauchen", "to dive", "κάνω καταδύσεις, βουτώ"],
        ["streiken", "to strike", "απεργώ"],
        ["demonstrieren", "to demonstrate", "διαδηλώνω"],
        ["stranden", "to strand", "προσαράσω, εξοκέλλω"],
        ["verhaften", "to arrest", "συλλαμβάνω"],
        ["rauben", "to rob", "ληστεύω"],
        ["erkämpfen", "to win by struggle", "κερδίζω κάτι με αγώνα"],
        ["unterschreiben", "to sign", "υπογράφω"],
        ["beschließen", "to decide", "αποφασίζω"],
        ["fordern", "to demand", "απαιτώ, ζητώ, αξιώνω"],
        ["drohen", "to threaten", "απειλώ"],
        ["verkleinern", "to reduce", "μικραίνω"],
        ["begleiten", "to accompany", "συνοδεύω"],
        ["erreichen", "to achieve", "πετυχαίνω, φτάνω"],
        ["folgen", "to follow", "ακολουθώ"],
        ["kommentieren", "to comment", "σχολιάζω"],
        ["gründen", "to establish", "ιδρύω, συγκροτώ"],
        ["sich entwickeln", "to develop", "αναπτύσσομαι, εξελίσσομαι"],
        ["flüchten", "to escape", "δραπετεύω"],
        ["schließen", "to close", "κλείνω"],
        ["verlassen", "to leave", "εγκαταλείπω"],
        ["entstehen", "to arise", "δημιουργείται, σχηματίζεται"],
        ["verreisen", "to travel", "πάω ταξίδι"],
        ["faulenzen", "to lounge", "τεμπελιάζω"],
        ["sich lohnen", "to be worth it", "αξίζει"],
        ["erlauben", "to allow", "επιτρέπω"],
        ["veröffentlichen", "to publish", "δημοσιεύω"],
        ["entsprechen + D", "to correspond to", "αναλογεί, ανταποκρίνομαι"],
        ["gelingen", "to succeed", "πετυχαίνει"],
        ["pflegen", "to care for", "περιποιούμαι, φροντίζω"],
        ["sich ändern", "to change", "αλλάζω"],
        ["unternehmen", "to undertake", "αναλαμβάνω, υιοθετώ"],
        ["verlassen", "to leave", "εγκαταλείπω"],
        ["gestalten", "to shape", "διαμορφώνω"],
        ["senden", "to send", "στέλνω"],
        ["funktionieren", "to function", "λειτουργώ"],
        ["integrieren", "to integrate", "ενσωματώνω"],
        ["importieren", "to import", "εισάγω"],
        ["erhalten", "to receive", "παίρνω, λαμβάνω"],
        ["drucken", "to print", "τυπώνω"],
        ["verwenden", "to use", "χρησιμοποιώ"],
        ["verreisen", "to travel", "φεύγω σε ταξίδι"],
        ["sich halten", "to maintain", "διατηρώ, έχω (ένα ζώο)"],
        ["entfliegen", "to fly away", "φεύγω πετώντας"],
        ["genießen", "to enjoy", "απολαμβάνω"],
        ["versorgen", "to take care of", "φροντίζω, περιποιούμαι"],
        ["passieren", "to happen", "συμβαίνει"],
        ["löschen", "to delete", "σβήνω"],
        ["versuchen", "to try", "προσπαθώ"],
        ["benutzen", "to use", "χρησιμοποιώ"],
        ["sich erkundigen", "to inquire", "ζητώ πληροφορίες"],
        ["streichen", "to delete", "διαγράφω"],
        ["erleichtern", "to facilitate", "διευκολύνω"],
        ["unterstützen", "to support", "υποστηρίζω"],
        ["melden", "to report", "δηλώνω"],
        ["besichtigen", "to visit", "επισκέπτομαι (ένα αξιοθέατο)"],
        ["erreichen", "to reach", "φτάνω κάπου"],
        ["verfügbar sein", "to be available", "διατίθεται"],
        ["sich befinden", "to be located", "βρίσκομαι"],
        ["bestehen", "to pass", "περνώ μια εξέταση"],
        ["sich fragen", "to wonder", "αναρωτιέμαι"],
        ["enttäuschen", "to disappoint", "απογοητεύω"],
        ["planen", "to plan", "σχεδιάζω, κανονίζω"],
        ["organisieren", "to organize", "διοργανώνω"],
        ["überlegen", "to consider", "σκέφτομαι"],
        ["besprechen", "to discuss", "συζητώ"],
        ["präsentieren", "to present", "παρουσιάζω"],
        ["beenden", "to finish", "τελειώνω, ολοκληρώνω"],
        ["vergehen", "to pass", "περνάει ο χρόνος"],
        ["fehlen", "to be missing", "λείπω"],
        ["befragen", "to survey", "συμμετέχω σε δημοσκόπηση"],
        ["sich bemühen", "to strive", "προσπαθώ, καταβάλλω προσπάθεια"],
        ["loben", "to praise", "επαινώ"],
        ["bemerken", "to notice", "παρατηρώ, επισημαίνω"],
        ["sich kleiden", "to dress", "ντύνομαι"],
        ["erwähnen", "to mention", "αναφέρω"],
        ["unterscheiden", "to distinguish", "διακρίνω, ξεχωρίζω"],
        ["sich bejahen lassen", "to answer affirmatively", "μπορεί να απαντηθεί με 'ναι'"],
        ["studieren", "to study", "σπουδάζω"],
        ["erleben", "to experience", "βιώνω, ζω"],
        ["sich erholen", "to recover", "ξεκουράζομαι"],
        ["bieten", "to offer", "προσφέρω"],
        ["verlangen", "to demand", "απαιτώ, ζητώ"],
        ["meinen", "to think", "νομίζω"],
        ["zahlen", "to pay", "πληρώνω"],
        ["stehen lassen", "to leave standing", "αφήνω να στέκεται, δεν το κινώ"],
        ["finanzieren", "to finance", "χρηματοδοτώ"],
        ["ersetzen", "to replace", "αντικαθιστώ"],
        ["sich verteilen", "to distribute", "διασκορπίζομαι"],
        ["betreuen", "to supervise", "επιβλέπω, είμαι υπεύθυνος για"],
        ["betreffen", "to affect", "αφορά"],
        ["kriegen", "to get", "παίρνω, αποκτώ"],
        ["beeindrucken", "to impress", "εντυπωσιάζω"],
        ["gelangen", "to reach", "φτάνω κάπου"],
        ["inbegriffen sein", "to be included", "συμπεριλαμβάνεται"],
        ["kritisieren", "to criticize", "κριτικάρω"],
        ["verschenken", "to give away", "χαρίζω, δίνω δωρεάν"],
        ["bilden", "to form", "κοιτάζω"],
        ["erstehen", "to acquire", "αγοράζω, αποκτώ"],
        ["blicken", "to look", "κοιτάζω"],
        ["formen", "to shape", "διαμορφώνω"],
        ["beurteilen", "to judge", "κρίνω"],
        ["überbewerten", "to overestimate", "υπερτιμώ"],
        ["klappen", "to succeed", "πετυχαίνει"],
        ["vereinbaren", "to agree", "συμφωνώ"],
        ["erfahren", "to learn", "πληροφορούμαι, μαθαίνω"],
        ["erwerben", "to acquire", "αποκτώ"],
        ["sich verpflichten + D", "to commit to", "δεσμεύομαι για"],
        ["vermeiden", "to avoid", "αποφεύγω"],
        ["verweisen + G", "to expel", "διώχνω"],
        ["sich befinden", "to be located", "βρίσκομαι"],
        ["begründen", "to justify", "αιτιολογώ"],
        ["rennen", "to run", "τρέχω"],
        ["schreien", "to scream", "φωνάζω"],
        ["berühren", "to touch", "αγγίζω"],
        ["interviewen", "to interview", "παίρνω συνέντευξη"],
        ["träumen", "to dream", "ονειρεύομαι"],
        ["sich bewegen", "to move", "κινούμαι"],
        ["entwickeln", "to develop", "αναπτύσσω"],
        ["beweisen", "to prove", "αποδεικνύω"],
        ["schwitzen", "to sweat", "ιδρώνω"],
        ["verbessern", "to improve", "βελτιώνω"],
        ["steigen", "to increase", "αυξάνομαι"],
        ["erläutern", "to explain", "εξηγώ"],
        ["beinflussen", "to influence", "επηρεάζω"],
        ["beschäftigen", "to employ", "απασχολώ"],
        ["üben", "to practice", "εξασκώ"],
        ["teilen", "to share", "μοιράζομαι"],
        ["vermissen", "to miss", "μου λείπει, αποθυμώ, νοσταλγώ"],
        ["erwarten", "to expect", "προσδοκώ"],
        ["sich verteilen", "to distribute", "διανέμεται"],
        ["sich entwickeln", "to develop", "αναπτύσομαι"],
        ["campen", "to camp", "κατασκηνώνω"],
        ["verpflichten", "to obligate", "υποχρεώνω"],
        ["befahren", "to drive through", "περνώ (οδικώς) από"],
        ["verunreinigen", "to pollute", "ρυπαίνω"],
        ["gefährden", "to endanger", "βάζω σε κίνδυνο"],
        ["haften", "to be liable", "ευθύνομαι (απέναντι στον νόμο)"],
        ["beseitigen", "to eliminate", "εξαλείφω, εξαφανίζω"],
        ["pfeifen", "to whistle", "σφυρίζω"],
        ["verfolgen", "to follow", "παρακολουθώ"],
        ["beachten", "to pay attention", "προσέχω"],
        ["belasten", "to burden", "επιβαρύνω"],
        ["profitieren", "to benefit", "επωφελούμαι"],
        ["behaupten", "to claim", "ισχυρίζομαι"],
        ["sich verbreiten", "to spread", "διαδίδεται"],
        ["beweisen", "to prove", "αποδεικνύω"],
        ["existieren", "to exist", "υπάρχω"],
        ["beinhalten", "to include", "περιλαμβάνει"],
        ["stärken", "to strengthen", "ενισχύω"],
        ["vermitteln", "to convey", "μεταδίδω"],
        ["schulen", "to train", "γυμνάζω, εξασκώ"],
        ["entlehnen", "to borrow", "δανείζομαι"],
        ["bedürfen + G", "to require", "χρειάζομαι"],
        ["beantragen", "to apply for", "υποβάλλω αίτηση για"],
        ["erfordern", "to require", "απαιτώ"],
        ["erfolgen", "to occur", "γίνεται"],
        ["betreuen", "to take care of", "μεριμνώ για, φροντίζω"],
        ["assoziieren", "to associate", "συνδέω"],
        ["erscheinen", "to appear", "εμφανίζομαι"],
        ["erschrecken", "to scare", "τρομάζω"],
        ["begegnen + D", "to encounter", "συναντώ"],
        ["bewundern", "to admire", "θαυμάζω"],
        ["charten", "to charter", "ναυλώνω"],
        ["verderben", "to spoil", "χαλάω, καταστρέφω"],
        ["begeistern", "to inspire", "ενθουσιάζω"],
        ["bedienen", "to serve", "εξυπηρετώ"],
        ["sich verhalten", "to behave", "συμπεριφέρομαι"],
        ["unterlassen", "to omit", "παραλείπω, δεν το κάνω"],
        ["fliehen", "to flee", "δραπετεύω"],
        ["schaffen", "to accomplish", "καταφέρνω"],
        ["verursachen", "to cause", "προκαλώ, προξενώ"],
        ["schaden + D", "to harm", "βλάπτω"],
        ["stricken", "to knit", "πλέκω"],
        ["reduzieren", "to reduce", "μειώνω"],
        ["recyceln", "to recycle", "ανακυκλώνω"],
        ["ersticken", "to suffocate", "πνίγομαι, πεθαίνω από ασφυξία"],
        ["verbannen", "to banish", "αποβάλλω, διώχνω"],
        ["sich verpflichten", "to commit", "υποχρεούμαι, δεσμεύομαι"],
        ["betragen", "to amount to", "ανέρχεται σε"],
        ["beschließen", "to decide", "αποφασίζω"],
        ["verschönern", "to beautify", "ομορφαίνω, καλλωπίζω"],
        ["starten", "to start", "ξεκινώ"],
        ["überzeugt sein", "to be convinced", "είμαι πεπεισμένος, σίγουρος"],
        ["verunsichert sein", "to be insecure", "αισθάνομαι ανασφαλής, αβεβαιότητα"],
        ["motivieren", "to motivate", "παρακινώ"],
        ["sich eignen", "to be suitable", "ενδείκνυται"],
        ["verändern", "to change", "αλλάζω"],
        ["verschlechtern", "to worsen", "επιδεινώνω, χειροτερεύω"],
        ["verlernen", "to unlearn", "ξεμαθαίνω"],
        ["erforschen", "to research", "διαμορφώνω"],
        ["beeinflussen", "to influence", "επηρεάζω"],
        ["entrichten", "to pay", "καταβάλλω, πληρώνω"],
        ["begrenzen", "to limit", "περιορίζω"],
        ["berechnen", "to calculate", "υπολογίζω"],
        ["übergeben", "to hand over", "παραδίδω"],
        ["senden", "to send", "στέλνω"],
        ["leiden", "to suffer", "υποφέρω"],
        ["bitten", "to ask", "παρακαλώ"],
        ["melken", "to milk", "αρμέγω"],
        ["besetzen", "to occupy", "καταλαμβάνω"],
        ["dokumentieren", "to document", "τεκμηριώνω, καταγράφω"],
        ["kontakt knüpfen", "to establish contacts", "συνάπτω σχέσεις"],
        ["kommentieren", "to comment", "σχολιάζω"],
        ["checken", "to check", "ελέγχω, τσεκάρω"],
        ["klingen", "to sound", "ηχώ, ακούγομαι"],
        ["sich verkleiden", "to disguise", "μεταμφιέζομαι"],
        ["sich schleichen", "to sneak", "περπατώ, γλιστρώ αθόρυβα"],
        ["warnen", "to warn", "προειδοποιώ"],
        ["erschrecken + A", "to scare someone", "τρομάζω κάποιον"],
        ["entwerfen", "to design", "σχεδιάζω"],
        ["vermuten", "to suspect", "υποθέτω"],
        ["kommentieren", "to comment", "σχολιάζω"],
        ["führen zu + D", "to lead to", "οδηγώ σε"],
        ["strukturieren", "to structure", "διαρθρώνω, δομώ"],
        ["bezeichnen", "to describe", "περιγράφω, χαρακτηρίζω"],
        ["erfassen", "to capture", "κατανοώ"],
        ["enthalten", "to contain", "περιέχει"],
        ["verschicken", "to send", "αποστέλλω"],
        ["stöbern", "to browse", "ψαχουλεύω"],
        ["entnehmen", "to take from", "αντλώ πληροφορίες"],
        ["ergeben", "to result in", "αποδίδω, σχηματίζω"],
        ["sich benehmen", "to behave", "συμπεριφέρομαι"],
        ["entsprechen", "to correspond", "αντιστοιχώ"],
        ["verschweigen", "to conceal", "αποσιωπώ"],
        ["schelten", "to scold", "κατσαδιάζω"],
        ["scheinen", "to seem", "φαίνομαι"],
        ["Seie Erklärung scheint logisch.", "Your explanation seems logical.", "Η εξήγησή σας φαίνεται λογική."],
        ["sich melden", "to get in touch", "δίνω σημάδια ζωής"],
        ["sich verwöhnen lassen", "to let oneself be pampered", "αφήνω να με περιποιηθούν"],
        ["versammeln", "to gather", "συγκεντρώνω"],
        ["kontaktieren", "to contact", "έρχομαι σε επαφή"],
        ["ermöglichen", "to enable", "καθιστώ δυνατό"],
        ["wirken", "to appear", "φαίνομαι, δείχνω"],
        ["erläutern", "to explain", "διευκρινίζω, εξηγώ"],
        ["fliehen", "to flee", "δραπετεύω"],
        ["verfilmen", "to film", "γυρίζω σε ταινία"],
        ["besetzen", "to occupy", "στελεχώνω"],
        ["schmunzeln", "to smirk", "χαμογελώ πίσω από τα μουστάκια μου."],
        ["verlaufen", "to run", "πηγαίνω, εξελίσσομαι"],
        ["benennen", "to name", "ονοματίζω"],
        ["erwähnen", "to mention", "αναφέρω"],
        ["abschließen", "to finish", "περατώνω, ολοκληρώνω, συνάπτω"],
        ["bewerten", "to evaluate", "αξιολογώ, βαθμολογώ"],
        ["verraten", "to reveal", "εκμυστηρεύομαι, φανερώνω"],
        ["sich trauen", "to dare", "τολμώ, παντρεύομαι"],
        ["auszeichnen", "to distinguish", "ξεχωρίζω"],
        ["definieren", "to define", "ορίζω"],
        ["stabilisieren", "to stabilize", "σταθεροποιώ"],
        ["schweigen", "to be silent", "σιωπώ"],
        ["sich eng verbunden fühlen", "to feel closely connected", "νιώθω δεμένος με κάποιον"],
        ["überfordern", "to overwhelm", "έχω υπερβολικές απαιτήσεις"],
        ["etw rächt sich", "something takes revenge", "εκδικείται"],
        ["vernachlässigen", "to neglect", "παραμελώ"],
        ["schätzen", "to appreciate", "εκτιμώ"],
        ["motivieren", "to motivate", "δίνω κίνητρο"],
        ["rutschen", "to slip", "γλιστρώ"],
        ["kassieren", "to collect", "εισπράττω"],
        ["spenden", "to donate", "δωρίζω"],
        ["retten", "to save", "σώζω"],
        ["verhindern", "to prevent", "αποτρέπω"],
        ["gähnen", "to yawn", "χασμουριέμαι"],
        ["klären", "to clarify", "ξεκαθαρίζω"],
        ["säen", "to sow", "σπέρνω"],
        ["sich wehren", "to defend oneself", "αμύνομαι"],
        ["hinüber gehen", "to cross over", "περνώ απέναντι"],
        ["kehren", "to sweep", "σκουπίζω"],
        ["beeinflussen", "to influence", "επηρεάζω"],
        ["hin- und hergerissen sein", "to be torn", "ταλαντεύομαι, δεν μπορώ να αποφασίσω"],
        ["tätig sein", "to work", "εργάζομαι"],
        ["erarbeiten", "to develop", "εκπονώ"],
        ["tätig sein als + N", "to work as", "δραστηριοποιούμαι ως, εργάζομαι ως"],
        ["renovieren", "to renovate", "ανακαινίζω"],
        ["mobil sein", "to be mobile", "είμαι πρόθυμος να μετακινούμαι"],
        ["forschen", "to research", "ερευνώ"],
        ["behandeln", "to treat", "νοσηλεύω, κουράρω"],
        ["interagieren", "to interact", "αλληλεπιδρώ"],
        ["empfinden", "to feel", "θεωρώ"],
        ["ermöglichen", "to enable", "καθιστώ δυνατόν"],
        ["beeinflussen", "to influence", "επηρεάζω"],
        ["vereinen", "to unite", "ενώνω"],
        ["besitzen", "to own", "έχω στην κατοχή μου"],
        ["sich einloggen", "to log in", "συνδέομαι, κάνω log in"],
        ["sich verfangen", "to get caught", "πιάνομαι στα δίχτυα, εγκλωβίζομαι"],
        ["bevorzugen", "to prefer", "προτιμώ"],
        ["sich vernetzen mit + D", "to network with", "συνδέομαι με"],
        ["verschwinden", "to disappear", "εξαφανίζομαι"],
        ["erläutern", "to explain", "εξηγώ"],
        ["recherchieren", "to research", "ερευνώ, συλλέγω πληροφορίες"],
        ["verhandeln", "to negotiate", "διαπραγματεύομαι"],
        ["verteilen", "to distribute", "μοιράζω"],
        ["unterstützen", "to support", "υποστηρίζω"],
        ["verfassen", "to write", "συντάσσω"],
        ["hinterlassen", "to leave behind", "αφήνω (π.χ.¨ εντύπωση)"],
        ["überfliegen", "to skim", "ρίχνω μια γρήγορη ματιά"],
        ["erfüllen", "to fulfill", "έχω, εκπληρώνω"],
        ["sich lümmeln", "to lounge", "αράζω"],
        ["zapfen", "to tap", "αντλώ"],
        ["agieren", "to act", "δρω"],
        ["sich verdreifachen", "to triple", "τριπλασιάζομαι"],
        ["toben", "to romp", "ξεσαλώνω, παίζω ανέμελα"],
        ["hocken", "to crouch", "κάθομαι όλη την ημέρα"],
        ["hänseln", "to tease", "κοροϊδεύω, περιγελώ"],
        ["prägen", "to shape", "διαμορφώνω"],
        ["sich schämen", "to be ashamed", "ντρέπομαι"],
        ["nummerieren", "to number", "αριθμώ, βγάζω αύξοντα αριθμό"],
        ["naschen", "to snack", "τρώω γλυκά, τρώω κρυφά"],
        ["sich bedienen", "to help oneself", "σερβίρομαι"],
        ["entwenden", "to steal", "κλέβω, αφαιρώ παράνομα."],
        ["regeln", "to regulate", "ρυθμίζω"],
        ["erwähnen", "to mention", "αναφέρω"],
        ["verarbeiten", "to process", "χρησιμοποιώ για την παραγωγή προϊόντων"],
        ["investieren", "to invest", "επενδύω"],
        ["veranstalten", "to organize", "διοργανώνω"],
        ["prämieren", "to award", "βραβεύω"],
        ["benachrichtigen", "to notify", "ειδοποιώ"],
        ["sich verspannen", "to tense up", "σφίγγομαι, πιάνω"],
        ["berühren", "to touch", "ακουμπώ, αγγίζω"],
        ["sich verringern", "to decrease", "μειώνεται"],
        ["kombinieren", "to combine", "συνδυάζω"],
        ["streicheln", "to pet", "χαϊδεύω"],
        ["schaukeln", "to rock", "κουνάω"],
        ["fördern", "to promote", "ενισχύω, υποστηρίζω"],
        ["ablösen", "to replace", "αντικαθιστώ"],
        ["erzielen", "to achieve", "επιτυγχάνω"],
        ["verschlafen", "to oversleep", "παρακοιμάμαι"],
        ["sich verspäten", "to be late", "αργώ, καθυστερώ"],
        ["sich verfahren", "to get lost", "χάνομαι οδηγώντας"],
        ["klopfen", "to knock", "χτυπάω"],
        ["verzeihen", "to forgive", "συγχωρώ"],
        ["schildern", "to describe", "περιγράφω"],
        ["erfordern", "to require", "απαιτώ"],
        ["staunen", "to marvel", "νιώθω έκπληξη"],
        ["raufen", "to tussle", "παλεύω"],
        ["vermischen", "to mix", "ανακατώνω"]

    ];

    return (
        <div>
            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="List of regular verbs" />
                <Header2 props="Liste mit Schwache Verben" />
            </Box>


            <Box className="global-box-not-header">

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}></TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Germany} alt="Flag of Germany" /> </TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_English} alt="Flag of England" /> </TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#c0c0c0' }}> <img src={Flag_Hellas} alt="Flag of Hellas" /> </TableCell>
                        </TableRow>

                        {verbs.map((verb, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>{index + 1}</TableCell>
                                <TableCell sx={{ color: 'green', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>{verb[0]}</TableCell>
                                <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>{verb[1]}</TableCell>
                                <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>{verb[2]}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </TableContainer>
            </Box>

            <ScrollToTopButton />
        </div>
    );
}

export default Z0035_Verbs_ListWithRegularVerbs;
