import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';


function Z0085_Articles_solcher() {
  return (
    <div>

        <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
            <Header1 props="such" />
            <Header2 props="solcher, solche, solches, solche" />
        </Box>


        <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell colspan={3} sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Singular</TableCell>
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Plural</TableCell>                
                </TableRow>

                <TableRow>             
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">er</span> Mann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">e</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">es</span> Kind</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">e</span> Leute</TableCell>                    
                </TableRow>

                <TableRow>             
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">en</span> Mann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">e</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">es</span> Kind</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">e</span> Leute</TableCell>                    
                </TableRow>

                <TableRow>             
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">em</span> Mann</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">er</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">em</span> Kind</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">en</span> Leute<span className="green-text">n</span></TableCell>                    
                </TableRow>

                <TableRow>             
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">es</span> Mann<span className="green-text">es</span></TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">er</span> Frau</TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">es</span> Kind<span className="green-text">es</span></TableCell>
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>solch<span className="green-text">er</span> Leute</TableCell>                    
                </TableRow>

            </Table>
        </TableContainer>


        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
            Examples:
        </Typography>

        <TableContainer component={Paper} sx={{marginTop:'1em'}} elevation={7}>
            <Table>             

                <TableRow> 
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich habe <span className="green-text">solchen</span> Hunger. (der Hunger)</TableCell>                
                </TableRow>

                <TableRow>                     
                    
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I'm so hungry. </TableCell>
                </TableRow>            
            
            </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{marginTop:'3em'}} elevation={7}>
            <Table>             

                <TableRow> 
                    <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Ich kann mit <span className="green-text">solchen</span> Schuhe<span className="green-text">n</span> nicht fahren. (der Schuh, die Schuhe)</TableCell>                
                </TableRow>

                <TableRow>                     
                    
                    <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>I'm so hungry. </TableCell>
                </TableRow>            
            
            </Table>
        </TableContainer>

    </div>
  )
}

export default Z0085_Articles_solcher