import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0151_SentenceConnection_dass() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Subordinate sentences with 'dass'" />
                <Header2 props="Nebensatz mit 'dass' " />
            </Box>

            <Box className="global-box-not-header">
                <Header3_Middle props="Syntax : dass + subject + ... + verb (at the end conjugated)" />
                <Header2 props="" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The word "<span className="green-text">dass</span>" imports Subordinate sentence (Nebensatz).<br />
                    After the word "dass", follows the <span className="green-text">subject</span> of the subordinate sentence and at the end the <span className="green-text">verb conjugated</span>.
                </Typography>


                <TableContainer component={Paper} sx={{ marginTop: '3em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main clause (Hauptsatz)</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subordinate clause (Nebensatz)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich hoffe,</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">dass</span> <u>ich</u> gute Noten im Zeugnis <u>habe</u>.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colspan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I hope, that i will have good grades in the test.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>



                <TableContainer component={Paper} sx={{ marginTop: '5em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Main clause (Hauptsatz)</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Subordinate clause (Nebensatz)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Ich glaube,</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">dass</span> <u>Maria</u> geheiratet <u>hat</u>.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colspan={2} sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>I believe, that Maria got married.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />



        </div>
    )
}

export default Z0151_SentenceConnection_dass