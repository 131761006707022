import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';


function Z0110_OtherPronounsAlles() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Other pronouns" />
                <Header1 props="alles, beides, viel(es), wenig(es), einiges" />
                <Header2 props="" />
            </Box>


            <Box className="global-box-not-header">

                <Header3_Middle props="2. alles, beides, vieles, weniges, einiges" />
                <Header2 props="" />


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    These words are pronouns, which means that after them doesn't follows a noun.<br /><br />

                    1. <span className="green-text">alles</span> (all)<br />
                    3. <span className="green-text">beides</span> (both)<br />
                    4. <span className="green-text">viel(es)</span> (many)<br />
                    5. <span className="green-text">wenige(es)</span> (a few)<br />
                    7. <span className="green-text">einiges</span> (a little)<br />
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>
                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }} colSpan={4}>Singular</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Wenn sie bei ihrem Freund ist, findet sie <span className="green-text">alles</span> schön.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>When she's with her boyfriend, she finds <span className="green-text">everything</span> beautiful.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Es gibt <span className="green-text">viel(es)</span>, was ich nicht verstehe.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>There are <span className="green-text">a lot</span> that I don't understand.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Sie war auch nur mit <span className="green-text">wenig</span>zufrieden.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>She was also with <span className="green-text">little</span> satisfied.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Hier stimmt <span className="green-text">einiges</span> nicht.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}><span className="green-text">Some</span> things are wrong here.</TableCell>
                        </TableRow>



                    </Table>
                </TableContainer>


            </Box>


            <ScrollToTopButton />

        </div>
    )
}

export default Z0110_OtherPronounsAlles