import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0105_Pronouns_Indefinite_man() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box sx={{ backgroundColor: '#eeeeee', borderRadius: 8 }}>
                <Header1 props="Indefinite Pronoun: 'man' " />
                <Header2 props="Indefinitpromomen: 'man' " />
            </Box>


            <Box className="global-box-not-header">
                <Header3_Middle props="1. man" />
                <Header2 props="one, you, we, they, people" />



                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '7em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    The indefinite pronouns refer to persons, things, and concepts that are not precisely defined.<br /><br />

                    The indefinite pronoun <span className="green-text">man</span> occurs only in the <span className="green-text">nominative</span> case. It is used with a verb in the third-person singular.<br />
                    If the accusative or dative case is called for, the form <span className="green-text">einen</span> or <span className="green-text">einem</span> is used, respectively. There is no genitive form of man. The possessive adjective sein is used instead.<br /><br />
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Nominativ</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Akkusativ</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#f8fc92' }}>Dativ</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>man</TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein<span className="green-text">en</span></TableCell>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>ein<span className="green-text">em</span></TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>


                <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                    Examples:
                </Typography>

                <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                    <Table>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>In Österreich spricht <span className="green-text">man</span> Deutsch.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>In Austria they speak Germa </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Man</span> soll seinen Freunden helfen.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>People should help their freunds. </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}><span className="green-text">Man</span> kann nicht alles wissen.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>One can't know everything.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er sieht <span className="green-text">einen</span> nicht, wenn er sich beellt.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He doesn't see you when he's in a hurry.</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>Er redet nicht mit <span className="green-text">einem</span>.</TableCell>
                            <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>He doesn't talk to people.</TableCell>
                        </TableRow>

                    </Table>
                </TableContainer>

            </Box>

            <ScrollToTopButton />


        </div>
    )
}

export default Z0105_Pronouns_Indefinite_man