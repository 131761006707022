import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z206_Prepositions_TimeDefinitions() {
  return (
    <div>
         

        <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box className="global-box-header">
            <Header1 props="Time Definitions without prepositions" />
            <Header2 props="Zeit Definitionen ohne Präpositionen" />
        </Box>


        <Box className="global-box-not-header" >

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Time Definitions with the words:<br/><br/>

                1. <spam class="red-text">jeder</spam> <spam class="green-text">(every)</spam>.<br/>

                2. <spam class="red-text">letzter</spam> <spam class="green-text">(last)</spam>.<br/>
                3. <spam class="red-text">vorletzter</spam> <spam class="green-text">(before last)</spam>.<br/>

                4. <spam class="red-text">voriger</spam> <spam class="green-text">(previous)</spam>.<br/>

                5. <spam class="red-text">nächster</spam> <spam class="green-text">(next)</spam>.<br/>
                6. <spam class="red-text">übernächster</spam> <spam class="green-text">(after next)</spam>.<br/>

                7. <spam class="red-text">dieser</spam> <spam class="green-text">(that)</spam>.<br/>
                8. <spam class="red-text">weniger</spam> <spam class="green-text">(a few)</spam>.<br/><br/>


                are used in <spam class="green-text">accusative without article</spam>.
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>jed<spam class="green-text">en</spam> Tag</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>every day</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because: der Tag (like: netten Mann) </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>vorig<spam class="green-text">es</spam> Jahr</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>previous year</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because: das Jahr (like: nettes Kind)</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>letzt<spam class="green-text">e</spam> Woche</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>last week</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because: die Woche (like: nette Frau)</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>nächst<spam class="green-text">en</spam> Sonntag</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>next Sunday</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because: der Sonntag </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>dies<spam class="green-text">en</spam> Monat</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>that month</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because: der Montag </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>wenig<spam class="green-text">e</spam> Studen</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>a few hours</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because it is plural die Stunde, die Stunden</TableCell>
                    </TableRow>                    

                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                With article are used expressions with the word <spam class="red-text">ganz</spam> <spam class="green-text">(all)</spam>.
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
                <Table>

                    <TableRow>
                        <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>den ganz<spam class="green-text">en</spam> Tag</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>all day</TableCell>
                        <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>because: der Tag (like: netten Mann) </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>

        </Box>

        <ScrollToTopButton />

    </div>
  )
}

export default Z206_Prepositions_TimeDefinitions
