import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';

import { Typography, IconButton, Box } from '@mui/material';
import { Table, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { TableHead, TableBody } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

import Flag_Germany from '../images/flag_Germany.jpg';
import Flag_English from '../images/flag_English.jpg';
import Flag_Hellas from '../images/flag_Hellas.jpg';


function Z204_Prepositions_Cardinal() {
  return (
    <div>

      <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
        <Box className="global-box-header">
          <Header1 props="Prepositions for the Cardinal Directions " />
          <Header2 props="Präpositionen für die Himmelsrichtungen" />
      </Box>



      <Box className="global-box-not-header" >

        <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '3em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
          With the cardinal points we use:<br/><br/>

          1. <spam class="red-text">in + Akkusativ</spam> : When the verb shows movement or change of place.<br/>
          2. <spam class="red-text">in + Dativ</spam> : When the verb shows stasis.
        </Typography>       


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                      Die Zugvögel <u>fliegen</u> im Winter <spam class="green-text">in den Süden</spam>.
                    </TableCell>
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                      The migratory bird fly at the winter <spam class="green-text">to the north</spam>.
                    </TableCell>
                </TableRow>                      

            </Table>
        </TableContainer>


        <TableContainer component={Paper} sx={{ marginTop: '1em' }} elevation={7}>
            <Table>

                <TableRow>
                    <TableCell sx={{ color: 'red', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#ffebee' }}>
                      Finnland <u>liegt</u> <spam class="green-text">im Norden</spam> von Europa.
                    </TableCell>
                </TableRow>

                <TableRow>                            
                    <TableCell sx={{ color: 'blue', textAlign: 'center', fontWeight: 'bold', backgroundColor: '#e3f2fd' }}>
                      Finland is <spam class="green-text">in the North</spam> of Europe.
                    </TableCell>
                </TableRow>                      

            </Table>
        </TableContainer>



      </Box>


      <ScrollToTopButton />


    </div>
  )
}

export default Z204_Prepositions_Cardinal