import React from 'react';
import { GridToolbarExport,GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box } from '@mui/material';


function Mui6_DataGrid_Toolbar() {

  return (

    <Box display="flex" gap={10} m={5}>
        {/* <GridToolbarExport />
        <GridToolbarFilterButton /> */}
        <GridToolbarQuickFilter />
    </Box>
    
  )
}

export default Mui6_DataGrid_Toolbar