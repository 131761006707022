import React from 'react';
import {Typography} from "@mui/material";

function Header3_Middle({props}) {
  return (
    <div>
        <Typography variant="h4" color="primary" 
                    sx={{textAlign:"center", marginBottom:"0.5em", marginTop:"0em", paddingTop:"5px"}}>
          {props}
        </Typography>        
    </div>
  )
}

export default Header3_Middle