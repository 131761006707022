import React from 'react';
import { Link } from 'react-router-dom';
import Header1 from '../components/Header1';
import Header2 from '../components/Header2';
import { Typography, IconButton, Grid, Button, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import Header3_Middle from '../components/Header3_Middle';
import ScrollToTopButton from '../components/ScrollToTopButton';

function Z0014_Verbs_Inseparable() {
    return (
        <div>

            <IconButton component={Link} to="/">  <HomeIcon />  </IconButton>
            <Box className="global-box-header">
                <Header1 props="Inseparable Prefix verbs" />
                <Header2 props="Untrennbare Verben" />
            </Box>

            <Box className="global-box-not-header" >
            <Header3_Middle props="1. Inseparable Prefix verbs" />

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Verbs beginning with the prefixes <span className="green-text">be-, emp-, ent-, er-, ge-, ver-, zer-, miss-</span> are called <span className="green-text">inseparable prefix verbs</span>,
                because these prefixes are never separated from the verb stem. <br />
                The inseparable prefixes do not have an independent meaning by themselves, and they cannot stand alone.<br />
                They do, however, change the meaning of the stem verb to which they are prefixed. <br />
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '1em' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>bekommen</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ich <span className="green-text">be</span>komme</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>du <span className="green-text">be</span>kommst</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>er, sie, es <span className="green-text">be</span>kommt</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wir <span className="green-text">be</span>kommen</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ihr <span className="green-text">be</span>kommt</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>sie, Sie <span className="green-text">be</span>kommen</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Inseparable prefix verbs can be either regular or irregular, following the same conjugational patterns of the verb stem,
                except that they do <span className="green-text">not</span> take the characteristic <span className="green-text">ge-</span> prefix in the past participle.<br />
                Therefore, those verbs beginning with the inseparable prefix <span className="green-text">ge-</span> have the same past participle form as the infinitive from which they are derived.
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>infinitive</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Simple Past</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Past Participle</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>fallen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to fall </TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>fiel</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ist <span className="green-text">ge</span>fallen</TableCell>
                        </TableRow>
                    </TableBody>

                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>gefallen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to please</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>gefiel</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>hat <span className="green-text">ge</span>fallen</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <TableContainer component={Paper} sx={{ marginTop: '5em' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>infinitive</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}></TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Simple Past</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#f8fc92'}}>Past Participle</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>langen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to be sufficient, suffice</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>langte</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}><span className="green-text">ge</span>langt</TableCell>
                        </TableRow>
                    </TableBody>

                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>gelangen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to reach</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>gelangte</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}><span className="green-text">ge</span>langt</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>



            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '5em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                In spoken German, the inseparable prefix does not receive any stress. The primary stress is given to the first syllable of the verb stem.<br />
                Only the prefix <span className="green-text">zer-</span> has a predictable meaning. It denotes destruction or reduction to small parts or components: <span className="green-text"> drucken</span> to squeeze,
                <span className="green-text"> zerdrucken</span> to squash.<br />
                The prefix <span className="green-text">be-</span> makes a verb transitive. (The verb can be followed by a direct object and is conjugated with haben.) <br />
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '5em' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er ist gekommen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>He has arrived </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>Er hat Geld bekommen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>He has received money</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />






            <Box className="global-box-not-header" >
            <Header3_Middle props="2. Separable Prefix verbs" />

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Another group of verbs is known as <span className="green-text">separable prefix verbs</span>, because the prefix is separated from the verb stem under certain conditions,
                which will be explained below. <br />
                Many separable prefixes are prepositions <span className="green-text">(an, auf, nach)</span> or adverbs <span className="green-text">(zurück, heim, vorbei)</span>. Others are verbs <span className="green-text">
                (kennen, spazieren, stehen)</span>. <br />
                Occasionally, adjectives <span className="green-text">(frei, kalt, wach)</span> and nouns <span className="green-text">(rad, Schlittschuh)</span> also function as separable prefixes. <br />
                The separable prefixes have definite meanings, very often denoting direction.<br /><br />

                Like inseparable prefix verbs, separable prefix verbs can be either strong or weak, following the same conjugational patterns of the verb stems. <br />
                However, unlike inseparable prefix verbs, they take the past participle prefix <span className="green-text">ge-</span> in addition to the separable prefix.<br /><br />

                Unlike inseparable prefix verbs, separable prefix verbs always have their main stress on the prefix.<br /><br />

                Study the following list of common separable prefixes and their basic meanings. <br />
                Note that it is also possible for the added prefix to change the meaning of the stem verb in very subtle ways.<br />
            </Typography>

            <TableContainer component={Paper} sx={{ marginTop: '5em' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ab</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>off, down</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>abfliegen (to take off)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>abschreiben (to copy down)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>an</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>at, on</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>anschauen (to look at)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>anziehen (to put on)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>auf</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>up, open</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aufstehen (to get up)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aufmachen (to open up)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aus</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>out</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ausbrechen (to break out)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ausbrennen (to burn out)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ein</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>intro, in</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>eintretten (to step into)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>einsteiges (to get into)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>fort</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>away</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>fortgehen (to go away)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>fortbleiben (to stay away)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>heim</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>toward the speaker, hither</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>hersehen (to look forward)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>herkommen (to come toward)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>hin</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}></TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>hergehen (to go there)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>hinwerfen (to throw there)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mit</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>with, along</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mitfahren (to ride along)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>mitlachen (to laugh along)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nach</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>after</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nachschauen (to look after)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nachkommen (to come after)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nieder</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>down</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>niederlegen (to lie down)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>niedersetsen (to sit down, set down)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>vor</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>before</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>vorsetzen (to set before)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>vorlegen (to put before)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>weg</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>away</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>weglaufen (to run away)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>wegnehmen (to take away)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zu</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>to, close</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zuhören (to listen to)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zumachen (to close)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zurück</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>back</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zurücknehmen (to listen to)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zurück (to close)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zusammen</TableCell>
                            <TableCell sx={{color:'blue', textAlign:'center', fontWeight:'bold', backgroundColor:'#e3f2fd'}}>together</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zusammenkommen (to come together)</TableCell>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zusammennähen (to switch together)</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant='body1' color="primary" sx={{ fontWeight: 'bold', marginTop: '1em' }} fontSize={{ xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }}>
                Not all German prefixes can be translated into idiomatic English by using these equivalents.
            </Typography>

            <TableContainer sx={{ marginTop: '2em' }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>ausbessern (to repair, mend)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>aussehen (to look like)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>nachmachen (to initiate)</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell sx={{color:'red', textAlign:'center', fontWeight:'bold', backgroundColor:'#ffebee'}}>zussammenlegen (to fold)</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>

            <ScrollToTopButton />


        </div>
    )
}

export default Z0014_Verbs_Inseparable